import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class ProductDetailModel extends GenericDataModel {
    name: String;
    categories: Array<String>;
    image: String;
    description: String;
    ingredients: String;
    skuCode: String;
    isAvailable: Boolean;
    isHotProduct: Boolean;
    cashback: Number;
    weight: Number;
    price: any;
    isVisible: Boolean;

    constructor(productDetailModel: ProductDetailModel) {
        super()
        Object.assign(this, productDetailModel)
    }
}

const PriceObj = {
    BRONZE: { price: 0 },
    PLATINUM: { price: 0 },
    PREMIUM_1: { price: 0 },
    PREMIUM_2: { price: 0 },
    END_CUSTOMER: { price: 0 },
    DISTRIBUTION_CHANNEL_1: { price: 0 },
    DISTRIBUTION_CHANNEL_2: { price: 0 },
    DISTRIBUTION_CHANNEL_3: { price: 0 },
}

const PointObj = {
    BRONZE: { point: 0 },
    PLATINUM: { point: 0 },
    PREMIUM_1: { point: 0 },
    PREMIUM_2: { point: 0 },
    END_CUSTOMER: { point: 0 },
    DISTRIBUTION_CHANNEL_1: { point: 0 },
    DISTRIBUTION_CHANNEL_2: { point: 0 },
    DISTRIBUTION_CHANNEL_3: { price: 0 },
}

const AdditionalMargin = {
    BRONZE: { additionalMargin: 0 },
    PLATINUM: { additionalMargin: 0 },
    PREMIUM_1: { additionalMargin: 0 },
    PREMIUM_2: { additionalMargin: 0 },
    END_CUSTOMER: { additionalMargin: 0 },
    DISTRIBUTION_CHANNEL_1: { additionalMargin: 0 },
    DISTRIBUTION_CHANNEL_2: { additionalMargin: 0 },
    DISTRIBUTION_CHANNEL_3: { additionalMargin: 0 },
}

const PurchaseLimitObj = {
    BRONZE: { purchaseLimit: 0 },
    PLATINUM: { purchaseLimit: 0 },
    PREMIUM_1: { purchaseLimit: 0 },
    PREMIUM_2: { purchaseLimit: 0 },
    END_CUSTOMER: { purchaseLimit: 0 },
    DISTRIBUTION_CHANNEL_1: { purchaseLimit: 0 },
    DISTRIBUTION_CHANNEL_2: { purchaseLimit: 0 },
    DISTRIBUTION_CHANNEL_3: { purchaseLimit: 0 },
}

export const ProductDetailObj = {
    ...GenericDataObj,
    name: null,
    categories: [],
    image: null,
    description: null,
    ingredients: null,
    skuCode: null,
    isAvailable: true,
    isHotProduct: false,
    cashback: 0,
    weight: 0,
    isVisible: true,
    price: {...PriceObj},
    point: {...PointObj},
    purchaseLimit: {...PurchaseLimitObj},
    additionalMargin: {...AdditionalMargin}
}
