import React from "react";
import {Table} from "antd";
import {connect} from "react-redux";
import moment from "moment";
import {doConvertNumberToRupiahFormat, getLevelText} from "../../ui-util/general-variable";
import {formatExportCashbackDcList} from "../../ui-util/dc-cashback.util";
import ExportDcCashbackComponent from "../../components/export-dc-cashback.component";

class DcCashbackListComponent extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Nama Avostore/DC',
                    dataIndex: 'buyerName',
                    key: 'buyerName',
                    width: 170,
                }, {
                    title: 'Level Avostore/DC',
                    dataIndex: 'buyerLevel',
                    key: 'buyerLevel',
                    width: 170,
                    render: (buyerLevel) => getLevelText(buyerLevel)
                }, {
                    title: 'No Pesanan',
                    dataIndex: 'orderId',
                    key: 'orderId',
                    width: 200,
                    render: (orderId, record) => <a href={`/order-detail/${record.idOrder}`} target="_blank"
                                                    style={{color: 'black'}}>{orderId}</a>
                }, {
                    title: 'No Guarantee',
                    dataIndex: 'billingId',
                    key: 'billingId',
                    width: 200,
                }, {
                    title: 'No Pre Order',
                    dataIndex: 'poPaymentId',
                    key: 'poPaymentId',
                    width: 200,
                },  {
                    title: 'Qty Produk',
                    dataIndex: 'qty',
                    key: 'qty',
                    width: 120,
                }, {
                    title: 'Sub Total Produk / Gross Line',
                    dataIndex: 'grossLine',
                    key: 'grossLine',
                    width: 160,
                    render: (grossLine) => doConvertNumberToRupiahFormat(grossLine)
                }, {
                    title: 'Ongkos Kirim',
                    dataIndex: 'shipping',
                    key: 'shipping',
                    width: 150,
                    render: (shipping) => doConvertNumberToRupiahFormat(shipping)
                }, {
                    title: 'Potongan Poin',
                    dataIndex: 'discountPoint',
                    key: 'discountPoint',
                    width: 150,
                    render: (discountPoint) => doConvertNumberToRupiahFormat(discountPoint)
                },{
                    title: 'Diskon Voucher',
                    dataIndex: 'voucher',
                    key: 'voucher',
                    width: 150,
                    render: (voucher) => doConvertNumberToRupiahFormat(voucher)
                }, {
                    title: 'Cashback',
                    dataIndex: 'cashback',
                    key: 'cashback',
                    render: (cashback) => doConvertNumberToRupiahFormat(cashback)
                }, {
                    title: 'Grand Total',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    width: 150,
                    render: (grandTotal) => doConvertNumberToRupiahFormat(grandTotal)
                }, {
                    title: 'Denda',
                    dataIndex: 'fines',
                    key: 'fines',
                    width: 150,
                    render: (fines) => doConvertNumberToRupiahFormat(fines)
                }, {
                    title: 'Total Pembayaran / Cash Paid by User',
                    dataIndex: 'cashPaidByUser',
                    key: 'cashPaidByUser',
                    width: 170,
                    render: (cashPaidByUser) => doConvertNumberToRupiahFormat(cashPaidByUser)
                }, {
                    title: 'Sisa Pembayaran',
                    dataIndex: 'remainingPayment',
                    key: 'remainingPayment',
                    render: (remainingPayment) => doConvertNumberToRupiahFormat(remainingPayment)
                }, {
                    title: 'Net Cash Value',
                    dataIndex: 'netCashValue',
                    key: 'netCashValue',
                    width: 170,
                    render: (netCashValue) => doConvertNumberToRupiahFormat(netCashValue)
                }, {
                    title: 'Harga End Customer / Top Line',
                    dataIndex: 'topLine',
                    key: 'topLine',
                    width: 170,
                    render: (topLine) => doConvertNumberToRupiahFormat(topLine)
                }, {
                    title: 'Top Line Net Cash Value',
                    dataIndex: 'topLineNetCashValue',
                    key: 'topLineNetCashValue',
                    width: 170,
                    render: (topLineNetCashValue) => doConvertNumberToRupiahFormat(topLineNetCashValue)
                }, {
                    title: 'Persentase Potensial Margin',
                    dataIndex: 'potentialMarginPercentage',
                    key: 'potentialMarginPercentage',
                    width: 170,
                    render: (potentialMarginPercentage) => `${potentialMarginPercentage}%`
                }, {
                    title: 'Potensial Cashback',
                    dataIndex: 'potentialCashback',
                    key: 'potentialCashback',
                    width: 170,
                    render: (potentialCashback) => doConvertNumberToRupiahFormat(potentialCashback)
                }, {
                    title: 'Handling Fee',
                    dataIndex: 'handlingFee',
                    key: 'handlingFee',
                    render: (handlingFee) => doConvertNumberToRupiahFormat(handlingFee)
                }, {
                    title: 'Cashback Per Cash Value',
                    dataIndex: 'cashbackPerCashValue',
                    key: 'cashbackPerCashValue',
                    width: 170,
                    render: (cashbackPerCashValue) => doConvertNumberToRupiahFormat(cashbackPerCashValue)
                },
            ]
        }
    }


    render() {
        const {columns} = this.state
        const {dcCashbackReducer} = this.props
        const {cashbackDcList, loading, monthSelected, dcName} = dcCashbackReducer
        const totalCashbackItem = {...dcCashbackReducer, ...{potentialMarginPercentage: `${dcCashbackReducer.potentialMarginPercentage}%`}}
        const monthString = moment(monthSelected).locale('id').format('MMMM')

        return (
            <div>
                <div style={{float: 'right', marginBottom: 20, marginRight: 5}}>
                    <ExportDcCashbackComponent selectedRow={formatExportCashbackDcList(cashbackDcList)}
                                               totalCashbackItem={[totalCashbackItem]}
                                               filename={`Cashback ${dcName} - ${monthString}`}/>
                </div>
                <Table
                    style={{marginTop: 20}}
                    scroll={{x: 1500}}
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={cashbackDcList}
                    rowKey="id"
                    loading={loading}
                    pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {dcCashbackReducer} = state
    return {dcCashbackReducer};
}

export default connect(mapStateToProps, {})(DcCashbackListComponent)
