import React, {PureComponent} from 'react'
import {Button, Image, Modal, Popconfirm, Table} from "antd";

import {CheckCircleOutlined} from "@ant-design/icons";
import {getBannerListApi, updateBannerApi} from "../../api/banner.api";
import BannerFormComponent from "../create-banner/banner-form.component";

class BannerListComponent extends PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {dataIndex: 'isAvailable', key: 'isAvailable', render: (valid) => valid && <CheckCircleOutlined key="check"/>},
                {title: 'Name', dataIndex: 'name', key: 'name'},
                {title: 'Description', dataIndex: 'description', key: 'description'},
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image} width={100} height={100}/>
                },
                {
                    title: 'Action', key: 'edit',
                    render: (record) =>
                        <>
                            <Button style={{marginRight: 10}}
                                    onClick={() => this.setState({modalVisibility: true, bannerDetail: record})}>Edit</Button>
                            <Popconfirm title={"Apakah anda ingin menghapus banner ini?"}
                                        onConfirm={() => this.deleteBannerButtonHandler(record)}
                            >
                                <Button>Delete</Button>
                            </Popconfirm>
                        </>
                }
            ],
            bannerList: [],
            modalVisibility: false,
            bannerDetail: {},
            loadingTable: false,
            loading: false
        }
    }

    componentDidMount() {
        this.getBannerList()
    }

    getBannerList = () => {
        this.setState({loadingTable: true})
        getBannerListApi({isValid: 1})
            .then(result => this.setState({bannerList: result, loadingTable: false}))
            .catch(err => {
                this.setState({loadingTable: false})
                console.log('Error getBannerListApi', err)
            })
    }

    editBanner = (bannerDetail, slug) => {
        updateBannerApi(bannerDetail)
            .then(result => {
                this.setState({modalVisibility: false, loading: false})
                if(slug === 'delete'){
                    alert('Banner berhasil dihapus!')
                } else {
                    alert('Banner berhasil diubah!')
                }
                this.getBannerList()
            })
            .catch(err => {
                this.setState({modalVisibility: false, loading: false})
                if(slug === 'delete'){
                    alert('Banner gagal dihapus!')
                } else {
                    alert('Banner gagal diubah!')
                }
                console.log('Error updateBannerApi', err)
            })
    }

    deleteBannerButtonHandler = (bannerDetail) => {
        bannerDetail.isValid = false
        this.editBanner(bannerDetail, 'delete')
    }

    render() {
        const {bannerList, columns, modalVisibility, bannerDetail, loadingTable, loading} = this.state
        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={bannerList}
                    pagination={false}
                    rowKey={'id'}
                    loading={loadingTable}
                />
                <Modal
                    visible={modalVisibility}
                    title={'Edit Banner'}
                    onCancel={() => {
                        this.setState({modalVisibility: false, bannerDetail: {}})
                    }}
                    footer={null}
                >
                    <BannerFormComponent bannerDetail={bannerDetail} slug={'edit'} submitButtonHandler={this.editBanner} labelCol={8}
                                         wrapperCol={30} width={'100%'} loading={loading} setLoadingTrue={() => this.setState({loading: true})}/>
                </Modal>

            </div>
        )
    }
}

export default BannerListComponent
