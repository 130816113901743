import React from 'react'
import {Button, Card, Dropdown, Input, Menu, Row} from "antd";
import {connect} from "react-redux";
import {productDispatcher} from "../../actions/product.action";
import ProductListComponent from "./product-list.component";
import {PRODUCT_REDUCER, SEARCH_PRODUCT_LIST} from "../../reducers/product.reducer";
import ExportProductComponent from "./export-product.component";
import {formatProductExport} from "../../ui-util/product.util";
import ImportPointProductModal from "./import-product.component";
import {CloudDownloadOutlined} from "@ant-design/icons";
import {Colors} from "../../ui-util/colors";
import {getCategoriesApi} from "../../api/product-category.api";

const {Search} = Input;

class ProductListPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            categoryList: [{
                id: '',
                name: 'Semua Kategori'
            }],
            importPointModalVisibility: false,
            filterList: [
                {
                    key: '',
                    tab: 'Semua Produk'
                }, {
                    key: 'FALSE',
                    tab: 'Produk Disembunyikan'
                }, {
                    key: 'TRUE',
                    tab: 'Produk Ditampilkan'
                }
            ],
            selectedValue: {key: '', tab: 'Semua Produk'},
            selectedCategories: {id: '', name: 'Semua Kategori'}
        }
    }

    componentDidMount() {
        getCategoriesApi()
            .then(result => {
                this.setState({categoryList: [...this.state.categoryList, ...result]})
            })
            .catch(err => {
                alert("Gagal mendapatkan data kategori !")
            })
    }


    componentWillUnmount() {
        this.props.productDispatcher(PRODUCT_REDUCER, {searchKey: '', productSelected: []})
    }

    onSearchProductHandler = (value) => {
        this.props.productDispatcher(SEARCH_PRODUCT_LIST, value)
    }

    onClickHandler = (item) => {
        this.setState({selectedValue: item})
    }

    onSelectCategory = (item) => {
        this.setState({selectedCategories: item})
    }

    dropDownHandler = () => {
        let {filterList} = this.state
        return (
            <Menu>
                {
                    filterList.map((item) => (
                        <Menu.Item key={item.key} onClick={() => this.onClickHandler(item)}>
                            <Button type="link">{item.tab}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    dropDownCategoriesHandler = () => {
        let {categoryList} = this.state
        return (
            <Menu>
                {
                    categoryList.map((item) => (
                        <Menu.Item key={item.id} onClick={() => this.onSelectCategory(item)}>
                            <Button type="link">{item.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    render() {
        const {productSelected} = this.props.productReducer
        const {importPointModalVisibility, selectedValue, selectedCategories} = this.state

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Produk</h3>
                <Row justify={'space-between'} style={{marginBottom: 50}}>
                    <Search
                        placeholder={`Cari produk`}
                        onSearch={value => this.onSearchProductHandler(value)}
                        style={{width: '20%', alignContent: 'center'}}
                    />
                    <Row>
                        <Dropdown overlay={this.dropDownCategoriesHandler()} trigger={['click']}>
                            <Button
                                style={{
                                    backgroundColor: Colors.darkseagreen,
                                    color: 'white',
                                    fontSize: 15,
                                    marginRight: 20
                                }}
                                size="large"
                                theme="filled"
                            >
                                {selectedCategories?.name}
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                            <Button
                                style={{
                                    backgroundColor: Colors.darkseagreen,
                                    color: 'white',
                                    fontSize: 15,
                                    marginRight: 20
                                }}
                                size="large"
                                theme="filled"
                            >
                                {selectedValue.tab}
                            </Button>
                        </Dropdown>
                        <ExportProductComponent selectedRow={formatProductExport(productSelected)}/>
                        <div>
                            <Button style={{borderWidth: 0, backgroundColor: 'transparent'}}>
                                <Row style={{alignItems: 'center', marginLeft: 50}}
                                     onClick={() => this.setState({importPointModalVisibility: true})}>
                                    <CloudDownloadOutlined style={{fontSize: 20, color: Colors.darkseagreen}}/>
                                    <div style={{fontSize: 18, marginLeft: 10, color: Colors.darkseagreen}}>Import</div>
                                </Row>
                            </Button>
                        </div>
                    </Row>
                </Row>
                <ProductListComponent selectedTab={selectedValue} selectedCategories={selectedCategories}/>
                <ImportPointProductModal
                    modalVisibility={importPointModalVisibility}
                    onCancel={() => this.setState({importPointModalVisibility: false})}
                />
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {productReducer} = state

    return {productReducer}
}

export default connect(mapStateToProps, {productDispatcher})(ProductListPage)
