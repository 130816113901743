import React, {Component} from 'react';
import {Button, Card, Table} from "antd";
import {getLevelText} from "../../ui-util/general-variable";
import {EditOutlined} from "@ant-design/icons";
import {getListPreOrderRequirementApi, updatePreOrderRequirementApi} from "../../api/pre-order.api";
import {PREORDER} from "../../models/order-detail.model";
import NewLeadsEditFormComponent from "../new-leads/new-leads-edit-form.component";

class PreOrderRequirementPage extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    title: 'Level',
                    dataIndex: 'level',
                    key: 'level',
                    render: (level) => getLevelText(level)
                },
                {
                    title: 'Minimum Produk',
                    dataIndex: 'minProductCount',
                    key: 'minProductCount',
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => <Button icon={<EditOutlined/>} style={{margin: 5}}
                                                onClick={() => this.onEditHandler(record)}/>
                },
            ],
            listItem: [],
            modalVisible: false,
            selectedItem: {}
        }
    }

    onEditHandler = (itemDetail) => this.setState({modalVisible: true, selectedItem: itemDetail})

    onCancelHandler = () => this.setState({modalVisible: false, selectedItem: {}})

    onFinishHandler = (values) => {
        const {selectedItem} = this.state;
        const dataToUpdate = {
            ...selectedItem,
            minProductCount: values.minProductCount ? Number(values.minProductCount) : selectedItem.minProductCount,
            minValue: values.minValue ? Number(values.minValue) : selectedItem.minValue,
        }

        this.setState({modalVisible: false})
        updatePreOrderRequirementApi(dataToUpdate)
            .then(result => {
                alert("Data berhasil diperbaharui !")
                window.location.reload()
            })
            .catch(err => {
                console.log("error updateNewLeadsRequirementApi data :", err)
                alert("Gagal mengubah data !")
            })
    }


    componentDidMount() {
        getListPreOrderRequirementApi({orderType: PREORDER, level: '', status: ''})
            .then(result => {
                this.setState({listItem: result.orderRequirementList })
            })
            .catch(err => {
                console.log("err getListPreOrderRequirementApi :", err)
                alert("Gagal mendapatkan data !!")
            })
    }


    render() {
        const {columns, listItem, modalVisible} = this.state

        return (
            <Card>
                <h5 style={{marginBottom: 20}}>Pre Order Requirement Setting</h5>
                <Table columns={columns}
                       pagination={false}
                       rowKey="id"
                       dataSource={listItem}
                />
                {
                    modalVisible &&
                    <NewLeadsEditFormComponent onFinish={this.onFinishHandler}
                                               modalVisibilityForm={modalVisible}
                                               onCancelHandler={this.onCancelHandler}
                                               slug={"pre-order"}
                    />
                }
            </Card>
        );
    }
}

export default PreOrderRequirementPage;
