import {GenericDataModel, GenericDataObj} from "./generic-data.model";
import {getDateEpochFormat} from "../ui-util/general-variable";

export default class ManifestDetailModel extends GenericDataModel{
    manifestId: String
    lastUpdate: String
    orderList: Array<any>
    state: String
    createdAt: String
    isDcOrders: Boolean

    constructor(manifestDetailModel: ManifestDetailModel) {
        super();
        Object.assign(this, manifestDetailModel)
    }
}

export const ManifestDetailObj = {
    ...GenericDataObj,
    manifestId: null,
    lastUpdate: null,
    orderList: [],
    state: null,
    isDcOrders: false,
    createdAt: getDateEpochFormat()
}
