import React from 'react';
import {Switch} from 'react-router-dom';
import SignIn from '../pages/signIn';
import Route from './Route';
import Orders from "../pages/orders";
import OrderDetail from "../pages/order-detail";
import Manifest from "../pages/manifest";
import ManifestDetail from "../pages/manifest-detail";
import CreateManifestComponent from "../pages/create-manifest";
import AvostoreList from "../pages/avostore-list";
import AvostoreDetail from "../pages/avostore-detail";
import VoucherPage from "../pages/voucher";
import VoucherDetail from "../pages/voucher-detail";
import NotificationPage from "../pages/notification";
import RevenuePage from "../pages/revenue";
import TargetSalesPage from "../pages/target-sales";
import AnalyticsProductPage from "../pages/analytics-product";
import AnalyticsUser from "../pages/analytics-user";
import TopSpenderPage from "../pages/top-spender";
import AnalyticsGuaranteePage from "../pages/analytics-guarantee";
import ProductListPage from "../pages/product-list";
import CreateProductPage from "../pages/product-create";
import UpdateProductPage from "../pages/product-update";
import DeliveryOrderPage from "../pages/delivery-order";
import PreOrderPage from "../pages/pre-order";
import EditStockProduct from "../pages/product-edit-stock"
import LandingPage from "../pages/landing-page";
import OrderAvostore from "../pages/orders-avostore";
import CreateBannerPage from "../pages/create-banner";
import BannerPage from "../pages/banner";
import RewardPage from "../pages/reward";
import DcOrders from "../pages/orders-dc";
import DcReport from "../pages/dc-report";
import UpdateParentRefPage from "../pages/update-parent-ref";
import SaveOrdersPage from "../pages/orders-ndn";
import DcPaymentPage from "../pages/dc-payment";
import ProductStockInfo from "../pages/product-stock-info";
import ProductEditDc from "../pages/product-edit-dc";
import GuaranteePage from "../pages/guarantee";
import GuaranteeDetailPage from "../pages/guarantee-detail";
import DcCashbackPage from "../pages/dc-cashback";
import TaxInvoicePage from "../pages/tax";
import DisbursementPage from "../pages/disbursement";
import DisbursementDetailPage from "../pages/disbursement-detail";
import PotentialLostPage from "../pages/potential-lost";
import ImportResiPage from "../pages/import-resi";
import VoucherFormPage from "../pages/voucher-form";
import OrderRequirementPage from "../pages/orders-requirement";
import CreateGiftPage from "../pages/gift-form";
import GiftItemList from "../pages/gift-items";
import GiftCardPage from "../pages/gift-cards";
import GiftCardFormPage from "../pages/gift-card-form";
import NewLeadsPage from "../pages/new-leads";
import AuditEmailPage from "../pages/audit-email";
import EmailSettingPage from "../pages/setting-email";
import FaqScreen from "../pages/faq";
import PreOrderRequirementPage from "../pages/pre-order-requirement";
import ProgressiveMargin from "../pages/progressive-margin";
import ProductCategory from "../pages/product-category";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={SignIn}/>

            <Route path="/avostore" component={LandingPage} isPrivate/>

            <Route path="/orders/list" component={Orders} isPrivate/>
            <Route path="/orders/save" component={SaveOrdersPage} isPrivate/>

            <Route path="/dc/orders" component={DcOrders} isPrivate/>
            <Route path="/dc/report" component={DcReport} isPrivate/>
            <Route path="/dc/cashback" component={DcCashbackPage} isPrivate/>

            <Route path="/dc-payment/list" component={DcPaymentPage} isPrivate/>

            <Route path="/orders-avostore" component={OrderAvostore} isPrivate/>
            <Route path="/order-detail/:id" component={OrderDetail} isPrivate/>
            <Route path="/manifest/list" component={Manifest} isPrivate/>
            <Route path="/manifest/create" component={CreateManifestComponent} isPrivate/>
            <Route path="/manifest/resi" component={ImportResiPage} isPrivate/>
            <Route path="/manifest-detail/:id" component={ManifestDetail} isPrivate/>

            <Route path="/avostore-list/list" component={AvostoreList} isPrivate/>
            <Route path="/avostore-list/parent-ref" component={UpdateParentRefPage} isPrivate/>
            <Route path="/avostore-detail/:id" component={AvostoreDetail} isPrivate/>

            <Route path="/voucher/create" component={VoucherFormPage} isPrivate/>
            <Route path="/voucher/list" component={VoucherPage} isPrivate/>
            <Route path="/voucher-detail/:id" component={VoucherDetail} isPrivate/>
            <Route path="/voucher-update-form/:id" component={VoucherFormPage} isPrivate/>

            <Route path="/banner/create" component={CreateBannerPage} isPrivate/>
            <Route path="/banner/list" component={BannerPage} isPrivate/>

            <Route path="/guarantee/list" component={GuaranteePage} isPrivate/>
            <Route path="/guarantee-detail/:type/:id" component={GuaranteeDetailPage} isPrivate/>

            <Route path="/reward/list" component={RewardPage} isPrivate/>

            <Route path="/notification/create" component={NotificationPage} isPrivate/>

            <Route path="/analytic/revenue" component={RevenuePage} isPrivate/>
            <Route path="/analytic/product" component={AnalyticsProductPage} isPrivate/>
            <Route path="/analytic/target-sales" component={TargetSalesPage} isPrivate/>
            <Route path="/analytic/analytics-user" component={AnalyticsUser} isPrivate/>
            <Route path="/analytic/top-spender" component={TopSpenderPage} isPrivate/>
            <Route path="/analytic/guarantee" component={AnalyticsGuaranteePage} isPrivate/>
            <Route path="/analytic/report-dc" component={DcReport} isPrivate/>

            <Route path="/product/create" component={CreateProductPage} isPrivate/>
            <Route path="/product/product-category" component={ProductCategory} isPrivate/>
            <Route path="/product/list" component={ProductListPage} isPrivate/>
            <Route path="/product/update" component={UpdateProductPage} isPrivate/>
            <Route path="/product/edit-stock-product" component={EditStockProduct} isPrivate/>
            <Route path="/product/edit-stock-product-dc" component={ProductEditDc} isPrivate/>
            <Route path="/product/product-stock-info" component={ProductStockInfo} isPrivate/>

            <Route path="/do/list" component={DeliveryOrderPage} isPrivate/>

            <Route path="/po/list" component={PreOrderPage} isPrivate/>
            <Route path="/tax/list" component={TaxInvoicePage} isPrivate/>
            <Route path="/disbursement/list" component={DisbursementPage} isPrivate/>
            <Route path="/disbursement-detail/:id" component={DisbursementDetailPage} isPrivate/>
            <Route path="/potential-lost/list" component={PotentialLostPage} isPrivate/>
            <Route path="/settings/minimum-order" component={OrderRequirementPage} isPrivate/>
            <Route path="/settings/new-leads" component={NewLeadsPage} isPrivate/>
            <Route path="/settings/pre-order" component={PreOrderRequirementPage} isPrivate/>
            <Route path="/settings/faq" component={FaqScreen} isPrivate/>
            <Route path="/settings/progressive-margin" component={ProgressiveMargin} isPrivate/>

            <Route path="/gift/create" component={CreateGiftPage} isPrivate/>
            <Route path="/gift/update/:id" component={CreateGiftPage} isPrivate/>
            <Route path="/gift/list" component={GiftItemList} isPrivate/>
            <Route path="/gift/gift-card" component={GiftCardPage} isPrivate/>
            <Route path="/gift/create-gift-card" component={GiftCardFormPage} isPrivate/>
            <Route path="/gift/update-gift-card/:id" component={GiftCardFormPage} isPrivate/>

            <Route path="/email/email-audit" component={AuditEmailPage} isPrivate/>
            <Route path="/email/email-setting" component={EmailSettingPage} isPrivate/>

            {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
            <Route component={SignIn}/>
        </Switch>
    );
}
