const INITIAL_STATE = {
    modalVisibilityResi: false,
    orderDetailToUpdateResi: {}
}

export const MODAL_REDUCER = 'MODAL_REDUCER'

export const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case MODAL_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
