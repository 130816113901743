import {isProd, stringToBase64} from "../ui-util/general-variable";

const RAJAONGKIR_KEY = "0c3a455e90fcc1c1c3011e86b132d4d3";
const RAJAONGKIR_URL = "https://pro.rajaongkir.com/api";

const AUTHORIZATION_KEY = "QVBQX0FWT1NUT1JFX0FVVEhPUklaQVRJT05fS0VZ";

const MIDTRANS_SERVER_KEY = isProd() ? 'Mid-server-1fUrmD8CLrpKUB3WzxkonhTd' : 'SB-Mid-server-OL4w_Z37tHJI0NICG5W7lc3x';
const MIDTRANS_AUTH_STRING = stringToBase64(`${MIDTRANS_SERVER_KEY}:`);
const MIDTRANS_TRANSACTION_URL = isProd() ? 'https://app.midtrans.com/snap/v1/transactions': 'https://app.sandbox.midtrans.com/snap/v1/transactions'

export{
    RAJAONGKIR_KEY,
    RAJAONGKIR_URL,
    MIDTRANS_AUTH_STRING,
    AUTHORIZATION_KEY,
    MIDTRANS_TRANSACTION_URL
}
