import React, {Component} from 'react';
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";
import ReactExport from "react-export-excel";
import {formatUserAnalyticsToExport} from "../../ui-util/analytics-user-list-util";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportUserAnalyticsListComponent extends Component {
    render() {
        const {userList} = this.props;
        let userListToExport = formatUserAnalyticsToExport(userList)

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={userListToExport} name="Analytics User">
                    <ExcelColumn label="Tanggal" value="joinDate"/>
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="No Hp" value="phoneNumber"/>
                    <ExcelColumn label="NIK" value="identityCardNumber"/>
                    <ExcelColumn label="Alamat" value="address"/>
                    <ExcelColumn label="Avostore Area" value="avostoreArea"/>
                    <ExcelColumn label="Level" value="level"/>
                    <ExcelColumn label="Status" value="status"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default (ExportUserAnalyticsListComponent);
