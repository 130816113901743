import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Dropdown, Menu, Row, Table} from "antd";
import SearchComponent from "../../components/search.component";
import moment from "moment";
import {avoskinId, convertStartEndDate, doConvertNumberToRupiahFormat, isEqual} from "../../ui-util/general-variable";
import {getPotentialLostProductsApi} from "../../api/product.api";
import _ from "lodash";
import {Colors} from "../../ui-util/colors";
import ExportPotentialLostComponent from "./export-potential-lost.component";
import {setPotentialLostDataToExport} from "../../ui-util/table-util";
import {getUserListApi} from "../../api/user.api";
import {DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_2} from "../../models/user-detail.model";

const {RangePicker} = DatePicker;

class PotentialLostPage extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Produk ID',
                    dataIndex: 'productId',
                    key: 'productId',
                    width: 170,
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 170,
                },
                {
                    title: 'Kode SKU',
                    dataIndex: 'skuCode',
                    key: 'skuCode',
                    width: 170,
                },
                {
                    title: 'Total Product',
                    dataIndex: 'potentialLostCount',
                    key: 'potentialLostCount',
                    width: 170,
                },
                {
                    title: 'Potensi Kehilangan Sales',
                    dataIndex: 'potentialLostValue',
                    key: 'potentialLostValue',
                    width: 170,
                    render: (potentialLostValue) => doConvertNumberToRupiahFormat(potentialLostValue)
                },
            ],
            userList: [{name: 'All', id: ''}],
            potentialLostData: [],
            filterStartDate: moment().subtract(30, 'days'),
            filterEndDate: moment(),
            searchKey: '',
            selectedRow: [],
            selectedRowKeys: [],
            loading: false,
            selectedSeller: {
                name: 'All',
                id: ''
            }
        }
    }

    componentDidMount() {
        this.fetchPotentialLostProductFromApi()
        this.fetchUserDcListFromApi()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(this.state.filterStartDate, prevState.filterStartDate) ||
            !isEqual(this.state.filterEndDate, prevState.filterEndDate) ||
            !isEqual(this.state.selectedSeller, prevState.selectedSeller)) {
            this.fetchPotentialLostProductFromApi()
        }
    }

    fetchPotentialLostProductFromApi = () => {
        this.setState({loading: true})
        const {filterStartDate, filterEndDate, selectedSeller} = this.state;
        let body = {
            startDate: convertStartEndDate(filterStartDate, 'start'),
            endDate: convertStartEndDate(filterEndDate),
            sellerId: selectedSeller.id
        }

        getPotentialLostProductsApi(body)
            .then(result => {
                this.setState({potentialLostData: result.data, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log("error get potentialLostData :", err)
                alert("something went wrong  !!")
            })
    }

    fetchUserDcListFromApi = () => {
        this.setState({loading: true})
        getUserListApi({level: [DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_1]})
            .then(result => {
                let avoskinData = [{name: 'Avoskin', id: avoskinId()}]
                let data = [...this.state.userList, ...avoskinData, ...result]
                this.setState({userList: data, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log("error fetchUserDcListFromApi :", err)
            })
    }

    onSearchEmailHandler = (value) => {
        this.setState({searchKey: value})
    }

    dataToDisplay = () => {
        const {potentialLostData, searchKey} = this.state
        let dataToDisplay = []
        if (!!searchKey) {
            dataToDisplay = _.filter(potentialLostData, item => item.productName.toLowerCase().includes(searchKey.toLowerCase()))
        } else {
            dataToDisplay = potentialLostData
        }
        return dataToDisplay
    }

    onDatePickerChange = (dates) => {
        this.setState({filterStartDate: dates[0], filterEndDate: dates[1]})
    }

    dropDownHandler = () => {
        let {userList} = this.state

        return (
            <Menu>
                {
                    userList.map((type, index) => (
                        <Menu.Item key={index} onClick={() => this.dropDownButtonHandler(type)}>
                            <Button type="link">{type.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    dropDownButtonHandler = (value) => {
        let selectedData = {id: value.id, name: value.name}
        this.setState({selectedSeller: selectedData})
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setPotentialLostDataToExport(rows);
        this.setState({selectedRow, selectedRowKeys});
    }

    render() {
        const {
            columns,
            loading,
            filterStartDate,
            filterEndDate,
            selectedSeller,
            selectedRow,
            selectedRowKeys
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Card>
                <h5 style={{marginBottom: 20}}>Potential Lost</h5>
                <Row justify={"space-between"} align="middle">
                    <Col>
                        <Row>
                            Nama Penjual :
                            <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                                <Button
                                    style={{
                                        backgroundColor: 'white',
                                        color: Colors.darkseagreen,
                                        minWidth: '150px',
                                        marginLeft: 20
                                    }}
                                    size="medium"
                                    theme="filled"
                                >
                                    {selectedSeller?.name}
                                </Button>
                            </Dropdown>
                        </Row>
                    </Col>
                    <Col><SearchComponent searchItem={'produk'} onSearchHandler={this.onSearchEmailHandler}/></Col>
                    <Col className="d-flex justify-content-around align-items-center" style={{marginLeft: 10}}>
                        Tgl. Pesanan :
                        <RangePicker
                            allowClear={false}
                            style={{width: '70%', marginLeft: 10}}
                            value={[filterStartDate, filterEndDate]}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                    </Col>
                    <Col type="flex" justify={"end"}>
                        {selectedRow.length > 0 ? <ExportPotentialLostComponent selectedRow={selectedRow}/> :
                            <Button disabled>Export</Button>}
                    </Col>
                </Row>
                <Table scroll={{x: 1500}}
                       columns={columns}
                       loading={loading}
                       rowSelection={rowSelection}
                       dataSource={this.dataToDisplay().map((d, index) => ({key: index, ...d}))}
                       pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                />
            </Card>
        );
    }
}

export default (PotentialLostPage);