import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Col, Divider, Row, Typography} from "antd";
import {Colors} from "../../ui-util/colors";
import {getAnalyticsUserActive, getAnalyticsUserRegister} from "../../api/analytics-user.api";
import moment from "moment";
import {isEqual} from "../../ui-util/general-variable";
import {ANALYTICS_USER} from "../../reducers/analytics-user.reducer";
import {analyticsUserDispatcher} from "../../actions/analytics-user.action";
import _ from 'lodash'

const {Text} = Typography;

class AnalyticsUserHeaderComponent extends Component {
    constructor() {
        super()

        this.state = {
            activeUser: {
                current: 0,
                percentage: "0%",
                prev: 0
            },
            registeredUser: {
                current: 0,
                percentage: "0%",
                prev: 0
            }
        }
    }

    componentDidMount(): void {
        this.fetchAnalyticsUserPercentage()
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if (!isEqual(this.props.analyticsUserReducer, nextProps.analyticsUserReducer)) {
            return true
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(this.props.analyticsUserReducer, prevProps.analyticsUserReducer)) {
            this.fetchAnalyticsUserPercentage()
        }
    }

    fetchAnalyticsUserPercentage = () => {
        const {startDate, endDate} = this.props.analyticsUserReducer
        let reqBody = {
            startDate: moment(startDate).startOf('day').unix(),
            endDate: moment(endDate).endOf('day').unix(),
            startDatePrev: moment(startDate).subtract(1, 'month').startOf('day').unix(),
            endDatePrev: moment(endDate).subtract(1, 'month').endOf('day').unix()
        }
        Promise.all([getAnalyticsUserRegister(reqBody), getAnalyticsUserActive(reqBody)])
            .then(result => {
                this.props.analyticsUserDispatcher(ANALYTICS_USER, {
                    authUser: result[0].data.registeredUser,
                    activeUser: result[1].data.registeredUser
                })
            })
            .catch(err => console.log("error get analytics user:", err))
    }

    render() {
        const {activeUser, authUser} = this.props.analyticsUserReducer
        const isInfinity = _.includes(authUser.percentage, 'Infinity') || _.includes(authUser.percentage, 'NaN')

        return (
            <Col span={12} align={'center'}>
                <Row align={'center'}>
                    <Card style={{
                        minWidth: 500,
                        borderWidth: 1,
                        borderColor: Colors.gainsboro,
                        borderRadius: 7
                    }}>
                        <Row align={'start'}>
                            <div style={{marginRight: 20}}>
                                <Text style={{fontSize: 16}}>User Register</Text>
                                <div align={'center'} style={{marginTop: 10}}>
                                    <h5>{!isInfinity ? authUser.percentage : '0%'}</h5>
                                </div>
                            </div>
                            <Divider style={{height: 75, borderWidth: 1, borderColor: 'black'}} type={"vertical"}/>
                            <div>
                                <div style={{fontSize: 16}}>{`This Period: ${authUser.current} users`}</div>
                                <br/>
                                <div style={{fontSize: 16}}>{`Prev Period: ${authUser.prev} users`}</div>
                            </div>
                        </Row>
                    </Card>
                </Row>
                <Row align={'center'} style={{paddingTop: 40}}>
                    <Card style={{
                        minWidth: 500,
                        borderWidth: 1,
                        borderColor: Colors.gainsboro,
                        borderRadius: 7
                    }}>
                        <Row align={'start'}>
                            <div style={{marginRight: 20}}>
                                <Text style={{fontSize: 16}}>User Active</Text>
                                <div align={'center'} style={{marginTop: 10}}>
                                    <h5>{!isInfinity ? activeUser.percentage : '0%'}</h5>
                                </div>
                            </div>
                            <Divider style={{height: 75, borderWidth: 1, borderColor: 'black'}} type={"vertical"}/>
                            <div>
                                <div style={{fontSize: 16}}>{`This Period: ${activeUser.current} users`}</div>
                                <br/>
                                <div style={{fontSize: 16}}>{`Prev Period: ${activeUser.prev} users`}</div>
                            </div>
                        </Row>
                    </Card>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsUserReducer} = state
    return {analyticsUserReducer};
}

export default connect(mapStateToProps, {analyticsUserDispatcher})(AnalyticsUserHeaderComponent);
