import React, {Component} from 'react';
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportDisbursementListComponent extends Component {
    render() {
        const {selectedRow} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Nama" value="userName"/>
                    <ExcelColumn label="Email" value="userEmail"/>
                    <ExcelColumn label="Tanggal Pengajuan" value="created_at"/>
                    <ExcelColumn label="Nama Pemilik Rekening" value="bankAccountName"/>
                    <ExcelColumn label="Nama Bank" value="bankAccountCode"/>
                    <ExcelColumn label="Nomor Rekening" value="bankAccountNumber"/>
                    <ExcelColumn label="Kode Bank" value="bankAccountCode"/>
                    <ExcelColumn label="Status" value="payoutStatus"/>
                    <ExcelColumn label="Total Disbursement" value="totalDisbursement"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}