import React, {PureComponent} from 'react';
import {Card, Col, Row, Table} from 'antd';
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";
import _ from "lodash";

class ProductList extends PureComponent {
    constructor() {
        super()

        this.state = {
            columns: [
                {title: 'Produk', dataIndex: 'name', key: 'product'},
                {
                    title: 'Harga Normal',
                    key: 'normalPrice',
                    dataIndex: 'normalPrice',
                    render: (normalPrice) => doConvertNumberToRupiahFormat(normalPrice)
                },
                {
                    title: 'Additional Margin',
                    dataIndex: 'subtotalMargin',
                    render: (subtotalMargin, record) => doConvertNumberToRupiahFormat(subtotalMargin/record?.count || 0)
                },
                {
                    title: 'Margin',
                    key: 'margin',
                    dataIndex: 'margin',
                },
                {
                    title: 'Harga Avostore',
                    key: 'avostorePrice',
                    dataIndex: 'avostorePrice',
                    render: (avostorePrice) => doConvertNumberToRupiahFormat(avostorePrice)
                },
                {title: 'Jumlah', dataIndex: 'count', key: 'count'},
                {
                    title: 'Cashback',
                    dataIndex: 'totalCashback',
                    render: (text) => this.props.orderDetail.isUsingCashback ? doConvertNumberToRupiahFormat(text) : 0
                },
                {
                    title: 'Subtotal',
                    dataIndex: 'subtotal',
                    render: (text) => doConvertNumberToRupiahFormat(text)
                },
                {
                    title: 'Subtotal Margin',
                    dataIndex: 'subtotalMargin',
                    render: (subtotalMargin) => doConvertNumberToRupiahFormat(subtotalMargin)
                },
            ]
        }
    }

    render() {
        const {columns} = this.state;
        const {orderDetail, taxDetail} = this.props
        const {products} = orderDetail;

        return (
            <Card style={{marginTop: 30}}>
                <h5>Informasi Pesanan Produk</h5>
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns}
                    dataSource={products}
                    size="middle"
                    summary={pageData => {
                        let totalOrder = 0
                        pageData.forEach(item => {
                            totalOrder += item.count
                        })

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell>
                                        <div>{totalOrder}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell/>
                                </Table.Summary.Row>
                            </>
                        )
                    }}
                />
                <div>
                    <Row style={{paddingTop: 20}}>
                        <Col span={18} className="d-flex flex-column align-items-end">
                            <p>Harga Produk Setelah Pajak</p>
                            <p>Additional Margin Setelah Pajak</p>
                            <p>Progressive Margin</p>
                            <p>Total Cashback</p>
                            <p>Potongan Voucher Produk</p>
                            <p>Diskon Poin</p>
                            <p>Subtotal Sebelum Pajak</p>
                            <p>Total Pajak</p>
                            <p>Ongkos Kirim</p>
                            <p>Potongan Ongkos Kirim</p>
                            <p>Total Potongan Voucher</p>
                        </Col>
                        <Col
                            span={6}
                            className="d-flex flex-column align-items-end"
                            style={{paddingRight: 10}}
                        >
                            <p>
                                {doConvertNumberToRupiahFormat(
                                    _.sumBy(orderDetail.products, 'subtotal')
                                )}
                            </p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.sumSubtotalMargin || 0)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.progressiveMargin)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail.grandCashback)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.voucherReductionComponent?.product || 0)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.discountPoint || 0)}</p>
                            <p>{doConvertNumberToRupiahFormat(taxDetail?.subTotalBeforeTax || 0)}</p>
                            <p>{doConvertNumberToRupiahFormat(taxDetail?.totalTax || 0)}</p>
                            <p>{doConvertNumberToRupiahFormat(orderDetail.shippingPrice)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.voucherReductionComponent?.shipping || 0)}</p>
                            <p>- {doConvertNumberToRupiahFormat(orderDetail?.voucherReduction || 0)}</p>
                        </Col>
                    </Row>
                    {
                        orderDetail?.grandTotalGuarantee > 0 &&
                        <Row>
                            <Col span={18} className="d-flex flex-column align-items-end">
                                <p>Total Down Payment</p>
                                <p>Total Sisa Pelunasan</p>
                            </Col>
                            <Col
                                span={6}
                                className="d-flex flex-column align-items-end"
                                style={{paddingRight: 10}}
                            >
                                <p>{doConvertNumberToRupiahFormat(orderDetail.grandTotalGuarantee)}</p>
                                <p>{doConvertNumberToRupiahFormat(orderDetail.grandTotal - orderDetail.grandTotalGuarantee)}</p>
                            </Col>
                        </Row>
                    }
                    {
                        orderDetail?.grandTotalPreOrder > 0 &&
                        <Row>
                            <Col span={18} className="d-flex flex-column align-items-end">
                                <p>Total Down Payment</p>
                                <p>Total Sisa Pelunasan</p>
                            </Col>
                            <Col
                                span={6}
                                className="d-flex flex-column align-items-end"
                                style={{paddingRight: 10}}
                            >
                                <p>{doConvertNumberToRupiahFormat(orderDetail.grandTotalPreOrder)}</p>
                                <p>{doConvertNumberToRupiahFormat(orderDetail.grandTotalPreOrder)}</p>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col span={18} className="d-flex flex-column align-items-end">
                            <p>Total Pembayaran</p>
                        </Col>
                        <Col
                            span={6}
                            className="d-flex flex-column align-items-end"
                            style={{paddingRight: 10}}
                        >
                            <p>{doConvertNumberToRupiahFormat(orderDetail.grandTotal)}</p>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }
}

export default ProductList
