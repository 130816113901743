import React from "react";
import {Button, Modal, Radio, Row, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {Colors} from "../../ui-util/colors";
import {ExcelRenderer} from "react-excel-renderer";
import {bulkUpdateProductApi} from "../../api/product.api";
import {generateMd5Id, randomNumber} from "../../ui-util/general-variable";

class ImportPointProductModal extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            excelFile: [],
            cols: [],
            rows: [],
            radioButtonValue: 'update'
        }
    }

    beforeUpload = (file) => {
        const {radioButtonValue} = this.state
        const isExcel =
            file.type === "application/vnd.ms-excel" ||
            file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        if (!!isExcel) {
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    console.log(err);
                } else {
                    let newRows = [];
                    let isDataClean = true
                    resp.rows.slice(1).map((row, index) => {
                        if (row && row !== "undefined") {
                            if(!row[1] || !row[2]){
                                isDataClean = false
                            } else {
                                newRows.push({
                                    id: radioButtonValue === 'update' ? row[0] : generateMd5Id(randomNumber(6)),
                                    skuCode: row[1],
                                    name: row[2],
                                    description: row[3],
                                    weight: row[4],
                                    cashback: row[5],
                                    endCustomerPrice: row[6],
                                    bronzePrice: row[7],
                                    platinumPrice: row[8],
                                    premium1Price: row[9],
                                    premium2Price: row[10],
                                    distributionChannel1Price: row[11],
                                    distributionChannel2Price: row[12],
                                    distributionChannel3Price: row[13],
                                    endCustomerPoint: row[14],
                                    bronzePoint: row[15],
                                    platinumPoint: row[16],
                                    premium1Point: row[17],
                                    premium2Point: row[18],
                                    distributionChannel1Point: row[19],
                                    distributionChannel2Point: row[20],
                                    distributionChannel3Point: row[21],
                                    isVisible: row[22],
                                });
                            }
                        }
                    });
                    if (newRows.length === 0) {
                        if(!isDataClean){
                            alert('Nama harus diisi !')
                        } else {
                            alert('Data tidak ditemukan di dalam file')
                        }
                    } else {
                        this.setState({
                            cols: resp.cols,
                            rows: newRows,
                            excelFile: [file]
                        });
                    }
                }
            });
        } else {
            alert('Mohon masukkan file excel')
        }
    }

    dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    handleUpload = () => {
        const {rows, isUpdate, radioButtonValue} = this.state
        const {onCancel} = this.props
        let requestBody = {
            isUpdate: radioButtonValue === 'update',
            products: rows
        }

        bulkUpdateProductApi(requestBody)
            .then(result => {
                if(!!result.message){
                    alert(result.message)
                } else {
                    onCancel()
                    alert(`Product berhasil di ${isUpdate ? 'update' : 'buat'}`)
                }
                window.location.reload()
            })
            .catch(err => {
                alert('Maaf proses import gagal, mohon periksa kembali file yang anda import !')
                console.log('Error bulkUpdateProductApi', err)
            })
    }

    onChangeRadioButton = (e) => {
        this.setState({radioButtonValue: e.target.value})
    }

    render() {
        const {modalVisibility, onCancel} = this.props
        const {excelFile, radioButtonValue} = this.state

        return (
            <Modal
                title={'Import Produk'}
                visible={modalVisibility}
                onCancel={onCancel}
                footer={null}
            >
                <Radio.Group onChange={this.onChangeRadioButton} value={radioButtonValue}>
                    <Radio value={'create'}>Create</Radio>
                    <Radio value={'update'}>Update</Radio>
                </Radio.Group>
                <div style={{marginTop: 10}}>
                    {
                        radioButtonValue === 'update' ?
                            <p>Silahkan masukkan file excel dengan data yang sudah terupdate</p> :
                            <p>Silahkan masukkan file excel dengan data produk baru saja sesuai format file export
                                produk</p>
                    }
                </div>
                <Row justify={"space-between"} style={{marginTop: 20}}>
                    <div style={{width: 180}}>
                        <Upload
                            name="file"
                            fileList={excelFile}
                            customRequest={this.dummyRequest}
                            beforeUpload={this.beforeUpload}
                            onRemove={() => this.setState({excelFile: []})}>
                            <Button icon={<UploadOutlined/>}>Select File</Button>
                        </Upload>
                    </div>
                    <Button
                        disabled={excelFile.length === 0}
                        onClick={this.handleUpload}
                        style={{backgroundColor: Colors.gainsboro}}
                    >
                        Upload
                    </Button>
                </Row>
            </Modal>
        )
    }
}

export default ImportPointProductModal
