import React from "react";
import {Button, Col, Modal, Row, Table, Upload} from "antd";
import {convertStartEndDate, doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";
import {getOrderDetailByOrderIdApi} from "../../api/order.api";
import PreviewPaymentProofModal from "./preview-payment-proof.modal";
import UpdatePaymentDetailModal from "../order-detail/update-payment-detail.modal";
import {COMPLETED} from "../../models/order-detail.model";
import moment from "moment";
import _ from "lodash";
import {updatePaymentTerminGuaranteeApi, uploadTaxInvoiceTerminApi} from "../../api/guarantee.api";
import TaxInvoiceModal from "../../components/tax-invoice.modal";
import {UploadOutlined} from "@ant-design/icons";
import {Colors} from "../../ui-util/colors";
import {sendTaxInvoiceTerminRequestEmailApi} from "../../api/email.api";

class PaymentTerminComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    key: 'label', dataIndex: 'label', title: ''
                },
                {
                    key: 'payAmount', dataIndex: 'payAmount', title: 'Jumlah Pembayaran',
                    render: (payAmount) => doConvertNumberToRupiahFormat(payAmount)
                }, {
                    key: 'fines', dataIndex: 'fines', title: 'denda',
                    render: (payAmount) => doConvertNumberToRupiahFormat(payAmount)
                }, {
                    key: 'totalPayAmount', dataIndex: 'totalPayAmount', title: 'Total Pembayaran',
                    render: (totalPayAmount) => doConvertNumberToRupiahFormat(totalPayAmount)
                }, {
                    key: 'transactionStatus', dataIndex: 'paymentDetail', title: 'Status',
                    render: (paymentDetail) => paymentDetail.transactionStatus
                }, {
                    key: 'transactionTime', dataIndex: 'paymentDetail', title: 'Waktu Transaksi',
                    render: (paymentDetail) => paymentDetail.transactionTime
                }, {
                    key: 'proofOfPayments', dataIndex: 'proofOfPayments', title: 'Bukti Pembayaran',
                    render: (proofOfPayments) => this.previewPaymentProofHandler(proofOfPayments)
                },
                {
                    key: '', title: 'Action',
                    render: (record, item, index) => this.actionButton(record, item, index)
                }
            ],
            paymentTerminList: [],
            paymentProof: [],
            previewImageModalVisilibity: false,
            requestTaxInvoiceModalVisibility: false,
            uploadTaxInvoiceModalVisibility: false,
            isVisible: false,
            taxInvoiceList: [],
            paymentIndex: 0
        }
    }

    componentDidMount() {
        const {guaranteeDetail} = this.props
        if (!!guaranteeDetail?.orderId) {
            this.getOrderDetail(guaranteeDetail?.orderId)
        }
    }

    previewPaymentProofHandler = (proofOfPayments) => {
        if(!!proofOfPayments){
            return (
                <Button onClick={() => this.setState({previewImageModalVisilibity: true, paymentProof: proofOfPayments})}>Preview</Button>
                )

        }
    }

    actionButton = (record, item, index) => {
        const {guaranteeDetail} = this.props
        if (index !== 0 && (guaranteeDetail?.billingId.includes('ARD') || guaranteeDetail?.billingId.includes('AVD') )) {
            if (record.paymentDetail.transactionStatus === 'paid') {
                return (
                    <Col>
                        {
                            !!record.taxInvoice ?
                                <a href={record.taxInvoice}>Unduh faktur pajak</a>
                                :
                                <Button onClick={() => this.setState({
                                    requestTaxInvoiceModalVisibility: true,
                                    paymentIndex: index
                                })}>{!record.isRequestTaxInvoice ? 'Request Faktur Pajak' : 'Request Ulang Faktur Pajak'}</Button>
                        }
                        <br/>
                        <Button
                            style={{marginTop: 10}}
                            onClick={() => this.setState({uploadTaxInvoiceModalVisibility: true, paymentIndex: index})}>
                            {!!record.taxInvoice ? 'Edit Faktur Pajak' : 'Upload Faktur Pajak'}</Button>
                    </Col>
                )
            }
            else {
                return <Button disabled={record.paymentDetail.transactionStatus === 'expire'} onClick={() => this.setState({isVisible: true, paymentIndex: index})}>Update Pembayaran</Button>
            }
        }
    }

    getOrderDetail = (orderId) => {
        const {guaranteeDetail} = this.props
        getOrderDetailByOrderIdApi(orderId)
            .then(result => {

                let downPayment = {
                    label: 'Down Payment',
                    payAmount: result?.grandTotalGuarantee,
                    fines: 0,
                    totalPayAmount: result?.grandTotalGuarantee,
                    paymentDetail: result?.paymentDetail,
                    proofOfPayments: !!result?.paymentDetail?.proofOfPayments && result?.paymentDetail?.proofOfPayments
                }
                let terminPayment = _.cloneDeep(guaranteeDetail.payment)
                terminPayment.forEach((item, index) => {
                    item.label = 'Termin ' + (index + 1)
                })
                this.setState({paymentTerminList: [downPayment, ...terminPayment]})
            })
            .catch(err => console.log(err))
    }

    onFinish = (value) => {
        const {paymentIndex} = this.state
        let {guaranteeDetail} = this.props
        let currentDate = moment().date()
        let currentMonth = moment().month()
        let currentYear = moment().year()
        let newDate = new Date(currentYear, currentMonth, 28)
        let expiredDateUnix = convertStartEndDate(newDate)
        guaranteeDetail.expiredDate = currentDate < 21 && guaranteeDetail.remainingPaymentGuarantee > 0 ? expiredDateUnix : guaranteeDetail.expiredDate
        guaranteeDetail.status = guaranteeDetail.remainingPaymentGuarantee === 0 ? COMPLETED : guaranteeDetail.status
        guaranteeDetail.payment[paymentIndex - 1].paymentDetail = {
            transactionStatus: value.transactionStatus,
            transactionId: value.transactionId,
            transactionTime: moment(value.transactionTime).format('YYYY-MM-DD HH:mm:ss'),
            vaNumber: value.vaNumber
        }
        updatePaymentTerminGuaranteeApi(guaranteeDetail)
            .then(result => {
                this.setState({isVisible: false})
                alert('Update pembayaran termin berhasil!!')
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    onFinishRequestTaxInvoice = (values) => {
        let {guaranteeDetail} = this.props
        const {paymentIndex} = this.state
        guaranteeDetail.payment[paymentIndex - 1].isRequestTaxInvoice = true
        values.paymentIndex = 0
        values.guaranteeDetail = guaranteeDetail
        sendTaxInvoiceTerminRequestEmailApi(values)
            .then(result => {
                alert('Email request faktur pajak berhasil dikirim !!')
            })
            .catch(err => {
                console.log('Error sendTaxInvoiceTerminRequestEmailApi', err)
            })
    }

    handleUpload = () => {
        const {taxInvoiceList, paymentIndex} = this.state
        if (taxInvoiceList[0].type !== 'application/pdf') {
            alert('File harus berformat .pdf !!')
            this.setState({taxInvoiceList: []})
        } else {
            const {id} = this.props.guaranteeDetail
            const form = new FormData();
            form.append('image', taxInvoiceList[0])
            form.append('id_guarantee', id)
            form.append('paymentIndex', (paymentIndex - 1))

            uploadTaxInvoiceTerminApi(form)
                .then(result => {
                    alert('Faktur pajak berhasil disimpan !!')
                    window.location.reload()
                })
                .catch(err => {
                    console.log('Error uploadTaxInvoiceTerminApi', err)
                })
        }
    }

    beforeUpload = (file) => {
        this.setState({taxInvoiceList: [file]});
    }

    dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    render() {
        const {columns, paymentTerminList, paymentProof, previewImageModalVisilibity, isVisible, requestTaxInvoiceModalVisibility, taxInvoiceList, uploadTaxInvoiceModalVisibility} = this.state
        const {guaranteeDetail} = this.props

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={paymentTerminList}
                    rowKey={'label'}
                    pagination={false}
                />
                <PreviewPaymentProofModal
                    paymentProof={paymentProof}
                    isVisible={previewImageModalVisilibity}
                    onCancel={() => this.setState({previewImageModalVisilibity: false})}
                />
                <Modal
                    title={'Update Detail Pembayaran termin'}
                    visible={isVisible}
                    onCancel={() => this.setState({isVisible: false})}
                    footer={null}
                >
                    <UpdatePaymentDetailModal orderDetail={guaranteeDetail} onFinish={this.onFinish}
                                              disabledUploadImage={true}/>
                </Modal>
                <TaxInvoiceModal
                    modalVisibility={requestTaxInvoiceModalVisibility}
                    onCancel={() => this.setState({requestTaxInvoiceModalVisibility: false})}
                    onFinish={this.onFinishRequestTaxInvoice}
                />
                <Modal
                    title={'Upload Faktur Pajak'}
                    visible={uploadTaxInvoiceModalVisibility}
                    onCancel={() => this.setState({uploadTaxInvoiceModalVisibility: false})}
                    footer={null}
                >
                    <Row justify={"space-between"}>
                        <div style={{width: 180}}>
                            <Upload customRequest={this.dummyRequest} fileList={taxInvoiceList}
                                    beforeUpload={this.beforeUpload}
                                    onRemove={() => this.setState({taxInvoiceList: []})}>
                                <Button icon={<UploadOutlined/>}>Select File</Button>
                            </Upload>
                        </div>
                        <Button
                            onClick={this.handleUpload}
                            disabled={taxInvoiceList.length === 0}
                            style={{backgroundColor: Colors.gainsboro}}
                        >
                            Upload
                        </Button>
                    </Row>
                </Modal>
            </>
        )
    }
}

export default PaymentTerminComponent
