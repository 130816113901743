import React from "react";
import {Button, Card, DatePicker, Input, Row, Tabs} from "antd";
import {Colors} from "../../ui-util/colors";
import {APPROVED, CANCELLED, PENDING} from "../../models/order-detail.model";
import {connect} from "react-redux";
import PreOrderListComponent from "./pre-order-list.component";
import {getPreOrderList, preOrderDispatcher} from "../../actions/pre-order.action";
import {PRE_ORDER_DETAIL} from "../../reducers/pre-order.reducer";
import moment from "moment";
import RemainingPaymentComponent from "./remaining-payment.component";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;
const {Search} = Input;

class PreOrderPage extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [{
                key: PENDING,
                tab: 'Pending'
            }, {
                key: APPROVED,
                tab: 'Approved'
            }, {
                key: CANCELLED,
                tab: 'Reject'
            }],
        }
    }

    componentDidMount() {
        this.props.getPreOrderList()
    }

    onTabChange = (tabId) => {
        this.props.preOrderDispatcher(PRE_ORDER_DETAIL, {activeTab: tabId})
        this.props.getPreOrderList()
    }

    onDatePickerChange = (dates) => {
        this.props.preOrderDispatcher(PRE_ORDER_DETAIL, {startDate: dates[0], endDate: dates[1]})
    }

    onFilter = () => {
        this.props.getPreOrderList()
    }

    componentWillUnmount() {
        this.props.preOrderDispatcher(PRE_ORDER_DETAIL, {activeTab: PENDING})
    }

    render() {
        const {tabList} = this.state
        const {activeTab, startDate, endDate} = this.props.preOrderReducer

        return (
            <Card>
                <h3>Pre Order List</h3>
                <Row style={{marginBottom: 20, marginTop: 20}} justify="space-between">
                    <Search placeholder="cari order id"
                            onSearch={(value) => this.props.preOrderDispatcher(PRE_ORDER_DETAIL, {searchKeyword: value})}
                            style={{width: 250, marginRight: 20, height: 50}}/>
                    <div>
                        Tgl. Pesanan
                        <RangePicker
                            style={{marginLeft: 20, marginRight: 20}}
                            value={[startDate, endDate]}
                            allowClear={false}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                onClick={() => this.onFilter()}>Filter</Button>
                    </div>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen}}
                >
                    {tabList.map((tab) => (
                        <TabPane tab={tab.tab} key={tab.key}>
                            {
                                activeTab === APPROVED ? <RemainingPaymentComponent/> : <PreOrderListComponent/>
                            }
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {preOrderReducer} = state;

    return {preOrderReducer};
};

export default connect(mapStateToProps, {getPreOrderList, preOrderDispatcher})(PreOrderPage)
