import React from 'react'
import _ from "lodash";
import {Button, DatePicker, Form, Input, Select, Upload} from "antd";
import moment from 'moment';
import {UploadOutlined} from "@ant-design/icons";

const {Option} = Select;

class UpdatePaymentDetailModal extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();
        this.state = {
            listItem: [
                {
                    label: 'Pilih Bank Pembayaran',
                    name: 'paymentMethod',
                    type: 'option',
                    required: true,
                    messageAlert: 'masukkan metode pembayaran',
                    options: [
                        {value: 'bca', label: 'BCA'},
                        {value: 'echannel', label: 'Mandiri'}
                    ]
                }, {
                    label: 'Nomor rekening',
                    name: 'vaNumber',
                    disabled: true,
                }, {
                    label: 'ID Transaksi',
                    name: 'transactionId',
                    placeholder: 'masukkan nomor order AVS-4263476-900',
                    required: true,
                    disabled: true,
                    messageAlert: 'masukkan nomor pesanan'
                }, {
                    label: 'Status Transaksi',
                    name: 'transactionStatus',
                    placeholder: 'ex: paid',
                    required: true,
                    disabled: true,
                    messageAlert: 'masukkan status transaksi'
                }, {
                    label: 'Waktu Transaksi',
                    name: 'transactionTime',
                    required: true,
                    type: 'dateTime',
                    messageAlert: 'masukkan waktu transaksi'
                }, {
                    label: 'Unggah Bukti Transfer',
                    name: 'paymentProof',
                    required: false,
                    type: 'image',
                }
            ],
            fileImageList: []
        }
    }

    componentDidMount() {
        const {orderDetail} = this.props
        let paymentDetail = {
            transactionId: !!orderDetail.billingId ? orderDetail.billingId : orderDetail.orderId,
            transactionStatus: 'paid'
        }

        this.formRef.current.setFieldsValue(paymentDetail)
    }

    onChangeSelectHandler = (value) => {
        let vaNumber = ''
        if (value === 'bca') {
            vaNumber = '8610474455'
        } else {
            vaNumber = '1370014101477'
        }
        this.formRef.current.setFieldsValue({vaNumber})
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    render() {
        const {listItem, fileImageList} = this.state
        const {onFinish, disabledUploadImage} = this.props
        return (
            <Form onFinish={(value) => onFinish(value, fileImageList)} ref={this.formRef}>
                {
                    _.map(listItem, (v, k) => {
                        switch (v.type) {
                            case 'option':
                                return (
                                    <Form.Item
                                        key={k.toString()}
                                        label={v.label}
                                        name={v.name}
                                        rules={[{
                                            required: v.required,
                                            message: v.messageAlert,
                                        }]}
                                    >
                                        <Select style={{width: 220}}
                                                onChange={(value) => this.onChangeSelectHandler(value)}>
                                            {
                                                v.options.map((item, index) => <Option key={index.toString()}
                                                                                       value={item.value}>{item.label}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                )
                            case 'dateTime':
                                return (
                                    <Form.Item
                                        key={k.toString()}
                                        label={v.label}
                                        name={v.name}
                                        rules={[{
                                            required: v.required,
                                            message: v.messageAlert,
                                        }]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                                        />
                                    </Form.Item>
                                )
                            case 'image':
                                return (
                                    <div key={k.toString()}>
                                        {
                                            !disabledUploadImage &&
                                            <Form.Item
                                                key={k.toString()}
                                                label={v.label}
                                                name={v.name}
                                                rules={[{
                                                    required: v.required,
                                                    message: v.messageAlert,
                                                }]}
                                            >
                                                <Upload fileList={fileImageList} beforeUpload={this.beforeUploadImage}
                                                        onRemove={this.removeButtonHandler}>
                                                    <div style={{marginTop: 10}}>
                                                        <Button>
                                                            <UploadOutlined/> Select File
                                                        </Button>
                                                        <p style={{marginTop: 5}}>Ukuran gambar maksimal 2 MB</p>
                                                    </div>
                                                </Upload>
                                            </Form.Item>
                                        }
                                    </div>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={k.toString()}
                                        label={v.label}
                                        name={v.name}
                                        rules={[{
                                            required: v.required,
                                            message: v.messageAlert,
                                        }]}
                                    >
                                        <Input placeholder={v.placeholder} disabled={v.disabled}/>
                                    </Form.Item>
                                )
                        }
                    })
                }
                <Form.Item style={{alignItems: 'center'}}>
                    <Button type="primary" htmlType="submit">
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default UpdatePaymentDetailModal
