import React from "react";
import {connect} from "react-redux";
import {orderDispatcher} from "../../actions/order.action";
import {getCashbackListDc} from "../../actions/dc.action";
import {Card, Col, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";

class DcCashbackHeaderComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            headerItemFirst: [
                {
                    label: 'Top Line Net Cash Value',
                    key: 'topLineNetCashValue'
                }, {
                    label: 'Potential Margin',
                    key: 'potentialMarginPercentage'
                }
            ],
            headerItemSecond: [
                {
                    label: 'Potential Cashback',
                    key: 'potentialCashback'
                }, {
                    label: 'Total Cashback',
                    key: 'totalCashback'
                }, {
                    label: 'Handling Fee',
                    key: 'handlingFee'
                }
            ]
        }
    }

    renderHeaderItem = (label, item) => {
        return (
            <div>
                <h4 style={{textAlign: 'center'}}>{label === 'Potential Margin' ? `${item}%` : doConvertNumberToRupiahFormat(item)}</h4>
            </div>
        )
    }

    render() {
        const {headerItemFirst, headerItemSecond} = this.state
        const {dcCashbackReducer} = this.props
        return (
            <Card style={{
                marginTop: 50,
                marginBottom: 50,
                borderWidth: 5,
                borderColor: Colors.gainsboro,
                borderRadius: 15,
                padding: 15
            }}>
                <h3 style={{textTransform: 'capitalize'}}>Cashback {dcCashbackReducer?.dcName}</h3>
                <Row style={{marginTop: 20}} justify={'space-between'}>
                    <Row>
                        {
                            headerItemFirst.map((item) => (
                                <Card key={item.key} style={{
                                    borderWidth: 1,
                                    borderRightWidth: item.key === 'topLineNetCashValue' ? 0 : 1,
                                    borderTopLeftRadius: item.key === 'topLineNetCashValue' ? 7 : 0,
                                    borderBottomLeftRadius: item.key === 'topLineNetCashValue' ? 7 : 0,
                                    borderTopRightRadius: item.key === 'topLineNetCashValue' ? 0 : 7,
                                    borderBottomRightRadius: item.key === 'topLineNetCashValue' ? 0 : 7,
                                    borderColor: Colors.gainsboro,
                                    marginBottom: 30
                                }} title={item.label} >
                                    <h4 style={{textAlign: 'center'}}>{item.key === 'potentialMarginPercentage' ? `${dcCashbackReducer[item.key]}%` : doConvertNumberToRupiahFormat(dcCashbackReducer[item.key])}</h4>
                                </Card>
                            ))
                        }
                    </Row>
                    {
                        headerItemSecond.map((item) => (
                            <Card key={item.key} style={{
                                borderWidth: 1,
                                borderColor: Colors.gainsboro,
                                borderRadius: 7,
                                marginBottom: 30,
                                minWidth: '20%'
                            }} title={item.label}>
                                <h4 style={{textAlign: 'center'}}>{doConvertNumberToRupiahFormat(dcCashbackReducer[item.key])}</h4>
                            </Card>
                        ))
                    }
                </Row>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {dcCashbackReducer} = state

    return {dcCashbackReducer};
}

export default connect(mapStateToProps, {orderDispatcher, getCashbackListDc})(DcCashbackHeaderComponent)
