import {firestore} from "../index";

const warehouseRef = firestore.collection('warehouse');
const warehouseProductRef = (warehouseId) => warehouseRef.doc(warehouseId).collection('products');

const getWarehouseListFromFirestore = (uid) => {
    return warehouseRef.where('userId', '==', uid).where('valid', '==', true).get()
}

const getWarehouseProductFromFirestore = (warehouseId) => {
    return warehouseProductRef(warehouseId).where('valid', '==', true).get()
}

export {
    getWarehouseListFromFirestore,
    getWarehouseProductFromFirestore
}
