import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportTopSpenderComponent extends React.Component {

    render() {
        const {topSpenderList, button} = this.props;
        return (
            <ExcelFile element={button}>
                <ExcelSheet data={topSpenderList} name="Order List">
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="level" value="level"/>
                    <ExcelColumn label="Avostore Area" value="avostoreArea"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="SKU Code" value="skuCode"/>
                    <ExcelColumn label="Nama Produk" value="productName"/>
                    <ExcelColumn label="Jumlah Produk" value="productQty"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
