import React from "react";
import {Image, Modal} from 'antd';

class PreviewPaymentProofModal extends React.PureComponent {
    render() {
        const {isVisible, onCancel, paymentProof} = this.props
        return (
            <Modal
                title={'Bukti Pembayaran'}
                visible={isVisible}
                onCancel={onCancel}
                footer={null}
            >
                {
                    paymentProof.map((item, index) => (
                        <div style={{margin: 10}} key={index.toString()}>
                            <Image
                                key={index.toString()}
                                width={200}
                                src={item.image}
                            />
                        </div>
                    ))
                }
            </Modal>
        )
    }
}

export default PreviewPaymentProofModal
