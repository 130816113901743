import {combineReducers} from 'redux';
import {userReducer} from "./user.reducer";
import {orderReducer} from "./order.reducer";
import {userAddressReducer} from "./user-address.reducer";
import {manifestReducer} from "./manifest.reducer";
import {warehouseReducer} from "./warehouse.reducer";
import {avostoreListReducer} from "./avostore-list.reducer";
import {userChainReducer} from "./user-chain.reducer";
import {voucherReducer} from "./voucher.reducer";
import {avostoreDetailReducer} from "./avostore-detail.reducer";
import {voucherDetailReducer} from "./voucher-detail.reducer";
import {revenueReducer} from "./revenue.reducer";
import {analyticsProductReducer} from "./analytics-product.reducer";
import {analyticsUserReducer} from "./analytics-user.reducer";
import {analyticsGuaranteeReducer} from "./analytics-guarantee.reducer";
import {productReducer} from "./product.reducer";
import {productStockReducer} from "./product-stock.reducer";
import {deliveryOrderReducer} from "./delivery-order.reducer";
import {preOrderReducer} from "./pre-order.reducer";
import {preOrderPaymentReducer} from "./pre-order-payment.reducer";
import {dcReportReducer} from "./dc-report.reducer";
import {saveOrderReducer} from "./save-order.reducer";
import {dcPaymentReducer} from "./dc-payment.reducer";
import {guaranteeReducer} from "./guarantee.reducer";
import {tabReducer} from "./tab.reducer";
import {filterDateReducer} from "./filter-date.reducer";
import {dcCashbackReducer} from "./dc-cashback.reducer";
import {modalReducer} from "./modal.reducer";
import {dcOrderReducer} from "./dc-order.reducer";
import {importResiReducer} from "./import-resi.reducer";
import {taxReducer} from "./tax.reducer";
import {dropdownSellerReducer} from "./dropdown-seller.reducer";
import {giftReducer} from "./gift.reducer";

export default combineReducers({
    userReducer,
    orderReducer,
    userAddressReducer,
    manifestReducer,
    warehouseReducer,
    avostoreListReducer,
    userChainReducer,
    voucherReducer,
    avostoreDetailReducer,
    voucherDetailReducer,
    revenueReducer,
    analyticsProductReducer,
    analyticsUserReducer,
    analyticsGuaranteeReducer,
    productReducer,
    productStockReducer,
    deliveryOrderReducer,
    preOrderReducer,
    preOrderPaymentReducer,
    dcReportReducer,
    saveOrderReducer,
    dcPaymentReducer,
    guaranteeReducer,
    tabReducer,
    filterDateReducer,
    dcCashbackReducer,
    modalReducer,
    dcOrderReducer,
    importResiReducer,
    taxReducer,
    dropdownSellerReducer,
    giftReducer
})
