import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const createProgressiveMarginApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/margin/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const getProgressiveMarginApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}api/v4/margin/list`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const updateProgressiveMarginApi = (reqBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/margin/update`,
            reqBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const deleteProgressiveMarginApi = (reqBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/margin/delete`,
            reqBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export {
    createProgressiveMarginApi,
    getProgressiveMarginApi,
    updateProgressiveMarginApi,
    deleteProgressiveMarginApi
}
