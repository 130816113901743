const INITIAL_STATE = {
    avostoreList: [],
    avostoreListAll: [],
    filteredAvostoreList: [],
    loading: false,
    avoStoreDcList: []
}

export const AVOSTORE_LIST = 'AVOSTORE_LIST';
export const AVOSTORE_LIST_ALL = 'AVOSTORE_LIST_ALL';
export const FILTERED_AVOSTORE_LIST = 'FILTERED_AVOSTORE_LIST';
export const SET_LOADING_AVOSTORE_LIST = 'SET_LOADING_AVOSTORE_LIST'
export const AVO_STORE_LIST_REDUCER = 'AVO_STORE_LIST_REDUCER'

export const avostoreListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AVOSTORE_LIST:
            return {...state, avostoreList: [...action.payload]}
        case FILTERED_AVOSTORE_LIST:
            return {...state, filteredAvostoreList: [...action.payload]}
        case AVOSTORE_LIST_ALL:
            return {...state, avostoreListAll: [...action.payload]}
        case SET_LOADING_AVOSTORE_LIST:
            return {...state, loading: action.payload}
        case AVO_STORE_LIST_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
