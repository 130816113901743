import React, {PureComponent} from 'react';
import { Table } from "antd";
import {getOrderListByManifestId} from "../../firebase/database/orders";
import {extractQuerySnapshotToArray} from "../../ui-util/general-variable";

class BuyerListComponent extends PureComponent {
    constructor() {
        super()

        this.state = {
            columns: [
                {
                    title: 'Order ID',
                    dataIndex: 'orderId',
                    key: 'orderId'
                },
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => <div>{shippingAddress.receiver}</div>
                },
                {
                    title: 'No. Hp',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => <div>{shippingAddress.phoneNumber}</div>
                },
                {
                    title: 'Jasa Kirim',
                    dataIndex: 'shippingMethod',
                    key: 'shippingMethod'
                },
                {
                    title: 'Service',
                    dataIndex: 'shippingService',
                    key: 'shippingService'
                },
                {
                    title: 'No. Resi',
                    dataIndex: 'awb',
                    key: 'awb'
                },
                {
                    title: 'Alamat',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => <div>{shippingAddress.address}, {shippingAddress.district}, {shippingAddress.city}, {shippingAddress.province}</div>
                }
            ],
            orderList: []
        }
    }

    componentDidMount(): void {
        const {id} = this.props.manifestDetail
        getOrderListByManifestId(id)
            .then(r => {
             let data = extractQuerySnapshotToArray(r)
                this.setState({orderList: data})
            })
            .catch(err => console.log('error get order list by manifest id', err))
    }

    render() {
        const { columns, orderList } = this.state;

        return (
            <Table columns={columns} dataSource={orderList} pagination={false} rowKey='id' />
        );
    }
}

export default BuyerListComponent;
