import {productStockListApi} from "../api/product-stock";
import {PRODUCT_STOCK_LIST} from "../reducers/product-stock.reducer";
import {getWarehouseListFromFirestore} from "../firebase/database/warehouse";
import {avoskinId, extractQuerySnapshotToArray} from "../ui-util/general-variable";
import {warehouseDispatcher} from "./warehouse.action";
import {WAREHOUSE_LIST} from "../reducers/warehouse.reducer";

const productStockDispatcher = (type, payload) => {
    return {type, payload}
}

const getWarehouseProductStockList = (productListType) => {
    return (dispatch) => {
        getWarehouseListFromFirestore(avoskinId())
            .then(result => {
                const warehouseList = extractQuerySnapshotToArray(result)
                const warehouseDetail = warehouseList[0]
                let bodyRequest = {
                    warehouseId: warehouseDetail.id,
                    productListType
                }
                dispatch(warehouseDispatcher(WAREHOUSE_LIST, {warehouseList: warehouseList}))
                productStockListApi(bodyRequest)
                    .then(result => {
                        dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                            productStockList: result.data,
                            warehouseDetail: warehouseDetail,
                            loading: false
                        }))
                    })
                    .catch(err => {
                            console.log("productStockListApi ", err)
                            dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                                loading: false
                            }))
                        }
                    )

            })
            .catch(err => {
                console.log('Error get warehouse list', err)
                dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                    loading: false
                }))
            })
    }
}

const getProductStockList = (productListType) => {
    return (dispatch, getState) => {
        let warehouseId = getState()?.productStockReducer?.warehouseDetail?.id
        let bodyRequest = {
            warehouseId,
            productListType
        }
        if (!!warehouseId) {
            productStockListApi(bodyRequest)
                .then(result => {
                    dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                        productStockList: result.data,
                        loadingTable: false
                    }))
                })
                .catch(err => {
                        console.log("productStockListApi getProductStockList: ", err)
                        dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                            loadingTable: false
                        }))
                    }
                )
        }
    }
}

const getProductStockListDc = (warehouseId, productListType) => {
    return (dispatch) => {
        let bodyRequest = {
            warehouseId,
            productListType: productListType,
        }

        if (!!warehouseId) {
            dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                loadingTable: true
            }))
            productStockListApi(bodyRequest)
                .then(result => {
                    dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                        productStockList: result.data,
                        loadingTable: false
                    }))
                })
                .catch(err => {
                        console.log("productStockListApi getProductStockListDc: ", err)
                        dispatch(productStockDispatcher(PRODUCT_STOCK_LIST, {
                            loadingTable: false
                        }))
                    }
                )
        }
    }
}

export {getWarehouseProductStockList, productStockDispatcher, getProductStockList, getProductStockListDc}
