import React from "react";
import {DatePicker, Row, Select} from "antd";
import {connect} from "react-redux";
import {orderDispatcher} from "../../actions/order.action";
import {DC_CASHBACK_REDUCER} from "../../reducers/dc-cashback.reducer";
import isEqual from "react-fast-compare";
import {getCashbackListDc} from "../../actions/dc.action";
import moment from "moment";

const {Option} = Select;

class DcCashbackFilterComponent extends React.PureComponent {

    datePickerHandler = (dates) => {
        this.props.orderDispatcher(DC_CASHBACK_REDUCER, {monthSelected: dates})
    }

    onFilterHandler = (dcId, dcDetail) => {
        this.props.orderDispatcher(DC_CASHBACK_REDUCER, {dcId, dcName: dcDetail?.children})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {monthSelected, dcId} = this.props.dcCashbackReducer
        if (!isEqual(dcId, prevProps.dcCashbackReducer.dcId) || !isEqual(monthSelected, prevProps.dcCashbackReducer.monthSelected)) {
            this.props.getCashbackListDc()
        }
    }

    render() {
        const {avoStoreDcList} = this.props.avostoreListReducer
        const {monthSelected, dcId} = this.props.dcCashbackReducer
        return (
            <Row justify={'space-between'}>
                <Row style={{alignItems: 'center'}}>
                    Pilih DC :
                    <Select style={{width: 250, marginLeft: 10}}
                            defaultValue={dcId}
                            onChange={(itemId, item) => this.onFilterHandler(itemId, item)}
                    >
                        {
                            avoStoreDcList.map((item, index) => (
                                <Option value={item.id} key={index.toString()}>{item.name}</Option>
                            ))
                        }
                    </Select>
                </Row>
                <Row style={{alignItems: 'center'}}>
                    Pilih Bulan :
                    <DatePicker
                        defaultValue={monthSelected}
                        style={{marginLeft: 10, marginRight: 30}}
                        onChange={(dates) => this.datePickerHandler(dates)}
                        disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        picker="month"
                        format={'MMMM'}/>

                </Row>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreListReducer, dcCashbackReducer} = state

    return {avostoreListReducer, dcCashbackReducer};
}

export default connect(mapStateToProps, {orderDispatcher, getCashbackListDc})(DcCashbackFilterComponent)
