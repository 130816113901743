import React, {PureComponent} from 'react';
import { Table } from "antd";

class ProductList extends PureComponent {
    constructor() {
        super()

        this.state = {
            columns: [
                { title: 'Produk', dataIndex: 'name', key: 'name', width: 750 },
                { title: 'Jumlah', dataIndex: 'count', key: 'count' },
                { title: 'Total Berat', dataIndex: 'weight', key: 'weight' },
            ]
        }
    }
    render() {
        const { columns } = this.state;
        const {productList} = this.props;

        return (
            <Table columns={columns} dataSource={productList} pagination={false} rowKey='id' />
        );
    }
}

export default ProductList;
