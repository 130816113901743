import React from "react";
import {Modal} from "antd";

class ApproveOrderModal extends React.PureComponent{
    render() {
        const {setModalVisibility, modalVisibility, handleOK, modalMessage} = this.props
        return (
            <Modal
                title={`Delivery Order`}
                style={{top: 20}}
                visible={modalVisibility}
                onCancel={() => setModalVisibility(false)}
                onOk={() => handleOK()}
            >
                <p>{modalMessage}</p>
            </Modal>
        )
    }
}

export default ApproveOrderModal
