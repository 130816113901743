export const Colors = {
    greenish_grey: '#729371',
    greenish_grey_two: '#93ae8f',
    medium_grey: '#707a6f',
    sage_green: '#8da688',
    salmon: '#fd6c69',
    dark: '#14272e',
    very_light_pink: '#d2d2d2',
    darkslategray: '#3c5937',
    dark_grey: '#2a2b2d',
    darkblue: '#425563',
    darkseagreen: '#8da689',
    whitesmoke: '#f1f4f0',
    gainsboro: '#d4e2d2',
    lightgray: '#cacbcc',
    dimgray: '#657c61',
    darkgray: '#a0a0a0',
    whitesmoke_gray: '#f0f0f0',
    salmon_light: '#fff4f4',
    lavender: '#ebebeb',
    lightsteelblue: '#97b8d3',
    aliceblue: '#eaf5ff',
    sandybrown: '#deb669',
    darkgoldenrod: '#b58123',
    lightslategray: '#707474',
    tan: '#d8aa8d',
    peru: '#b47c59',
    blanchedalmond: '#ffedc9',
    steelblue: '#2971ab',
    darkdray: '#89a3b8',
    goldenrod: '#d99924',
    silver: '#b5b5b5',
    papayawhip: '#fff2d9',
    mistyrose: '#ffe7e6',
    darkolivegreen : '#4e6849',
    linen: '#faece3',
    gray: '#747878',
    rosybrown: '#c19173',
    midnightblue: '#1e3976'
}
