import React, {PureComponent} from 'react';
import {Card, Input, Row, Tabs} from 'antd';
import {connect} from 'react-redux';
import OrderList from './order-list.component';
import {getOrderList, getOrderListAction} from '../../actions/order.action';
import {getWarehouseList} from "../../actions/warehouse.action";
import {FLUSH_PAGINATION, ORDER_LIST_AVOSTORE, ORDER_REDUCER} from "../../reducers/order.reducer";
import TabComponent from "../../components/tab.component";
import {CANCELLED, COMPLETED, DELIVERY, SUBMITTED} from "../../models/order-detail.model";
import {isEqual} from "../../ui-util/general-variable";
import {generalDispatcher} from "../../actions/general.action";
import {TAB_REDUCER} from "../../reducers/tab.reducer";
import WarehouseAvoskinComponent from "../../components/warehouse-avoskin.component";
import FilterDateComponent from "../../components/filter-date.component";
import ExportOrderComponent from "../../components/export-order.component";
import UpdateResiModal from "../../components/update-resi.modal";
import FilterByBuyerComponent from "./filter-by-buyer.component";

const {TabPane} = Tabs;
const {Search} = Input;

class Orders extends PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: '',
                    tab: 'Semua'
                }, {
                    key: SUBMITTED,
                    tab: 'Belum Bayar'
                }, {
                    key: 'waitingForDelivery',
                    tab: 'Perlu Dikirim'
                }, {
                    key: DELIVERY,
                    tab: 'Dikirim'
                }, {
                    key: COMPLETED,
                    tab: 'Selesai'
                }, {
                    key: CANCELLED,
                    tab: 'Dibatalkan'
                }
            ],
            processingTabList: [
                {
                    key: '',
                    tab: 'Semua'
                }, {
                    key: 'processing',
                    tab: 'Perlu diproses'
                }, {
                    key: 'proceed',
                    tab: 'Telah diproses'
                }
            ],
        };
    }

    componentDidMount(): void {
        this.props.getWarehouseList()
        this.props.getOrderListAction()
    }

    onProcessingTabChange = (tabId) => {
        this.props.generalDispatcher(TAB_REDUCER, {processingActiveTab: tabId})
    };

    orderListRender = () => {
        const {activeTab, processingActiveTab} = this.props.tabReducer
        const {processingTabList} = this.state;
        switch (activeTab) {
            case 'waitingForDelivery':
                return (
                    <Tabs
                        defaultActiveKey={processingActiveTab}
                        activeKey={processingActiveTab}
                        type="card"
                        onChange={this.onProcessingTabChange}>
                        {
                            processingTabList.map((tab) => (
                                <TabPane tab={tab.tab} key={tab.key}>
                                    <OrderList/>
                                </TabPane>
                            ))}
                    </Tabs>
                );
            default:
                return <OrderList/>
        }
    };

    onSearchOrderHandler = (searchKey) => {
        this.props.generalDispatcher(ORDER_REDUCER, {searchKey})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {warehouseReducer, orderReducer, tabReducer} = this.props;
        if (!isEqual(warehouseReducer.warehouseDetail, prevProps.warehouseReducer.warehouseDetail) ||
            orderReducer.currentPage !== prevProps.orderReducer.currentPage ||
            orderReducer.searchKey !== prevProps.orderReducer.searchKey ||
            orderReducer.pageSize !== prevProps.orderReducer.pageSize ||
            tabReducer.activeTab !== prevProps.tabReducer.activeTab ||
            tabReducer.processingActiveTab !== prevProps.tabReducer.processingActiveTab
        ) {
            this.props.getOrderListAction()
        }
    }

    componentWillUnmount(): void {
        this.props.generalDispatcher(FLUSH_PAGINATION)
        this.props.generalDispatcher(ORDER_LIST_AVOSTORE, {
            warehouseDcId: '',
            selectedRowExcel: []
        })
        this.props.generalDispatcher(TAB_REDUCER, {activeTab: ''})
    }

    render() {
        const {id: uid} = this.props.userReducer;
        const {selectedRowExcel} = this.props.orderReducer
        const {tabList} = this.state;

        return (
            <Card>
                <WarehouseAvoskinComponent/>
                <TabComponent
                    tabList={tabList}
                    content={
                        <div>
                            <Row type="flex" justify={'space-between'} align="middle">
                                <Search
                                    placeholder="Cari Pesanan"
                                    onSearch={value => this.onSearchOrderHandler(value)}
                                    style={{width: 250, height: 50}}
                                />
                                <Row>
                                    <FilterDateComponent
                                        filterHandler={() => this.props.getOrderListAction()}
                                        filterLabel={'Tgl Pesanan'}
                                    />
                                    <ExportOrderComponent
                                        fileName={'Pesanan Saya'}
                                        selectedRow={selectedRowExcel}
                                    />
                                </Row>
                            </Row>
                            <Row type={"flex"} align={"end"}>
                                <FilterByBuyerComponent handleButtonFilterClick={() => this.props.getOrderListAction()}/>
                            </Row>
                            {!!uid && this.orderListRender()}
                        </div>
                    }
                />
                <UpdateResiModal afterUpdateHandler={() => this.props.getOrderListAction()}/>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {userReducer, warehouseReducer, orderReducer, tabReducer} = state;

    return {userReducer, warehouseReducer, orderReducer, tabReducer};
};

export default connect(mapStateToProps, {
    getOrderList,
    getWarehouseList,
    getOrderListAction,
    generalDispatcher
})(Orders);
