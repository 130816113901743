import React from "react";
import {Card} from "antd";
import TargetSalesComponent from "./target-sales.component";

class TargetSalesPage extends React.PureComponent {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Card>
                <TargetSalesComponent/>
            </Card>
        )
    }
}

export default TargetSalesPage
