import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import {getWarehouseDcListFromApi} from "../../actions/warehouse.action";
import {getUserListByLevel} from "../../actions/user.action";
import {Card} from "antd";
import TabComponent from "../../components/tab.component";
import {CANCELLED, COMPLETED, DELIVERY, SUBMITTED} from "../../models/order-detail.model";
import OrderDcFilterComponent from "./order-dc-filter.component";
import OrderDcListComponent from "./order-dc-list.component";
import {getDcOrderListAction} from "../../actions/dc.action";
import isEqual from "react-fast-compare";
import UpdateResiModal from "../../components/update-resi.modal";
import TabPrintLabelComponent from "../../components/tab-print-label.component";
import {generalDispatcher} from "../../actions/general.action";
import {DC_ORDER_REDUCER} from "../../reducers/dc-order.reducer";
import {TAB_REDUCER} from "../../reducers/tab.reducer";

class DcOrders extends PureComponent {

    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: '',
                    tab: 'Semua'
                }, {
                    key: SUBMITTED,
                    tab: 'Belum Bayar'
                }, {
                    key: 'waitingForDelivery',
                    tab: 'Perlu Dikirim'
                }, {
                    key: DELIVERY,
                    tab: 'Dikirim'
                }, {
                    key: COMPLETED,
                    tab: 'Selesai'
                }, {
                    key: CANCELLED,
                    tab: 'Dibatalkan'
                }
            ],
        }
    }

    componentDidMount() {
        this.initialData()
    }

    initialData = async () => {
        this.props.getUserListByLevel('report')
        await this.props.getWarehouseDcListFromApi()
        this.props.getDcOrderListAction()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let {activeTab, processingActiveTab} = this.props.tabReducer
        let {searchKey, currentPage, pageSize} = this.props.dcOrderReducer
        let {avoStoreDcList} = this.props.avostoreListReducer
        if (!isEqual(activeTab, prevProps.tabReducer.activeTab) ||
            !isEqual(searchKey, prevProps.dcOrderReducer.searchKey) ||
            !isEqual(currentPage, prevProps.dcOrderReducer.currentPage) ||
            !isEqual(pageSize, prevProps.dcOrderReducer.pageSize) ||
            !isEqual(processingActiveTab, prevProps.tabReducer.processingActiveTab) ||
            !isEqual(avoStoreDcList, prevProps.avostoreListReducer.avoStoreDcList)
        ) {
            this.props.getDcOrderListAction()
        }
    }

    componentWillUnmount() {
        this.props.generalDispatcher(DC_ORDER_REDUCER, {searchKey: '', selectedRowKeys: [], selectedRowExcel: []})
        this.props.generalDispatcher(TAB_REDUCER, {activeTab: ''})
    }

    render() {
        const {tabList} = this.state
        const {activeTab} = this.props.tabReducer
        return (
            <Card>
                <TabComponent
                    tabList={tabList}
                    content={
                        <div>
                            <OrderDcFilterComponent/>
                            {
                                activeTab === 'waitingForDelivery' ?
                                    <TabPrintLabelComponent content={<OrderDcListComponent/>}/>
                                    :
                                    <OrderDcListComponent/>
                            }
                        </div>
                    }
                />
                <UpdateResiModal afterUpdateHandler={() => this.props.getDcOrderListAction()}/>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer, tabReducer, dcOrderReducer, avostoreListReducer} = state

    return {warehouseReducer, tabReducer, dcOrderReducer, avostoreListReducer};
}

export default connect(mapStateToProps, {
    generalDispatcher,
    getWarehouseDcListFromApi,
    getUserListByLevel,
    getDcOrderListAction
})(DcOrders);
