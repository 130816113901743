import React from 'react';
import {Colors} from "../../ui-util/colors";
import {Card, Tabs} from "antd";
import ProductStockListComponent from "./product-stock-list.component";
import {connect} from "react-redux";
import {productStockDispatcher} from "../../actions/product-stock.action";
import {SET_LOADING_PRODUCT_STOCK} from "../../reducers/product-stock.reducer";

const {TabPane} = Tabs;

class EditStockProduct extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'products',
                    tab: 'Avostore'
                },
                {
                    key: 'po_products',
                    tab: 'Pre Order'
                },
                {
                    key: 'po_dc_products',
                    tab: 'Pre Order DC'
                },
                {
                    key: 'do_products',
                    tab: 'Delivery Order'
                },
                {
                    key: 'dc_products',
                    tab: 'Distribution Channel'
                }
            ],
            activeTab: 'products'
        }
    }

    onTabChangeHandler = (tabId) => {
        this.setState({activeTab: tabId})
        this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loadingTable: true})
    }

    getWarehouseStockType = (activeTab) => {
        switch (activeTab) {
            case 'do_products':
                return 'Delivery Order'
            case 'po_products':
                return 'Pre Order'
            case 'po_dc_products':
                return 'Pre Order DC'
            case 'dc_products':
                return 'Distribution Channel'
            default:
                return 'Avostore'
        }
    }

    render() {
        const {tabList, activeTab} = this.state
        const stockType = this.getWarehouseStockType(activeTab)

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>{`Edit Product Stock ${stockType}`}</h3>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onTabChangeHandler}
                    tabBarStyle={{color: Colors.darkseagreen}}
                    type="card"
                    size={'large'}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <ProductStockListComponent productListType={activeTab}/>
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {productStockReducer} = state
    return {productStockReducer}
}

export default connect(mapStateToProps, {productStockDispatcher})(EditStockProduct);
