import React, {Component} from 'react';
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportProductDailyCountComponent extends Component {
    render() {
        const {productDailyCount} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={productDailyCount} name="Produk Terjual Per Hari">
                    <ExcelColumn label="Tanggal" value="dateToStr"/>
                    <ExcelColumn label="Produk Terjual" value="count"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default ExportProductDailyCountComponent;
