const setOrderToUpdateResi = (rows) => {
    let selectRow = []
    rows.forEach((row) => {
        let data = {
            id: row?.id,
            orderId: row?.orderId,
            receiverName: row?.shippingAddress.receiver,
            shippingMethod: row?.shippingMethod,
            warehouseName: row?.warehouseDetail?.name,
            awb: !!row?.awb ? row?.awb : ''
        }
        selectRow.push(data)
    })
    return selectRow
}

export {setOrderToUpdateResi}
