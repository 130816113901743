const formatProductExport = (rows) => {
    let selectedRow = []
    rows.forEach((row) => {
        let data = {
            id: row.id,
            skuCode: row.skuCode,
            name: row.name,
            description: row?.description,
            weight: row?.weight,
            cashback: row?.cashback,
            endCustomerPrice: row?.price?.END_CUSTOMER?.price,
            bronzePrice: row?.price?.BRONZE?.price,
            platinumPrice: row?.price?.PLATINUM?.price,
            premium1Price: row?.price?.PREMIUM_1?.price,
            premium2Price: row?.price?.PREMIUM_2?.price,
            dc1Price: row?.price?.DISTRIBUTION_CHANNEL_1?.price,
            dc2Price: row?.price?.DISTRIBUTION_CHANNEL_2?.price,
            dc3Price: !!row?.price?.DISTRIBUTION_CHANNEL_3?.price,
            endCustomerPoint: !!row?.point?.END_CUSTOMER?.point,
            bronzePoint: !!row?.point?.BRONZE?.point,
            platinumPoint: row?.point?.PLATINUM?.point,
            premium1Point: row?.point?.PREMIUM_1?.point,
            premium2Point: row?.point?.PREMIUM_2?.point,
            distributionChannel1Point: row?.point?.DISTRIBUTION_CHANNEL_1?.point,
            distributionChannel2Point: row?.point?.DISTRIBUTION_CHANNEL_2?.point,
            distributionChannel3Point: row?.point?.DISTRIBUTION_CHANNEL_3?.point,
            endCustomerAddMargin: !!row?.additionalMargin?.END_CUSTOMER?.additionalMargin,
            bronzeAddMargin: !!row?.additionalMargin?.BRONZE?.additionalMargin,
            platinumAddMargin: row?.additionalMargin?.PLATINUM?.additionalMargin,
            premium1AddMargin: row?.additionalMargin?.PREMIUM_1?.additionalMargin,
            premium2AddMargin: row?.additionalMargin?.PREMIUM_2?.additionalMargin,
            distributionChannel1AddMargin: row?.additionalMargin?.DISTRIBUTION_CHANNEL_1?.additionalMargin,
            distributionChannel2AddMargin: row?.additionalMargin?.DISTRIBUTION_CHANNEL_2?.additionalMargin,
            distributionChannel3AddMargin: row?.additionalMargin?.DISTRIBUTION_CHANNEL_3?.additionalMargin,
            isVisible: row?.isVisible,
        }
        selectedRow.push(data)
    })
    return selectedRow
}

export {formatProductExport}
