const INITIAL_STATE = {
    voucherList : []
}

export const VOUCHER_LIST = 'VOUCHER_LIST'

export const voucherReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VOUCHER_LIST:
            return {...state, voucherList: [...action.payload]}
        default:
            return state
    }
}
