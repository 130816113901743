import {firebaseStorage} from "../index";

const rewardStorageRef = (imageName) => firebaseStorage.ref(`rewards/${imageName}`);

const putRewardImage = (imageName, filePath) => {
    return rewardStorageRef(imageName).put(filePath)
}

const getDownloadUrlRewardImage = (imageName) => {
    return rewardStorageRef(imageName).getDownloadURL()
}

export {putRewardImage, rewardStorageRef, getDownloadUrlRewardImage}
