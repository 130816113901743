import React, {Component} from 'react';
import {Button, Card, Table} from "antd";
import _ from "lodash";
import {getEmailSettingListApi, updateEmailSettingApi} from "../../api/email.api";
import UpdateEmailSettingFormComponent from "./update-email-setting.component";
import {getEmailType} from "../../ui-util/general-variable";


class EmailSettingPage extends Component {

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Tipe Email',
                    dataIndex: 'emailType',
                    key: 'emailType',
                    render: (emailType) => <p>{getEmailType(emailType)}</p>
                },
                {
                    title: 'Recipients',
                    dataIndex: 'recipients',
                    key: 'recipients',
                    render: (recipients) => <div>{_.map(recipients, (r, i) => <p key={i}>{r}</p>)}</div>
                },
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (record) => (
                        <React.Fragment>
                            <Button
                                type="link"
                                onClick={() => this.onClickHandler(record)}
                            >
                                Edit Recipient
                            </Button>
                        </React.Fragment>
                    )
                }
            ],
            expandColumn: [{title: 'Recipient', dataIndex: 'recipients', key: 'recipients'}],
            itemList: [],
            loading: false,
            isVisible: false,
            recipients: {},
            selectedData: {
                id: '',
                emailType: '',
                recipients: []
            }
        }
    }

    componentDidMount() {
        this.setState({loading: true})
        getEmailSettingListApi()
            .then(result => {
                this.setState({itemList: result, loading: false})
            })
            .catch(err => {
                console.log("error getEmailSettingListApi :", err)
                this.setState({loading: false})
            })
    }


    onClickHandler = (value) => {
        this.setState({isVisible: true, selectedData: value})
    }

    onFinish = (item) => {
        const {selectedData} = this.state
        let body = {
            ...selectedData,
            ...item
        }

        updateEmailSettingApi(body)
            .then(result => {
                alert("Email berhasil diperbaharui !")
                window.location.reload()
            })
            .catch(err => {
                alert("Email gagal diperbaharui !")
                console.log("err updateEmailSettingApi :", err)
            })
    }

    render() {
        const {columns, loading, itemList, isVisible, selectedData} = this.state

        return (
            <Card>
                <h5 style={{marginBottom: 20, marginLeft: 20}}>Pengaturan Email</h5>
                <div>
                    <Table
                        className="components-table-demo-nested"
                        columns={columns}
                        loading={loading}
                        dataSource={itemList}
                        rowKey={'id'}
                        pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                    />
                </div>
                {
                    !!isVisible && <UpdateEmailSettingFormComponent
                        isVisible={isVisible} onFinish={this.onFinish}
                        onCloseHandler={() => this.setState({isVisible: false})}
                        item={selectedData}
                    />
                }
            </Card>
        );
    }

}

export default EmailSettingPage;