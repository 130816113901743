import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, DatePicker, Menu, Row, Select, Typography} from "antd";
import {Colors} from "../../ui-util/colors";
import moment from "moment";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";
import {
    dcReportDispatcher,
    getDcAvostoreSalesReport,
    getDcSalesReport,
    getMarginData,
    getTopSellingProduct
} from "../../actions/dc-report.action";
import {REPORT_DATE_RANGE, SET_DC_ID} from "../../reducers/dc-report.reducer";
import {getUserListByLevel} from "../../actions/user.action";
import _ from "lodash";
import ReportDcComponent from "./report-dc.component";
import ReportChainComponent from "./report-chain.component";

const {RangePicker} = DatePicker;
const {Text} = Typography;
const {Option} = Select;

class DcReport extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            grossSales: 0,
            currentTargetSales: 0,
            prevTargetSales: 0,
            selectedName: '',
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                }, {
                    title: 'SKU',
                    dataIndex: 'skuCode',
                    key: 'skuCode',
                }, {
                    title: 'Jumlah',
                    dataIndex: 'count',
                    key: 'count',
                }
            ],
            columnsMargin: [
                {
                    title: 'Total Sales',
                    dataIndex: 'grandSubTotal',
                    key: 'grandSubTotal',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Diskon',
                    dataIndex: 'marginPercent',
                    key: 'marginPercent',
                }, {
                    title: 'Total Diskon',
                    dataIndex: 'totalMargin',
                    key: 'totalMargin',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                },
                {
                    title: 'Total Sales - Margin',
                    dataIndex: 'total',
                    key: 'total',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }
            ],
            levels: [
                {
                    name: 'All',
                    value: ''
                },
                {
                    name: 'Platinum',
                    value: 'PLATINUM'
                },
                {
                    name: 'Premium 1',
                    value: 'PREMIUM_1'
                },
                {
                    name: 'Premium 2',
                    value: 'PREMIUM_2'
                },
            ],
            selectedLevel: {
                name: 'All',
                value: ''
            }
        }
    }

    componentDidMount() {
        this.props.getUserListByLevel('report')
        this.props.getDcSalesReport()
        this.props.getTopSellingProduct()
        this.props.getDcAvostoreSalesReport()
    }


    onDatePickerChange = (dates) => {
        this.props.dcReportDispatcher(REPORT_DATE_RANGE, {
            startDate: dates[0],
            endDate: dates[1]
        })
    }

    filterButtonHandler = () => {
        this.props.getDcSalesReport()
        this.props.getTopSellingProduct()
        this.props.getDcAvostoreSalesReport()
    }

    dropDownLevel = () => {
        let {levels} = this.state

        return (
            <Menu>
                {
                    levels.map((l, index) => (
                        <Menu.Item key={index}>
                            <Button type="link"
                                    onClick={() => this.selectedLevelHandler(l)}
                            >{l.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    selectedLevelHandler = (level) => {
        this.setState({selectedLevel: level})
        this.props.dcReportDispatcher(REPORT_DATE_RANGE, {level: level.value})
    }

    onFilterHandler = (userId) => {
        const {avostoreListReducer} = this.props
        const {avoStoreDcList} = avostoreListReducer
        let selectedUser = _.find(avoStoreDcList, v => v.id === userId)
        this.setState({selectedName: selectedUser.name})
        this.props.dcReportDispatcher(SET_DC_ID, {dcUserId: userId})
    }

    render() {
        const {dcReportReducer, avostoreListReducer} = this.props
        const {
            orderTotal,
            grandSubTotal,
            orderTotalAvoDc,
            grandSubTotalAvoDc,
            topProductListDc,
            topProductListChain,
            startDate,
            endDate,
            productOrderTotal,
            totalOrderDc,
            grandTotalOrderDc,
            totalProductOrderDc,
            productTotalAvoDc
        } = dcReportReducer
        const {avoStoreDcList} = avostoreListReducer
        const {loading, columns, selectedLevel, selectedName} = this.state
        let dcName = avoStoreDcList.length > 0 && !selectedName ? avoStoreDcList[0].name : selectedName

        return (
            <Card style={{backgroundColor: 'transparent'}}>
                <div>
                    <div>
                        <h3>{`Report ${dcName}`}</h3>
                    </div>
                    <Row style={{marginTop: 20}} justify={'space-between'} align="center">
                        <div>
                            Pilih Nama DC
                            <Select style={{width: 250, marginLeft: 10, marginRight: 10}}
                                    defaultValue={dcName}
                                    onChange={(item) => this.onFilterHandler(item)}>
                                {
                                    avoStoreDcList.map((item, index) => (
                                        <Option value={item.id} key={index.toString()}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.filterButtonHandler()}>
                                Filter
                            </Button>
                        </div>
                        <div>
                            <Text style={{marginRight: 10}}>Date Range</Text>
                            <RangePicker
                                allowClear={false}
                                style={{marginRight: 10}}
                                defaultValue={[startDate, endDate]}
                                disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                                onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                                format={'DD/MM/YYYY'}/>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                                    onClick={() => this.filterButtonHandler()}
                                    loading={loading}>Filter</Button>
                        </div>
                    </Row>
                    <ReportDcComponent orderTotal={orderTotal}
                                       grandSubTotal={grandSubTotal} selectedName={selectedName}
                                       topProductListDc={topProductListDc} columns={columns}
                                       productOrderTotal={productOrderTotal}
                                       totalOrderDc={totalOrderDc}
                                       grandTotalOrderDc={grandTotalOrderDc}
                                       totalProductOrderDc={totalProductOrderDc}
                    />
                    <ReportChainComponent orderTotal={orderTotalAvoDc}
                                          productTotalAvoDc={productTotalAvoDc}
                                          dropDownLevel={this.dropDownLevel}
                                          selectedLevel={selectedLevel}
                                          grandSubTotal={grandSubTotalAvoDc}
                                          filterButtonHandler={this.filterButtonHandler}
                                          loading={loading}
                                          topProductListChain={topProductListChain} columns={columns}/>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {dcReportReducer, avostoreListReducer} = state
    return {dcReportReducer, avostoreListReducer};
}

export default connect(mapStateToProps, {
    getDcSalesReport,
    getTopSellingProduct,
    getDcAvostoreSalesReport,
    getMarginData,
    dcReportDispatcher,
    getUserListByLevel
})(DcReport);
