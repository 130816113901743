import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    keyword: '',
    activeTab: 'PROCEED',
    orderList: [],
    currentPage: 1,
    pageSize: 10,
    dataCount: 0,
    loading: false,
    buyerId: ""
}

export const SAVE_ORDER_REDUCER = 'SAVE_ORDER_REDUCER'
export const FLUSH_PAGINATION_NDN = 'FLUSH_PAGINATION_NDN'

export const saveOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FLUSH_PAGINATION_NDN:
            return {...state, pageSize: 10, currentPage: 1, keyword: ''}
        case SAVE_ORDER_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
