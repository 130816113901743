import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const createFaqApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/faq/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getListFaqApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/faq/list`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const updateFaqApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v4/faq/update`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {createFaqApi, getListFaqApi, updateFaqApi}