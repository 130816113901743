import React from "react";
import {Button, Card, Col, DatePicker, Row, Tabs} from "antd";
import moment from "moment";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {dcPaymentDispatcher, getDcOrderList} from "../../actions/dc-payment.action";
import {DC_PAYMENT_REDUCER, FLUSH_PAGINATION_DC_PAYMENT} from "../../reducers/dc-payment.reducer";
import DcPaymentListComponent from "./dc-payment-list.component";
import SearchComponent from "../../components/search.component";

const {RangePicker} = DatePicker;
const {TabPane} = Tabs;

class DcPaymentPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'UNPAID',
                    tab: 'Belum Dibayar'
                }, {
                    key: 'PAID',
                    tab: 'Sudah Dibayar'
                }
            ],
        }
    }

    onChangeTab = (activeTab) => {
        this.props.dcPaymentDispatcher(FLUSH_PAGINATION_DC_PAYMENT)
        this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {activeTab})
    }

    componentWillUnmount() {
        this.props.dcPaymentDispatcher(FLUSH_PAGINATION_DC_PAYMENT)
        this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {activeTab: 'UNPAID'})
    }

    onSearchOrderHandler = (value) => {
        this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {keyword: value})
    }

    render() {
        const {tabList} = this.state
        const {startDate, endDate, activeTab} = this.props.dcPaymentReducer
        return (
            <Card>
                <Row type="flex" justify={'space-between'} align="middle">
                    <SearchComponent searchItem={'Pesanan'} onSearchHandler={this.onSearchOrderHandler} />
                    <Col span={14}>
                        <Col span={20} className="d-flex justify-content-around align-items-center">
                            Tgl. Pesanan
                            <RangePicker
                                allowClear={false}
                                style={{width: '60%'}}
                                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                                value={[startDate, endDate]}
                                onCalendarChange={(dates) => this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {
                                    startDate: dates[0],
                                    endDate: dates[1]
                                })}
                                disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                            />
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.props.getDcOrderList()}>Filter</Button>
                        </Col>
                    </Col>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onChangeTab}
                    tabBarStyle={{color: Colors.darkseagreen, marginTop: 10}}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <DcPaymentListComponent />
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    const {dcPaymentReducer} = state;

    return {dcPaymentReducer}
}

export default connect(mapStateToProps, {dcPaymentDispatcher, getDcOrderList})(DcPaymentPage)
