import React from "react";
import {Button, Card, Form, List, Select} from "antd";
import {connect} from "react-redux";
import {getAvostoreListAll} from "../../actions/user.action";
import _ from "lodash";
import {DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_2} from "../../models/user-detail.model";
import {updateParentRefApi} from "../../api/user.api";

const {Option} = Select;

class UpdateParentRefPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            updateParentRefForm: [
                {
                    key: 'parentRef',
                    label: 'Pilih Distribution Channel',
                    required: true,
                    messageAlert: 'Masukkan parentRef Distribution Channel'
                }, {
                    key: 'userList',
                    label: 'Pilih Chain DC',
                    required: true,
                    messageAlert: 'Masukkan chain DC'
                }
            ],
            loading: false
        }
    }

    componentDidMount() {
        this.props.getAvostoreListAll()
    }

    onFinish = (values) => {
        this.setState({loading: true})
        updateParentRefApi(values)
            .then(result => {
                this.setState({loading: false})
                alert('Parent AVO store berhasil di ubah!')
                window.location.href = '/avostore-list/list'
            })
            .catch(err => console.log('Error updateParentRefApi', err))
    }

    render() {
        const {updateParentRefForm, loading} = this.state
        const {avostoreListAll} = this.props.avostoreListReducer;
        let DC_1 = _.partition(avostoreListAll, {'level': DISTRIBUTION_CHANNEL_1})
        let DC_2 = _.partition(DC_1[1], {'level': DISTRIBUTION_CHANNEL_2})
        let userDC = DC_1[0].concat(DC_2[0])
        let userAvostore = DC_2[1]

        return (
            <Card>
                <h5 style={{marginBottom: 20, marginLeft: 20}}>Pengaturan Chain DC</h5>
                <Form style={{width: '60%', marginLeft: 20}} onFinish={this.onFinish}>
                    <List
                        dataSource={updateParentRefForm}
                        renderItem={(item, index) =>
                            <Form.Item
                                key={index.toString()}
                                label={item.label}
                                name={item.key}
                                rules={[{
                                    required: item.required,
                                    message: item.messageAlert,
                                }]}
                            >
                                <Select
                                    placeholder={item.placeholder}
                                    mode={item.key === 'userList' && "multiple"}
                                    showSearch={item.key === 'userList' ? true : false}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        item.key === 'parentRef' &&
                                        userDC.map((item, index) => (
                                            <Option value={item.referralId}
                                                    key={index.toString()}>{item.name}</Option>
                                        ))}
                                    {
                                        item.key === 'userList' &&
                                        userAvostore.map((item, index) => (
                                            <Option value={item.id}
                                                    key={index.toString()}>{item.name}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        }
                    />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Simpan
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreListReducer} = state;

    return {avostoreListReducer};
};

export default connect(mapStateToProps, {getAvostoreListAll})(UpdateParentRefPage)
