import React, {Component} from 'react';
import {doConvertNumberToRupiahFormat, generateMd5Id, getLevelText, randomNumber} from "../../ui-util/general-variable";
import {
    createProgressiveMarginApi,
    deleteProgressiveMarginApi,
    getProgressiveMarginApi,
    updateProgressiveMarginApi
} from "../../api/progressive-margin.api";
import {Button, Card, Divider, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import ProgressiveMarginFormComponent from "./progressive-margin-form.component";
import {Colors} from "../../ui-util/colors";

const INITIAL_VALUE = {
    id: null,
    level: null,
    minPurchaseValue: 0,
    margin: 0
}

class ProgressiveMargin extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    title: 'Level',
                    dataIndex: 'level',
                    key: 'level',
                    render: (level) => getLevelText(level)
                },
                {
                    title: 'Minimum Purchase Value',
                    dataIndex: 'minPurchaseValue',
                    key: 'minPurchaseValue',
                    render: (minPurchaseValue) => doConvertNumberToRupiahFormat(minPurchaseValue)
                },
                {
                    title: 'Margin',
                    dataIndex: 'margin',
                    key: 'margin'
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => <>
                        <Button icon={<EditOutlined/>} style={{margin: 5}}
                                onClick={() => this.onEditHandler(record)}/>
                        <Divider type={'vertical'}/>
                        <Button icon={<DeleteOutlined/>} style={{margin: 5}}
                                onClick={() => this.onDeleteHandler(record)}/>
                    </>
                },
            ],
            itemList: [],
            modalVisible: false,
            selectedItem: {...INITIAL_VALUE},
            slug: ''
        }
    }

    componentDidMount() {
        this.getListMargin()
    }

    getListMargin = () => {
        getProgressiveMarginApi()
            .then(result => {
                this.setState({itemList: result.data})
            })
            .catch(err => {
                alert("error get list data !")
                console.log("err get list data :", err)
            })
    }

    onEditHandler = (value) => {
        this.setState({modalVisible: true, selectedItem: value, slug: 'edit'})
    }

    onDeleteHandler = (value) => {
        let body = {
            id: value?.id,
            level: value?.level
        }

        deleteProgressiveMarginApi(body)
            .then(result => {
                alert("Data berhasil dihapus !")
                this.getListMargin()
            })
            .catch(err => {
                alert("Data gagal dihapus !")
                console.log("err :", err)
            })
    }

    onFinishHandler = (value) => {
        const {slug, selectedItem} = this.state

        if (slug === 'edit'){
            let body = {
                ...selectedItem,
                ...value
            }

            this.onUpdateHandler(body)
        }
        else{
            let body = {
                id: generateMd5Id(randomNumber(6)),
                ...value
            }

            this.onCreateNewMarginHandler(body)
        }
    }

    onCreateNewMarginHandler = (body) => {
        createProgressiveMarginApi(body)
            .then(result => {
                alert("Data berhasil ditambahkan !")
                this.getListMargin()
                this.setState({modalVisible: false, selectedItem: {...INITIAL_VALUE}})
            })
            .catch(err => {
                console.log("err :", err)
                alert("Data gagal ditambahkan !")
                this.setState({modalVisible: false, selectedItem: {...INITIAL_VALUE}})
            })
    }

    onUpdateHandler = (body) => {
        updateProgressiveMarginApi(body)
            .then(result => {
                alert("Data berhasil diperbaharui !")
                this.getListMargin()
                this.setState({modalVisible: false, selectedItem: {...INITIAL_VALUE}})
            })
            .catch(err => {
                console.log("err :", err)
                alert("Data gagal diperbaharui !")
                this.setState({modalVisible: false, selectedItem: {...INITIAL_VALUE}})
            })
    }

    onCancelHandler = () => {
        this.setState({modalVisible: false, selectedItem: {...INITIAL_VALUE}})
    }

    onCreateHandler = () => {
        this.setState({modalVisible: true})
    }

    render() {
        const {itemList, columns, modalVisible, selectedItem} = this.state

        return (
            <Card>
                <h5 style={{marginBottom: 30}}>Progressive Margin Component</h5>
                <div align={'end'} style={{marginBottom: 20}}>
                    <Button style={{color: 'white', backgroundColor: Colors.darkseagreen}}
                            onClick={this.onCreateHandler}>Add New Margin</Button>
                </div>
                <Table columns={columns}
                       pagination={false}
                       rowKey="id"
                       dataSource={itemList}
                />
                {
                    modalVisible &&
                    <ProgressiveMarginFormComponent onFinish={this.onFinishHandler}
                                                    modalVisibilityForm={modalVisible}
                                                    onCancelHandler={this.onCancelHandler}
                                                    selectedItem={selectedItem}
                    />
                }
            </Card>
        );
    }
}

export default ProgressiveMargin;