import React from "react";
import {Button, Card, Divider, Form, Input, Modal, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {
    formatUnixDateToReadable,
    generateMd5Id,
    getLevelText,
    isAdmin,
    isDC,
    isEqual,
    randomNumber
} from "../../ui-util/general-variable";
import {EditOutlined, SplitCellsOutlined} from "@ant-design/icons";
import {orderDispatcher} from "../../actions/order.action";
import {AVOSTORE_DETAIL} from "../../reducers/avostore-detail.reducer";
import {warehouseUpdateApi} from "../../api/warehouse.api";
import {createUserWarehouseApi} from "../../api/user.api";
import _ from "lodash";
import {WarehouseObject} from "../../models/warehouse.model";

class AvostoreDetailBodyComponent extends React.PureComponent {
    formRef = React.createRef();
    constructor() {
        super();

        this.state = {
            loading: false,
            isModalVisible: false,
            selectedWarehouse: {...WarehouseObject},
            formItem: [{
                label: 'Region',
                name: 'region',
                required: true,
                messageAlert: 'masukkan region'
            },]
        }
    }

    updateWarehouse = () => {
        const {warehouseAvoStoreList} = this.props.warehouseReducer
        this.setState({loading: true})
        warehouseAvoStoreList.forEach((item, index) => {
            this.setState({loading: true})
            item.valid = false
            this.disabledWarehouse(item, index)
        })
    }

    disabledWarehouse = (warehouseDetail, index) => {
        const {warehouseAvoStoreList} = this.props.warehouseReducer
        warehouseUpdateApi(warehouseDetail)
            .then(() => {
                if ((index + 1) === warehouseAvoStoreList.length) {
                    this.onProcessWarehouse()
                }
            })
            .catch(err => {
                console.log('Error warehouseUpdateApi', err)
            })
    }

    onProcessWarehouse = () => {
        const {avoskinWarehouseList, avostoreDetail} = this.props.avostoreDetailReducer
        avoskinWarehouseList.forEach((item, index) => {
            item.name = `Gudang ${item.city} - ${avostoreDetail.name}`
            item.phoneNumber = avostoreDetail.phoneNumber
            item.userId = avostoreDetail.id
            item.id = generateMd5Id(randomNumber(6))
            this.createWarehouse(item, index)
        })
    }

    createWarehouse = (warehouseDetail, index) => {
        const {avoskinWarehouseList} = this.props.avostoreDetailReducer
        createUserWarehouseApi(warehouseDetail)
            .then(result => {
                if ((index + 1) === avoskinWarehouseList.length) {
                    this.setState({loading: false})
                    alert('Warehouse berhasil di create');
                    window.location.reload()
                }
            })
            .catch(err => {
                console.log("error create warehouse :", err)
                alert('gagal update data !!');
                this.setState({visible: false});
            })
    }

    listOrderBy = (list) => {
        return _.orderBy(list, 'zipcode', 'asc')
    }

    onUpdateRegion = (item) => {
        this.setState({isModalVisible: true, selectedWarehouse: item})
    }

    onFinish = (value) => {
        let dataToUpdate = {
            ...this.state.selectedWarehouse,
            ...value
        }
        warehouseUpdateApi(dataToUpdate)
            .then(result => {
                this.setState({isModalVisible: false})
                window.location.reload()
            })
            .catch(err => {
                alert("error submit data !")
                console.log("warehouseUpdateApi region :", err)
            })
    }

    render() {
        const {loading, isModalVisible, formItem} = this.state
        const {avoskinWarehouseList, avostoreDetail} = this.props.avostoreDetailReducer
        const {joinDate, level, point, parentRef, userRole, updatedLevelAt, validForChallenge, validForProgressiveMargin} = avostoreDetail
        const {warehouseAvoStoreList} = this.props.warehouseReducer
        const avostoreWarehouseOrdered = this.listOrderBy(warehouseAvoStoreList)
        const avoskinWarehouseOrdered = this.listOrderBy(avoskinWarehouseList)

        return (
            <Card style={{marginTop: 20, borderWidth: 1, borderColor: Colors.darkseagreen}}>
                <Row>
                    <div style={{width: '48%', paddingRight: 20}}>
                        <div style={{width: '100%'}}>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>Level User</div>
                                <Row align={'middle'}>
                                    <div style={{marginLeft: 10}}>{getLevelText(level)}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true,
                                                formType: 'level'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>Poin User</div>
                                <Row align={'middle'}>
                                    <div style={{marginLeft: 10}}>{point}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true,
                                                formType: 'point'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>Parent Ref</div>
                                <Row align={'middle'}>
                                    <div style={{marginLeft: 10}}>{parentRef}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'parentRef'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>Join Date</div>
                                <Row align={'middle'}>
                                    <div
                                        style={{marginLeft: 10}}>{formatUnixDateToReadable(joinDate, 'DD MMMM YYYY')}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'joinDate'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>Last Update Level</div>
                                <Row align={'middle'}>
                                    <div
                                        style={{marginLeft: 10}}>{!!updatedLevelAt ? formatUnixDateToReadable(updatedLevelAt, 'DD MMMM YYYY') :
                                        formatUnixDateToReadable(joinDate, 'DD MMMM YYYY')}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'updateLevel'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 20}} justify={'space-between'}>
                                <div>User Valid For Challenge</div>
                                <Row align={'middle'}>
                                    <div style={{marginLeft: 10}}>{!!validForChallenge && validForChallenge === true ? 'YA' : 'TIDAK'}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'validForChallenge'
                                            })}/>
                                </Row>
                            </Row>
                            <Row style={{alignItems: 'center'}} justify={'space-between'}>
                                <div>User Valid For Progresif Margin</div>
                                <Row align={'middle'}>
                                    <div style={{marginLeft: 10}}>{!!validForProgressiveMargin && validForProgressiveMargin === true ? 'YA' : 'TIDAK'}</div>
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'validForProgressiveMargin'
                                            })}/>
                                </Row>
                            </Row>
                        </div>
                    </div>
                    <Divider type={'vertical'} style={{borderWidth: 1, height: 300, borderColor: Colors.darkseagreen}}/>
                    <div style={{width: '48%', marginLeft: 20}}>
                        <div style={{width: '100%'}}>
                            <Row style={{alignItems: 'center', marginBottom: 10}} justify={'space-between'}>
                                <Row style={{width: '80%'}}>
                                    <div style={{width: '25%'}}>User Role</div>
                                    <div>:</div>
                                    <div style={{marginLeft: 10}}>{userRole}</div>
                                </Row>
                                {
                                    isAdmin() &&
                                    <Button icon={<EditOutlined color={Colors.darkseagreen}/>}
                                            style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                            onClick={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {
                                                modalVisibilityForm: true, formType: 'updateUserRole'
                                            })}/>
                                }
                            </Row>
                            <Row style={{marginBottom: 10}} justify={'space-between'}>
                                <Row style={{width: '80%'}}>
                                    <div style={{width: '25%'}}>Warehouse</div>
                                    <div>:</div>
                                    <div style={{marginLeft: 10, width: '71%'}}>
                                        {
                                            warehouseAvoStoreList.map((item, index) => {
                                                return (
                                                    <Row key={index.toString()} style={{marginBottom: 10}}>
                                                        <div>{index + 1}. {item.name}. {item.address}, {item.district}, {item.city}, {item.province}</div>
                                                        <Row justify={'space-between'}>
                                                            <div style={{paddingTop: 8}}> Region : {item?.region ? item.region : ''}</div>
                                                            <Button
                                                                icon={<EditOutlined color={Colors.darkseagreen}/>}
                                                                style={{borderWidth: 0, color: Colors.darkseagreen}}
                                                                onClick={() => this.onUpdateRegion(item)}
                                                            />
                                                        </Row>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                                {
                                    isDC(level) ?
                                        (isEqual(avostoreWarehouseOrdered[0]?.zipcode, avoskinWarehouseOrdered[0]?.zipcode) &&
                                            isEqual(avostoreWarehouseOrdered[1]?.zipcode, avoskinWarehouseOrdered[1]?.zipcode))
                                            ? null :
                                            <Button icon={ <SplitCellsOutlined color={!loading && Colors.darkseagreen} spin={loading}/>}
                                                    disabled={loading}
                                                    style={{marginLeft: 10, borderWidth: 0, color: Colors.darkseagreen}}
                                                    onClick={() => this.updateWarehouse()}/>
                                        : null
                                }
                            </Row>
                        </div>
                    </div>
                </Row>
                {
                    isModalVisible &&
                    <Modal
                        title={"Update Region"}
                        visible={isModalVisible}
                        onCancel={() => this.setState({isModalVisible : false, selectedWarehouse: {...WarehouseObject}})}
                        footer={null}
                    >
                        <Form name="dynamic_form_nest_item" style={{width: '70%'}} onFinish={this.onFinish}
                              ref={this.formRef}>
                            {
                                _.map(formItem, (v,k) => {
                                    return(
                                        <Form.Item
                                            key={k}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    )
                                })
                            }
                            <Form.Item style={{alignItems: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreDetailReducer, warehouseReducer} = state;

    return {avostoreDetailReducer, warehouseReducer};
}

export default connect(mapStateToProps, {orderDispatcher})(AvostoreDetailBodyComponent)
