import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Col, Descriptions, Modal, Row, Tag} from 'antd';
import {
    CANCELLED,
    COMPLETED,
    DC_INSTALLMENT,
    DC_PREORDER,
    DC_REGULAR,
    DELIVERY,
    GUARANTEE,
    PACKAGING,
    PENDING,
    PREORDER,
    REGULAR,
    SUBMITTED
} from '../../models/order-detail.model';
import {getOrderDetailById} from '../../firebase/database/orders';
import ProductList from '../order-detail/product-list.component';
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    getDateEpochFormat,
    randomNumber
} from '../../ui-util/general-variable';
import ShipmentTracking from './shipment-tracking.component';
import OrderHistoryTimeline from './order-history-timeline.component'
import {getOrderDetailFromApi, setOrderAuditApi} from "../../api/order.api";
import OrderStockHistoryTimelineComponent from "./order-stock-history-timeline.component";
import PaymentDetailComponent from "./payment-detail.component";
import TaxInvoiceComponent from "./tax-invoice.component";
import {UserDetailObject} from "../../models/user-detail.model";
import TaxInvoiceRequestComponent from "./tax-invoice-request.component";
import {Colors} from "../../ui-util/colors";
import ModalAlertComponent from "../../components/modal-alert.component";
import OrderAuditState from "../../models/OrderAuditState.json";
import {updateRelatedDataOrderFromApi, updateRemainingPaymentPreOrderApi} from "../../api/pre-order.api";
import GiftListComponent from "./gift-list.component";
import UpdatePaymentDetailModal from "./update-payment-detail.modal";
import moment from "moment";
import _ from 'lodash'
import PreviewPaymentProofModal from "../guarantee-detail/preview-payment-proof.modal";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class OrderDetail extends PureComponent {
    constructor() {
        super();

        this.state = {
            orderDetail: null,
            disbursementDetail: null,
            buyerDetail: {...UserDetailObject},
            guaranteeDetail: {},
            remainingPaymentPreOrderDetail: {},
            poPaymentModalVisible: false,
            paymentProof: {},
            previewImageModalVisilibity: false,
            taxDetail: {}

        };
    }

    componentDidMount(): void {
        getOrderDetailFromApi(this.props.match.params.id)
            .then(result => {
                let {
                    disbursementDetail,
                    orderDetail,
                    buyerDetail,
                    guaranteeDetail,
                    remainingPaymentPreOrderDetail,
                    taxDetail
                } = result.data
                this.setState({
                    disbursementDetail,
                    orderDetail,
                    buyerDetail,
                    guaranteeDetail,
                    remainingPaymentPreOrderDetail,
                    taxDetail
                })
            })
            .catch(err => {
                console.log("getOrderDetailFromApi :", err)
                alert('Gagal mendapatkan rincian pesanan');
            })
    }

    getInvoiceButton = (orderDetail) => {
        const {guaranteeDetail, remainingPaymentPreOrderDetail} = this.state
        const {orderType, paymentDetail} = orderDetail

        switch (true) {
            case orderType === PREORDER && remainingPaymentPreOrderDetail?.status === COMPLETED:
            case orderType === GUARANTEE && guaranteeDetail?.status === COMPLETED:
            case (orderType === REGULAR || orderType === DC_REGULAR) && paymentDetail.transactionStatus === 'paid':
            case orderType === DC_INSTALLMENT:
            case orderType === DC_PREORDER:
                return <Tag key={'invoice'}>
                    <a style={{color: Colors.darkseagreen}} href={orderDetail?.snapLink} target="_blank"
                       download>Invoice</a>
                </Tag>
            default:
                return null
        }
    }

    renderOrderStateTag = (orderDetail) => {
        const {orderState, paymentDetail} = orderDetail;

        let color = 'orange';
        let label = 'BELUM DIBAYAR';
        if (orderState === SUBMITTED && paymentDetail.transactionStatus === 'paid') {
            color = 'green';
            label = 'SUDAH DIBAYAR';
        } else if (orderState === PENDING && paymentDetail.transactionStatus === 'paid') {
            color = 'green';
            label = 'SUDAH DIBAYAR';
        } else if (orderState === CANCELLED) {
            color = 'black';
            label = 'DIBATALKAN';
        } else if (orderState === COMPLETED) {
            color = 'gray';
            label = 'SELESAI';
        } else if (orderState === DELIVERY) {
            color = 'blue';
            label = 'SEDANG DIKIRIM';
        } else if (orderState === PACKAGING) {
            color = 'red';
            label = 'SEDANG DIPROSES';
        }

        return (
            <>
                {
                    this.getInvoiceButton(orderDetail)
                }
                <Tag color={color} key={orderState}>
                    {label}
                </Tag>
            </>
        );
    };

    setModalVisibility = (value) => {
        this.setState({modalVisible: value})
    }

    cancelOrderHandler = () => {
        const {orderDetail} = this.state;
        let requestBody = {
            uid: orderDetail.sellerId,
            orderDetail: {
                ...orderDetail,
                orderState: CANCELLED
            }
        }

        updateRelatedDataOrderFromApi(requestBody)
            .then(() => {
                this.setOrderAudit()
            })
            .catch(err => {
                this.setModalVisibility(false)
                alert("error !!")
                console.log("updateOrderDetailAPi :", err)
            })
    }

    setOrderAudit = () => {
        const {orderDetail} = this.state;
        let auditOrderDetail = {
            id: generateMd5Id(randomNumber(6)),
            orderId: orderDetail.id,
            actorId: orderDetail.buyerId,
            action: listOfKey[2],
            description: OrderAuditState.SELLER.ORDER_CANCELED_BY_SELLER,
            timestamp: getDateEpochFormat()
        }

        setOrderAuditApi(auditOrderDetail)
            .then(result => {
                this.setModalVisibility(false)
                window.location.reload()
            })
            .catch(error => {
                console.log("setOrderAuditApi :", error)
            })
    }

    onFinish = (value, fileImage) => {
        const {remainingPaymentPreOrderDetail} = this.state;
        let remainingPaymentToUpdate = {
            ...remainingPaymentPreOrderDetail,
            status: COMPLETED,
            paymentMethod: value.paymentMethod,
            payment: {
                transactionStatus: value.transactionStatus,
                transactionId: value.transactionId,
                transactionTime: moment(value.transactionTime).format('YYYY-MM-DD HH:mm:ss'),
                vaNumber: value.vaNumber
            }
        }

        updateRemainingPaymentPreOrderApi(remainingPaymentToUpdate)
            .then(r => {
                this.setState({poPaymentModalVisible: false})
                alert('data berhasil disimpan !!')
                window.location.reload()
            })
            .catch(err => {
                console.log("err updateRemainingPaymentPreOrderApi:", err)
                alert('gagal menyimpan data pembayaran !!')
            })
    }

    isDisabledPaymentPo = () => {
        const {remainingPaymentPreOrderDetail, orderDetail} = this.state

        switch (true) {
            case orderDetail?.paymentDetail?.transactionStatus !== 'paid':
            case remainingPaymentPreOrderDetail?.payment?.transactionStatus === 'paid':
                return true
            default:
                return false
        }
    }

    onPressHandler = (proofOfPayments) => {
        this.setState({previewImageModalVisilibity: true, paymentProof: proofOfPayments})
    }


    render() {
        const {
            orderDetail,
            disbursementDetail,
            buyerDetail,
            modalVisible,
            remainingPaymentPreOrderDetail,
            poPaymentModalVisible,
            previewImageModalVisilibity,
            paymentProof,
            taxDetail
        } = this.state;

        return (
            <div>
                {!!orderDetail && (
                    <Row>
                        <Col span={18} style={{padding: 20}}>
                            <Card>
                                <div className="d-flex justify-content-between">
                                    <h5 style={{width: 300}}>Rincian Pesanan </h5>
                                    <h5>{this.renderOrderStateTag(orderDetail)}</h5>
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <h6 style={{margin: 0}}>No. Pesanan</h6>
                                    {orderDetail.orderId}
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <h6 style={{margin: 0}}>Nama</h6>
                                    {orderDetail.isOrderDc ? buyerDetail.name : orderDetail.shippingAddress.receiver}
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <h6 style={{margin: 0}}>Kode Voucher</h6>
                                    {orderDetail?.voucherName && _.map(orderDetail?.voucherName, v => v).join(' , ')}
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <h6 style={{margin: 0}}>Alamat Pengiriman</h6>
                                    {orderDetail.shippingAddress.address}, {orderDetail.shippingAddress.district},
                                    {orderDetail.shippingAddress.city}, {orderDetail.shippingAddress.province}
                                </div>
                                <ShipmentTracking orderDetail={orderDetail}/>
                            </Card>
                            {
                                remainingPaymentPreOrderDetail?.id &&
                                <Card style={{marginTop: 20}}>
                                    <div className="d-flex justify-content-between">
                                        <h5 style={{width: 300}}>Rincian Pembayaran Preorder</h5>
                                        <div style={{flexDirection: 'row'}}>
                                            {
                                                remainingPaymentPreOrderDetail?.payment?.proofOfPayments?.length > 0 &&
                                                <Button style={{marginRight: 20}}
                                                        onClick={() => this.onPressHandler(remainingPaymentPreOrderDetail?.payment?.proofOfPayments)}>
                                                    Lihat Bukti Pembayaran
                                                </Button>
                                            }
                                            <Button
                                                onClick={() => this.setState({poPaymentModalVisible: true})}
                                                disabled={this.isDisabledPaymentPo()}
                                                style={{
                                                    backgroundColor: Colors.gainsboro,
                                                    borderColor: Colors.darkseagreen,
                                                    borderRadius: 5
                                                }}>Update Sisa Pembayaran
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{paddingTop: 30}}>
                                        <h6 style={{margin: 0}}>No. Pesanan</h6>
                                        {orderDetail.orderId}
                                    </div>
                                    <div style={{paddingTop: 30}}>
                                        <h6 style={{margin: 0}}>No Pre Order</h6>
                                        {remainingPaymentPreOrderDetail?.poPaymentId}
                                    </div>
                                    <div style={{paddingTop: 30}}>
                                        <h6 style={{margin: 0}}>PO Status</h6>
                                        {remainingPaymentPreOrderDetail?.status}
                                    </div>
                                    <div style={{paddingTop: 30}}>
                                        <h6 style={{margin: 0}}>Total Sisa Pembayaran</h6>
                                        {doConvertNumberToRupiahFormat(remainingPaymentPreOrderDetail?.payAmount)}
                                    </div>
                                </Card>
                            }
                            <ProductList taxDetail={taxDetail} orderDetail={orderDetail}/>
                            {orderDetail?.gifts?.length > 0 && <GiftListComponent orderDetail={orderDetail}/>}
                        </Col>
                        <Col span={6} style={{paddingTop: 20, paddingRight: 20}}>
                            <OrderHistoryTimeline orderId={orderDetail.id}/>
                            <OrderStockHistoryTimelineComponent orderId={orderDetail.orderId}/>
                            {
                                orderDetail.orderState === SUBMITTED &&
                                <Card style={{marginTop: 30}}>
                                    <Descriptions title="Pembatalan Pesanan" layout="vertical" column={1}/>
                                    <Row justify={"space-between"} style={{marginBottom: 20}}>
                                        <Button
                                            onClick={() => this.setState({modalVisible: true})}
                                            style={{backgroundColor: Colors.gainsboro}}
                                        >
                                            Batalkan Pesanan
                                        </Button>
                                    </Row>
                                </Card>
                            }
                            <PaymentDetailComponent orderDetail={orderDetail}/>
                            {
                                orderDetail.isOrderDc
                                && orderDetail.paymentDetail.transactionStatus === 'paid'
                                && !orderDetail?.taxInvoice
                                && <TaxInvoiceRequestComponent orderDetail={orderDetail}/>
                            }
                            {!!disbursementDetail.payoutReferenceNo && (
                                <Card style={{marginTop: 30}}>
                                    <Descriptions title="Pencairan Dana" layout="vertical" column={1}>
                                        <Descriptions.Item label="Waktu Pengajuan">
                                            {formatUnixDateToReadable(disbursementDetail.created_at, 'll')}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="ID referensi">
                                            {disbursementDetail.payoutReferenceNo}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Status">
                                            <Tag color="blue">
                                                {disbursementDetail.payoutStatus.toUpperCase()}
                                            </Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            )}
                            {
                                orderDetail.isOrderDc
                                && orderDetail.paymentDetail.transactionStatus === 'paid'
                                && <TaxInvoiceComponent orderDetail={orderDetail}/>
                            }
                        </Col>
                    </Row>
                )}
                <ModalAlertComponent
                    title={'Pembatalan Pesanan'}
                    modalVisibility={modalVisible}
                    setModalVisibility={this.setModalVisibility}
                    handleOK={this.cancelOrderHandler}
                    modalMessage={'Apakah anda yakin akan membatalkan pesanan ini ?'}
                />
                {
                    poPaymentModalVisible &&
                    <Modal
                        title={'Update Sisa Pembayaran PO'}
                        visible={poPaymentModalVisible}
                        onCancel={() => this.setState({poPaymentModalVisible: false})}
                        footer={null}
                    >
                        <UpdatePaymentDetailModal orderDetail={orderDetail} onFinish={this.onFinish}/>
                    </Modal>
                }
                {
                    previewImageModalVisilibity &&
                    <PreviewPaymentProofModal
                        paymentProof={paymentProof}
                        isVisible={previewImageModalVisilibity}
                        onCancel={() => this.setState({previewImageModalVisilibity: false})}
                    />
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    const {warehouseReducer} = state;
    return {warehouseReducer}
};

export default connect(mapStateToProps, {getOrderDetailById})(OrderDetail);
