import React from "react";
import {Card} from "antd";
import CreateProductFormComponent from "../product-create/create-product-form.component";
import {connect} from "react-redux";

class UpdateProductPage extends React.PureComponent{
    render() {
        const {productDetail} = this.props.productReducer

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Update Produk</h3>
                <CreateProductFormComponent productDetailUpdate={productDetail} slug={'edit'}/>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {productReducer} = state

    return {productReducer}
}

export default connect(mapStateToProps, {})(UpdateProductPage)
