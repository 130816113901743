import React, {Component} from 'react';
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";
import {setSalesDetailTable} from "../../ui-util/sales-detail-table-util";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportSalesDetail extends Component {
    render() {
        const {salesDetail} = this.props;
        let dataList = setSalesDetailTable(salesDetail)

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={dataList} name="Revenue Sales List">
                    <ExcelColumn label="Order Id" value="orderId"/>
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Gross Amount" value="grossAmount"/>
                    <ExcelColumn label="Total Payment Received" value="totalPaymentReceived"/>
                    <ExcelColumn label="Cashback" value="cashback"/>
                    <ExcelColumn label="Shipping" value="shipping"/>
                    <ExcelColumn label="Voucher" value="voucher"/>
                    <ExcelColumn label="Point" value="pointUsage"/>
                    <ExcelColumn label="Net Sales" value="netSales"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
