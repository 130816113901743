import {generateMd5Id} from "../ui-util/general-variable";


export class GenericDataModel {
    id: String
    valid: Boolean

    constructor(genericDataModel: GenericDataModel) {
        Object.assign(this, genericDataModel)
    }
}

export const GenericDataObj = {
    id: generateMd5Id(),
    valid: true
}
