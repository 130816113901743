import {RAJAONGKIR_KEY, RAJAONGKIR_URL, AUTHORIZATION_KEY} from "./thirth-party.config"
import {generateCodeShippingMethod, cloudFunctionBaseUrl} from "../ui-util/general-variable"
import axios from 'axios'

const getShipmentTracking = (awb, shippingCourier) => {
    const shippingMethod = generateCodeShippingMethod(shippingCourier);

    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/awb/check`,
            {
                awb,
                shippingMethod
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data.result)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getAirWayBillStateRajaOngkir = (awb, shippingCourier) => {
    let courierCode = generateCodeShippingMethod(shippingCourier);
    return new Promise((resolve, reject) => {
            axios.post(
                `${RAJAONGKIR_URL}/waybill`,
                {
                    waybill: `${awb}`,
                    courier: `${courierCode}`
                },
                {
                    headers: {
                        'content-type': 'application/json',
                        'key': `${RAJAONGKIR_KEY}`
                    },
                })
                .then(function (response) {
                    resolve(response.data.rajaongkir.result)
                }).catch(function (err) {
                    reject(err)
                }
            )
        })
}

export {
    getShipmentTracking,
    getAirWayBillStateRajaOngkir
}
