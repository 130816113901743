import React from "react";
import {Table} from "antd";
import {connect} from "react-redux";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {Link} from "react-router-dom";
import GuaranteeTerminListComponent from "./guarantee-termin-list.component";
import {guaranteeDispatcher} from "../../actions/guarantee.action";
import {GUARANTEE_REDUCER} from "../../reducers/guarantee.reducer";

class GuaranteeListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'userGuaranteeName',
                    key: 'userGuaranteeName',
                }, {
                    title: 'Order Id',
                    dataIndex: 'orderId',
                    key: 'orderId',
                }, {
                    title: 'Guarantee Id',
                    dataIndex: 'billingId',
                    key: 'guaranteeId',
                }, {
                    title: 'Total',
                    dataIndex: 'orderGrandTotal',
                    key: 'orderGrandTotal',
                    render: (orderGrandTotal) => doConvertNumberToRupiahFormat(orderGrandTotal)
                }, {
                    title: 'Order Date',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (orderDate) => formatUnixDateToReadable(orderDate)
                }, {
                    title: 'Payment Late',
                    dataIndex: 'paymentLate',
                    key: 'status',
                    render: (paymentLate) => <div>{paymentLate.days} hari</div>
                },
                {
                    title: 'Aksi',
                    key: 'action',
                    render: (guaranteeDetail) => this.guaranteeDetailButton(guaranteeDetail)
                }
            ]
        }
    }

    guaranteeDetailButton = (guaranteeDetail) => {
        const {guaranteeType} = this.props.guaranteeReducer
        return (
            <Link to={`/guarantee-detail/${guaranteeType?.value}/${guaranteeDetail.id}`} target="_blank">
                Rincian
            </Link>
        )
    }

    expandTable = (record) => {
        return (
            <>
                <Table
                    columns={[
                        {
                            key: 'dueDate', dataIndex: 'dueDate', title: 'Batas Pembayaran',
                            render: (dueDate) => formatUnixDateToReadable(dueDate, 'DD-MM-YYYY')
                        }, {
                            key: 'cicilan', dataIndex: 'payAmount', title: 'Jumlah Cicilan',
                            render: (payAmount) => doConvertNumberToRupiahFormat(payAmount)
                        }, {
                            key: 'payAmount', dataIndex: 'totalPayAmount', title: 'Total Pembayaran',
                            render: (totalPayAmount) => totalPayAmount && doConvertNumberToRupiahFormat(totalPayAmount)
                        }, {
                            key: 'transactionStatus', dataIndex: 'paymentDetail', title: 'Status Transaksi',
                            render: (paymentDetail) => paymentDetail.transactionStatus
                        }, {
                            key: 'transactionTime', dataIndex: 'paymentDetail', title: 'Waktu Transaksi',
                            render: (paymentDetail) => paymentDetail.transactionTime
                        }, {
                            key: 'fines', title: 'Denda',
                            render: (record) => record.paymentDetail.transactionStatus === 'paid' ? doConvertNumberToRupiahFormat(record.fines) : doConvertNumberToRupiahFormat(record.totalFines)
                        },
                        {
                            key: 'paymentLate', dataIndex: 'daysLateness', title: 'Keterlambatan /hari',
                            render: (daysLateness) => daysLateness > 0 && <div>{daysLateness} hari</div>
                        }
                    ]}
                    dataSource={record.payment}
                    rowKey={'dueDate'}
                    pagination={false}
                />
            </>
        )
    }

    onChangePagination = (currentPage) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {currentPage})
    }

    onShowSize = (currentPage, pageSize) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {currentPage, pageSize})
    }

    render() {
        const {guaranteeList, totalData, currentPage, loading} = this.props.guaranteeReducer
        const {columns} = this.state

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={guaranteeList}
                expandedRowRender={(record) => <GuaranteeTerminListComponent guaranteeDetail={record} />}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: totalData,
                    current: currentPage,
                    onChange: this.onChangePagination,
                    onShowSizeChange: this.onShowSize,
                    showSizeChanger: true,
                }}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const {guaranteeReducer} = state

    return {guaranteeReducer}
}

export default connect(mapStateToProps, {guaranteeDispatcher})(GuaranteeListComponent)
