import {firebaseStorage} from "../index";

const paymentProofRef = (imageName) => firebaseStorage.ref('paymentProof').child(imageName)

const paymentProofStorageRef = (imageName) => firebaseStorage.ref(`paymentProof/${imageName}`);

const putPaymentProofImage = (fileName, file) => {
    return paymentProofStorageRef(fileName).put(file)
}

export {
    paymentProofRef,
    putPaymentProofImage
}
