import React, {Component} from 'react';
import {Button, Col, Row, Select} from "antd";
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from "../../models/user-detail.model";
import {Colors} from "../../ui-util/colors";
import {SET_BUYER_LEVEL} from "../../reducers/order.reducer";
import {connect} from "react-redux";
import {generalDispatcher} from "../../actions/general.action";

const { Option } = Select;

class FilterByBuyerComponent extends Component {
    onFilterHandler = (level) => {
        this.props.generalDispatcher(SET_BUYER_LEVEL, {buyerLevel: level})
    }

    render() {
        const {handleButtonFilterClick} = this.props

        return (
            <Row type="flex" align="middle">
                <Col span={30} className="d-flex justify-content-around align-items-center">
                    Buyer Level:
                    <Select defaultValue={''} style={{width: 250, margin: 10}} onChange={(level) => this.onFilterHandler(level)}>
                        <Option value={''}>ALL</Option>
                        <Option value={PREMIUM_2}>PREMIUM 2</Option>
                        <Option value={PREMIUM_1}>PREMIUM 1</Option>
                        <Option value={PLATINUM} >PLATINUM</Option>
                        <Option value={BRONZE}>BRONZE</Option>
                        <Option value={END_CUSTOMER}>END CUSTOMER</Option>
                        <Option value={DISTRIBUTION_CHANNEL_1}>DISTRIBUTION CHANNEL 1</Option>
                        <Option value={DISTRIBUTION_CHANNEL_2}>DISTRIBUTION CHANNEL 2</Option>
                        <Option value={DISTRIBUTION_CHANNEL_3}>DISTRIBUTION CHANNEL 3</Option>
                    </Select>
                    <Button style={{ backgroundColor: Colors.darkseagreen, color: 'white' }} onClick={() => handleButtonFilterClick()}>
                        Filter
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default connect(null, {generalDispatcher})(FilterByBuyerComponent);
