import {UserDetailObject} from "../models/user-detail.model";

const INITIAL_STATE = {
    avostoreDetail: {...UserDetailObject},
    auditPoint: [],
    modalVisibilityForm: false,
    formType: 'level',
    avoskinWarehouseList: []
}

export const AVOSTORE_DETAIL = 'AVOSTORE_DETAIL';
export const AVOSTORE_POINT_HISTORY = 'AVOSTORE_POINT_HISTORY';

export const avostoreDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AVOSTORE_DETAIL:
            return {...state, ...action.payload}
        case AVOSTORE_POINT_HISTORY:
            return {...state, ...action.payload}
        default:
            return state
    }
}
