import React, {Component} from 'react';
import {Card, Image, Table} from "antd";

class GiftListComponent extends Component {
    constructor() {
        super()

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (url) => <Image src={url} width={100} height={100}/>
                },
                {
                    title: 'Nama',
                    dataIndex: 'itemName',
                    key: 'itemName',
                    width: 250
                },
                {
                    title: 'Detail Gift',
                    dataIndex: 'description',
                    key: 'description',
                    width: 300,
                    render: (description) => !!description && description !== "null" ? description : ''
                },
                {
                    title: 'Jumlah',
                    dataIndex: 'count',
                    key: 'count'
                },
            ]
        }
    }

    render() {
        const {columns} = this.state;
        const {gifts} = this.props.orderDetail;

        return (
            <Card style={{marginTop: 30}}>
                <h5>Informasi Gift</h5>
                <Table
                    pagination={false}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={gifts}
                    size="middle"
                />
            </Card>
        );
    }
}

export default GiftListComponent;