import React from "react";
import {Button, Form, Input, Modal} from "antd";
import _ from "lodash";

class TaxInvoiceModal extends React.PureComponent{

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    label: 'Nama',
                    name: 'name',
                    messageAlert: 'Mohon masukkan nama',
                    placeholder: 'Cth: Agnes Monica',
                    required: true
                }, {
                    label: 'NIK',
                    name: 'identityNumber',
                    messageAlert: 'Mohon masukkan NIK',
                    placeholder: 'Cth: 12345678901234567890',
                    required: true
                },{
                    label: 'NPWP',
                    name: 'taxNumber',
                    messageAlert: 'Mohon masukkan NPWP',
                    placeholder: 'Cth: 12345678901234567890',
                    required: false
                },{
                    label: 'Nama Badan Usaha',
                    name: 'companyName',
                    messageAlert: 'Mohon masukkan nama badan usaha',
                    placeholder: 'Cth: PT. Maju Lancar',
                    required: false
                },
            ],
        }
    }

    render() {
        const {modalVisibility, onCancel, onFinish} = this.props
        const {columns} = this.state
        return (
            <Modal
                title={'Permintaan Faktur Pajak'}
                visible={modalVisibility}
                onCancel={onCancel}
                footer={null}
            >
                <Form onFinish={onFinish}>
                    {
                        _.map(columns, (item, index) =>
                            <Form.Item
                                key={index.toString()}
                                label={item.label}
                                name={item.name}
                                rules={[{
                                    required: item.required,
                                    message: item.messageAlert,
                                }]}
                            >
                                <Input placeholder={item.placeholder}/>
                            </Form.Item>
                        )
                    }

                    <Form.Item style={{alignItems: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Simpan
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default TaxInvoiceModal
