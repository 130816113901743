import moment from "moment";

const INITIAL_STATE = {
    activeTab: 'ACTIVE',
    paymentPreOrderList: [],
    loading: false,
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    searchKeyword: ''
}

export const PRE_ORDER_PAYMENT = 'PRE_ORDER_PAYMENT'

export const preOrderPaymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRE_ORDER_PAYMENT:
            return {...state, ...action.payload}
        default:
            return state
    }
}
