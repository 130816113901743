import React, {PureComponent} from "react";
import {Card} from "antd";
import CreateNotificationComponent from "./create-notification.component";

class NotificationPage extends PureComponent{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Card>
                <CreateNotificationComponent />
            </Card>
        )
    }
}

export default NotificationPage
