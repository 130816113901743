import React from 'react'
import {Form, InputNumber} from "antd";

class ProductStockFormEditComponent extends React.PureComponent {
    formRef = React.createRef();

    componentDidMount(): void {
        const {productDetail} = this.props
        this.formRef.current.setFieldsValue(productDetail)
    }

    render() {
        const {onChange} = this.props
        return (
            <Form ref={this.formRef}>
                <Form.Item
                    name={'stock'}
                    rules={[
                        {
                            required: true,
                            message: `Please Input stock!`,
                        },
                    ]}
                >
                    <InputNumber onChange={onChange}/>
                </Form.Item>
            </Form>
        )
    }
}

export default ProductStockFormEditComponent
