import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from './routes';
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import rootReducer from './reducers'
import './App.css';
import thunk from 'redux-thunk';
import Dashboard from "./pages/dashboard";

require('dotenv').config()

const store = createStore(rootReducer, {}, applyMiddleware(thunk))

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <Dashboard content={<Routes/>} />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App;
