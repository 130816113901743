import React from 'react';
import {Button, Card, Col, DatePicker, Dropdown, Menu, Row, Tabs} from "antd";
import {avoskinId, isEqual, onScrollToTop} from "../../ui-util/general-variable";
import {Colors} from "../../ui-util/colors";
import moment from "moment";
import _ from "lodash";
import {setTransactionListToExport} from "../../ui-util/table-util";
import ExportTransactionOrderComponent from "./export-transaction-order.component";
import SearchComponent from "../../components/search.component";
import ExportTransactionGuaranteeComponent from "./export-transaction-guarantee.component";
import {getUserListApi} from "../../api/user.api";
import {DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3} from "../../models/user-detail.model";
import {SET_TRANSACTION_LIST_TAX} from "../../reducers/tax.reducer";
import {getTransactionList, taxDispatcher} from "../../actions/tax.action";
import {connect} from "react-redux";
import TaxOrderComponent from "./tax-order.component";
import TaxGuaranteeComponent from "./tax-guarantee.component";
import TaxPreorderComponent from "./tax-preorder.component";
import ExportTransactionPreorderComponent from "./export-transaction-preorder.component";

const {RangePicker} = DatePicker;
const {TabPane} = Tabs;

class TaxInvoicePage extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'ORDER',
                    tab: 'Order'
                },
                {
                    key: 'GUARANTEE',
                    tab: 'Guarantee'
                },
                {
                    key: 'PREORDER',
                    tab: 'Pre Order'
                }
            ],
            columns: [],
            transactionList: [],
            guaranteeList: [],
            filterStartDate: moment().subtract(30, 'days'),
            filterEndDate: moment(),
            searchKey: '',
            selectedRow: [],
            selectedRowKeys: [],
            loading: false,
            activeTab: 'ORDER',
            selectedSeller: {
                name: 'All',
                id: ''
            },
            userList: [{name: 'All', id: ''}]
        }
    }

    componentDidMount() {
        this.props.taxDispatcher(SET_TRANSACTION_LIST_TAX, {sellerId: ''})
        this.setState({columns: this.state.orderColumns})
        this.props.getTransactionList()
        this.fetchUserDcListFromApi()
        onScrollToTop()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(this.props.taxReducer.sellerId, prevProps.taxReducer.sellerId) ||
            !isEqual(this.props.taxReducer.startDate, prevProps.taxReducer.startDate) ||
            !isEqual(this.props.taxReducer.activeTab, prevProps.taxReducer.activeTab) ||
            !isEqual(this.props.taxReducer.endDate, prevProps.taxReducer.endDate)) {
            this.props.getTransactionList()
        }
    }

    fetchUserDcListFromApi = () => {
        this.setState({loading: true})
        getUserListApi({level: [DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_3]})
            .then(result => {
                let avoskinData = [{name: 'Avoskin', id: avoskinId()}]
                let data = [...this.state.userList, ...avoskinData, ...result]
                this.setState({userList: data, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log("error fetchUserDcListFromApi :", err)
                alert("something went wrong  !!")
            })
    }

    onDatePickerChange = (dates) => {
        this.setState({filterStartDate: dates[0], filterEndDate: dates[1]})
        this.props.taxDispatcher(SET_TRANSACTION_LIST_TAX, {loading: false, startDate: dates[0], endDate: dates[1]})
    }

    onSearchEmailHandler = (value) => {
        this.setState({searchKey: value})
    }

    getDataTransaction = () => {
        const {activeTab} = this.state
        const {orderTransactionList, guaranteeTransactionList, preOrderTransactionList} = this.props.taxReducer

        switch (activeTab) {
            case 'PREORDER':
                return preOrderTransactionList
            case 'GUARANTEE':
                return guaranteeTransactionList
            default:
                return orderTransactionList
        }
    }

    dataToDisplay = () => {
        const {searchKey} = this.state
        let dataToFilter = this.getDataTransaction()
        let dataToDisplay = []

        if (!!searchKey) {
            dataToDisplay = _.filter(dataToFilter, item => item.email.toLowerCase().includes(searchKey.toLowerCase()))
        } else {
            dataToDisplay = dataToFilter
        }
        return dataToDisplay
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setTransactionListToExport(rows);
        this.setState({selectedRow, selectedRowKeys});
    }

    onChangeTab = (value) => {
        this.setState({activeTab: value, selectedRow: [], selectedRowKeys: []})
        this.props.taxDispatcher(SET_TRANSACTION_LIST_TAX, {activeTab: value})
    }

    onExportDataHandler = () => {
        const {activeTab, selectedRow} = this.state;

        switch (true) {
            case selectedRow.length > 0 && activeTab === 'PREORDER':
                return <ExportTransactionPreorderComponent selectedRow={selectedRow} />
            case selectedRow.length > 0 && activeTab === 'ORDER':
                return <ExportTransactionOrderComponent selectedRow={selectedRow}/>
            case selectedRow.length > 0 && activeTab === 'GUARANTEE':
                return <ExportTransactionGuaranteeComponent selectedRow={selectedRow}/>
            default:
                return <Button disabled>Export</Button>
        }
    }

    dropDownHandler = () => {
        let {userList} = this.state

        return (
            <Menu>
                {
                    userList.map((type, index) => (
                        <Menu.Item key={index} onClick={() => this.dropDownButtonHandler(type)}>
                            <Button type="link">{type.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    dropDownButtonHandler = (value) => {
        let selectedData = {id: value.id, name: value.name}
        this.setState({selectedSeller: selectedData})
        this.props.taxDispatcher(SET_TRANSACTION_LIST_TAX, {sellerId: selectedData.id})
    }

    getTable = () => {
        const {selectedRowKeys, activeTab} = this.state;
        const {loading} = this.props.taxReducer
        const dataToDisplay = this.dataToDisplay()
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        switch (activeTab) {
            case 'PREORDER':
                return <TaxPreorderComponent loading={loading} dataToDisplay={dataToDisplay}
                                             rowSelection={rowSelection}/>
            case 'GUARANTEE':
                return <TaxGuaranteeComponent loading={loading} dataToDisplay={dataToDisplay}
                                              rowSelection={rowSelection}/>
            default:
                return <TaxOrderComponent loading={loading} dataToDisplay={dataToDisplay}
                                          rowSelection={rowSelection}/>
        }
    }


    render() {
        const {
            filterStartDate,
            filterEndDate,
            tabList,
            activeTab,
            selectedSeller
        } = this.state;

        return (
            <Card>
                <h5 style={{marginBottom: 20, marginLeft: 20}}>Daftar Faktur Pajak</h5>
                <Row justify={"space-between"} align="middle">
                    <Col>
                        <Row>
                            Nama Penjual :
                            <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                                <Button
                                    style={{
                                        backgroundColor: 'white',
                                        color: Colors.darkseagreen,
                                        minWidth: 150,
                                        marginLeft: 20
                                    }}
                                    size="medium"
                                    theme="filled"
                                >
                                    {selectedSeller?.name}
                                </Button>
                            </Dropdown>
                        </Row>
                    </Col>
                    <Col><SearchComponent searchItem={'email'} onSearchHandler={this.onSearchEmailHandler}/></Col>
                    <Col>
                        Tgl. Transaksi
                        <RangePicker
                            allowClear={false}
                            style={{width: '70%', marginLeft: 20}}
                            value={[filterStartDate, filterEndDate]}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                    </Col>
                    <Col style={{marginRight: 20}}>{this.onExportDataHandler()}</Col>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onChangeTab}
                    tabBarStyle={{color: Colors.darkseagreen, marginTop: 10}}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>{this.getTable()}</TabPane>
                    ))}
                </Tabs>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {taxReducer} = state;
    return {taxReducer};
};

export default connect(mapStateToProps, {getTransactionList, taxDispatcher})(TaxInvoicePage);
