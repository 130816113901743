import moment from 'moment';
import md5 from 'react-native-md5';
import {Base64} from 'js-base64';
import HashMap from "hashmap";
import React from "react";
import {Tag} from "antd";

const isEqual = require("react-fast-compare");

const uid = localStorage.getItem('AVOSTORE_UID');
const role = localStorage.getItem('AVOSTORE_ROLE');

const extractQuerySnapshotToArray = (querySnapshot) => {
    let result = [];

    querySnapshot.forEach((item) => result.push(item.data()));

    return result;
};

const doConvertNumberToRupiahFormat = (numberToFormat) => {
    return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(numberToFormat);
};

const formatUnixDateToReadable = (unixDateToFormat, format = 'ddd, DD MMM YYYY') => {
    return moment.unix(unixDateToFormat).format(format);
};

const generateMd5Id = (randomString) => {
    return md5.hex_md5(moment().unix() + randomNumber(6) + randomString);
};

const randomNumber = (n) => {
    let add = 1,
        max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
        return randomNumber(max) + randomNumber(n - max);
    }

    max = Math.pow(10, n + add);
    let min = max / 10; // Math.pow(10, n) basically
    let number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ('' + number).substring(add);
};

const countProductPrice = (subtotal, count) => {
    return subtotal / count;
};

const generateCodeShippingMethod = (name) => {
    switch (name) {
        case "POS Indonesia (POS)":
            return 'pos';
        case "RPX Holding (RPX)":
            return 'rpx';
        case "Jalur Nugraha Ekakurir (JNE)":
            return 'jne';
        case "J&T Express":
            return 'jnt';
        case "SiCepat Express":
            return "sicepat"
        default:
            return ''
    }
}

const cloudFunctionBaseUrl = () => {
    if (isProd()) {
        return 'https://asia-east2-avostore-ea3d7.cloudfunctions.net/'
    } else {
        return 'https://us-central1-avostore-dev.cloudfunctions.net/'
    }
}

const isProd = () => {
    // return Config.ENV == 'PROD' ? true : false;
    return process.env.REACT_APP_IS_PRODUCTION === "true";
}

const stringToBase64 = (stringToEncode) => {
    return Base64.encode(stringToEncode);
}

const getDateCustomFormat = (dateToFormat, format) => {
    return moment(dateToFormat).format(format);
}

const getDateEpochFormat = (dateToFormat) => {
    return moment(dateToFormat).unix();
}

const getPaymentMethodText = (paymentMethod) => {
    switch (paymentMethod) {
        case 'credit_card':
            return 'Kartu Kredit'
        case 'bca_va':
            return 'BCA Virtual Account'
        case 'bca':
        case 'BCA':
            return 'BCA'
        case 'mandiri':
        case 'Mandiri':
            return 'Mandiri'
        case 'bni_va':
            return 'BNI Virtual Account'
        case 'permata_va':
            return 'Permata Virtual Account'
        case 'echannel':
            return 'Mandiri Virtual Account'
        case 'gopay':
            return 'GoPay'
        case 'shopeepay':
            return 'shopeePay'
        case 'manual_transfer':
            return 'Manual transfer'
        default:
            return 'Kartu Kredit, Bank Transfer, GoPay'
    }
}

const getLevelText = (level) => {
    switch (level) {
        case "PREMIUM_2":
            return "PREMIUM 2"
        case "PREMIUM_1":
            return "PREMIUM 1"
        case "PLATINUM":
            return "PLATINUM"
        case "BRONZE":
            return "BRONZE"
        case "END_CUSTOMER":
            return "END CUSTOMER"
        case "DISTRIBUTION_CHANNEL_1":
            return "DISTRIBUTION CHANNEL 1"
        case "DISTRIBUTION_CHANNEL_2":
            return "DISTRIBUTION CHANNEL 2"
        case "DISTRIBUTION_CHANNEL_3":
            return "DISTRIBUTION CHANNEL 3"
        default:
            return ""
    }
}

const renameImageToDateFormat = (fileName, type) => {
    let fileNameSplit = fileName.split('.');
    return type + getDateEpochFormat() + '.' + fileNameSplit[fileNameSplit.length - 1]
}

const renameImageAvostoreEcard = (fileName, email) => {
    let fileNameSplit = fileName.split('/');
    return `eCard-${email}.${fileNameSplit[fileNameSplit.length - 1]}`
}

const getShippingMethodeImage = (shippingMethod) => {
    switch (shippingMethod) {
        case 'POS Indonesia (POS)':
            return require("../images/shipping-logo/pos.png")
        case 'RPX Holding (RPX)':
            return require("../images/shipping-logo/rpx.png")
        case 'J&T Express':
            return require("../images/shipping-logo/jnt.png")
        case 'Jalur Nugraha Ekakurir (JNE)':
            return require("../images/shipping-logo/jne.png")
        case 'Self Service':
            return require("../images/shipping-logo/cod.png")
        case 'SiCepat Express':
            return require("../images/shipping-logo/Sicepat.png")
        case 'Porter':
            return require("../images/shipping-logo/logoPorter.png")
        case 'Lion Parcel':
            return require("../images/shipping-logo/Lion_Parcel.png")
        default:
            return ""
    }
}

const getWarehouseAvostoreJogjaId = () => {
    if (isProd()) {
        return 'e46172468c9989cc781efa06184536f1'
    } else {
        return 'df48efa9465a06147894002ca0e11062'
    }
}

const getWarehouseAvostoreJakartaId = () => {
    if (isProd()) {
        return '8635a86ef2dbefe441553e5c76d24bb0'
    } else {
        return 'f497e08b5932c590a1d6a36fc81eaf45'
    }
}

const convertStartEndDate = (date, slug) => {
    let returnDate = ''
    if (slug === 'start') {
        returnDate = moment(date).startOf('day').unix()
    } else {
        returnDate = moment(date).endOf('day').unix()
    }
    return returnDate
}

const percentFormat = (value, state) => {
    let valueFormat = 0
    if (state === 'twoDigits') {
        valueFormat = (value.toFixed(2))
    } else {
        valueFormat = Number(value.toString().split(".", 1))
    }
    return `${valueFormat}%`
}

const getMonthAndYear = (state) => {
    let dateFormat = ''
    if (state === 'current') {
        dateFormat = `${moment().month() + 1}_${moment().year()}`
    } else {
        dateFormat = `${moment().subtract(1, 'months').month() + 1}_${moment().year()}`
    }
    return dateFormat
}

const extractQuerySnapshotToHashmap = (querySnapshot) => {
    let result = new HashMap();

    querySnapshot.forEach(item => {
        result.set(item.id, item.data());
    })

    return result;
}

const formatNumberToRupiahUnit = (item) => {
    let strNominal = item.toString()
    let nominal = 0

    switch (true) {
        case item.toString().length > 9:
            nominal = strNominal.slice(0, strNominal.length - 9) + ' M'
            break;
        case item.toString().length > 6:
            nominal = strNominal.slice(0, strNominal.length - 6) + ' jt'
            break;
        case item.toString().length > 3:
            nominal = strNominal.slice(0, strNominal.length - 3) + ' rb'
    }

    return nominal
}

const userIdDC = () => {
    const id = isProd() ? '002KMxX4uXXA1lftcuiCw8PlMbP2' : 'C9QfzNLM7FRqtRgUyoHhEUr0PEz2'

    return id
}

const userIdDCReport = () => {
    const id = isProd() ? '002KMxX4uXXA1lftcuiCw8PlMbP2' : 'C9QfzNLM7FRqtRgUyoHhEUr0PEz2'

    return id
}

const isDC = (level) => {
    return level === 'DISTRIBUTION_CHANNEL_1' || level === 'DISTRIBUTION_CHANNEL_2' || level === 'DISTRIBUTION_CHANNEL_3'
}

const isAdmin = () => {
    if (!!isProd()) {
        switch (uid) {
            case 'XxSW2gUhUePwATYsgp2q4QWHsKX2':
                //TODO: masukin admin lain dr prod
                return true
            default:
                return false
        }
    } else {
        switch (uid) {
            case 'K9s7LBWRXuOhTPUgbcaHt8Sa1Vb2':
            case 'wY6906n9x8bMDegC8VSgqd5BAWQ2':
                return true
            default:
                return false
        }
    }
}

const isUserRole = (userRole) => {
    switch (userRole) {
        case 'QURNSU5JU1RSQVRPUg==':
        case 'T1BFUkFUSU9OQUw=':
        case 'U0FMRVM=':
        case 'RklOQU5DRQ==':
        case 'SU5WRU5UT1JZ':
            return true
        default:
            return false
    }
}

const roleName = (userRole) => {
    switch (userRole) {
        case 'QURNSU5JU1RSQVRPUg==':
            return 'ADMINISTRATOR'
            break;
        case 'T1BFUkFUSU9OQUw=':
            return 'OPERATIONAL'
            break;
        case 'U0FMRVM=':
            return 'SALES'
            break;
        case 'U0FMRVM=':
            return 'FINANCE'
            break;
        default:
            return ''
    }
}

const avoskinId = () => {
    const id = isProd() ? 'XxSW2gUhUePwATYsgp2q4QWHsKX2' : 'K9s7LBWRXuOhTPUgbcaHt8Sa1Vb2'
    return id
}

const orderStateRequestBody = (activeTab) => {

    let orderState = []
    let paymentDetail = {}
    let sort = 'desc'

    switch (activeTab) {
        case 'SUBMITTED':
            paymentDetail = {transactionStatus: null}
            orderState = ['SUBMITTED']
            break;
        case '':
            orderState = ['SUBMITTED', 'PACKAGING', 'DELIVERY', 'COMPLETED', 'CANCELLED']
            break;
        case 'waitingForDelivery':
            orderState = ['SUBMITTED', 'PACKAGING']
            sort = 'asc'
            paymentDetail = {transactionStatus: 'paid'}
            break;
        default:
            orderState = [activeTab]
    }

    return {orderState, paymentDetail, sort}
}

const renderLabelOrderState = (record) => {
    let color = 'red';
    let label = 'BELUM DIBAYAR';
    let colorAwb = Boolean(record.awb) ? 'green' : 'red';
    let labelAwb = Boolean(record.awb) ? 'SUDAH RESI' : 'BELUM RESI';

    switch (record.orderState) {
        case 'SUBMITTED':
            if (record.paymentDetail.transactionStatus === 'paid') {
                color = 'yellow';
                label = 'SUDAH DIBAYAR';
            }
            break;
        case 'PACKAGING':
            color = 'orange';
            label = 'SEDANG DIPROSES';
            break
        case 'DELIVERY':
            color = 'blue';
            label = 'SEDANG DIKIRIM';
            break;
        case 'COMPLETED':
            color = 'green';
            label = 'SELESAI';
            break;
        case 'CANCELLED':
            color = 'black';
            label = 'DIBATALKAN';
            break
        default:
            color = 'red'
    }


    return (
        <React.Fragment>
            <Tag color={color} key={record.orderState}>
                {label}
            </Tag>
            <Tag color={colorAwb} key={'awb'}>
                {labelAwb}
            </Tag>
        </React.Fragment>
    );
}

const getStatusDisbursement = (status) => {
    switch (status) {
        case 'queued':
            return "Menunggu persetujuan"
        case 'approved':
            return "Pengajuan disetujui"
        case 'rejected':
            return "Pengajuan ditolak"
        default:
            return ""
    }
}

const getEmailType = (emailType) => {
    switch (emailType) {
        case "EMAIL_RECEIVER_CANCEL_ORDER_UNPROCESS":
            return 'Email Receiver Cancel Order Unprocess';
        case "EMAIL_RECEIVER_GUARANTEE_DUE_DATE":
            return 'Email Receiver Guarantee Due Date';
        case "EMAIL_RECEIVER_TERMIN_PAYMENT_RECEIPT":
            return 'Email Receiver Termin Payment Receipt';
        case "EMAIL_RECEIVER_USER_COMPLAIN":
            return 'Email Receiver User Complain';
        case "EMAIL_RECEIVER_NEW_ORDER":
            return "Email Receiver New Order"
        case "EMAIL_RECEIVER_USER_EDIT_PROFILE":
            return 'Email Receiver User Edit Profile';
        case "EMAIL_RECEIVER_ORDER_PAYMENT_RECEIPT_AVOSTORE":
            return 'Email Receiver Order Payment Receipt Avostore';
        case "EMAIL_RECEIVER_ORDER_PAYMENT_RECEIPT":
            return 'Email Receiver Order Payment Receipt';
        case "EMAIL_RECEIVER_CREATE_PAYOUT":
            return 'Email Receiver Create Payout';
        case "EMAIL_RECEIVER_GUARANTEE_DP_PAYMENT_RECEIPT_DP":
            return "Email Receiver Guarantee Payment DP"
        case "DC_ADMIN_NOTIFICATION_EMAIL":
            return 'DC Admin Notification Email';
        case "EMAIL_RECEIVER_USER_INACTIVED":
            return 'Email Receiver User Inactive';
        case "EMAIL_RECEIVER_TAX":
            return 'Email Receiver Tax';
        case "EMAIL_RECEIVER_TERMIN_PAYMENT_RECEIPT_AVOSTORE":
            return 'Email Receiver Payment Receipt Termin Avostore';
        case "EMAIL_RECEIVER_USER_ACTIVED":
            return "Email Receiver User Active"
        case "EMAIL_RECEIVER_ORDER_CANCEL_BY_BUYER":
            return 'Email Receiver Order Cancel By Buyer';
        case "EMAIL_RECEIVER_WARNING_CANCEL_ORDER_UNPROCESS":
            return 'Email Receiver Warning Order Cancel Unprocess';
        default:
            return ''
    }
}

const onScrollToTop = () => window.scrollTo(0,0)

export {
    extractQuerySnapshotToArray,
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    countProductPrice,
    randomNumber,
    generateCodeShippingMethod,
    cloudFunctionBaseUrl,
    isProd,
    stringToBase64,
    getDateCustomFormat,
    getPaymentMethodText,
    getDateEpochFormat,
    isEqual,
    getLevelText,
    renameImageToDateFormat,
    renameImageAvostoreEcard,
    getShippingMethodeImage,
    getWarehouseAvostoreJogjaId,
    convertStartEndDate,
    percentFormat,
    getMonthAndYear,
    extractQuerySnapshotToHashmap,
    formatNumberToRupiahUnit,
    userIdDC,
    userIdDCReport,
    isDC,
    isAdmin,
    isUserRole,
    roleName,
    getWarehouseAvostoreJakartaId,
    avoskinId,
    orderStateRequestBody,
    renderLabelOrderState,
    getStatusDisbursement,
    onScrollToTop,
    getEmailType
};
