import React, { Component } from "react";
import { Card, Timeline, Collapse } from 'antd';
import _ from 'lodash';
import {
    formatUnixDateToReadable,
    extractQuerySnapshotToArray
} from '../../ui-util/general-variable';
import { getOrderAuditFromFirestore } from '../../firebase/database/order-audit';

const { Panel } = Collapse;

class OrderHistoryTimeline extends Component {
    constructor(){
        super()

        this.state={
            orderAuditDetail: null
        }
    }

    panelHeader = () => {
        return( <h5>Riwayat Pesanan</h5> )
    }

    onTimelineCollapse = () => {
        const {orderId} = this.props
        getOrderAuditFromFirestore(orderId)
			.then((querySnapshot) => {
				let orderAuditData = extractQuerySnapshotToArray(querySnapshot)
                let historyOrderList = _.orderBy(orderAuditData, ['timestamp'], ['asc'])
				this.setState({orderAuditDetail: historyOrderList})
			})
			.catch(err => console.log("error get order audit from firebase", err.code))
    }

    render() {
        const { orderAuditDetail } = this.state;

        return (
            <Card>
                <Collapse bordered={false} expandIconPosition="right" onChange={() => this.onTimelineCollapse()}>
                    <Panel header={this.panelHeader()} key="1">
                        <Timeline>
                        {
                            _.map(orderAuditDetail, (v, k) => (
                                <Timeline.Item key={k}>
                                        <p style={{ marginBottom: 0 }}>
                                        {formatUnixDateToReadable(
                                            v.timestamp,
                                            "DD-MM-YYYY HH:mm:ss"
                                        )}
                                        </p>
                                        <p>{v.description}</p>
                                </Timeline.Item>
                            ))
                        }
                        </Timeline>
                    </Panel>
                </Collapse>
            </Card>
        );
    }
}

export default OrderHistoryTimeline;
