import {VOUCHER_LIST} from "../reducers/voucher.reducer";
import {getVoucherListApi} from "../api/voucher.api";

const voucherDispatcher = (type, payload) => {
    return {type, payload}
}

const getVoucherList = () => {
    return (dispatch) => {
        getVoucherListApi()
            .then(result => {
                const voucherList = result.data
                dispatch(voucherDispatcher(VOUCHER_LIST, voucherList))
            })
            .catch(err => {
                console.log('error', err)
            })
    }
}

export {voucherDispatcher, getVoucherList}
