import React from "react";
import {Button, Dropdown, Menu, Row} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {orderDispatcher} from "../actions/order.action";
import {IMPORT_RESI_REDUCER} from "../reducers/import-resi.reducer";

class CityDropdownComponent extends React.PureComponent{

    dropDownHandler = () => {
        return (
            <Menu>
                {
                    ['jakarta', 'jogjakarta'].map((city, index) => (
                        <Menu.Item key={index} onClick={() => this.props.orderDispatcher(IMPORT_RESI_REDUCER, {warehouseLocation: city, selectedRowKeys: [], selectedRowToExport: []})}>
                            <Button type="link" style={{textTransform: 'capitalize'}}>{city}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    render() {
        const {warehouseLocation} = this.props.importResiReducer
        return (
            <Row style={{alignItems: 'center'}}>
                <h5>Warehouse : </h5>
                <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                    <Button
                        style={{backgroundColor: Colors.darkseagreen, marginLeft: 10,  color: 'white', textTransform: 'capitalize'}}
                        size="large"
                        theme="filled"
                    >
                        {warehouseLocation}
                    </Button>
                </Dropdown>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {importResiReducer} = state;

    return {importResiReducer};
};

export default connect(mapStateToProps, {orderDispatcher})(CityDropdownComponent)
