import {firestore} from '../index';

const orderRef = firestore.collection('orders');

const getOrderDetailById = (idOrder) => {
    return orderRef.doc(idOrder).get();
};

const updateOrderDetail = (idOrder, orderObj) => {
    return orderRef.doc(idOrder).set(orderObj, {merge: true});
};

const getOrderListByManifestId = (manifestId) => {
    return orderRef.where('manifestId', '==', manifestId).get()
}

const getOrderListByBuyerId = (buyerId) => {
    return orderRef.where('buyerId', '==', buyerId).get()
}

const getOrderDetailByOrderId = (orderId) => {
    return orderRef.where('orderId', '==', orderId).get()
}

export { getOrderDetailById, updateOrderDetail, getOrderListByManifestId, getOrderDetailByOrderId, getOrderListByBuyerId };

