import React, {PureComponent} from "react";
import {Button, Checkbox, Form, Input, Row, Select, Upload} from "antd";
import {pushNotification} from "../../api/push-notif.api";
import {UploadOutlined} from '@ant-design/icons';
import {NotificationDetailObj} from "../../models/notification.model";
import {renameImageToDateFormat} from "../../ui-util/general-variable";
import {notificationRef, putNotificationImage} from "../../firebase/storage/notification-image";
import {storageTaskEvent} from "../../firebase";
import {connect} from "react-redux";
import {getAvostoreListAll} from "../../actions/user.action";

const {TextArea} = Input;
const {Option} = Select;

class CreateNotificationComponent extends PureComponent {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            notificationForm: [
                {
                    key: 'title',
                    label: 'Judul Notifikasi',
                    required: true,
                    messageAlert: 'Masukkan judul notifikasi'
                }, {
                    key: 'description',
                    label: 'Deskripsi Notifikasi',
                    required: true,
                    messageAlert: 'Masukkan deskripsi notifikasi',
                    type: 'textarea',
                }, {
                    key: 'image',
                    label: 'Gambar Notifikasi',
                    type: 'image'

                }, {
                    key: 'sendAll',
                    label: 'Penerima Notifikasi',
                    type: 'checkbox',
                    checkboxLabel: 'Kirim ke semua user'
                }, {
                    key: 'userLevel',
                    label: 'Penerima Notifikasi Berdasarkan Level',
                    type: 'select',
                    placeholder: 'Select level',
                    checkboxLabel: 'Kirim berdasarkan level user',
                    selectOption: [
                        {
                            label: 'PREMIUM 2',
                            key: 'PREMIUM_2'
                        }, {
                            label: 'PREMIUM 1',
                            key: 'PREMIUM_1'
                        }, {
                            label: 'PLATINUM',
                            key: 'PLATINUM'
                        }, {
                            label: 'END CUSTOMER',
                            key: 'END_CUSTOMER'
                        }, {
                            label: 'DISTRIBUTION CHANNEL 1',
                            key: 'DISTRIBUTION_CHANNEL_1'
                        }, {
                            label: 'DISTRIBUTION CHANNEL 2',
                            key: 'DISTRIBUTION_CHANNEL_2'
                        },
                        {
                            label: 'DISTRIBUTION CHANNEL 3',
                            key: 'DISTRIBUTION_CHANNEL_3'
                        }
                    ]
                }, {
                    key: 'receiverId',
                    label: 'Penerima Notifikasi Berdasarkan Id User',
                    type: 'select',
                    placeholder: 'Select user',
                    checkboxLabel: 'Kirim berdasarkan id user'
                }
            ],
            fileImageList: [],
            fileImage: {},
            disabledSubmitButton: false,
            notificationDetail: {
                ...NotificationDetailObj
            },
            sendAllValue: false,
            selectValue: false,
            selectKey: null,
            data: {}
        }
    }

    componentDidMount() {
        this.props.getAvostoreListAll()
    }

    onFinish = (values) => {
        let {fileImageList} = this.state
        this.setState({disabledSubmitButton: true, data: values})
        if (fileImageList.length > 0) {
            let fileName = renameImageToDateFormat(fileImageList[0].name, 'notification-')
            this.uploadImageToStorage(fileName, fileImageList[0])
        } else {
            this.sendPushNotification(values)
        }
    }

    sendPushNotification = (requestBody) => {
        pushNotification(requestBody)
            .then(result => {
                this.formRef.current.resetFields();
                this.setState({disabledSubmitButton: false, fileImageList: [], sendAllValue: false, selectValue: false})
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage]});
        }
        return false
    }


    uploadImageToStorage(fileName, fileImage) {
        let {data} = this.state
        putNotificationImage(fileName, fileImage).on(
            storageTaskEvent.STATE_CHANGED,
            snapshot => {
            },
            err => console.log('err', err),
            () => {
                notificationRef(fileName).getDownloadURL()
                    .then(url => {
                        data.image = url
                        this.sendPushNotification(data)
                    })
            }
        )
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {notificationForm, disabledSubmitButton, fileImageList, sendAllValue, selectValue, selectKey} = this.state
        const {avostoreListAll} = this.props.avostoreListReducer;
        return (
            <Form style={{width: '60%'}} onFinish={this.onFinish} ref={this.formRef}
                  initialValues={{'sendAll': false, 'image': null, receiverId: [], userLevel: []}}>
                {
                    notificationForm.map((item, index) => {
                        switch (item.type) {
                            case 'textarea':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <TextArea rows={4} placeholder={item.placeholder}
                                                  disabled={(sendAllValue || selectValue) && item.key === 'receiverId' ? true : false}/>
                                    </Form.Item>
                                )
                            case 'image':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Upload fileList={fileImageList} beforeUpload={this.beforeUploadImage}
                                                onRemove={this.removeButtonHandler}>
                                            <Row style={{alignItems: 'center'}}>
                                                <Button>
                                                    <UploadOutlined/> Select File
                                                </Button>
                                                <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2 MB</p>
                                            </Row>
                                        </Upload>
                                    </Form.Item>
                                )
                            case 'checkbox':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={selectValue ? true : false}
                                                  onChange={(e) => this.setState({sendAllValue: e.target.checked})}>{item.checkboxLabel}</Checkbox>
                                    </Form.Item>
                                )
                            case 'select':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Select
                                            placeholder={item.placeholder}
                                            mode="multiple"
                                            showSearch
                                            disabled={(sendAllValue || selectValue) && item.key !== selectKey ? true : false}
                                            onChange={(value) => {
                                                if (value.length > 0) {
                                                    this.setState({selectValue: true, selectKey: item.key})
                                                } else {
                                                    this.setState({selectValue: false})
                                                }
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                item.key === 'userLevel' && item.selectOption.map((item, index) => (
                                                    <Option value={item.key}
                                                            key={index.toString()}>{item.label}</Option>
                                                ))}
                                            {
                                                item.key === 'receiverId' &&
                                                avostoreListAll.map((item, index) => (
                                                    <Option value={item.id}
                                                            key={index.toString()}>{item.name}</Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Input placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    })
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disabledSubmitButton}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreListReducer} = state;

    return {avostoreListReducer};
};

export default connect(mapStateToProps, {getAvostoreListAll})(CreateNotificationComponent);
