import React, {Component} from 'react';
import {Avatar} from 'antd';
import {getShippingMethodeImage} from "../ui-util/general-variable";
import {Colors} from "../ui-util/colors";

export default class PrintLabelDcComponent extends Component {
    printOrderData() {
        let data = [];
        const cmToPx = 55;
        const {selectedPrint} = this.props;

        Boolean(selectedPrint) &&
        selectedPrint.forEach((order, idx) => {
            data.push(
                <div key={order.id} style={{width: '100%', height:'100%'}}>
                    <div style={{
                        borderTop: '2px solid black',
                        borderBottom: '2px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black'
                    }}>
                        <table style={{width: 19 * cmToPx, paddingLeft: cmToPx}}>
                            <tbody>
                            <tr style={{borderBottom: '2px solid black'}}>
                                <td style={{borderRight: '0.5px solid gray', borderColor: Colors.lightgray, paddingLeft: 10}}>
                                    <p>Nomor Pesanan</p>
                                    <p>{order.orderId}</p>
                                </td>
                                <td style={{paddingLeft: 10, paddingRight: 10}}>
                                    <Avatar shape="square" size={60} src={getShippingMethodeImage(order.shippingMethod)}/>
                                    <p>Shipping : {order.shippingMethod} - {order.shippingService}</p>
                                </td>
                            </tr>
                            <tr style={{borderBottom: '2px solid black'}}>
                                <td style={{borderRight: '0.5px solid gray', borderColor: Colors.lightgray, paddingLeft: 10}}>
                                    <p style={{fontWeight: 'bold'}}>Dari:</p>
                                    <p>{order.warehouseDetail.name}</p>
                                    <p>{order.warehouseDetail.phoneNumber}</p>
                                    <p>{`${order.warehouseDetail.address}, ${order.warehouseDetail
                                        .district}, ${order.warehouseDetail.city}, ${order.warehouseDetail
                                        .province}, ${order.warehouseDetail.zipcode}`}</p>
                                </td>
                                <td style={{paddingLeft: 10, paddingRight: 10}}>
                                    <p style={{fontWeight: 'bold'}}>Kepada: </p>
                                    <p>{order.shippingAddress.receiver}</p>
                                    <p>{order.shippingAddress.phoneNumber}</p>
                                    <p>{`${order.shippingAddress.address}, ${order.shippingAddress
                                        .district}, ${order.shippingAddress.city}, ${order.shippingAddress
                                        .province}, ${order.shippingAddress.zipcode}`}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table style={{width: 19 * cmToPx, marginTop: 20, marginBottom: 20}}>
                            <thead style={{fontWeight: 'bold'}}>
                            <tr>
                                <td style={{paddingLeft: 10, paddingBottom: 10}}>Nama Product</td>
                                <td style={{paddingBottom: 10, textAlign: 'center'}}>Qty Produk</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.printListProduct(order.products)}
                            {(idx) % 2 === 0 && <tr style={{display: 'block', pageBreakAfter: 'always'}}/>}
                            </tbody>
                        </table>
                        {
                            order?.gifts?.length > 0 &&
                            <div style={{marginBottom: 10}}>
                                <table style={{width: 19 * cmToPx, paddingLeft: cmToPx, border: 2}} id='tableProduct'>
                                    <thead style={{fontWeight: 'bold'}}>
                                    <tr>
                                        <td style={{paddingLeft: 10, paddingBottom: 10}}>Nama Gift</td>
                                        <td style={{paddingBottom: 10, textAlign: 'center'}}>Qty Gift</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.printListGift(order.gifts)}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            );
        });
        return data;
    }

    printListProduct(products) {
        let produk = [];
        products.forEach((product) =>
            produk.push(
                <tr key={product.id} style={{ border: '1px solid black'}}>
                    <td style={{paddingLeft: 10, width: 750}}>{product.name}</td>
                    <td style={{textAlign: 'center'}}>{product.count}</td>
                </tr>
            )
        );
        return produk;
    }

    printListGift = (gifts) => {
        let giftList = [];
        gifts.forEach((gifts) =>
            giftList.push(
                <tr key={gifts.id} style={{ borderTop: '1px solid grey'}}>
                    <td style={{paddingLeft: 10, width: 750}}>{gifts.itemName}</td>
                    <td style={{textAlign: 'center'}}>{gifts.count}</td>
                </tr>
            )
        );
        return giftList;
    }

    render() {
        return <div style={{marginLeft: 25, marginTop: 25}}>{this.printOrderData()}</div>;
    }
}
