import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Form, Image, Input, InputNumber, Row, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {GiftDetailObj} from "../../models/gift-detail.model";
import {getDateEpochFormat} from "../../ui-util/general-variable";
import {createGiftItemApi, getDetailGiftItemApi, updateGiftItemApi, uploadGiftItemImageApi} from "../../api/gift.api";

const {TextArea} = Input;

class CreateGiftPage extends React.PureComponent {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            formItems: [
                {
                    key: 'image',
                    label: 'Gambar item gift',
                    placeholder: 'Masukkan image voucher',
                    type: 'image',
                    required: true
                },
                {
                    key: 'itemName',
                    label: 'Nama Item Gift',
                    placeholder: 'Masukkan nama gift',
                    required: true,
                    type: 'string',
                    max: 40
                },
                {
                    key: 'description',
                    label: 'Deskripsi Item Gift',
                    placeholder: 'Masukkan deskripsi gift',
                    type: 'textarea'
                },
                {
                    key: 'stock',
                    label: 'Stock',
                    placeholder: 'Masukkan stock item',
                    type: 'number',
                    required: true
                },
                {
                    key: 'weight',
                    label: 'Berat',
                    placeholder: 'Masukkan berat item dalam gram',
                    type: 'number',
                    required: true
                },
            ],
            giftDetail: {...GiftDetailObj},
            fileImage: []
        }
    }

    onFinish = (values) => {
        const {giftDetail, fileImage} = this.state
        const {id} = this.props.match.params

        let newGiftDetail = {
            ...giftDetail,
            description: values.description,
            stock: Number(values.stock),
            weight: Number(values.weight),
            itemName: values.itemName,
            created_at: getDateEpochFormat(),
            image: fileImage[0]
        }

        if (id) {
            let giftDetailToUpdate = {
                ...values,
                id: giftDetail.id,
                stock: values.stock ? Number(values.stock) : giftDetail.stock,
                weight: values.weight ? Number(values.weight) : giftDetail.weight,
            }
            this.onUpdateGift(giftDetailToUpdate)
        } else {
            createGiftItemApi(newGiftDetail)
                .then(result => {
                    alert("Gift Item berhasil dibuat")
                    window.location.href = '/gift/list'
                })
                .catch(err => {
                    alert("Gift Item gagal diunggah !!")
                    console.log("error create gift item :", err)
                })
        }
    }

    onUpdateGift = (giftDetail) => {
        const {fileImage} = this.state
        if (fileImage.length > 0) {
            let body = {image: fileImage[0]}

            uploadGiftItemImageApi(body)
                .then(result => {
                    giftDetail.image = result.data.image
                    this.doUpdateGift(giftDetail)
                })
                .catch(err => {
                    console.log("err upload image :", err)
                })
        } else {
            this.doUpdateGift(giftDetail)
        }
    }

    doUpdateGift = (giftDetail) => {
        updateGiftItemApi(giftDetail)
            .then(result => {
                alert("Update gift Item berhasil !")
                window.location.href = '/gift/list'
            })
            .catch(err => {
                alert("Gift Item gagal diperbaharui !!")
                console.log("error update gift item :", err)
            })
    }

    removeButtonHandler = () => {
        this.setState({fileImage: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else if (fileImage.type !== 'image/jpeg' && fileImage.type !== 'image/png') {
            alert('Upload image tidak sesuai !!')
        } else {
            this.setState({fileImage: [fileImage], showImage: false});
        }
        return false
    }

    componentDidMount() {
        const {id} = this.props.match.params
        if (!!id) {
            const body = {id}
            getDetailGiftItemApi(body)
                .then(result => {
                    let giftDetail = result.data.GiftProductDetail
                    this.formRef.current.setFieldsValue(giftDetail)
                    this.setState({giftDetail})
                })
                .catch(err => {
                    alert("gagal mendapatkan detail gift !")
                    console.log("err getDetailGiftItemApi :", err)
                })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            window.location.reload()
        }
    }


    render() {
        const {formItems, fileImage, giftDetail} = this.state

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Buat Item Gift</h3>
                <Form {...layout} style={{width: '70%'}} onFinish={this.onFinish} ref={this.formRef}
                      initialValues={Object.assign(GiftDetailObj)}>

                    {
                        formItems.map((item, index) => {
                            switch (item.type) {
                                case 'image':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.name}
                                            rules={[
                                                {required: item.required, message: item.placeholder}
                                            ]}
                                        >
                                            <Upload
                                                fileList={fileImage}
                                                onRemove={this.removeButtonHandler}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {
                                                    giftDetail.image &&
                                                    <Image
                                                        src={giftDetail.image}
                                                        width={100}
                                                    />
                                                }
                                                <Row
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginTop: 10
                                                    }}>
                                                    <Button icon={<UploadOutlined/>}>
                                                        Upload
                                                    </Button>
                                                    <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                                </Row>
                                            </Upload>
                                        </Form.Item>
                                    )
                                case 'number':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[
                                                {required: item.required, message: item.placeholder},
                                            ]}
                                        >
                                            <InputNumber min="0" style={{width: 250}}
                                                         placeholder={item.placeholder}/>
                                        </Form.Item>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[
                                                {required: item.required, message: item.placeholder},
                                            ]}
                                        >
                                            {
                                                item.type === 'textarea'
                                                    ? <TextArea rows={4} placeholder={item.placeholder}/>
                                                    : <Input placeholder={item.placeholder}/>
                                            }
                                        </Form.Item>
                                    )
                            }
                        })
                    }
                    <Form.Item wrapperCol={{offset: 8}}>
                        <div className="steps-action">
                            <Button type="primary" htmlType="submit">
                                Done
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
};

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps,)(CreateGiftPage);