import React from "react";
import {Card} from "antd";
import Chart from 'chart.js';
import {Colors} from "../../ui-util/colors";
import {formatNumberToRupiahUnit, getDateCustomFormat, isEqual} from "../../ui-util/general-variable";
import {connect} from "react-redux";

class RevenueChartComponent extends React.PureComponent {
    constructor() {
        super();
        this.chartRef = React.createRef();
    }

    componentDidMount(): void {
        this.initiateChartData()
    }

    initiateChartData = () => {
        let {datasets} = this.props.revenueReducer.chartData
        datasets.borderColor = Colors.darkseagreen
        datasets.backgroundColor = 'transparent'
        datasets.pointBackgroundColor = Colors.darkseagreen
        datasets.pointBorderColor = Colors.darkseagreen
        const startDate = getDateCustomFormat(this.props.revenueReducer.startDate, 'MM/DD/YYYY')

        if (window.revenueChart && window.revenueChart !== null) {
            window.revenueChart.destroy()
        }

        window.revenueChart = new Chart(this.chartRef.current, {
            type: 'line',
            options: {
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            type: 'time',
                            time: {
                                unit: 'day'
                            },
                            ticks: {
                                min: startDate
                            },
                            gridLines: {
                                color: 'rgba(0, 0, 0, 0)'
                            },
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                callback: (value) => {
                                    return formatNumberToRupiahUnit(value)
                                }
                            },
                            gridLines: {
                                color: 'rgba(0, 0, 0, 0.05)'
                            },
                        }
                    ]
                }
            },
            data: {
                datasets: [datasets]
            },
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(prevProps.revenueReducer.chartData, this.props.revenueReducer.chartData)) {
            this.initiateChartData()
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Card style={{borderColor: Colors.gainsboro, borderWidth: 1, alignItems: 'center', marginTop: 20}}>
                <canvas style={{height: 350}} ref={this.chartRef}/>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {revenueReducer} = state

    return {revenueReducer}
}

export default connect(mapStateToProps, {})(RevenueChartComponent)
