const INITIAL_STATE = {
    giftItemList: []
}

export const SET_GIFT_ITEMS = 'SET_GIFT_ITEMS';

export const giftReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GIFT_ITEMS:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
