import {
    avoskinId,
    convertStartEndDate,
    formatUnixDateToReadable,
    getDateEpochFormat
} from "../ui-util/general-variable";
import {
    getAnalyticsGuaranteeList,
    getTotalGuaranteeActiveComplete,
    getTotalGuaranteePaid
} from "../api/analytics-guarantee.api";
import {ANALYTICS_GUARANTEE, ANALYTICS_GUARANTEE_TOTAL} from "../reducers/analytics-guarantee.reducer";

const analyticsGuaranteeDispatcher = (type, payload) => {
    return {type, payload}
}

const getDataAnalyticsGuaranteeList = () => {
    return (dispatch, getState) => {
        dispatch(analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {loading: true}))
        let {startDate, endDate, activeTab, currentPage, pageSize, guaranteeType} = getState().analyticsGuaranteeReducer
        let bodyRequest = {
            startDate: formatUnixDateToReadable(getDateEpochFormat(startDate), 'YYYY-MM-DD'),
            endDate: formatUnixDateToReadable(getDateEpochFormat(endDate), 'YYYY-MM-DD'),
            startDateEpoch: convertStartEndDate(startDate, 'start'),
            endDateEpoch: convertStartEndDate(endDate),
            guaranteeType,
            status: activeTab
        }

        let params = {
            page: currentPage - 1,
            size: pageSize
        }

        getAnalyticsGuaranteeList(bodyRequest, params)
            .then(result => {
                dispatch(analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {
                    analyticsGuaranteeList: result.data,
                    totalData: result.totalDatas,
                    loading: false
                }))
            })
            .catch(err => {
                dispatch(analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {
                    analyticsGuaranteeList: [],
                    totalData: 0,
                    loading: false
                }))
                console.log(err)
            })
    }
}

const getTotalGuaranteeData = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, activeTab, guaranteeType} = getState().analyticsGuaranteeReducer
        const {seller} = getState().dropdownSellerReducer
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerId = []
        switch (seller) {
            case 'DC':
                avoStoreDcList.forEach((item, index) => {
                    if (item.id !== null) {
                        sellerId.push(item.id)
                    }
                })
                break;
            case 'Avoskin':
                sellerId = [avoskinId()]
                break
            default:
                sellerId = []
        }
        let bodyRequest = {
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate),
            status: activeTab,
            guaranteeType,
            sellerId
        }

        getTotalGuaranteeActiveComplete(bodyRequest)
            .then(result => {
                dispatch(analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE_TOTAL, {totalGuarantee: result.data}))
            })
            .catch(err => console.log(err))
    }
}

const getTotalGuaranteeDataPaid = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, guaranteeType} = getState().analyticsGuaranteeReducer
        startDate = convertStartEndDate(startDate, 'start')
        endDate = convertStartEndDate(endDate)

        getTotalGuaranteePaid(startDate, endDate, guaranteeType)
            .then(result => {
                dispatch(analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE_TOTAL, {totalGuarantee: result.data}))
            })
            .catch(err => console.log(err))
    }
}

export {
    analyticsGuaranteeDispatcher,
    getDataAnalyticsGuaranteeList,
    getTotalGuaranteeData,
    getTotalGuaranteeDataPaid
}
