import React, {Component} from 'react';
import {Table} from "antd";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";

class ProductListTaxComponent extends Component {
    constructor() {
        super()

        this.state = {
            columns: [
                { title: 'Produk', dataIndex: 'name', key: 'name', width: 750 },
                { title: 'Kode SKU', dataIndex: 'skuCode', key: 'skuCode'},
                { title: 'Jumlah', dataIndex: 'count', key: 'count' },
                { title: 'Subtotal', dataIndex: 'subtotal', key: 'subtotal', render: (subtotal) => doConvertNumberToRupiahFormat(subtotal) },
                { title: 'Subtotal Margin', dataIndex: 'subtotalMargin', key: 'subtotalMargin', render: (subtotalMargin) => doConvertNumberToRupiahFormat(subtotalMargin || 0) },
            ]
        }
    }
    render() {
        const { columns } = this.state;
        const {productList} = this.props;

        return (
            <Table columns={columns} dataSource={productList} pagination={false} rowKey='id' />
        );
    }
}

export default ProductListTaxComponent;
