import React, {PureComponent} from "react";
import {Button, DatePicker, Form, Image, Input, List, Row, Switch, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {RewardDetailObj} from "../../models/reward.model";
import {renameImageToDateFormat} from "../../ui-util/general-variable";
import {storageTaskEvent} from "../../firebase";
import {putRewardImage, rewardStorageRef} from "../../firebase/storage/reward-image";
import moment from "moment";
import isEqual from "react-fast-compare";

const {TextArea} = Input;

class RewardFormComponent extends PureComponent {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            rewardForm: [
                {
                    label: 'Title',
                    name: 'title',
                    type: 'input',
                    required: true,
                    messageAlert: 'Please input title!',
                    placeholder: 'Title'
                }, {
                    label: 'Description',
                    name: 'description',
                    type: 'textarea',
                    required: true,
                    placeholder: 'Description'
                }, {
                    label: 'Image',
                    name: 'image',
                    type: 'image',
                    required: true,
                    messageAlert: 'Image'
                }, {
                    label: 'Start Date',
                    name: 'startDate',
                    type: 'datepicker',
                    required: true,
                    placeholder: 'Start Date'
                }, {
                    label: 'End Date',
                    name: 'endDate',
                    type: 'datepicker',
                    required: true,
                    placeholder: 'End Date'
                }, {
                    label: 'Valid',
                    name: 'valid',
                    type: 'switch',
                },
            ],
            fileImageList: [],
            loading: false,
            showImage: false,
            rewardDetail: {...RewardDetailObj}
        }
    }

    componentDidMount() {
        let {rewardDetail} = this.props
        rewardDetail.startDate = moment.unix(rewardDetail.startDate)
        rewardDetail.endDate = moment.unix(rewardDetail.endDate)
        this.formRef.current.setFieldsValue(rewardDetail)
        this.setState({showImage: !!rewardDetail?.image})
    }

    onFinish = (values) => {
        let {fileImageList, rewardDetail} = this.state
        let {submitButtonHandler} = this.props
        rewardDetail = {...rewardDetail, ...values}

        if (fileImageList.length > 0) {
            let fileName = renameImageToDateFormat(fileImageList[0].name, 'reward-')
            this.setState({rewardDetail})
            this.uploadImageToStorage(fileName, fileImageList[0])
        } else {
            submitButtonHandler(rewardDetail)
        }
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    uploadImageToStorage = (fileName, fileImage) => {
        let {rewardDetail} = this.state
        const {submitButtonHandler} = this.props
        putRewardImage(fileName, fileImage).on(
            storageTaskEvent.STATE_CHANGED,
            snapshot => {
            },
            err => {
                this.setState({loading: false})
                alert('Gagal mengunggah gambar banner')
                console.log('err', err)
            },
            () => {
                rewardStorageRef(fileName).getDownloadURL()
                    .then(url => {
                        rewardDetail.image = url
                        submitButtonHandler(rewardDetail)
                    })
            }
        )
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        let {rewardDetail} = this.props
        if (!isEqual(this.props.rewardDetail, prevProps.rewardDetail)) {
            rewardDetail.startDate = moment.unix(rewardDetail.startDate)
            rewardDetail.endDate = moment.unix(rewardDetail.endDate)
            this.formRef.current.setFieldsValue(rewardDetail)
        }
    }

    render() {
        const {rewardForm, fileImageList, loading, showImage} = this.state
        const {rewardDetail} = this.props

        return (
            <Form ref={this.formRef} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <List
                    dataSource={rewardForm}
                    renderItem={(item, index) => {
                        switch (item.type) {
                            case 'image':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Upload
                                            fileList={fileImageList}
                                            onRemove={this.removeButtonHandler}
                                            beforeUpload={this.beforeUploadImage}
                                        >
                                            {
                                                showImage &&
                                                <Image
                                                    src={rewardDetail.image}
                                                    width={100}
                                                    style={{backgroundColor: 'red'}}
                                                />}
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 10
                                                }}>
                                                <Button icon={<UploadOutlined/>}>
                                                    Upload
                                                </Button>
                                                <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                            </Row>
                                        </Upload>
                                    </Form.Item>
                                )
                            case 'textarea':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <TextArea placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                            case 'datepicker':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <DatePicker style={{width: 460}}/>
                                    </Form.Item>
                                )
                            case 'switch':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                        valuePropName="checked"
                                    >
                                        <Switch checked={rewardDetail?.valid} onChange={() => this.setState({})}/>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Input placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    }}
                />
                <Form.Item
                    wrapperCol={{
                        span: 8,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default RewardFormComponent
