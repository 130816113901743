import React from 'react';
import {Button, Dropdown, Menu, Row, Table} from "antd";
import ExportProductListComponent from "./export-product-list.component";
import {SET_LOADING, SET_ORDER_TYPE} from "../../reducers/analytics-product.reducer";
import {connect} from "react-redux";
import {analyticsProductDispatcher} from "../../actions/analytics-product.action";
import _ from "lodash";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";

class AnalyticsProductListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            keyword: '',
            columns: [
                {
                    title: 'Nama Produk',
                    dataIndex: 'name',
                    key: 'name',
                    width: 120,
                }, {
                    title: 'SKU',
                    dataIndex: 'skuCode',
                    key: 'skuCode',
                    width: 80
                }, {
                    title: 'Terjual',
                    dataIndex: 'productSold',
                    key: 'productSold',
                    width: 80
                }, {
                    title: '% Terjual',
                    dataIndex: 'productSoldPercentage',
                    key: 'productSoldPercentage',
                    width: 80
                },
                {
                    title: 'Penjualan Bulan Lalu',
                    dataIndex: 'prevProductSold',
                    key: 'prevProductSold',
                    width: 80
                },
                {
                    title: 'Pertumbuhan Penjualan',
                    dataIndex: 'productGrowthPercentage',
                    key: 'productGrowthPercentage',
                    width: 80
                },
            ],
            orderType: [
                {
                    name: 'Regular',
                    value: 'REGULAR'
                },
                {
                    name: 'Distribution Channel',
                    value: 'DC_REGULAR'
                },
                {
                    name: 'Delivery Order',
                    value: 'DC_DELIVERY_ORDER'
                },
                {
                    name: 'Pre Order',
                    value: 'PREORDER'
                },
                {
                    name: 'All',
                    value: ''
                },
            ],
            selectedType: {
                name: 'All',
                value: ''
            },
        }
    }

    dropDownWarehouseStock = () => {
        let {orderType} = this.state

        return (
            <Menu>
                {
                    orderType.map((order, index) => (
                        <Menu.Item key={index}>
                            <Button type="link"
                                    onClick={() => this.orderTypeButtonHandler(order)}
                            >{order.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    orderTypeButtonHandler = (order) => {
        let value = []
        this.setState({selectedType: order})

        if (order.value !== '') {
            value.push(order.value)
        } else {
            value.push("REGULAR", "DC_REGULAR", "DC_DELIVERY_ORDER", "PREORDER")
        }
        this.props.analyticsProductDispatcher(SET_ORDER_TYPE, {orderType: value})
        this.props.analyticsProductDispatcher(SET_LOADING, {loading: true})
    }

    onSearchHandler = (value) => {
        this.setState({keyword: value})
    }

    getDataToDisplay = () => {
        const {productSoldList} = this.props.analyticsProductReducer

        const productSoldListToDisplay = _.filter(productSoldList, item => {
            let name = item.name.toLowerCase().includes(this.state.keyword.toLowerCase())
            if (!!name) {
                return item.name.toLowerCase().includes(this.state.keyword.toLowerCase())
            }
        });

        return productSoldListToDisplay
    }

    render() {
        const {columns, selectedType} = this.state
        const productSoldList = this.getDataToDisplay()
        const {loading} = this.props.analyticsProductReducer

        return (
            <div style={{marginTop: 50}}>
                <Row justify={'space-between'} style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{width: '30%'}}>
                        <h5 style={{textAlign: 'start'}}>Daftar Produk Terjual</h5>
                    </div>
                    <div style={{marginBottom: 10, textAlign:'end'}}>
                        <SearchComponent searchItem={'produk'} onSearchHandler={this.onSearchHandler} />
                        <Dropdown overlay={this.dropDownWarehouseStock()} trigger={['click']}>
                            <Button
                                style={{
                                    backgroundColor: 'white',
                                    color: Colors.darkseagreen,
                                    marginRight: 10,
                                    minWidth: '100px'
                                }}
                                size="medium"
                                theme="filled"
                            >
                                {selectedType.name}
                            </Button>
                        </Dropdown>
                        <ExportProductListComponent productList={productSoldList}/>
                    </div>
                </Row>
                <Table
                    dataSource={productSoldList}
                    columns={columns}
                    loading={loading}
                    rowKey="productId"
                    pagination={false}
                    scroll={{x: 1500}}
                    summary={pageData => {
                        let totalProductSold = 0
                        let totalStock = 0
                        let totalProductSoldPrevMonth = 0

                        pageData.forEach(item => {
                            totalProductSold += item.productSold
                            totalStock += item.stock
                            totalProductSoldPrevMonth += item.prevProductSold
                        })

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell>
                                        <div>{totalProductSold}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                    <Table.Summary.Cell>
                                        <div>{totalProductSoldPrevMonth}</div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell/>
                                </Table.Summary.Row>
                            </>
                        )
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer, analyticsProductReducer} = state;

    return {warehouseReducer, analyticsProductReducer};
};

export default connect(mapStateToProps, {analyticsProductDispatcher})(AnalyticsProductListComponent);
