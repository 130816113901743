const INITIAL_STATE = {
    activeTab: '',
    processingActiveTab: '',
    disabled: false,
    isPrinted: null
}

export const TAB_REDUCER = 'TAB_REDUCER'
export const FLUSH_TAB_REDUCER = 'FLUSH_TAB_REDUCER'

export const tabReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case FLUSH_TAB_REDUCER:
            return {...INITIAL_STATE}
        case TAB_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
