import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function mapStateToProps(state) {
    return {};
}

class ExportTransactionPreorderComponent extends Component {
    render() {
        const {selectedRow} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Nama Pembeli" value="buyerName"/>
                    <ExcelColumn label="Nama Penjual" value="sellerName"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Level" value="buyerLevel"/>
                    <ExcelColumn label="Nik/NPWP" value="identityCardNumber"/>
                    <ExcelColumn label="Alamat" value="address"/>
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="ID Pre Order" value="poPaymentId"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Tanggal Pembayaran" value="paymentOrderTime"/>
                    <ExcelColumn label="Nama Product" value="productName"/>
                    <ExcelColumn label="Kode Sku" value="skuCode"/>
                    <ExcelColumn label="Harga Product" value="productPrice"/>
                    <ExcelColumn label="Additional Margin" value="additionalMargin"/>
                    <ExcelColumn label="Qty" value="productQty"/>
                    <ExcelColumn label="Subtotal" value="productSubTotal"/>
                    <ExcelColumn label="Subtotal Margin" value="subTotalMargin"/>
                    <ExcelColumn label="Diskon Poin" value="discountPoint"/>
                    <ExcelColumn label="Diskon Voucher" value="voucherReduction"/>
                    <ExcelColumn label="Cashback" value="grandCashback"/>
                    <ExcelColumn label="Shipping Price" value="shippingPrice"/>
                    <ExcelColumn label="Progressive Margin" value="progressiveMargin"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="Denda" value="fines"/>
                    <ExcelColumn label="Pembayaran Pre Order" value="grandTotalPreOrder"/>
                    <ExcelColumn label="Metode Pembayaran" value="paymentMethod"/>
                    <ExcelColumn label="Midtrans Id" value="midtransId"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default connect(
    mapStateToProps,
)(ExportTransactionPreorderComponent);
