import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const getAnalyticsProductApi = (requestBody, url) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/${url}`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {
    getAnalyticsProductApi,
}
