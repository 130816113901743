import React, {PureComponent} from 'react';
import {Card} from "antd";
import CreateManifestComponent from "./create-manifest.component";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import {connect} from "react-redux";
import {getOrderListToCreateManifestAction} from "../../actions/manifest.action";
import {orderDispatcher} from "../../actions/order.action";
import {avoskinId, userIdDC} from "../../ui-util/general-variable";
import {generalDispatcher} from "../../actions/general.action";
import CreateManifestHeaderComponent from "./create-manifest-header.component";
import UpdateResiModal from "../../components/update-resi.modal";
import isEqual from "react-fast-compare";
import {getUserListByLevel} from "../../actions/user.action";
import {MANIFEST_REDUCER} from "../../reducers/manifest.reducer";

class Manifest extends PureComponent {

    constructor() {
        super();

        this.state = {
            selectedRow: [],
            selectedPrint: [],
            selectedRowKeys: [],
            manifestUser: 'Avoskin',
            userList: [
                {
                    name: 'Avoskin',
                    id: avoskinId()
                }, {
                    name: 'DC',
                    id: userIdDC()
                }
            ]
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        await this.props.getUserListByLevel('report')
        this.props.getOrderListToCreateManifestAction()
    }

    onSelectPrint = (orderListSelected) => {
        let selectedRow = setOrderDataSelectedRows(orderListSelected);
        this.setState({selectedPrint: orderListSelected, selectedRow});
    };

    clearSelectedRow = () => {
        this.setState({selectedPrint: [], selectedRow: []})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {manifestUser, shippingMethod, searchKey} = this.props.manifestReducer
        if (!isEqual(manifestUser, prevProps.manifestReducer.manifestUser) ||
            !isEqual(shippingMethod, prevProps.manifestReducer.shippingMethod) ||
            !isEqual(searchKey, prevProps.manifestReducer.searchKey)) {
            this.props.getOrderListToCreateManifestAction()
        }
    }

    componentWillUnmount() {
        this.props.generalDispatcher(MANIFEST_REDUCER, {shippingMethod: 'Semua', selectedRowKeys: []})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {selectedPrint} = this.state;
        return (
            <Card>
                <CreateManifestHeaderComponent/>
                <CreateManifestComponent
                    onSelectPrint={this.onSelectPrint}
                    selectedPrint={selectedPrint}
                    clearSelectedRow={this.clearSelectedRow}
                />
                <UpdateResiModal afterUpdateHandler={null}/>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {manifestReducer} = state;

    return {manifestReducer}
}

export default connect(mapStateToProps, {
    orderDispatcher,
    generalDispatcher,
    getOrderListToCreateManifestAction,
    getUserListByLevel
})(Manifest);
