import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportTransactionOrderComponent extends React.Component {
    render() {
        const {selectedRow} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Nama Pembeli" value="buyerName"/>
                    <ExcelColumn label="Nama Penjual" value="sellerName"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Level" value="buyerLevel"/>
                    <ExcelColumn label="Nik/NPWP" value="identityCardNumber"/>
                    <ExcelColumn label="Alamat" value="address"/>
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="ID Guarantee" value="billingId"/>
                    <ExcelColumn label="ID Pre Order" value="poPaymentId"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Tanggal Pembayaran" value="paymentOrderTime"/>
                    <ExcelColumn label="Nama Product" value="productName"/>
                    <ExcelColumn label="Kode Sku" value="skuCode"/>
                    <ExcelColumn label="Harga Product" value="productPrice"/>
                    <ExcelColumn label="Additional Margin" value="additionalMargin"/>
                    <ExcelColumn label="Qty" value="productQty"/>
                    <ExcelColumn label="Subtotal" value="productSubTotal"/>
                    <ExcelColumn label="Subtotal Margin" value="subTotalMargin"/>
                    <ExcelColumn label="Diskon Poin" value="discountPoint"/>
                    <ExcelColumn label="Diskon Voucher Produk" value="voucherProductIncludeTax"/>
                    <ExcelColumn label="Diskon Voucher Shipping" value="voucherShippingIncludeTax"/>
                    <ExcelColumn label="Total Potongan Voucher" value="voucherReduction"/>
                    <ExcelColumn label="Cashback" value="grandCashback"/>
                    <ExcelColumn label="Shipping Price" value="shippingPrice"/>
                    <ExcelColumn label="Progressive Margin" value="progressiveMargin"/>
                    <ExcelColumn label="Harga Setelah Pajak" value="subTotalAfterTax"/>
                    <ExcelColumn label="Harga Sebelum Pajak" value="subTotalBeforeTax"/>
                    <ExcelColumn label="Total Pajak" value="totalTax"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="DP guarantee" value="grandTotalGuarantee"/>
                    <ExcelColumn label="DP Pre Order" value="grandTotalPreOrder"/>
                    <ExcelColumn label="Metode Pembayaran" value="paymentMethod"/>
                    <ExcelColumn label="Midtrans Id" value="midtransId"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
