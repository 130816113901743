import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class OrderAuditModel extends GenericDataModel{
    orderId: String
    actorId: String
    actorName: String
    action: String
    subAction: String
    description: String
    timestamp: Number

    constructor(orderAuditModel: OrderAuditModel) {
        super();
        Object.assign(this, orderAuditModel)
    }
}

export const OrderAuditObj = {
    ...GenericDataObj,
    orderId: null,
    actorId: null,
    actorName: null,
    action: null,
    subAction:null,
    description: null,
    timestamp: 0
}
