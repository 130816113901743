import React, {PureComponent} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {generalDispatcher} from "../../actions/general.action";
import {MANIFEST_REDUCER} from "../../reducers/manifest.reducer";

class FilterShippingComponent extends PureComponent {

    OrderListByShippingMethod = (shippingMethod) => {
        this.props.generalDispatcher(MANIFEST_REDUCER, {shippingMethod})
    }

    dropDownItem = () => {
        const menuList = [
            'POS Indonesia (POS)',
            'RPX Holding (RPX)',
            'Jalur Nugraha Ekakurir (JNE)',
            'J&T Express',
            'Porter',
            'SiCepat Express',
            'Lion Parcel',
            'Self Service',
            'Semua'
        ]

        return (
            <Menu>
                {
                    menuList.map((menuItem, index) => (
                        <Menu.Item key={index} onClick={() => this.OrderListByShippingMethod(menuItem)}>
                            <Button
                                type="link">{menuItem}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    render() {
        const {shippingMethod} = this.props.manifestReducer

        return (
            <Dropdown overlay={this.dropDownItem} trigger={['click']}>
                <Button
                    style={{backgroundColor: Colors.darkseagreen, color: 'white', minWidth: '20%'}}
                    icon={<DownOutlined/>}
                    size="large"
                    theme="filled"
                    onClick={() => this.props.generalDispatcher(MANIFEST_REDUCER, {
                        selectedRowExcel: [],
                        selectedPrint: [],
                        selectedRowKeys: []
                    })}
                >
                    {shippingMethod}
                </Button>
            </Dropdown>
        );
    }
}

const mapStateToProps = state => {
    const {manifestReducer} = state;

    return {manifestReducer}
}

export default connect(mapStateToProps, {generalDispatcher})(FilterShippingComponent);
