import {countProductPrice, formatUnixDateToReadable} from "./general-variable";

const setOrderDataPreOrderSelectedRows = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        item.products.forEach((product, idx) => {
            let data = {};
            if (idx === 0) {
                data = {
                    orderId: item.orderId,
                    orderType: item.orderType,
                    poPaymentId: item.poPaymentId,
                    orderDate: formatUnixDateToReadable(item.orderDate),
                    approvalDate: formatUnixDateToReadable(item.approvalDate),
                    buyerId: item.buyerId,
                    sellerId: item.sellerId,
                    userName: item.userName,
                    awb: item.awb,
                    paymentMethod: item.paymentMethod,
                    paymentStatus: item.paymentDetail.transactionStatus,
                    paymentTime: item.paymentDetail.transactionTime,
                    vaNumber: item.paymentDetail.vaNumber,
                    shippingMethod: item.shippingMethod,
                    shippingService: item.shippingService,
                    orderState: item.orderState,
                    status: item.status,
                    receiverName: item.shippingAddress.receiver,
                    shippingAddress: `${item.shippingAddress.address}, ${item.shippingAddress.district}, ${item.shippingAddress.city}, ${item.shippingAddress.province}, ${item.shippingAddress.zipcode}`,
                    voucherCode: item.voucherCode,
                    voucherDiscount: item.voucherReduction,
                    discountPoint: item.discountPoint,
                    shippingPrice: item.shippingPrice,
                    grandCashback: item.grandCashback,
                    grandTotal: item.grandTotal,
                    grandTotalGuarantee: item.grandTotalGuarantee,
                    grandTotalPreOrder: item.grandTotalPreOrder,
                    fines: item.fines,
                    payAmount: item.payAmount,
                    transactionId: item.paymentRemaining.transactionId,
                    transactionStatus: item.paymentRemaining.transactionStatus,
                    transactionTime: item.paymentRemaining.transactionTime,
                    vaNumberPo: item.paymentRemaining.vaNumber,
                    productId: product.id,
                    productName: product.name,
                    productPrice: countProductPrice(product.subtotal, product.count),
                    productQty: product.count,
                    productsubTotal: product.subtotal,

                };
            } else {
                data = {
                    orderId: '',
                    orderType: '',
                    poPaymentId: '',
                    orderDate: '',
                    approvalDate: '',
                    buyerId: '',
                    sellerId: '',
                    userName: '',
                    awb: '',
                    paymentMethod: '',
                    paymentStatus: '',
                    paymentTime: '',
                    vaNumber: '',
                    shippingMethod: '',
                    shippingService: '',
                    orderState: '',
                    status: '',
                    receiverName: '',
                    shippingAddress: '',
                    voucherCode: '',
                    voucherDiscount: '',
                    discountPoint: '',
                    shippingPrice: '',
                    grandCashback: '',
                    grandTotal: '',
                    grandTotalGuarantee: '',
                    grandTotalPreOrder: '',
                    fines: '',
                    payAmount: '',
                    transactionId: '',
                    transactionStatus: '',
                    transactionTime: '',
                    vaNumberPo: '',
                    productId: product.id,
                    productName: product.name,
                    productPrice: countProductPrice(product.subtotal, product.count),
                    productQty: product.count,
                    productsubTotal: product.subtotal
                };
            }
            selectedRow.push(data);
        })
    });
    return selectedRow;
};



const setRemainingPaymentPreOrderRows = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        let data = {
            status: item.status,
            orderType: item.orderType,
            orderId: item.orderId,
            poPaymentId: item.poPaymentId,
            orderDate: formatUnixDateToReadable(item.orderDate, 'll'),
            approvalDate: formatUnixDateToReadable(item.approvalDate, 'll'),
            expiredDate: formatUnixDateToReadable(item.expiredDate, 'll'),
            grandTotal: item.orderGrandTotal,
            payAmount: item.payAmount,
            fines: item.fines,
            buyerId: item.userId,
            userName: item.userName,
            paymentReceipt: item.paymentReceipt,
            paymentMethod: item.paymentMethod,
            paymentStatus: item.payment.transactionStatus,
            paymentTime: item.payment.transactionTime,
        };
        selectedRow.push(data);
    });
    return selectedRow;
};

export {
    setOrderDataPreOrderSelectedRows,
    setRemainingPaymentPreOrderRows
}
