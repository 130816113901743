import React from "react";
import ReactExport from "react-export-excel";
import {CloudDownloadOutlined} from '@ant-design/icons';
import {Button, Row} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportProductComponent extends React.Component {
    render() {
        const {selectedRow} = this.props;
        return (
            <ExcelFile element={
                <Button style={{borderWidth: 0, backgroundColor: 'transparent'}} disabled={selectedRow.length === 0}>
                    <Row style={{alignItems: 'center'}}>
                        <CloudDownloadOutlined style={{fontSize: 20, color: Colors.darkseagreen}}/>
                        <div style={{fontSize: 18, marginLeft: 10, color: Colors.darkseagreen}}>Export</div>
                    </Row>
                </Button>
            }>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Id Produk" value="id"/>
                    <ExcelColumn label="SKU Produk" value="skuCode"/>
                    <ExcelColumn label="Nama Produk" value="name"/>
                    <ExcelColumn label="Deskripsi Produk" value="description"/>
                    <ExcelColumn label="Berat Bersih" value="weight"/>
                    <ExcelColumn label="Cashback Produk" value="cashback"/>
                    <ExcelColumn label="Harga End Customer" value="endCustomerPrice"/>
                    <ExcelColumn label="Harga Bronze" value="bronzePrice"/>
                    <ExcelColumn label="Harga Platinum" value="platinumPrice"/>
                    <ExcelColumn label="Harga Premium 1" value="premium1Price"/>
                    <ExcelColumn label="Harga Premium 2" value="premium2Price"/>
                    <ExcelColumn label="Harga Distribution Channel 1" value="dc1Price"/>
                    <ExcelColumn label="Harga Distribution Channel 2" value="dc2Price"/>
                    <ExcelColumn label="Harga Distribution Channel 3" value="dc3Price"/>
                    <ExcelColumn label="Point End Customer" value="endCustomerPoint"/>
                    <ExcelColumn label="Point Bronze" value="bronzePoint"/>
                    <ExcelColumn label="Point Platinum" value="platinumPoint"/>
                    <ExcelColumn label="Point Premium 1" value="premium1Point"/>
                    <ExcelColumn label="Point Premium 2" value="premium2Point"/>
                    <ExcelColumn label="Point Distribution Channel 1" value="distributionChannel1Point"/>
                    <ExcelColumn label="Point Distribution Channel 2" value="distributionChannel2Point"/>
                    <ExcelColumn label="Point Distribution Channel 3" value="distributionChannel3Point"/>
                    <ExcelColumn label="Additional Margin End Customer" value="endCustomerAddMargin"/>
                    <ExcelColumn label="Additional Margin Bronze" value="bronzeAddMargin"/>
                    <ExcelColumn label="Additional Margin Platinum" value="platinumAddMargin"/>
                    <ExcelColumn label="Additional Margin Premium 1" value="premium1AddMargin"/>
                    <ExcelColumn label="Additional Margin Premium 2" value="premium2AddMargin"/>
                    <ExcelColumn label="Additional Margin Distribution Channel 1" value="distributionChannel1AddMargin"/>
                    <ExcelColumn label="Additional Margin Distribution Channel 2" value="distributionChannel2AddMargin"/>
                    <ExcelColumn label="Additional Margin Distribution Channel 3" value="distributionChannel3AddMargin"/>
                    <ExcelColumn label="Visible" value="isVisible"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
