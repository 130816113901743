import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportDcCashbackComponent extends React.Component {
    render() {
        const {selectedRow, totalCashbackItem, filename} = this.props;
        return (
            <ExcelFile filename={filename} element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="No" value="no"/>
                    <ExcelColumn label="Nama Avostore / DC" value="buyerName"/>
                    <ExcelColumn label="Level Avostore / DC" value="buyerLevel"/>
                    <ExcelColumn label="No Pesanan" value="orderId"/>
                    <ExcelColumn label="No Guarantee" value="billingId"/>
                    <ExcelColumn label="No Pre Order" value="poPaymentId"/>
                    <ExcelColumn label="Qty Produk" value="qty"/>
                    <ExcelColumn label="Sub Total Produk / Gross Line" value="grossLine"/>
                    <ExcelColumn label="Ongkos Kirim" value="shipping"/>
                    <ExcelColumn label="Potongan Poin" value="discountPoint"/>
                    <ExcelColumn label="Diskon Voucher" value="voucherReduction"/>
                    <ExcelColumn label="Cashback" value="cashback"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="Denda" value="fines"/>
                    <ExcelColumn label="Total Pembayaran / Cash Paid by User" value="cashPaidByUser"/>
                    <ExcelColumn label="Sisa Pembayaran" value="remainingPayment"/>
                    <ExcelColumn label="Net Cash Value" value="netCashValue"/>
                    <ExcelColumn label="Harga End Customer / Top Line" value="topLine"/>
                    <ExcelColumn label="Top Line Net Cash Value" value="topLineNetCashValue"/>
                    <ExcelColumn label="Persentase Potensial Margin" value="potentialMarginPercentage"/>
                    <ExcelColumn label="Potensial Cashback" value="potentialCashback"/>
                    <ExcelColumn label="Handling Fee" value="handlingFee"/>
                    <ExcelColumn label="Cashback Per Cash Value" value="cashbackPerCashValue"/>
                </ExcelSheet>
                <ExcelSheet data={totalCashbackItem} name="Total Cashback Item">
                    <ExcelColumn label="Top Line Net Cash Value" value="topLineNetCashValue"/>
                    <ExcelColumn label="Potensial Margin" value="potentialMarginPercentage"/>
                    <ExcelColumn label="Potential Cashback" value="potentialCashback"/>
                    <ExcelColumn label="Total Cashback" value="totalCashback"/>
                    <ExcelColumn label="Total Handling Fee" value="handlingFee"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
