import {formatUnixDateToReadable} from "./general-variable";
const formatPointExport = (data) => {
    let newExportData = [];
    data.forEach((item) => {
        let data = {
            email: item?.email,
            createdAt: formatUnixDateToReadable(item?.createdAt, 'YYYY-MM-DD hh:mm:ss'),
            orderId: item?.orderId,
            action: item?.action,
            prevPoint: item.prevPoint,
            addPoint: item.addPoint,
            minusPoint: item.minusPoint,
            currentPoint: item.currentPoint,
        };
        newExportData.push(data);
    });
    return newExportData;
};

export {formatPointExport}
