import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export const PENDING = 'PENDING'
export const APPROVED = 'APPROVED'
export const SUBMITTED = 'SUBMITTED'
export const PACKAGING = 'PACKAGING'
export const DELIVERY = 'DELIVERY'
export const COMPLETED = 'COMPLETED'
export const CANCELLED = 'CANCELLED'

type OrderState = SUBMITTED | PACKAGING | DELIVERY | COMPLETED | CANCELLED

export const REGULAR = 'REGULAR'
export const GUARANTEE = 'GUARANTEE'
export const PREORDER = 'PREORDER'
export const DC_PREORDER = 'DC_PREORDER'
export const DC_REGULAR = 'DC_REGULAR'
export const DC_INSTALLMENT = 'DC_INSTALLMENT'
export const DC_DELIVERY_ORDER = 'DC_DELIVERY_ORDER'
export const PREORDER_GUARANTEE = 'PREORDER_GUARANTEE'

interface PaymentDetail {
    transactionId: String,
    transactionTime: String,
    vaNumber: String,
    transactionStatus: String,
}

export default class OrderDetailModel extends GenericDataModel {
    orderId: String;
    orderType: OrderType;
    sellerId: String;
    buyerId: String;
    orderState: OrderState;
    products: Array<any>;
    shippingMethod: String;
    shippingService: String;
    shippingPrice: Number;
    grandTotal: Number;
    grandTotalGuarantee: Number;
    grandCashback: Number;
    discountPoint: Number;
    paymentMethod: String;
    voucherReduction: Number;
    voucherCode: String;
    shippingAddress: String;
    orderDate: String; //EPOCH
    awb: String;
    paymentDetail: PaymentDetail;
    referenceNo:String;
    payoutReferenceNo: String;
    deliveryTime: Number;
    isComplaint: Boolean;
    isGuarantee: Boolean;
    isUsingCashback: Boolean;
    isOrderDc: Boolean;
    isOrderDcWithGuarantee: Boolean;
    pointUsage: Number;
    pointEarned: Number;
    totalToDisburse: Number;
    totalDisbursed: Number;
    warehouseId: String;
    paymentReceipt: String;
    approvalDate: Number

    constructor(orderDetailModel: OrderDetailModel) {
        super()
        Object.assign(this, orderDetailModel)
    }
}

export const OrderDetailObj = {
    ...GenericDataObj,
    orderId: null,
    orderType: '',
    sellerId: null,
    buyerId: null,
    orderState: null,
    products: [],
    shippingMethod: null,
    shippingService: null,
    shippingPrice: 0,
    grandTotal: 0,
    grandTotalGuarantee: 0,
    grandCashback: 0,
    discountPoint: 0,
    paymentMethod: null,
    voucherReduction: 0,
    voucherCode: null,
    shippingAddress: null,
    orderDate: null,
    awb: null,
    paymentDetail: {
        transactionId: null,
        transactionTime: null,
        vaNumber: null,
        transactionStatus: '',
    },
    referenceNo: null,
    payoutReferenceNo: null,
    deliveryTime: null,
    isComplaint: false,
    isGuarantee: false,
    isUsingCashback: false,
    isOrderDc: false,
    isOrderDcWithGuarantee: false,
    pointUsage: 0,
    pointEarned: 0,
    totalToDisburse: 0,
    totalDisbursed: 0,
    warehouseId: null,
    paymentReceipt: null,
    approvalDate: null
}
