import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().startOf( 'month'),
    endDate: moment().endOf('days'),
    productGrowth: {current: 0, prev: 0},
    productSoldPerDay: [],
    productSoldList: [],
    productChart: {},
    loading: false,
    orderType:["REGULAR","DC_REGULAR","DC_DELIVERY_ORDER","PREORDER"],
    reloadPage: false
}

export const ANALYTICS_PRODUCT = 'ANALYTICS_PRODUCT'
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
export const SET_LOADING = 'SET_LOADING'

export const analyticsProductReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ANALYTICS_PRODUCT:
        case SET_ORDER_TYPE:
        case SET_LOADING:
            return {...state, ...action.payload}
        default:
            return state
    }
}
