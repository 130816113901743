import React, {Component} from 'react'
import {Button, Form, Image, Input, InputNumber, Radio, Row, Select, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {Colors} from "../../ui-util/colors";
import {ProductDetailObj} from "../../models/product.model";
import {renameImageToDateFormat} from "../../ui-util/general-variable";
import {productStorageRef, putProductImage} from "../../firebase/storage/product-image";
import {storageTaskEvent} from "../../firebase";
import {createProductApi, updateProductDetailApi} from "../../api/product.api";
import {getCategoriesApi} from "../../api/product-category.api";

const {TextArea} = Input;
const {Option} = Select;

class CreateProductFormComponent extends Component {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            productForm: [
                {
                    label: 'Foto Produk',
                    name: 'image',
                    type: 'image',
                    required: true,
                    messageAlert: 'Masukkan foto produk',
                }, {
                    label: 'Nama Produk',
                    name: 'name',
                    type: 'input',
                    required: true,
                    messageAlert: 'Masukkan nama produk',
                    placeholder: 'Cth: Looke Cosmetics'
                },
                {
                    label: 'Kategori Produk',
                    name: 'categories',
                    type: 'select',
                    required: true,
                    messageAlert: 'Masukkan kategori produk',
                    placeholder: 'Cth: Cosmetics'
                },
                {
                    label: 'Berat Bersih',
                    name: 'weight',
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan berat bersih',
                    placeholder: 'Cth: 100'
                }, {
                    label: 'Cashback',
                    name: 'cashback',
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan jumlah cashback',
                    placeholder: 'Cth: 10000'
                }, {
                    label: 'SKU',
                    name: 'skuCode',
                    type: 'input',
                    required: true,
                    messageAlert: 'Masukkan kode SKU',
                    placeholder: 'Cth: 202008'
                }, {
                    label: 'GS1',
                    name: 'gsOneCode',
                    type: 'input',
                    required: true,
                    messageAlert: 'Masukkan kode GS1',
                    placeholder: 'Cth: 202008'
                }, {
                    label: 'Description',
                    name: 'description',
                    type: 'textarea',
                    required: true,
                    messageAlert: 'Masukkan deskripsi',
                    placeholder: 'Cth: deskripsi'
                }, {
                    label: 'Price End Customer',
                    name: ['price', 'END_CUSTOMER', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga end customer',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Bronze',
                    name: ['price', 'BRONZE', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga bronze',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Platinum',
                    name: ['price', 'PLATINUM', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga platinum',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Premium 1',
                    name: ['price', 'PREMIUM_1', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga premium 1',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Premium 2',
                    name: ['price', 'PREMIUM_2', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga premium 2',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Distribution Channel 1',
                    name: ['price', 'DISTRIBUTION_CHANNEL_1', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga distribution channel 1',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Distribution Channel 2',
                    name: ['price', 'DISTRIBUTION_CHANNEL_2', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga distribution channel 2',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Price Distribution Channel 3',
                    name: ['price', 'DISTRIBUTION_CHANNEL_3', 'price'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan harga distribution channel 3',
                    placeholder: 'Cth: 100000',
                    slug: 'price'
                }, {
                    label: 'Point Bronze',
                    name: ['point', 'BRONZE', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin bronze',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point End Customer',
                    name: ['point', 'END_CUSTOMER', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin end customer',
                    placeholder: 'Cth: 10'
                }, {
                    label: 'Point Platinum',
                    name: ['point', 'PLATINUM', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin platinum',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point Premium 1',
                    name: ['point', 'PREMIUM_1', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin  premium 1',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point Premium 2',
                    name: ['point', 'PREMIUM_2', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin  premium 2',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point Distribution Channel 1',
                    name: ['point', 'DISTRIBUTION_CHANNEL_1', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin distribution channel 1',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point Distribution Channel 2',
                    name: ['point', 'DISTRIBUTION_CHANNEL_2', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin distribution channel 2',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Point Distribution Channel 3',
                    name: ['point', 'DISTRIBUTION_CHANNEL_3', 'point'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan bobot poin distribution channel 3',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Bronze',
                    name: ['additionalMargin', 'BRONZE', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin bronze',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin End Customer',
                    name: ['additionalMargin', 'END_CUSTOMER', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin end customer',
                    placeholder: 'Cth: 10'
                }, {
                    label: 'Additional Margin Platinum',
                    name: ['additionalMargin', 'PLATINUM', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin platinum',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Premium 1',
                    name: ['additionalMargin', 'PREMIUM_1', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin  premium 1',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Premium 2',
                    name: ['additionalMargin', 'PREMIUM_2', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin  premium 2',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Distribution Channel 1',
                    name: ['additionalMargin', 'DISTRIBUTION_CHANNEL_1', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin distribution channel 1',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Distribution Channel 2',
                    name: ['additionalMargin', 'DISTRIBUTION_CHANNEL_2', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan additional margin distribution channel 2',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Additional Margin Distribution Channel 3',
                    name: ['additionalMargin', 'DISTRIBUTION_CHANNEL_3', 'additionalMargin'],
                    type: 'input-number',
                    required: true,
                    messageAlert: 'Masukkan Additional Margin distribution channel 3',
                    placeholder: 'Cth: 10',
                }, {
                    label: 'Tampilkan Produk',
                    name: 'isVisible',
                    type: 'radio',
                    required: true,
                    optionItem: [
                        {name: 'Ya', value: true},
                        {name: 'Tidak', value: false}
                    ]
                },
            ],
            fileImageList: [],
            productDetail: {...ProductDetailObj},
            loading: false,
            showImage: false,
            categoryList: []
        }
    }

    componentDidMount() {
        const {productDetailUpdate} = this.props
        this.getCategories()
        this.formRef.current.setFieldsValue(productDetailUpdate)
        if (!!productDetailUpdate) {
            this.setState({showImage: true})
        }
    }

    getCategories = () => {
        getCategoriesApi()
            .then(result => {
                this.setState({categoryList: result})
            })
            .catch(err => {
                alert("Gagal mendapatkan data kategori !")
            })
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    onFinishHandler = (values) => {
        this.setState({loading: true})
        let {fileImageList, productDetail} = this.state
        let {slug} = this.props
        if (fileImageList.length > 0) {
            let fileName = renameImageToDateFormat(fileImageList[0].name, 'product-')
            Object.assign(productDetail, values)
            putProductImage(fileName, fileImageList[0]).on(
                storageTaskEvent.STATE_CHANGED,
                snapshot => {
                },
                err => console.log('err', err),
                () => {
                    productStorageRef(fileName).getDownloadURL()
                        .then(urlImage => {
                            if (slug === 'edit') {
                                values.image = urlImage
                                this.updateProduct(values)
                            } else {
                                productDetail.image = urlImage
                                this.createProduct(productDetail)
                            }
                        })
                }
            )
        } else {
            this.updateProduct(values)
        }
    }

    createProduct = (productDetail) => {
        createProductApi(productDetail)
            .then(result => {
                this.setState({loading: false})
                alert('Berhasil !!')
                window.location.href = '/product/list'
            })
            .catch(err => console.log(err))
    }

    updateProduct = (values) => {
        const {productDetailUpdate} = this.props
        updateProductDetailApi(productDetailUpdate.id, values)
            .then(result => {
                this.setState({loading: false})
                alert('Berhasil !!')
                window.location.href = '/product/list'
            })
            .catch(err => console.log(err))
    }

    getOptions = () => {
        const {categoryList} = this.state;

        return (
            categoryList.map((item, index) => (
                <Option value={item?.name}
                        key={index.toString()}>{item?.name}</Option>
            ))
        )
    }

    render() {
        const {productForm, fileImageList, loading, showImage, categoryList} = this.state
        const {productDetailUpdate} = this.props

        return (
            <Form ref={this.formRef} onFinish={this.onFinishHandler} labelCol={{span: 6}} wrapperCol={{span: 10}}>
                {
                    productForm.map((productItem, index) => {
                        switch (productItem.type) {
                            case 'image':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.messageAlert,
                                        }]}
                                    >
                                        <Upload
                                            fileList={fileImageList}
                                            onRemove={this.removeButtonHandler}
                                            beforeUpload={this.beforeUploadImage}
                                        >
                                            {
                                                showImage &&
                                                <Image
                                                    src={productDetailUpdate.image}
                                                    width={100}
                                                />
                                            }
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 10
                                                }}>
                                                <Button icon={<UploadOutlined/>}>
                                                    Upload
                                                </Button>
                                                <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                            </Row>
                                        </Upload>
                                    </Form.Item>
                                )
                            case 'textarea':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.messageAlert,
                                        }]}
                                    >
                                        <TextArea placeholder={productItem.placeholder}/>
                                    </Form.Item>
                                )
                            case 'input-number':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.messageAlert,
                                        }]}
                                    >
                                        <InputNumber min={0} style={{width: 570}}
                                                     placeholder={productItem.placeholder}
                                        />
                                    </Form.Item>
                                )
                            case 'radio':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        // onChange={this.onValuesChangeHandler}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.placeholder,
                                        }]}
                                    >
                                        <Radio.Group>
                                            {
                                                productItem.optionItem.map((item, index) => {
                                                    return (
                                                        <Radio style={{display: 'block'}} value={item.value}
                                                               key={index.toString()}>{item.name}</Radio>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                )
                            case 'select':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.placeholder,
                                        }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder={productItem.placeholder}
                                        >
                                            {
                                                categoryList.map((item, index) => (
                                                    <Option value={item?.id}
                                                            key={index.toString()}>{item?.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={productItem.label}
                                        name={productItem.name}
                                        rules={[{
                                            required: productItem.required,
                                            message: productItem.messageAlert,
                                        }]}
                                    >
                                        <Input placeholder={productItem.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    })
                }
                <Form.Item
                    wrapperCol={{
                        span: 3,
                        offset: 4,
                    }}
                >
                    <Button type="primary" htmlType="submit" style={{
                        backgroundColor: Colors.gainsboro,
                        borderColor: Colors.darkseagreen,
                        color: 'black',
                        borderRadius: 5
                    }} loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default CreateProductFormComponent
