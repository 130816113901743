import React, {Component} from 'react';
import {Card, Col, Divider, Row, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";

class ReportDcComponent extends Component {
    render() {
        const {
            orderTotal,
            grandSubTotal,
            topProductListDc,
            columns,
            productOrderTotal,
            totalOrderDc,
            grandTotalOrderDc,
            totalProductOrderDc
        } = this.props

        return (
            <div>
                <Row style={{marginTop: 20}}>
                    <Col span={12}>
                        <Card style={{
                            // maxWidth: '90%',
                            borderWidth: 1,
                            borderColor: Colors.gainsboro,
                            marginLeft: 30,
                            borderRadius: 7,
                            minHeight: 150
                        }}>
                            <Row align={'start'}>
                                <div style={{
                                    width: '40%',
                                    borderRightWidth: 'thin',
                                    borderRightStyle: 'solid',
                                    borderColor: 'gray',
                                    marginRight: 10
                                }}>
                                    <div style={{fontSize: 16}}>Total Order : {orderTotal}</div>
                                    <Divider/>
                                    <div style={{fontSize: 16}}>Total Produk : {productOrderTotal}</div>
                                </div>
                                <div>
                                    <div style={{fontSize: 16}}>Total Sales :</div>
                                    <div style={{marginLeft: 5, marginTop: 10, fontSize: 20}}>
                                        {doConvertNumberToRupiahFormat(grandSubTotal)}
                                        {/*<p style={{fontSize: 12, color: Colors.salmon}}>* total sales adalah subtotal*/}
                                        {/*    (harga*/}
                                        {/*    produk x jumlah produk) order</p>*/}
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{
                            // maxWidth: '90%',
                            borderWidth: 1,
                            borderColor: Colors.gainsboro,
                            marginLeft: 30,
                            borderRadius: 7,
                            minHeight: 150
                        }}>
                            <Row align={'start'}>
                                <Col style={{
                                    width: '40%',
                                    borderRightWidth: 'thin',
                                    borderRightStyle: 'solid'
                                }}>
                                    <div
                                        style={{fontSize: 16}}>Total Pembelian : {totalOrderDc}</div>
                                    <Divider/>
                                    <div style={{fontSize: 16}}>Total Produk : {totalProductOrderDc}</div>
                                </Col>
                                <Col style={{marginLeft: 10}}>
                                    <div style={{fontSize: 16}}>Total sales : </div>
                                    <div style={{marginLeft: 5, marginTop: 10, fontSize: 20}}>
                                        {doConvertNumberToRupiahFormat(grandTotalOrderDc)}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}>
                    <h5>Top Produk Dibeli</h5>
                    <Table dataSource={topProductListDc.map((d, i) => ({ key: i, ...d }))} columns={columns}/>
                </div>
            </div>
        );
    }
}

export default (ReportDcComponent);