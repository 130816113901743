const INITIAL_STATE = {
    productList : [],
    searchKey: '',
    productDetail: {},
    productSelected: [],
    categoryIds: []
}

export const PRODUCT_LIST = 'PRODUCT_LIST'
export const SEARCH_PRODUCT_LIST = 'SEARCH_PRODUCT_LIST'
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL'
export const PRODUCT_REDUCER = 'PRODUCT_REDUCER'
export const SET_CATEGORY_IDS = 'SET_CATEGORY_IDS'

export const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return {...state, productList: [...action.payload]}
        case SEARCH_PRODUCT_LIST:
            return {...state, searchKey: action.payload}
        case PRODUCT_REDUCER:
        case PRODUCT_DETAIL:
        case SET_CATEGORY_IDS:
            return {...state, ...action.payload}
        default:
            return state
    }
}
