import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const getLevelApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/level/list`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const updateRewardApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/level/update-reward`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {getLevelApi, updateRewardApi}

