import React from 'react';
import {Card} from "antd";
import AnalyticsProductHeaderComponent from "./analytics-product-header.component";
import AnalyticsProductDailyCountComponent from "./analytics-product-daily-count.component";
import AnalyticsProductListComponent from "./analytics-product-list";
import {connect} from "react-redux";
import {getWarehouseList} from "../../actions/warehouse.action";
import {getUserListByLevel} from "../../actions/user.action";
import {isEqual} from "../../ui-util/general-variable";
import {
    getAnalyticsProductGrowthAction, getAnalyticsProductSoldListAction,
    getAnalyticsProductSoldPerDayAction
} from "../../actions/analytics-product.action";

class AnalyticsProductPage extends React.PureComponent {

    componentDidMount(): void {
        this.props.getWarehouseList()
        this.props.getUserListByLevel('report')
        this.props.getAnalyticsProductGrowthAction()
        this.props.getAnalyticsProductSoldPerDayAction()
        this.props.getAnalyticsProductSoldListAction()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(this.props.dropdownSellerReducer, prevProps.dropdownSellerReducer) ||
            !isEqual(this.props.analyticsProductReducer.reloadPage, prevProps.analyticsProductReducer.reloadPage)) {
            this.props.getAnalyticsProductGrowthAction()
            this.props.getAnalyticsProductSoldPerDayAction()
            this.props.getAnalyticsProductSoldListAction()
        } else if(!isEqual(this.props.analyticsProductReducer.orderType, prevProps.analyticsProductReducer.orderType)){
            this.props.getAnalyticsProductSoldListAction()
        }
    }

    render() {
        return (
            <Card style={{backgroundColor: 'transparent'}}>
                <AnalyticsProductHeaderComponent/>
                <AnalyticsProductDailyCountComponent/>
                <AnalyticsProductListComponent/>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsProductReducer, dropdownSellerReducer} = state;

    return {analyticsProductReducer, dropdownSellerReducer};
};

export default connect(mapStateToProps, {
    getWarehouseList,
    getUserListByLevel,
    getAnalyticsProductGrowthAction,
    getAnalyticsProductSoldPerDayAction,
    getAnalyticsProductSoldListAction
})(AnalyticsProductPage);
