import {firebaseStorage} from "../index";

const notificationRef = (imageName) => firebaseStorage.ref('notification').child(imageName)

const notificationStorageRef = (imageName) => firebaseStorage.ref(`notification/${imageName}`);

const putNotificationImage = (fileName, file) => {
    return notificationStorageRef(fileName).put(file)
}

export {notificationStorageRef, putNotificationImage, notificationRef}
