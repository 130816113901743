import React from 'react'
import {Card} from "antd";
import CreateProductFormComponent from "./create-product-form.component";

class CreateProductPage extends React.PureComponent {
    render() {
        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Tambah Produk</h3>
                <CreateProductFormComponent slug={'create'}/>
            </Card>
        )
    }
}

export default CreateProductPage
