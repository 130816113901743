import React from "react";
import {Button, Row, Select} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {generalDispatcher} from "../actions/general.action";
import {DC_ORDER_REDUCER} from "../reducers/dc-order.reducer";

const {Option} = Select;

class DropdownWarehouseDcComponent extends React.PureComponent{

    render() {
        const {warehouseReducer, onFilter} = this.props
        const {warehouseAvoStoreList} = warehouseReducer
        return (
            <Row span={7} className="d-flex justify-content-around align-items-center">
                Warehouse DC :
                <Select defaultValue={''} style={{width: 250, marginLeft: 10, marginRight: 10}}
                        onChange={(item) => this.props.generalDispatcher(DC_ORDER_REDUCER, {warehouseDcId: item})}>
                    {
                        warehouseAvoStoreList.map((item, index) => (
                            <Option value={item.warehouseId}
                                    key={index.toString()}>{item.warehouseName ? item.warehouseName : item.name}</Option>
                        ))
                    }
                </Select>
                <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        onClick={onFilter}>
                    Filter
                </Button>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    const {warehouseReducer} = state;

    return {warehouseReducer}
}

export default connect(mapStateToProps, {generalDispatcher})(DropdownWarehouseDcComponent)
