import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportGuaranteeComponent extends React.Component {
    render() {
        const {guaranteeOrderList, button, filename} = this.props;
        return (
            <ExcelFile element={button} filename={filename}>
                <ExcelSheet data={guaranteeOrderList} name="Order Guarantee List">
                    <ExcelColumn label="Nama" value="userGuaranteeName"/>
                    <ExcelColumn label="ID User" value="userId"/>
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="ID Guarantee" value="billingId"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Grand Total" value="orderGrandTotal"/>
                    <ExcelColumn label="Status Guarantee" value="status"/>
                    <ExcelColumn label="Product ID" value="productId"/>
                    <ExcelColumn label="Nama Product" value="productName"/>
                    <ExcelColumn label="Harga Product" value="productPrice"/>
                    <ExcelColumn label="Qty" value="productQty"/>
                    <ExcelColumn label="Subtotal" value="productsubTotal"/>
                    <ExcelColumn label="Termin" value="termin"/>
                    <ExcelColumn label="Persentase Pembayaran" value="paymentPercent"/>
                    <ExcelColumn label="Payment Method" value="paymentMethod"/>
                    <ExcelColumn label="ID Pembayaran" value="transactionId"/>
                    <ExcelColumn label="Status Pembayaran" value="paymentStatus"/>
                    <ExcelColumn label="Waktu Pembayaran" value="paymentTime"/>
                    <ExcelColumn label="Total Pembayaran Guarantee" value="payAmount"/>
                    <ExcelColumn label="VA Number" value="vaNumber"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
