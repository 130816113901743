import {
    countProductPrice,
    formatUnixDateToReadable,
    getLevelText,
    getPaymentMethodText,
    getStatusDisbursement
} from "./general-variable";
import _ from 'lodash'

const setOrderDataSelectedRows = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        let productToPrint = item?.gifts ? [...item.products, ...item.gifts] : [...item.products]
        productToPrint.forEach((product, idx) => {
            let data = {};
            if (idx === 0) {
                data = {
                    orderId: item.orderId,
                    orderType: item.orderType,
                    orderDate: formatUnixDateToReadable(item.orderDate),
                    buyerId: item.buyerId,
                    sellerId: item.sellerId,
                    sellerName: item.sellerName ? item.sellerName : '',
                    sellerEmail: item.sellerEmail ? item.sellerEmail : '',
                    awb: item.awb,
                    paymentMethod: item.paymentMethod,
                    paymentStatus: item.paymentDetail.transactionStatus,
                    paymentTime: item.paymentDetail.transactionTime,
                    vaNumber: item.paymentDetail.vaNumber,
                    shippingMethod: item.shippingMethod,
                    shippingService: item.shippingService,
                    orderState: item.orderState,
                    receiverName: item.shippingAddress.receiver,
                    shippingAddress: `${item.shippingAddress.address}, ${item.shippingAddress.district}, ${item.shippingAddress.city}, ${item.shippingAddress.province}, ${item.shippingAddress.zipcode}`,
                    voucherCode: item.voucherCode,
                    voucherDiscount: item.voucherReduction,
                    voucherDiscountShipping: item?.voucherReductionComponent?.shipping || 0,
                    voucherDiscountProduct: item?.voucherReductionComponent?.product || 0,
                    discountPoint: item.discountPoint,
                    shippingPrice: item.shippingPrice,
                    grandCashback: item.grandCashback,
                    grandTotal: item.grandTotal,
                    grandTotalGuarantee: item.grandTotalGuarantee,
                    grandTotalPreOrder: item.grandTotalPreOrder,
                    productId: product.id,
                    productName: product?.itemName ? product.itemName : product.name,
                    productPrice: product?.subtotal ? countProductPrice(product.subtotal, product.count) : 0,
                    productQty: product.count,
                    productsubTotal: product?.subtotal ? product.subtotal : 0,
                    progressiveMargin: item?.progressiveMargin ? item?.progressiveMargin : 0,
                    sumSubtotalMargin: item?.sumSubtotalMargin || 0,
                    additionalMargin: product?.subtotalMargin ? (product?.subtotalMargin / product?.count) : 0,
                    subtotalMargin: product?.subtotalMargin || 0,
                };
            } else {
                data = {
                    orderId: '',
                    orderType: '',
                    orderDate: '',
                    buyerId: '',
                    sellerId: '',
                    awb: '',
                    paymentMethod: '',
                    paymentStatus: '',
                    paymentTime: '',
                    vaNumber: '',
                    shippingMethod: '',
                    shippingService: '',
                    orderState: '',
                    receiverName: '',
                    shippingAddress: '',
                    voucherCode: '',
                    voucherDiscount: '',
                    discountPoint: '',
                    shippingPrice: '',
                    grandCashback: '',
                    grandTotal: '',
                    grandTotalGuarantee: '',
                    grandTotalPreOrder: '',
                    productId: product.id,
                    productName: product?.itemName ? product.itemName : product.name,
                    productPrice: product?.subtotal ? countProductPrice(product.subtotal, product.count) : 0,
                    additionalMargin: product?.subtotalMargin ? (product?.subtotalMargin / product?.count) : 0,
                    subtotalMargin: product?.subtotalMargin || 0,
                    productQty: product.count,
                    productsubTotal: product?.subtotal ? product.subtotal : 0,
                    sumSubtotalMargin: ''
                };
            }
            selectedRow.push(data);
        });
    });
    return selectedRow;
};

const setTransactionListToExport = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        let productToPrint = item?.gifts ? [...item.products, ...item.gifts] : [...item.products]
        productToPrint.forEach((product, idx) => {
            let data = {}
            if (idx === 0) {
                data = {
                    productName: product?.itemName ? product.itemName : product.name,
                    productPrice: product.subtotal ? countProductPrice(product.subtotal, product.count) : 0,
                    productQty: product.count,
                    productSubTotal: product.subtotal ? product.subtotal : 0,
                    subTotalMargin: product.subtotalMargin ? product.subtotalMargin : 0,
                    skuCode: product.skuCode,
                    buyerName: item.buyerName,
                    sellerName: item.sellerName,
                    buyerLevel: getLevelText(item.buyerLevel),
                    email: item.email,
                    identityCardNumber: item.identityCardNumber,
                    address: `${item.address.address}, ${item.address.district}, ${item.address.city}, ${item.address.province}, ${item.address.zipcode}`,
                    orderId: item.orderId,
                    billingId: item.billingId,
                    poPaymentId: item.poPaymentId,
                    orderDate: formatUnixDateToReadable(item.orderDate),
                    discountPoint: item.discountPoint,
                    voucherProductIncludeTax: item.voucherProductIncludeTax ? item?.voucherProductIncludeTax : 0,
                    voucherShippingIncludeTax: item?.voucherShippingAfterTax || 0,
                    voucherReduction: item.voucherReduction ? item?.voucherReduction : 0,
                    grandCashback: item.cashback,
                    shippingPrice: item.shippingPrice,
                    subTotalAfterTax: item?.subTotalAfterTax ? item.subTotalAfterTax : 0,
                    subTotalBeforeTax: item?.subTotalBeforeTax ? item.subTotalBeforeTax : 0,
                    totalTax: item?.totalTax,
                    grandTotal: item.grandTotal,
                    progressiveMargin: item?.progressiveMargin,
                    additionalMargin:product?.subtotalMargin ? (product?.subtotalMargin / product?.count) : 0,
                    grandTotalGuarantee: item.grandTotalGuarantee,
                    grandTotalPreOrder: item.grandTotalPreOrder,
                    invoice: item.paymentReceipt ? item.invoice : '',
                    paymentReceipt: item.paymentReceipt,
                    payAmount: item.payment.payAmount ? item.payment.payAmount : '',
                    fines: item.payment.fines ? item.payment.fines : item.fines,
                    totalPayAmount: item.payment.totalPayAmount ? item.payment.totalPayAmount : '',
                    paymentOrderTime: item.paymentDetail.transactionTime,
                    paymentMethod: _.includes(item.paymentMethod, 'credit') ? item.paymentMethod : getPaymentMethodText(item.paymentMethod),
                    midtransId: item?.midtransId,
                    guaranteePaymentDate: item?.payment?.paymentDetail?.transactionTime ? item.payment.paymentDetail.transactionTime : '',
                }
            } else {
                data = {
                    productName: product?.itemName ? product.itemName : product.name,
                    productPrice: product.subtotal ? countProductPrice(product.subtotal, product.count) : 0,
                    additionalMargin:product?.subtotalMargin ? (product?.subtotalMargin / product?.count) : 0,
                    subTotalMargin: product?.subtotalMargin,
                    productQty: product.count,
                    skuCode: product.skuCode,
                    productSubTotal: product.subtotal,
                    buyerName: '',
                    email: '',
                    identityCardNumber: '',
                    address: '',
                    orderId: '',
                    billingId: '',
                    orderDate: '',
                    discountPoint: '',
                    voucherProductIncludeTax: '',
                    voucherShippingIncludeTax: '',
                    voucherReduction: '',
                    grandCashback: '',
                    shippingPrice: '',
                    subTotalAfterTax: '',
                    subTotalBeforeTax: '',
                    totalTax: '',
                    grandTotal: '',
                    grandTotalGuarantee: '',
                    invoice: '',
                    paymentReceipt: '',
                    payAmount: '',
                    fines: '',
                    totalPayAmount: '',
                    guaranteePaymentDate: '',
                    grandTotalPreOrder:'',
                    poPaymentId: ''
                };
            }

            selectedRow.push(data);
        });
    });

    return selectedRow;
};

const setDisbursementListToExport = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        let data = {
            id: item.id,
            userId: item.userId,
            userName: item.userName,
            userEmail: item.userEmail,
            bankAccountName: item.bankAccountName,
            bankAccountCode: item.bankAccountCode,
            bankAccountNumber: item.bankAccountNumber,
            created_at: formatUnixDateToReadable(item.created_at, 'll'),
            totalDisbursement: item.totalDisbursement,
            payoutStatus: getStatusDisbursement(item.payoutStatus),
        };
        selectedRow.push(data);
    });

    return selectedRow;
};

const setPotentialLostDataToExport = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        let data = {
            productId: item.productId,
            productName: item.productName,
            skuCode: item.skuCode,
            potentialLostCount: item.potentialLostCount,
            potentialLostValue: item.potentialLostValue,

        };
        selectedRow.push(data);
    });

    return selectedRow;
};

export {
    setOrderDataSelectedRows,
    setTransactionListToExport,
    setDisbursementListToExport,
    setPotentialLostDataToExport
}
