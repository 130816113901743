import {firebaseStorage} from "../index";

const bannerStorageRef = (imageName) => firebaseStorage.ref(`banner/${imageName}`);

const putBannerImage = (imageName, filePath) => {
    return bannerStorageRef(imageName).put(filePath)
}

const getDownloadUrlBannerImage = (imageName) => {
    return bannerStorageRef(imageName).getDownloadURL()
}

export {putBannerImage, bannerStorageRef, getDownloadUrlBannerImage}
