import React, {Component} from 'react';
import {Button, Card, Col, Form, Row, Select, Table} from "antd";
import {getDetailDisbursementApi, updateDisbursementDetailApi} from "../../api/disbursement.api";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    getStatusDisbursement
} from "../../ui-util/general-variable";
import {Colors} from "../../ui-util/colors";

const {Option} = Select;

class DisbursementDetailPage extends Component {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            disbursementDetail: {},
            columns: [
                {title: 'Nama', dataIndex: 'userName', key: 'userName'},
                {
                    title: 'No. Pesanan',
                    dataIndex: 'orderId',
                    key: 'orderId',
                    render: (orderId, record) => <a href={`/order-detail/${record.id}`} target="_blank"
                                                    style={{color: 'black'}}>{orderId}</a>
                },
                {title: 'No. Guarantee', dataIndex: 'billingId', key: 'billingId'},
                {
                    title: 'Total Pencairan',
                    dataIndex: 'totalAmount',
                    key: 'totalAmount',
                    render: (totalAmount) => doConvertNumberToRupiahFormat(totalAmount)
                },
            ],
            listItem: [
                {
                    value: 'approved',
                    status: 'Pengajuan disetujui'
                },
                {
                    value: 'rejected',
                    status: 'Pengajuan ditolak'
                },
            ],
            selectedStatus: ''
        }
    }

    componentDidMount() {
        this.getDetailDisbursement()
    }

    getDetailDisbursement = () => {
        const {id} = this.props.match.params
        let body = {disbursementId: id}

        getDetailDisbursementApi(body)
            .then(result => {
                this.setState({disbursementDetail: result})
            })
            .catch(err => {
                console.log("error get detail disbursement :", err)
            })
    }

    onChangeSelectHandler = (value) => {
        this.setState({selectedStatus: value})
    }

    onFinish = () => {
        const {selectedStatus, disbursementDetail} = this.state
        let disbursementDataToUpdate = {id: disbursementDetail.id, payoutStatus: selectedStatus}

        if (!!selectedStatus) {
            updateDisbursementDetailApi(disbursementDataToUpdate)
                .then(result => {
                    alert("sukses melakukan update data")
                    window.location.reload()
                })
                .catch(err => {
                    console.log("error set data :", err)
                    alert("gagal melakukan update data")
                })
        } else {
            alert("mohon pilih status untuk di update !!")
        }
    }


    render() {
        const {disbursementDetail, columns, listItem} = this.state

        return (
            <Card>
                <h5>Detail Disbursement</h5>
                <Row style={{marginBottom: 30}}>
                    <Card style={{width: '100%', borderRadius: 20}}>
                        <Row justify={'space-between'}>
                            <Col span={7} style={{
                                borderRightWidth: 'thin',
                                borderRightStyle: 'solid'
                            }}>
                                <div><h6 style={{margin: 0}}>{disbursementDetail?.userName}</h6></div>
                                <p style={{marginTop: 10, marginBottom: 10}}>{disbursementDetail?.userEmail}</p>
                                <p>Disbursement ID : <span style={{margin: 0}}>{disbursementDetail?.id}</span></p>
                            </Col>
                            <Col style={13} style={{marginLeft: 50}} flex={1}>
                                <p>Nama Bank : <span style={{margin: 0}}>{disbursementDetail?.bankAccountCode}</span>
                                </p>
                                <p>Nomor Rekening : <span
                                    style={{margin: 0}}>{disbursementDetail?.bankAccountNumber}</span></p>
                                <p>Pemilik Rekening : <span
                                    style={{margin: 0}}>{disbursementDetail?.bankAccountName}</span></p>
                            </Col>
                            <Col span={4}>
                                <Card style={{borderWidth: 'thin', borderColor: Colors.greenish_grey, borderRadius:10}}>
                                    <h6>Total Pencairan</h6>
                                    {doConvertNumberToRupiahFormat(disbursementDetail?.totalDisbursement)}
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row style={{marginBottom: 30}}>
                    <Card style={{width: '100%', borderRadius: 20}}>
                        <Row justify={'space-between'}>
                            <Col span={7} style={{
                                borderRightWidth: 'thin',
                                borderRightStyle: 'solid'
                            }}>
                                <p>Tanggal Pengajuan : <span
                                    style={{margin: 0}}>{formatUnixDateToReadable(disbursementDetail?.created_at, 'lll')}</span>
                                </p>
                            </Col>
                            <Col span={8} flex={1}>
                                <p>Status Pengajuan :</p>
                                <p style={{
                                    margin: 0,
                                    fontWeight: 'bold',
                                    color: Colors.salmon
                                }}>{getStatusDisbursement(disbursementDetail?.payoutStatus)}</p>
                            </Col>
                            <Col style={11}>
                                <p>Update Pencairan Dana :</p>
                                <Form onFinish={(value) => this.onFinish(value)} ref={this.formRef}>
                                    <Form.Item>
                                        <Select style={{width: 220}}
                                                placeholder={'Pilih Status'}
                                                onChange={(value) => this.onChangeSelectHandler(value)}>
                                            {
                                                listItem.map((item) => <Option key={item.value}
                                                                               value={item.value}>{item.status}</Option>)
                                            }
                                        </Select>
                                        <Button type="primary" htmlType="submit"
                                                style={{marginLeft: 10, backgroundColor: Colors.greenish_grey}}>
                                            Simpan
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                {
                    disbursementDetail?.disbursementList?.length > 0 &&
                    <div style={{marginTop: 10}}>
                        <h6>Daftar Pencairan</h6>
                        <Table scroll={{x: 1500}}
                               columns={columns}
                               dataSource={disbursementDetail?.disbursementList.map((d, index) => ({key: index, ...d}))}
                               pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                        />
                    </div>
                }
            </Card>
        );
    }
}

export default DisbursementDetailPage;