import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Drawer} from "antd";

class EmailListComponent extends PureComponent {
    render() {
        const {emailList} = this.props;

        return (
            <Drawer
                title={`Daftar Email (${emailList.length})`}
                width={320}
                closable={false}
                onClose={() => this.props.setEmailListVisibility(false)}
                visible={this.props.isEmailListVisible}
            >
                <div>
                    {emailList.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))}
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    const {voucherDetailReducer} = state;

    return {voucherDetailReducer}
}

export default connect(
    mapStateToProps,
)(EmailListComponent);
