import moment from "moment";
import {convertStartEndDate, orderStateRequestBody} from "../ui-util/general-variable";
import {getCashbackDcListApi} from "../api/dc-report.api";
import {orderDispatcher} from "./order.action";
import {DC_CASHBACK_REDUCER} from "../reducers/dc-cashback.reducer";
import {generalDispatcher} from "./general.action";
import {DC_ORDER_REDUCER} from "../reducers/dc-order.reducer";
import {getOrderListDcApi} from "../api/order.api";
import {TAB_REDUCER} from "../reducers/tab.reducer";

const getCashbackListDc = () => {
    return (dispatch, getState) => {
        dispatch(orderDispatcher(DC_CASHBACK_REDUCER, {loading: true}))
        let {monthSelected, dcId} = getState().dcCashbackReducer
        let startDate = moment(monthSelected).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(monthSelected).endOf('month').format('YYYY-MM-DD')
        let startDateEpoch = convertStartEndDate(startDate, 'start')
        let endDateEpoch = convertStartEndDate(endDate)

        let bodyRequest = {
            startDate,
            endDate,
            startDateEpoch,
            endDateEpoch,
            dcId
        }

        getCashbackDcListApi(bodyRequest)
            .then(result => {
                dispatch(orderDispatcher(DC_CASHBACK_REDUCER, {
                    topLineNetCashValue: result.totalTopLineNetCashValue,
                    potentialCashback: result.totalPotentialCashback,
                    totalCashback: result.totalCashbackPerCashValue,
                    handlingFee: result.totalHandlingFee,
                    cashbackDcList: result.transactionList,
                    potentialMarginPercentage: result.potentialMarginPercentage,
                    loading: false
                }))
            })
            .catch(err => {
                dispatch(orderDispatcher(DC_CASHBACK_REDUCER, {loading: false}))
                console.log('Error getCashbackDcListApi action', err)
            })
    }
}

const getDcOrderListAction = () => {
    return (dispatch, getState) => {
        const {
            currentPage,
            searchKey,
            pageSize,
            warehouseDcId
        } = getState().dcOrderReducer;
        const {avoStoreDcList} = getState().avostoreListReducer
        const {activeTab, isPrinted} = getState().tabReducer
        const {startDate, endDate} = getState().filterDateReducer

        let sellerIdDc = []
        avoStoreDcList.forEach((item) => {
            if (item.id !== null) {
                sellerIdDc.push(item.id)
            }
        })

        const {orderState, paymentDetail, sort} = orderStateRequestBody(activeTab)

        dispatch(generalDispatcher(DC_ORDER_REDUCER, {
            loading: true
        }))
        dispatch(generalDispatcher(TAB_REDUCER, {
            disabled: true
        }))

        let requestBodyDc = {
            sellerId: sellerIdDc,
            orderState,
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate),
            warehouseId: warehouseDcId,
            paymentDetail,
            sort,
            isPrinted
        }

        let params = {
            showPerPage: pageSize,
            page: currentPage - 1,
            keyword: searchKey
        }

        if (sellerIdDc.length > 0) {
            getOrderListDcApi(requestBodyDc, params)
                .then(result => {
                    dispatch(generalDispatcher(DC_ORDER_REDUCER, {
                        dcOrderList: result.orders,
                        dataCount: result.dataCount,
                        loading: false
                    }))
                    dispatch(generalDispatcher(TAB_REDUCER, {
                        disabled: false
                    }))
                })
                .catch(err => {
                    dispatch(generalDispatcher(DC_ORDER_REDUCER, {
                        loading: false
                    }))
                    dispatch(generalDispatcher(TAB_REDUCER, {
                        disabled: false
                    }))
                    console.log('getOrderListQuery', err)
                })
        }
    }
}

export {
    getCashbackListDc,
    getDcOrderListAction
}
