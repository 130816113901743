import React from "react";
import {Button, DatePicker, Form, Input, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import isEqual from "react-fast-compare";
import moment from "moment";
import {connect} from "react-redux";
import {revenueDispatcher} from "../../actions/revenue.action";
import {TARGET_SALES_DATE} from "../../reducers/revenue.reducer";

class TargetSalesFormComponent extends React.PureComponent {
    formRef = React.createRef();

    componentDidMount() {
        const {targetSalesDetail, isEdit} = this.props
        if (isEdit) {
            this.formRef.current.setFieldsValue(targetSalesDetail)
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {isEdit} = this.props
        if (!isEqual(this.props.targetSalesDetail, prevProps.targetSalesDetail) && isEdit) {
            this.formRef.current.setFieldsValue(this.props.targetSalesDetail)
        }
    }

    render() {
        const {isEdit, onFinish} = this.props
        return (
            <Form ref={this.formRef} onFinish={onFinish}>
                {
                    !isEdit &&
                    <Row style={{marginBottom: 10, marginLeft: 10}}>
                        <div style={{marginRight: 52}}>Date :</div>
                        <DatePicker
                            onChange={(dates) => this.props.revenueDispatcher(TARGET_SALES_DATE, {targetSalesDate: moment(dates).format('YYYY_MM')})}
                            picker="month"
                            format={'MMMM'}/>
                    </Row>
                }
                <Form.Item
                    label="Target sales"
                    name={'targetValue'}
                    rules={[{
                        required: true,
                        message: 'Please input target sales',
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}


export default connect(null, {revenueDispatcher})(TargetSalesFormComponent)
