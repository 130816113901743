import React from "react";
import {Button, Card, Descriptions, Row, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {Colors} from "../../ui-util/colors";
import {uploadTaxInvoiceApi} from "../../api/order.api";

class TaxInvoiceComponent extends React.PureComponent {
    formRef = React.createRef();

    constructor() {
        super();
        this.state = {
            fileList: [],
            uploading: false,
            showUploadTaxInvoice: false
        }
    }

    beforeUpload = (file) => {
        this.setState({fileList: [file]});
    }

    handleUpload = () => {
        const {fileList} = this.state
        if(fileList[0].type !== 'application/pdf'){
            alert('File harus berformat .pdf !!')
            this.setState({taxInvoiceList: []})
        } else {
            const {id} = this.props.orderDetail
            const form = new FormData();
            form.append('image', fileList[0])
            form.append('id_order', id)

            uploadTaxInvoiceApi(form)
                .then(result => {
                    alert('data berhasil disimpan !!')
                    window.location.reload()
                })
                .catch(err => {
                    console.log('Error uploadTaxInvoiceApi', err)
                })
        }
    }

    removeButtonHandler = () => {
        this.setState({fileList: []})
    }

    dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    render() {
        const {fileList: taxInvoiceList, uploading, showUploadTaxInvoice} = this.state
        const {taxInvoice} = this.props.orderDetail
        return (
            <Card style={{marginTop: 30}}>
                <Descriptions title="Faktur Pajak" layout="vertical" column={1}/>

                <Row justify={"space-between"} style={{marginBottom: 20}}>
                    {!!taxInvoice && <a href={taxInvoice}>Unduh Faktur Pajak</a>}
                    <Button
                        onClick={() => this.setState({showUploadTaxInvoice: true})}
                        style={{backgroundColor: Colors.gainsboro}}
                    >
                        {!!taxInvoice ? 'Edit' : 'Upload'} Faktur Pajak
                    </Button>
                </Row>

                {showUploadTaxInvoice &&
                <Row justify={"space-between"}>
                    <div style={{width: 180}}>
                        <Upload customRequest={this.dummyRequest} fileList={taxInvoiceList} beforeUpload={this.beforeUpload}
                                onRemove={this.removeButtonHandler}>
                            <Button icon={<UploadOutlined/>}>Select File</Button>
                        </Upload>
                    </div>
                    <Button
                        onClick={this.handleUpload}
                        disabled={taxInvoiceList.length === 0}
                        loading={uploading}
                        style={{backgroundColor: Colors.gainsboro}}
                    >
                        {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                </Row>
                }
            </Card>
        )
    }
}

export default TaxInvoiceComponent
