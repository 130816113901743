import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Colors} from "../../ui-util/colors";

class LandingPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: Colors.darkseagreen}}>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps,)(LandingPage);
