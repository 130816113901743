import React from "react";
import ProductList from "../orders/product-list.component";
import {Button, Divider, Row, Table} from "antd";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    renderLabelOrderState
} from "../../ui-util/general-variable";
import {Link} from "react-router-dom";
import {CANCELLED} from "../../models/order-detail.model";
import {connect} from "react-redux";
import OrderCount from "../orders/order-count.component";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import {DC_ORDER_REDUCER} from "../../reducers/dc-order.reducer";
import {generalDispatcher} from "../../actions/general.action";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import ButtonPrintLabelComponent from "../../components/button-print-label.component";
import GiftListComponent from "../orders/gift-list.component";

class OrderDcListComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => shippingAddress.receiver
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (orderDate) => formatUnixDateToReadable(orderDate)
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (grandTotal) => doConvertNumberToRupiahFormat(grandTotal)
                }, {
                    title: 'Status',
                    key: 'orderState',
                    render: (record) => renderLabelOrderState(record)
                },
                {title: 'Jasa Kirim', dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, record) => (
                        <React.Fragment>
                            <Link to={`/order-detail/${record.id}`}>
                                Rincian
                            </Link>
                            <Divider type="vertical"/>
                            <Button
                                disabled={record.orderState === CANCELLED || record.paymentDetail.transactionStatus !== 'paid'}
                                type="link"
                                onClick={() => {
                                    this.props.generalDispatcher(MODAL_REDUCER, {
                                        modalVisibilityResi: true,
                                        orderDetailToUpdateResi: record
                                    })
                                }}
                            >
                                Update Resi
                            </Button>
                        </React.Fragment>
                    )
                }
            ]
        };
    }

    onSelectRow = (selectedRowKeys, selectedRows) => {
        let selectedRowExcel = setOrderDataSelectedRows(selectedRows);
        this.props.generalDispatcher(DC_ORDER_REDUCER, {selectedRowExcel, selectedOrderToPrintLabel: selectedRows, selectedRowKeys})
    }

    onChangePagination = (current) => {
        this.props.generalDispatcher(DC_ORDER_REDUCER, {currentPage: current})
    }

    onShowSize = (current, pageSize) => {
        this.props.generalDispatcher(DC_ORDER_REDUCER, {pageSize: pageSize, currentPage: 1})
    }

    render() {
        const {columns} = this.state
        const {dcOrderList, loading, dataCount, currentPage, selectedRowKeys} = this.props.dcOrderReducer
        const {activeTab} = this.props.tabReducer

        return (
            <>
                <Row justify={'space-between'} style={{marginBottom: 20}}>
                    <OrderCount count={dataCount}/>
                    {activeTab === 'waitingForDelivery' && <ButtonPrintLabelComponent/>}
                </Row>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    expandedRowRender={(record) =>{
                        return(
                            <>
                                <ProductList productList={record.products}/>
                                {record.gifts.length > 0 && <GiftListComponent giftList={record.gifts} /> }
                            </>
                        )
                    }}
                    dataSource={dcOrderList}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys,
                        onChange: this.onSelectRow}}
                    loading={loading}
                    pagination={{
                        total: dataCount,
                        onChange: this.onChangePagination,
                        current: currentPage,
                        onShowSizeChange: this.onShowSize,
                        showSizeChanger: true
                    }}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => {
    const {dcOrderReducer, tabReducer} = state

    return {dcOrderReducer, tabReducer};
}

export default connect(mapStateToProps, {generalDispatcher})(OrderDcListComponent)
