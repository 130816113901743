import {getAnalyticsProductApi} from "../api/analytics-product.api";
import {ANALYTICS_PRODUCT} from "../reducers/analytics-product.reducer";
import {avoskinId, getDateEpochFormat} from "../ui-util/general-variable";

const analyticsProductDispatcher = (type, payload) => {
    return {type, payload}
}

const getAnalyticsProductGrowthAction = () => {
    return (dispatch, getState) => {
        const {startDate, endDate} = getState().analyticsProductReducer
        const {id} = getState().warehouseReducer.warehouseAnalytics

        const {seller} = getState().dropdownSellerReducer
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerId = []
        switch (seller) {
            case 'DC':
                avoStoreDcList.forEach((item, index) => {
                    if (item.id !== null) {
                        sellerId.push(item.id)
                    }
                })
                break;
            case 'Avoskin':
                sellerId = [avoskinId()]
                break
            default:
                sellerId = []
        }


        let bodyRequest = {
            warehouseId: seller === 'Avoskin' && id,
            startDate: getDateEpochFormat(startDate),
            endDate: getDateEpochFormat(endDate),
            sellerId
        }

        getAnalyticsProductApi(bodyRequest, 'product-growth')
            .then(result => {
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {productGrowth: result.data.productGrowth}))
            })
            .catch(err => {
                console.log('Err getAnalyticsProductApi', err)
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {productGrowth: {current: 0, prev: 0}}))
            })
    }
}

const getAnalyticsProductSoldPerDayAction = () => {
    return (dispatch, getState) => {
        const {startDate, endDate} = getState().analyticsProductReducer
        const {id} = getState().warehouseReducer.warehouseAnalytics
        const {seller} = getState().dropdownSellerReducer
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerId = []
        switch (seller) {
            case 'DC':
                avoStoreDcList.forEach((item, index) => {
                    if (item.id !== null) {
                        sellerId.push(item.id)
                    }
                })
                break;
            case 'Avoskin':
                sellerId = [avoskinId()]
                break
            default:
                sellerId = []
        }

        let bodyRequest = {
            warehouseId: seller === 'Avoskin' && id,
            startDate: getDateEpochFormat(startDate),
            endDate: getDateEpochFormat(endDate),
            sellerId
        }

        getAnalyticsProductApi(bodyRequest, 'product-sold-per-day')
            .then(result => {
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {productSoldPerDay: result.data}))
            })
            .catch(err => {
                console.log('getAnalyticsProductApi err', err)
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {productSoldPerDay: []}))
            })
    }
}

const getAnalyticsProductSoldListAction = () => {
    return (dispatch, getState) => {
        dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {loading: true}))
        const {startDate, endDate, orderType} = getState().analyticsProductReducer
        const {id} = getState().warehouseReducer.warehouseAnalytics
        const {seller} = getState().dropdownSellerReducer
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerId = []
        switch (seller) {
            case 'DC':
                avoStoreDcList.forEach((item, index) => {
                    if (item.id !== null) {
                        sellerId.push(item.id)
                    }
                })
                break;
            case 'Avoskin':
                sellerId = [avoskinId()]
                break
            default:
                sellerId = []
        }

        let bodyRequest = {
            warehouseId: seller === 'Avoskin' && id,
            startDate: getDateEpochFormat(startDate),
            endDate: getDateEpochFormat(endDate),
            orderType,
            sellerId
        }

        getAnalyticsProductApi(bodyRequest, 'product-sold-list')
            .then(result => {
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {
                    productSoldList: result.data.productSoldList,
                    loading: false
                }))
            })
            .catch(err => {
                console.log('getAnalyticsProductApi err', err)
                dispatch(analyticsProductDispatcher(ANALYTICS_PRODUCT, {loading: false}))
            })
    }
}

export {
    analyticsProductDispatcher,
    getAnalyticsProductGrowthAction,
    getAnalyticsProductSoldPerDayAction,
    getAnalyticsProductSoldListAction
}
