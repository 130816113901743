import {auth, provider} from "../index";

const signInWithEmailPasswordFirebase = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password)
}

const signOut = () => {
    return auth.signOut();
}

const getCurrentUserToken = () => {
    return auth.currentUser.getIdToken(true);
}

const signInWithGoogle = () => {
    return auth.signInWithPopup(provider)
}

export {
    signInWithEmailPasswordFirebase,
    signOut,
    getCurrentUserToken,
    signInWithGoogle
}
