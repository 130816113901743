import React, {PureComponent} from 'react';
import {Card, Tabs} from "antd";
import ManifestList from "../manifest/manifest-list.component";
import {Colors} from "../../ui-util/colors";
import {connect} from 'react-redux';
import {getManifestList} from "../../actions/manifest.action";
import DropdownAvoskinDcComponent from "../../components/dropdown-avoskin-dc.component";
import {generalDispatcher} from "../../actions/general.action";
import {FLUSH_PAGINATION_MANIFEST_REDUCER, MANIFEST_REDUCER} from "../../reducers/manifest.reducer";
import isEqual from "react-fast-compare";

const {TabPane} = Tabs;

class Manifest extends PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'PROCEED',
                    tab: 'Bersama Kurir'
                }, {
                    key: 'COMPLETED',
                    tab: 'Sampai Jasa Kirim'
                }
            ],
        }
    }

    componentDidMount(): void {
        this.props.getManifestList()
    }

    onTabChange = (tabId) => {
        const {pageSizeManifestList} = this.props.manifestReducer
        if(pageSizeManifestList !== 10){
            this.props.generalDispatcher(FLUSH_PAGINATION_MANIFEST_REDUCER)
        }
        this.props.generalDispatcher(MANIFEST_REDUCER, {activeTab: tabId})
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(!isEqual(this.props.manifestReducer.activeTab, prevProps.manifestReducer.activeTab) ||
            !isEqual(this.props.manifestReducer.manifestUser, prevProps.manifestReducer.manifestUser) ||
            !isEqual(this.props.manifestReducer.currentPageManifestList, prevProps.manifestReducer.currentPageManifestList) ||
            !isEqual(this.props.manifestReducer.pageSizeManifestList, prevProps.manifestReducer.pageSizeManifestList)
        ){
            this.props.getManifestList()
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {tabList} = this.state
        const {activeTab} = this.props.manifestReducer

        return (
            <Card>
                <DropdownAvoskinDcComponent />
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen, marginTop: 10}}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <ManifestList />
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {manifestReducer} = state;

    return {manifestReducer}
}

export default connect(mapStateToProps, {getManifestList, generalDispatcher})(Manifest);
