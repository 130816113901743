import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    keyword: '',
    activeTab: 'UNPAID',
    orderList: [],
    currentPage: 1,
    pageSize: 10,
    dataCount: 0,
    loading: false
}

export const DC_PAYMENT_REDUCER = 'DC_PAYMENT_REDUCER'
export const FLUSH_PAGINATION_DC_PAYMENT = 'FLUSH_PAGINATION_DC_PAYMENT'

export const dcPaymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FLUSH_PAGINATION_DC_PAYMENT:
            return {...state, pageSize: 10, currentPage: 1, keyword: ''}
        case DC_PAYMENT_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
