import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().startOf('month'),
    endDate: moment(),
    orderTotal: 0,
    grandSubTotal: 0,
    orderTotalAvoDc: 0,
    grandSubTotalAvoDc: 0,
    productTotalAvoDc: 0,
    productOrderTotal: 0,
    totalOrderDc: 0,
    grandTotalOrderDc:0,
    totalProductOrderDc:0,
    marginData: [],
    topProductListDc: [],
    topProductListChain: [],
    level: '',
    dcUserId: null
}

export const REPORT_SALES_DC = 'REPORT_SALES_DC'
export const REPORT_DATE_RANGE = 'REPORT_DATE_RANGE'
export const SET_DC_ID = 'SET_DC_ID'

export const dcReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REPORT_DATE_RANGE:
        case REPORT_SALES_DC:
        case SET_DC_ID:
            return {...state, ...action.payload}
        default:
            return state
    }
}
