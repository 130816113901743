import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class NotificationDetailModel extends GenericDataModel {
    title: String
    description: String
    image: String
    sendAll: Boolean
    userLevel: Array<any>
    receiverId: Array<any>

    constructor() {
        super();
        Object.assign(this, NotificationDetailModel)
    }
}

export const NotificationDetailObj = {
    ...GenericDataObj,
    title: null,
    description: null,
    image: null,
    receiverId: [],
    sendAll: false,
    userLevel: []
}
