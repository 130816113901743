import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Image, Popconfirm, Table} from "antd";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import {deleteGiftItemApi} from "../../api/gift.api";
import {DeleteOutlined, EditOutlined, FileOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {getGiftItemListFromApi} from "../../actions/gift.action";
import {GiftDetailObj} from "../../models/gift-detail.model";
import GiftItemDetailComponent from "./gift-item-detail.component";

class GiftItemList extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (url) => <Image src={url} width={100} height={100}/>
                },
                {
                    title: 'Nama',
                    dataIndex: 'itemName',
                    key: 'itemName',
                    width: 250
                },
                {
                    title: 'Stock',
                    dataIndex: 'stock',
                    key: 'stock',
                },
                {
                    title: 'Tgl. Dibuat',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: text => (formatUnixDateToReadable(text, 'DD-MM-YYYY'))
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <React.Fragment>
                            <Button icon={<FileOutlined/>} style={{margin: 5}} onClick={() => this.onShowDetailHandler(record)}/>
                            <Link to={`/gift/update/${record.id}`}>
                                <Button icon={<EditOutlined/>} style={{margin: 5}}/>
                            </Link>
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={() => this.onDeleteHandler(record)}
                                onCancel={this.onCancelHandler}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined/>} style={{margin: 5}}/>
                            </Popconfirm>
                        </React.Fragment>
                    ),
                },
            ],
            giftDetail: {...GiftDetailObj},
            isGiftDetailVisible: false
        }
    }

    onShowDetailHandler = (giftDetail) => {
        this.setState({giftDetail, isGiftDetailVisible: true})
    }

    onDeleteHandler = (giftDetail) => {
        let body = {id : giftDetail.id}
        deleteGiftItemApi(body)
            .then(result => {
                window.location.href = '/gift/list'
                alert("Gift item berhasil di hapus !!")
            })
            .catch(err => {
                alert("Gagal hapus item gift !!")
                console.log('err', err)
            })
    }

    onCloseHandler = () => {
        this.setState({isGiftDetailVisible: false})
    }

    onCancelHandler = () => {
        console.log("cancel delete item")
    }

    componentDidMount() {
        this.props.getGiftItemListFromApi()
    }


    render() {
        const {columns, isGiftDetailVisible, giftDetail} = this.state
        const {giftItemList} = this.props.giftReducer

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Item Gift</h3>
                <Table
                    scroll={{x: 1500}}
                    columns={columns}
                    dataSource={giftItemList}
                    rowKey="id"
                />
                <GiftItemDetailComponent
                    onCloseHandler={this.onCloseHandler}
                    isGiftDetailVisible={isGiftDetailVisible}
                    giftDetail={giftDetail}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {giftReducer} = state
    return {giftReducer};
}

export default connect(mapStateToProps, {getGiftItemListFromApi})(GiftItemList);