import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isUserRole} from "../ui-util/general-variable";

export default function RouteWrapper(
    {
        component: Component,
        isPrivate,
        ...rest
    }) {

    const signed = localStorage.getItem('AVOSTORE_UID');
    const role = localStorage.getItem('AVOSTORE_ROLE');

    /**
     * Redirect user to signIn page if he tries to access a private      route
     * without emailAuth.
     */
    if (isPrivate && !signed) {
        return <Redirect to="/"/>;
    }

    if (isPrivate && !isUserRole(role)) {
        localStorage.removeItem('AVOSTORE_UID')
        localStorage.removeItem('AVOSTORE_ROLE')
        return <Redirect to="/"/>;
    }


    /**
     * Redirect user to Main page if he tries to access a non private route
     * (signIn or SignUp) after being authenticated.
     */
    if (!isPrivate && signed && isUserRole(role)) {
        return <Redirect to="/avostore"/>;
    }

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return <Route {...rest} component={Component}/>;
}
