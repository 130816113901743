import {PENDING} from "../models/order-detail.model";
import moment from "moment";

const INITIAL_STATE = {
    activeTab: PENDING,
    deliveryOrderList: [],
    loading: false,
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    searchKeyword: ''
}

export const DELIVERY_ORDER_DETAIL = 'DELIVERY_ORDER_DETAIL'
export const DELIVERY_ORDER_LIST = 'DELIVERY_ORDER_LIST'

export const deliveryOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELIVERY_ORDER_LIST:
            return {...state, deliveryOrderList: action.payload}
        case DELIVERY_ORDER_DETAIL:
            return {...state, ...action.payload}
        default:
            return state
    }
}
