const INITIAL_STATE = {}

export const USER_ADDRESS = 'USER_ADDRESS';

export const userAddressReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_ADDRESS:
            return {...action.payload};
        default:
            return state;
    }
}
