import {getListTransactionOfTaxApi} from "../api/tax.api";
import {SET_TRANSACTION_LIST_TAX} from "../reducers/tax.reducer";
import {convertStartEndDate} from "../ui-util/general-variable";

const taxDispatcher = (type, payload) => {
    return {type, payload}
}

const getTransactionList = () => {
    return (dispatch, getState) => {
        dispatch(taxDispatcher(SET_TRANSACTION_LIST_TAX, {
            loading: true
        }))
        const {startDate, endDate, sellerId, activeTab} = getState().taxReducer
        let body = {
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate),
            status: activeTab,
            sellerId
        }

        getListTransactionOfTaxApi(body)
            .then(result => {
                dispatch(taxDispatcher(SET_TRANSACTION_LIST_TAX, {
                    guaranteeTransactionList: activeTab === 'GUARANTEE' ? result : [],
                    orderTransactionList: activeTab === 'ORDER' ? result : [],
                    preOrderTransactionList: activeTab === 'PREORDER' ? result : [],
                    loading: false
                }))
            })
            .catch(err => {
                console.log('getTransactionList', err)
                dispatch(taxDispatcher(SET_TRANSACTION_LIST_TAX, {
                    loading: false
                }))
            })
    }
}

export {
    getTransactionList,
    taxDispatcher
}
