import React, {Component} from 'react';
import {Button, Form, Input, Select, Space} from "antd";
import _ from "lodash";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const {Option} = Select;

class GiftItemListFormComponent extends Component {
    render() {
        const {item, id, index, giftCardDetail, giftItemList} = this.props
        return (
            <Form.List label={item.label} name={item.key} key={index.toString()}>
                {(itemList, {add, remove}) => (
                    <>
                        <Form.Item
                            key={index.toString()}
                            label={'Daftar Gift Item'}
                            name={'productEdit'}
                        >
                            {
                                !!id ?
                                    <>
                                        {_.map(giftCardDetail.items, item => {
                                            return (
                                                <div
                                                    key={item.id}>{`${item.itemName}, qty: ${item.qty}`}</div>
                                            )
                                        })}
                                    </>
                                    :
                                    <div>-</div>

                            }
                        </Form.Item>
                        {itemList.map(({key, name, fieldKey}) => (
                            <Space key={key} style={{
                                display: 'flex',
                                marginBottom: 8,
                                marginLeft: '20%'
                            }}
                                   align="baseline">
                                <Form.Item
                                    label={item.label}
                                    name={[name, 'id']}
                                    fieldKey={[fieldKey, 'id']}
                                    rules={[{
                                        required: item.required,
                                        message: item.placeholder,
                                    }]}
                                >
                                    <Select
                                        style={{width: 275, marginRight: 10}}
                                        placeholder={item.placeholder}
                                    >
                                        {
                                            giftItemList.map((item, index) => (
                                                <Option value={item?.id}
                                                        key={index.toString()}>{item?.itemName}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={'Jumlah'}
                                    name={[name, 'qty']}
                                    fieldKey={[fieldKey, 'qty']}
                                    rules={[{
                                        required: item.required,
                                        message: item.placeholder,
                                    }]}
                                >
                                    <Input placeholder={'Masukkan jumlah item'}/>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)}/>
                            </Space>
                        ))}
                        <Form.Item wrapperCol={{offset: 8}}>
                            <Button type="dashed" onClick={() => add()}
                                    icon={<PlusOutlined/>}>
                                {!!id ? `Edit Gift Item` : `Tambahkan Gift Item`}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        );
    }
}

export default GiftItemListFormComponent;