import moment from 'moment';

const INITIAL_STATE = {
    orderList: [],
    orderAvostoreList: [],
    dataOrderAvostoreCount: 0,
    filterStartDate: moment().subtract(30, 'days'),
    filterEndDate: moment(),
    shippingMethod: null,
    searchKey: null,
    dataCount: 0,
    processingActiveTab: 'all',
    currentPage: 1,
    pageSize: 10,
    loading: false,
    activeTab: 'all',
    orderListDc: [],
    userIdManifestSelected: {},
    sellerId: '',
    warehouseDcId: '',
    selectedRowExcel: [],
    selectedRowPrintLabel: [],
    buyerLevel: ''
}

export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_LIST_AVOSTORE = 'ORDER_LIST_AVOSTORE';
export const ORDER_LIST_FILTER_DATE = 'ORDER_LIST_FILTER_DATE';
export const SHIPPING_METHOD = 'SHIPPING_METHOD';
export const SEARCH_KEY = 'SEARCH_KEY';
export const FLUSH_PAGINATION = 'FLUSH_PAGINATION'
export const ORDER_REDUCER = 'ORDER_REDUCER'
export const SET_BUYER_LEVEL = 'SET_BUYER_LEVEL'

export const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORDER_REDUCER:
        case ORDER_LIST:
        case ORDER_LIST_AVOSTORE:
        case SET_BUYER_LEVEL:
        case ORDER_LIST_FILTER_DATE:
            return {...state, ...action.payload}
        case SEARCH_KEY:
            return {...state, searchKey: action.payload}
        case SHIPPING_METHOD:
            return {...state, shippingMethod: action.payload}
        case FLUSH_PAGINATION:
            return {...state, searchKey: null, dataCount: 0, currentPage: 1, pageSize: 10}
        default:
            return state
    }
}
