import React, {PureComponent} from 'react'
import {Card} from "antd";
import BannerListComponent from "./banner-list.component";

class BannerPage extends PureComponent{
    render() {
        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Banner</h3>
                <BannerListComponent />
            </Card>
        )
    }
}

export default BannerPage
