import React from "react";
import {Button, DatePicker, Row} from "antd";
import moment from "moment";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {generalDispatcher} from "../actions/general.action";
import {FILTER_DATE_REDUCER} from "../reducers/filter-date.reducer";

const {RangePicker} = DatePicker;

class FilterDateComponent extends React.PureComponent {

    onDatePickerChange = (dates) => {
        this.props.generalDispatcher(FILTER_DATE_REDUCER, {startDate: dates[0], endDate: dates[1]})
    }

    render() {
        const {filterHandler, filterDateReducer, filterLabel} = this.props
        const {startDate, endDate} = filterDateReducer

        return (
            <Row justify={'space-between'} align="middle">
                {filterLabel}
                <RangePicker
                    allowClear={false}
                    style={{width: '60%', marginRight: 10, marginLeft: 10}}
                    value={[startDate, endDate]}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                    disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                />
                <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        onClick={filterHandler}>Filter</Button>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    const {filterDateReducer} = state;

    return {filterDateReducer}
}

export default connect(mapStateToProps, {generalDispatcher})(FilterDateComponent)
