import React from "react";
import {Button, Table, Tag} from "antd";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable, onScrollToTop} from "../../ui-util/general-variable";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../../models/order-detail.model";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {dcPaymentDispatcher, getDcOrderList} from "../../actions/dc-payment.action";
import {DC_PAYMENT_REDUCER} from "../../reducers/dc-payment.reducer";
import isEqual from "react-fast-compare";

class DcPaymentListComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (text) => (<div>{text.receiver}</div>)
                }, {
                    title: 'No. Handphone',
                    dataIndex: 'shippingAddress',
                    key: 'phoneNumber',
                    render: (text) => (<div>{text.phoneNumber}</div>)
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                }, {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    render: (text, record) => {
                        let color = 'red';
                        let label = 'BELUM DIBAYAR';
                        let keyAwb = 'false';
                        let colorAwb = 'red';
                        let labelAwb = 'BELUM RESI';

                        if (text === SUBMITTED) {
                            if (record.paymentDetail.transactionStatus === 'paid') {
                                color = 'yellow';
                                label = 'SUDAH DIBAYAR';
                            }
                        } else if (text === CANCELLED) {
                            color = 'black';
                            label = 'DIBATALKAN';
                        } else if (text === COMPLETED) {
                            color = 'green';
                            label = 'SELESAI';
                        } else if (text === DELIVERY) {
                            color = 'blue';
                            label = 'SEDANG DIKIRIM';
                        } else if (text === PACKAGING) {
                            color = 'orange';
                            label = 'SEDANG DIPROSES';
                        }

                        if (Boolean(record.awb)) {
                            keyAwb = 'true';
                            colorAwb = 'green';
                            labelAwb = 'SUDAH RESI';
                        }

                        return (
                            <React.Fragment>
                                <Tag color={color} key={text}>
                                    {label}
                                </Tag>
                                <Tag color={colorAwb} key={keyAwb}>
                                    {labelAwb}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {
                    title: 'Action',
                    key: 'rincian',
                    render: (record) => this.actionButtonHandler(record)
                }
            ],
            loadingApproveButton: false,
            index: 0
        };
    }

    componentDidMount() {
        onScrollToTop()
        this.props.getDcOrderList()
    }

    actionButtonHandler = (record) => {
        const {activeTab} = this.props.dcPaymentReducer
        return (
            <Link to={`/order-detail/${record.id}`}>
                <Button style={{width: 180}}>
                    {
                        activeTab === 'UNPAID' ? `Update Pembayaran` :
                        !record?.taxInvoice && record.paymentDetail.transactionStatus === 'paid' ? `Kirim Faktur Pajak` : `Rincian`
                    }
                </Button>
            </Link>
        )
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let {activeTab, currentPage, pageSize, keyword} = this.props.dcPaymentReducer
        if (!isEqual(prevProps.dcPaymentReducer.activeTab, activeTab) ||
            !isEqual(prevProps.dcPaymentReducer.currentPage, currentPage) ||
            !isEqual(prevProps.dcPaymentReducer.pageSize, pageSize) ||
            !isEqual(prevProps.dcPaymentReducer.keyword, keyword)
        ) {
            this.props.getDcOrderList()
        }
    }

    render() {
        const {columns} = this.state
        const {orderList, dataCount, currentPage, loading} = this.props.dcPaymentReducer

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={orderList}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: dataCount,
                    onChange: (current) => this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {currentPage: current}),
                    current: currentPage,
                    onShowSizeChange: (currentPage, pageSize) => this.props.dcPaymentDispatcher(DC_PAYMENT_REDUCER, {
                        currentPage,
                        pageSize
                    }),
                    showSizeChanger: true
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    const {dcPaymentReducer} = state;

    return {dcPaymentReducer}
}

export default connect(mapStateToProps, {dcPaymentDispatcher, getDcOrderList})(DcPaymentListComponent)
