import {GenericDataModel} from "./generic-data.model";

export default class BannerDetailModel extends GenericDataModel{
    name: String
    image: String
    description: String
    isAvailable: Boolean
    isValid: Boolean

    constructor(bannerDetailModel : BannerDetailModel){
        super()
        Object.assign(this, bannerDetailModel)
    }
}

export const BannerDetailObj = {
    id: '',
    name: null,
    image: null,
    description: null,
    isAvailable: false,
    isValid: true
}


