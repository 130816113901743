import React, {Component} from 'react';
import {Table} from "antd";
import ProductListTaxComponent from "./product-list.component";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable, getLevelText} from "../../ui-util/general-variable";
import moment from "moment";

class TaxPreorderComponent extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Nama Pembeli',
                    dataIndex: 'buyerName',
                    key: 'buyerName',
                    width: 170,
                }, {
                    title: 'Nama Penjual',
                    dataIndex: 'sellerName',
                    key: 'sellerName',
                    width: 170,
                }, {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 200,
                }, {
                    title: 'Level',
                    dataIndex: 'buyerLevel',
                    key: 'buyerLevel',
                    width: 170,
                    render: (buyerLevel) => getLevelText(buyerLevel)
                }, {
                    title: 'Alamat',
                    dataIndex: 'address',
                    key: 'address',
                    width: 200,
                    render: (address) => `${address.address}, ${address.district}, ${address.city}, ${address.province}`
                }, {
                    title: 'NIK',
                    dataIndex: 'identityCardNumber',
                    key: 'identityCardNumber',
                    width: 170,
                }, {
                    title: 'No Pesanan',
                    dataIndex: 'orderId',
                    key: 'orderId',
                    width: 200,
                },
                {
                    title: 'No Pre Order',
                    dataIndex: 'poPaymentId',
                    key: 'poPaymentId',
                    width: 200,
                }, {
                    title: 'Tanggal Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    width: 150,
                    render: (orderDate) => formatUnixDateToReadable(orderDate, 'll')
                },
                {
                    title: 'Additional Margin',
                    dataIndex: 'sumSubtotalMargin',
                    key: 'sumSubtotalMargin',
                    width: 150,
                    render: (sumSubtotalMargin) => doConvertNumberToRupiahFormat(sumSubtotalMargin)
                },
                {
                    title: 'Progressive Margin',
                    dataIndex: 'progressiveMargin',
                    key: 'progressiveMargin',
                    width: 150,
                    render: (progressiveMargin) => doConvertNumberToRupiahFormat(progressiveMargin)
                },
                {
                    title: 'Potongan Voucher',
                    dataIndex: 'voucherReduction',
                    key: 'voucherReduction',
                    width: 150,
                    render: (voucherReduction) => doConvertNumberToRupiahFormat(voucherReduction)
                },
                {
                    title: 'Total Order',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    width: 150,
                    render: (grandTotal) => doConvertNumberToRupiahFormat(grandTotal)
                },
                {
                    title: 'Total Pembayaran Termin',
                    dataIndex: 'grandTotalPreOrder',
                    key: 'grandTotalPreOrder',
                    width: 150,
                    render: (grandTotalPreOrder) => doConvertNumberToRupiahFormat(grandTotalPreOrder)
                },
                {
                    title: 'Total Denda',
                    dataIndex: 'fines',
                    key: 'fines',
                    width: 120,
                    render: (fines) => doConvertNumberToRupiahFormat(fines ? fines : 0)
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'payAmount',
                    key: 'payAmount',
                    width: 170,
                    render: (payAmount, record) => doConvertNumberToRupiahFormat(payAmount + record.fines)
                },
                {
                    title: 'Tanggal Pembayaran',
                    dataIndex: 'payment',
                    key: 'payment',
                    width: 150,
                    render: (payment) => moment(payment?.transactionTime).format('ll')
                }
            ]
        }
    }
    render() {
        const {columns} = this.state
        const {loading, dataToDisplay, rowSelection} = this.props

        return (
            <Table scroll={{ x: 'calc(1000px + 100%)', y: 600 }}
                   size="middle"
                   columns={columns}
                   loading={loading}
                   expandedRowRender={(record) => <ProductListTaxComponent productList={record.products} />}
                   rowSelection={rowSelection}
                   dataSource={dataToDisplay.map((d, index) => ({key: index, ...d}))}
                   pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
            />
        );
    }
}

export default TaxPreorderComponent;
