import React from "react";
import {Table} from "antd";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {connect} from "react-redux";
import ProductList from "../orders/product-list.component";
import {ANALYTICS_GUARANTEE} from "../../reducers/analytics-guarantee.reducer";
import {analyticsGuaranteeDispatcher, getDataAnalyticsGuaranteeList} from "../../actions/analytics-guarantee.action";

class AnalyticsGuaranteeListComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'userGuaranteeName',
                    key: 'userGuaranteeName',
                }, {
                    title: 'Order Id',
                    dataIndex: 'orderId',
                    key: 'orderId',
                }, {
                    title: 'Guarantee Id',
                    dataIndex: 'billingId',
                    key: 'guaranteeId',
                }, {
                    title: 'Total',
                    dataIndex: 'orderGrandTotal',
                    key: 'orderGrandTotal',
                    render: (orderGrandTotal) => doConvertNumberToRupiahFormat(orderGrandTotal)
                }, {
                    title: 'Order Date',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (orderDate) => formatUnixDateToReadable(orderDate)
                }, {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                }
            ]
        }
    }

    expandTable = (record) => {
        return (
            <>
                <Table
                    columns={[
                        {
                            key: 'dueDate', dataIndex: 'dueDate', title: 'Batas Pembayaran',
                            render: (dueDate) => formatUnixDateToReadable(dueDate, 'DD-MM-YYYY')
                        }, {
                            key: 'cicilan', dataIndex: 'payAmount', title: 'Jumlah Cicilan',
                            render: (payAmount) => doConvertNumberToRupiahFormat(payAmount)
                        }, {
                            key: 'payAmount', dataIndex: 'totalPayAmount', title: 'Total Pembayaran',
                            render: (totalPayAmount) => totalPayAmount && doConvertNumberToRupiahFormat(totalPayAmount)
                        }, {
                            key: 'transactionStatus', dataIndex: 'paymentDetail', title: 'Status Transaksi',
                            render: (paymentDetail) => paymentDetail.transactionStatus
                        }, {
                            key: 'transactionTime', dataIndex: 'paymentDetail', title: 'Waktu Transaksi',
                            render: (paymentDetail) => paymentDetail.transactionTime
                        }, {
                            key: 'fines', title: 'Denda',
                            render: (record) => record.paymentDetail.transactionStatus === 'paid' ? doConvertNumberToRupiahFormat(record.fines) : doConvertNumberToRupiahFormat(record.totalFines)
                        }, {
                            key: 'paymentLate', dataIndex: 'daysLateness', title: 'Keterlambatan /hari',
                            render: (daysLateness) => daysLateness > 0 && <div>{daysLateness} hari</div>
                        }
                    ]}
                    dataSource={record.payment}
                    rowKey={'dueDate'}
                    pagination={false}
                />
                <div style={{marginBottom: 20}}/>
                <ProductList productList={record.products}/>
            </>
        )
    }

    onChangePagination = (current) => {
        this.props.analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {currentPage: current})
    }

    onShowSize = async (currentPage, pageSize) => {
        this.props.analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {currentPage, pageSize})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {currentPage, pageSize} = this.props.analyticsGuaranteeReducer
        if(currentPage !== prevProps.analyticsGuaranteeReducer.currentPage || pageSize !== prevProps.analyticsGuaranteeReducer.pageSize){
            this.props.getDataAnalyticsGuaranteeList()
        }
    }

    render() {
        const {analyticsGuaranteeList, totalData, currentPage, loading} = this.props.analyticsGuaranteeReducer
        const {columns} = this.state
        return (

                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={analyticsGuaranteeList}
                    expandedRowRender={(record) => this.expandTable(record)}
                    rowKey="id"
                    loading={loading}
                    pagination={{
                        total: totalData,
                        current: currentPage,
                        onChange: this.onChangePagination,
                        onShowSizeChange: this.onShowSize,
                        showSizeChanger: true,
                    }}
                />
        )
    }
}

const mapStateToProps = (state) => {
    const {analyticsGuaranteeReducer} = state

    return {analyticsGuaranteeReducer}
}

export default connect(mapStateToProps, {analyticsGuaranteeDispatcher, getDataAnalyticsGuaranteeList})(AnalyticsGuaranteeListComponent)
