import React, {PureComponent} from 'react';
import {Input} from "antd";

const {Search} = Input;

class SearchComponent extends PureComponent {
    render() {
        const {searchItem} = this.props
        return (
            <div>
                <Search
                    placeholder={`Cari ${searchItem}`}
                    onSearch={value => this.props.onSearchHandler(value)}
                    style={{marginTop: 20, width: 250, height: 50}}
                />
            </div>
        );
    }
}

export default SearchComponent;