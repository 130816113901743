import React from "react";
import {Row} from "antd";
import {connect} from "react-redux";
import {generalDispatcher} from "../../actions/general.action";
import {DC_ORDER_REDUCER} from "../../reducers/dc-order.reducer";
import FilterDateComponent from "../../components/filter-date.component";
import DropdownWarehouseDcComponent from "../../components/dropdown-warehouse-dc.component";
import SearchComponent from "../../components/search.component";
import {getDcOrderListAction} from "../../actions/dc.action";
import ExportOrderComponent from "../../components/export-order.component";

class OrderDcFilterComponent extends React.PureComponent {
    render() {
        const {selectedRowExcel} = this.props.dcOrderReducer
        return (
            <Row type="flex" justify={'space-between'} align="middle">
                <SearchComponent searchItem={'Pesanan'}
                                 onSearchHandler={value => this.props.generalDispatcher(DC_ORDER_REDUCER, {searchKey: value})}/>
                <DropdownWarehouseDcComponent onFilter={() => {
                    this.props.getDcOrderListAction()
                    this.props.generalDispatcher(DC_ORDER_REDUCER, {selectedRowKeys: [], selectedRowExcel: []})
                }}/>
                <Row>
                    <FilterDateComponent
                        filterHandler={() => this.props.getDcOrderListAction()}
                        filterLabel={'Tgl Pesanan'}
                    />
                    <ExportOrderComponent
                        fileName={'Pesanan DC'}
                        selectedRow={selectedRowExcel}
                    />
                </Row>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {dcOrderReducer} = state

    return {dcOrderReducer};
}

export default connect(mapStateToProps, {generalDispatcher, getDcOrderListAction})(OrderDcFilterComponent)
