import React, {Component} from 'react';
import {Button, Card, Image, Popconfirm, Row, Table} from "antd";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import {deleteGiftCardApi, getGiftCardListApi} from "../../api/gift-card.api";
import {DeleteOutlined, EditOutlined, FileOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import GiftCardDetailComponent from "./gift-card-detail.component";
import {GiftCardModelObj} from "../../models/gift-card.model";
import SearchComponent from "../../components/search.component";
import _ from "lodash";
import {connect} from "react-redux";
import {getWarehouseDcAvoskinList} from "../../actions/warehouse.action";

class GiftCardPage extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (url) => <Image src={url ? url : require('../../images/no-image.png')} width={100} height={100}/>
                },
                {
                    title: 'Nama',
                    dataIndex: 'name',
                    key: 'name',
                    width: 250
                },
                {
                    title: 'Detail Gift',
                    dataIndex: 'description',
                    key: 'description',
                    width: 300
                },
                {
                    title: 'Tgl. Mulai',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    render: text => (formatUnixDateToReadable(text, 'll'))
                },
                {
                    title: 'Tgl. Selesai',
                    dataIndex: 'endDate',
                    key: 'endDate',
                    render: text => (formatUnixDateToReadable(text, 'll'))
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <React.Fragment>
                            <Button icon={<FileOutlined/>} style={{margin: 5}} onClick={() => this.onShowDetailHandler(record)}/>
                            <Link to={`/gift/update-gift-card/${record.id}`}>
                                <Button icon={<EditOutlined/>} style={{margin: 5}}/>
                            </Link>
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={() => this.onDeleteHandler(record)}
                                onCancel={this.onCancelHandler}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined/>} style={{margin: 5}}/>
                            </Popconfirm>
                        </React.Fragment>
                    ),
                },
            ],
            columnItems : [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (url) => <Image src={url} width={50} height={50}/>
                },
                {
                    title: 'Nama',
                    dataIndex: 'itemName',
                    key: 'itemName'
                },
                {
                    title: 'Quantity',
                    dataIndex: 'qty',
                    key: 'qty'
                }
            ],
            giftCardList: [],
            isShowDrawer: false,
            giftCardDetail: {...GiftCardModelObj},
            searchKey: ''
        }
    }

    onDeleteHandler = (giftCardDetail) => {
        let body = {id : giftCardDetail.id}
        deleteGiftCardApi(body)
            .then(result => {
                window.location.reload()
                alert("Gift card berhasil di hapus !!")
            })
            .catch(err => {
                alert("Gagal hapus data !!")
                console.log('err', err)
            })
    }

    onCancelHandler = () => {
        console.log("cancel delete item")
    }

    onShowDetailHandler = (giftCardDetail) => {
        this.setState({giftCardDetail, isShowDrawer: true})
    }

    onCloseHandler = () => {
        this.setState({isShowDrawer: false})
    }

    componentDidMount() {
        this.props.getWarehouseDcAvoskinList()
        getGiftCardListApi()
            .then(result => {
                this.setState({giftCardList: result.data})
            })
            .catch(err => {
                console.log("error getGiftCardListApi :", err)
                alert("Gagal mendapatkan data !!")
            })
    }

    onSearchVoucherHandler = (value) => {
        this.setState({searchKey: value})
    }

    getDataToDisplay = () => {
        const {searchKey, giftCardList} = this.state
        let dataToDisplay = giftCardList

        if (!!searchKey) {
            dataToDisplay = _.filter(giftCardList, item => item.name.toLowerCase().includes(searchKey))
        }
        return dataToDisplay
    }

    onExpandRowHandler = (giftDetail) => {
        const {columnItems} = this.state
        return(
            <Table
                columns={columnItems}
                dataSource={giftDetail.items}
                rowKey={'id'}
                pagination={false}
            />
        )

    }


    render() {
        const {warehouseReducer } = this.props
        const {warehouseDcAvoskinList} = warehouseReducer
        const {columns, isShowDrawer, giftCardDetail} = this.state

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Gift Card</h3>
                <Row>
                    <SearchComponent searchItem={'gift card'} onSearchHandler={this.onSearchVoucherHandler}/>
                </Row>
                <Table
                    scroll={{x: 1500}}
                    columns={columns}
                    expandedRowRender={(record) => this.onExpandRowHandler(record)}
                    dataSource={this.getDataToDisplay()}
                    rowKey="id"
                />
                {
                    isShowDrawer &&
                    <GiftCardDetailComponent
                        onCloseHandler={this.onCloseHandler}
                        isGiftDetailVisible={isShowDrawer}
                        giftCardDetail={giftCardDetail}
                        warehouseDcAvoskinList={warehouseDcAvoskinList}
                    />
                }
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer} = state
    return {warehouseReducer};
}

export default connect(mapStateToProps, {getWarehouseDcAvoskinList})(GiftCardPage);