const INITIAL_STATE = {}

export const USER_DETAIL = 'USER_DETAIL';

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_DETAIL:
            return {...action.payload};
        default:
            return state;
    }
}
