import React, {Component} from 'react';
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportAvostoreListToExcel extends Component {
    render() {
        const {selectedRow} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Avostore List">
                    <ExcelColumn label="Id" value="id"/>
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Level" value="level"/>
                    <ExcelColumn label="Avostore Area" value="avostoreArea"/>
                    <ExcelColumn label="Parent Id" value="parentRef"/>
                    <ExcelColumn label="Poin" value="point"/>
                    <ExcelColumn label="No Telp" value="phoneNumber"/>
                    <ExcelColumn label="No KTP" value="identityCardNumber"/>
                    <ExcelColumn label="No referral" value="referralId"/>
                    <ExcelColumn label="Tanggal Bergabung" value="joinDate"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
