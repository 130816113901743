import React from "react";
import {Button, DatePicker, Row, Select} from "antd";
import {connect} from "react-redux";
import {getGuaranteeListAction, guaranteeDispatcher} from "../../actions/guarantee.action";
import moment from "moment";
import {GUARANTEE_REDUCER} from "../../reducers/guarantee.reducer";
import {Colors} from "../../ui-util/colors";

const {Option} = Select;
const {RangePicker} = DatePicker;

class GuaranteeListHeaderComponent extends React.PureComponent {

    onDatePickerChange = (dates) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {startDate: dates[0], endDate: dates[1]})
    }

    onFilterHandler = (sellerId) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {sellerId})
    }

    render() {
        const {startDate, endDate, sellerId, guaranteeType} = this.props.guaranteeReducer
        const {avoStoreDcList} = this.props.avostoreListReducer
        let avostoreList = [...avoStoreDcList, {id: 'K9s7LBWRXuOhTPUgbcaHt8Sa1Vb2', name: 'Avoskin'}]
        return (
            <Row style={{marginBottom: 20}}>
                <div>
                    <RangePicker
                        defaultValue={[startDate, endDate]}
                        format={'DD/MM/YYYY'}
                        allowClear={false}
                        disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                    />
                    <Button style={{
                        color: 'white',
                        backgroundColor: Colors.darkseagreen,
                        marginLeft: 20
                    }} onClick={() => this.props.getGuaranteeListAction()}>Filter</Button>
                </div>
                {
                    guaranteeType?.value === 'GUARANTEE' &&
                    <Row>
                        <div style={{marginLeft: 40}}>
                            Filter Penjual :
                            <Select defaultValue={sellerId} style={{width: 200, marginLeft: 20}}
                                    onChange={(item) => this.onFilterHandler(item)}>
                                {
                                    avostoreList.map((item, index) => (
                                        <Option value={item.id} key={index.toString()}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <Button style={{
                            color: 'white',
                            backgroundColor: Colors.darkseagreen,
                            marginLeft: 20
                        }} onClick={() => this.props.getGuaranteeListAction()}>Filter</Button>
                    </Row>
                }
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {guaranteeReducer, avostoreListReducer} = state

    return {guaranteeReducer, avostoreListReducer}
}

export default connect(mapStateToProps, {
    guaranteeDispatcher,
    getGuaranteeListAction
})(GuaranteeListHeaderComponent)
