import {MANIFEST_REDUCER} from "../reducers/manifest.reducer";
import {avoskinId, convertStartEndDate} from "../ui-util/general-variable";
import {getManifestDetailApi, getManifestListApi, getOrderListToCreateManifestApi} from "../api/manifest.api";
import {generalDispatcher} from "./general.action";
import {getOrderListByManifestIdApi} from "../api/order.api";

const manifestDispatcher = (type, payload) => {
    return {type, payload}
}

const getManifestList = () => {
    return (dispatch, getState) => {
        dispatch(generalDispatcher(MANIFEST_REDUCER, {loadingManifestList: true}))
        let {activeTab, manifestUser, pageSizeManifestList, currentPageManifestList} = getState().manifestReducer
        let requestBody = {
            state: activeTab,
            isDcOrders: (manifestUser === 'DC') ? true : false
        }

        let params = {
            page: currentPageManifestList - 1,
            size: pageSizeManifestList
        }

        getManifestListApi(requestBody, params)
            .then(result => {
                dispatch(generalDispatcher(MANIFEST_REDUCER, {
                    manifestList: result.data,
                    loadingManifestList: false,
                    totalDataManifestList: result.totalDatas
                }))
            })
            .catch(err => {
                console.log('Error get manifest list from firestore', err)
                dispatch(generalDispatcher(MANIFEST_REDUCER, {loadingManifestList: false}))
            })
    }
}

const getOrderListToCreateManifestAction = () => {
    return (dispatch, getState) => {
        dispatch(generalDispatcher(MANIFEST_REDUCER, {loading: true}))
        const {avoStoreDcList} = getState().avostoreListReducer
        const {startDate, endDate} = getState().filterDateReducer
        const {pageSize, currentPage, manifestUser, shippingMethod, searchKey} = getState().manifestReducer

        let sellerIdDc = []
        avoStoreDcList.forEach((item, index) => {
            if (item.id !== null) {
                sellerIdDc.push(item.id)
            }
        })

        let requestBody = {
            sellerId: manifestUser === 'DC' ? sellerIdDc : [avoskinId()],
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate),
            shippingMethod: shippingMethod === 'Semua' ? "" : shippingMethod,
            search: searchKey
        }

        const params = {size: pageSize, page: currentPage - 1}

        getOrderListToCreateManifestApi(requestBody, params)
            .then(result => {
                dispatch(generalDispatcher(MANIFEST_REDUCER, {
                    orderListForManifest: result.data,
                    loading: false,
                    totalData: result.totalDatas
                }))
            })
            .catch(err => {
                console.log('Error get manifest list from firestore', err)
                dispatch(generalDispatcher(MANIFEST_REDUCER, {loading: false}))
            })
    }
}

const getManifestDetailAndOrderListAction = (id) => {
    return (dispatch) => {
        dispatch(generalDispatcher(MANIFEST_REDUCER, {loadingManifestDetail: true}))

        Promise.all([
            getManifestDetailApi({manifestId: id}),
            getOrderListByManifestIdApi({manifestId: id})
        ])
            .then(result => {
                dispatch(generalDispatcher(MANIFEST_REDUCER, {
                    manifestDetail: result[0],
                    orderListInManifest: result[1],
                    loadingManifestDetail: false
                }))
            })
            .catch((err) => {
                console.log('Error getManifestDetailAndOrderListAction', err)
                dispatch(generalDispatcher(MANIFEST_REDUCER, {loadingManifestDetail: false}))
            })
    }
}

export {getManifestList, manifestDispatcher, getOrderListToCreateManifestAction, getManifestDetailAndOrderListAction}
