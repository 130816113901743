import React, {Component} from 'react';
import {connect} from 'react-redux';
import {VoucherDetailObj} from "../../models/voucher.model";
import {Button, Card, DatePicker, Form, Image, Input, InputNumber, Radio, Row, Select, Space, Upload} from "antd";
import {getDateEpochFormat, randomNumber, renameImageToDateFormat} from "../../ui-util/general-variable";
import {getAvostoreListAll} from "../../actions/user.action";
import {MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from "../../models/user-detail.model";
import {storageTaskEvent} from "../../firebase";
import {setVoucherImage, voucherImageRef} from "../../firebase/storage/voucher-image";
import {getVoucherDetailApi, setVoucherAPi, updateVoucherDetailApi} from "../../api/voucher.api";
import moment from "moment";
import {getProductList} from "../../actions/product.action";

const {TextArea} = Input;
const {Option} = Select;

class VoucherFormPage extends Component {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            voucherForm: [
                {
                    key: 'voucherName',
                    label: 'Nama Voucher',
                    placeholder: 'Masukkan nama voucher',
                    required: true,
                    whitespace: true
                },
                {
                    key: 'voucherImage',
                    label: 'Image Voucher',
                    placeholder: 'Masukkan image voucher',
                    type: 'image',
                    required: true,
                },
                {
                    key: 'description',
                    label: 'Description',
                    placeholder: 'Masukkan deskripsi voucher',
                    type: 'textarea'
                },
                {
                    key: 'voucherType',
                    label: 'Tipe Voucher',
                    placeholder: 'Pilih tipe voucher',
                    required: true,
                    type: 'radio',
                    optionItem: [
                        {name: 'Diskon Persen', value: 'percent'},
                        {name: 'Diskon Nominal', value: 'nominal'},
                        {name: 'Free Shipping Nominal', value: 'free_shipping_nominal'},
                        {name: 'Free Shipping Persen', value: 'free_shipping_percent'},
                        {name: 'Free Shipping Persen (Sub Total)', value: 'free_shipping_percent_subtotal'}
                    ]
                },
                {
                    key: 'isMultiple',
                    label: 'Voucher Multiple',
                    required: true,
                    type: 'radio',
                    optionItem: [
                        {name: 'Ya', value: true},
                        {name: 'Tidak', value: false}
                    ]
                },
                {
                    key: 'voucherValue',
                    label: 'Nilai Voucher',
                    placeholder: 'Masukkan nilai voucher. Cth: 50000',
                    type: 'number',
                    required: true
                },
                {
                    key: 'maximumVoucherValue',
                    label: 'Maksimal Nilai Voucher',
                    placeholder: 'Masukkan nilai voucher. Cth: 50000',
                    type: 'maxValue'
                },
                {
                    key: 'maximumUseCount',
                    label: 'Maksimal Penggunaan',
                    placeholder: 'Masukkan maksimal penggunaan',
                    type: 'number'
                },
                {
                    key: 'maximumTotalUsePerUser',
                    label: 'Maksimal Penggunaan per User',
                    placeholder: 'Masukkan maksimal penggunaan per user',
                    type: 'number',
                },
                {
                    key: 'minimumPurchasePerOrder',
                    label: 'Minimum Pembelanjaan',
                    placeholder: 'Masukkan minimal pembelanjaan. Cth: 50000',
                    type: 'number'
                },
                {
                    key: 'startDate',
                    label: 'Tanggal Mulai',
                    required: true,
                    placeholder: 'Masukkan Tanggal Mulai',
                    type: 'date',
                },
                {
                    key: 'endDate',
                    label: 'Tanggal Berakhir',
                    placeholder: 'Masukkan Tanggal Berakhir',
                    required: true,
                    type: 'date',
                },
                {
                    key: 'paymentMethod',
                    label: 'Metode Pembayaran',
                    placeholder: 'Pilih metode pembayaran',
                    type: 'select',
                },
                {
                    key: 'userLevelList',
                    label: 'Level User',
                    placeholder: 'Pilih level user',
                    type: 'select',
                },
                {
                    key: 'emailList',
                    label: 'Email List',
                    placeholder: 'Masukkan daftar email yang dapat menggunakan kode promo',
                    type: 'select'
                },
                {
                    key: 'minimumProducts',
                    label: 'Product List',
                    placeholder: 'Masukkan produk yang dapat menggunakan kode promo',
                    type: 'list'
                }
            ],
            paymentList: [
                {value: 'gopay', name: 'Gopay'},
                {value: 'shopeepay', name: "ShopeePay"},
                {value: 'bca_va', name: 'BCA'},
                {value: 'echannel', name: 'Mandiri'},
                {value: 'bni_va', name: 'BNI'},
                {value: 'permata_va', name: 'Permata'},
                {value: 'mandiri', name: "Credit Card Mandiri"},
                {value: 'bni', name: "Credit Card BNI"},
                {value: 'bri', name: "Credit Card BRI"}
            ],
            levelList: [
                {name: 'End customer', value: END_CUSTOMER},
                {name: 'Bronze', value: BRONZE},
                {name: 'Platinum', value: PLATINUM},
                {name: 'Premium 1', value: PREMIUM_1},
                {name: 'Premium 2', value: PREMIUM_2},
                {name: 'Distribution Channel 1', value: DISTRIBUTION_CHANNEL_1},
                {name: 'Distribution Channel 2', value: DISTRIBUTION_CHANNEL_2},
                {name: 'Distribution Channel 3', value: DISTRIBUTION_CHANNEL_3}
            ],
            voucherDetail: {...VoucherDetailObj},
            voucherCode: [],
            voucherTypeValue: [],
            isShowEmail: false,
            showImage: false,
            fileImage: [],
            isShowMaxValue: false,
            productList: []
        }
    }

    componentDidMount() {
        this.props.getAvostoreListAll()
        this.props.getProductList()
        const {id} = this.props.match.params

        if (!!id) {
            getVoucherDetailApi({id})
                .then(result => {
                    this.setState({voucherDetail: result.data})
                    const voucherDetailToUpdate = {
                        ...result.data,
                        startDate: moment.unix(result.data.startDate),
                        endDate: moment.unix(result.data.endDate),
                    }
                    this.formRef.current.setFieldsValue(voucherDetailToUpdate)
                    if (voucherDetailToUpdate.voucherType === 'percent' || voucherDetailToUpdate.voucherType === 'free_shipping_percent') {
                        this.setState({isShowMaxValue: true})
                    } else {
                        this.setState({isShowMaxValue: false})
                    }
                })
                .catch(err => {
                    console.log("err :", err)
                })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            window.location.reload()
        }
    }

    onFinish = (values) => {
        let {voucherDetail, fileImage} = this.state

        if (fileImage.length > 0) {
            let fileName = renameImageToDateFormat(fileImage[0].name, 'voucher-')
            setVoucherImage(fileName, fileImage[0]).on(
                storageTaskEvent.STATE_CHANGED,
                snapshot => {
                },
                err => console.log('err', err),
                () => {
                    voucherImageRef(fileName).getDownloadURL()
                        .then(urlImage => {
                            if (!!this?.props?.match?.params?.id) {
                                values.image = urlImage
                                this.updateVoucherDetail(values)
                            } else {
                                voucherDetail.image = urlImage
                                this.setVoucherDetailToFirebaseAPi(voucherDetail, values)
                            }
                        })
                }
            )
        } else if (!this?.props?.match?.params?.id && fileImage.length === 0) {
            this.setVoucherDetailToFirebaseAPi(voucherDetail, values)
        } else {
            this.updateVoucherDetail(values)
        }
    };

    setVoucherDetailToFirebaseAPi = (voucherDetail, values) => {
        Object.assign(voucherDetail, values)
        voucherDetail.startDate = Number(getDateEpochFormat(values.startDate))
        voucherDetail.endDate = Number(getDateEpochFormat(values.endDate))
        voucherDetail.voucherCode = randomNumber(6)

        setVoucherAPi(voucherDetail)
            .then(result => {
                alert("voucher berhasil dibuat")
                window.location.href = '/voucher/list'
            })
            .catch(err => {
                alert("Voucher gagal dibuat !")
                console.log("err create voucher :", err)
            })
    }

    updateVoucherDetail = (value) => {
        const {voucherDetail} = this.state;
        let maximumVoucherValue = (value.voucherType === 'free_shipping_percent' || value.voucherType === 'percent')
        && value.maximumVoucherValue >= 0 ? value.maximumVoucherValue : voucherDetail.maximumVoucherValue

        let voucherDetailToUpdate = {
            ...value,
            voucherValue: value?.voucherValue ? value.voucherValue : voucherDetail.voucherValue,
            maximumVoucherValue,
            startDate: value?.startDate ? Number(getDateEpochFormat(value.startDate)) : voucherDetail.startDate,
            endDate: value?.endDate ? Number(getDateEpochFormat(value.endDate)) : voucherDetail.endDate
        }

        updateVoucherDetailApi(voucherDetailToUpdate, voucherDetail.id)
            .then(result => {
                window.location.href = '/voucher/list'
                alert("Voucher berhasil di update !!")
            })
            .catch(err => console.log("err :", err))
    }


    removeButtonHandler = () => this.setState({fileImage: []})

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else if (fileImage.type !== 'image/jpeg' && fileImage.type !== 'image/png') {
            alert('Upload image tidak sesuai !!')
        } else {
            this.setState({fileImage: [fileImage], showImage: false});
        }
        return false
    }

    getOptionList = (item) => {
        const {levelList, paymentList} = this.state;
        const {avostoreListAll} = this.props.avostoreListReducer;

        switch (item.key) {
            case 'paymentMethod':
                return (
                    paymentList.map((item, index) => (
                        <Option value={item.value}
                                key={index.toString()}>{item.name}</Option>
                    ))
                )
            case 'userLevelList':
                return (
                    levelList.map((item, index) => (
                        <Option value={item.value}
                                key={index.toString()}>{item.name}</Option>
                    ))
                )
            case 'emailList':
                return (
                    avostoreListAll.map((item, index) => (
                        <Option value={item?.email}
                                key={index.toString()}>{item?.email}</Option>
                    ))
                )
            default:
                return null
        }
    }

    onValuesChangeHandler = (e) => {
        if (e.target.value === 'percent' || e.target.value === 'free_shipping_percent') {
            this.setState({isShowMaxValue: true})
        } else {
            this.setState({isShowMaxValue: false})
        }
    }


    render() {
        const {voucherForm, fileImage, voucherDetail, isShowMaxValue} = this.state
        const initializeValue = Object.assign(VoucherDetailObj);
        const {productList} = this.props.productReducer

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>{!!this?.props?.match?.params?.id ? 'Edit Voucher' : 'Buat Voucher'}</h3>
                <Form {...layout} style={{width: '70%'}} onFinish={this.onFinish} ref={this.formRef}
                      initialValues={initializeValue}>
                    {
                        voucherForm.map((item, index) => {
                            switch (item.type) {
                                case 'image':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.name}
                                            rules={[
                                                {required: item.required, message: item.placeholder}
                                            ]}
                                        >
                                            <Upload
                                                fileList={fileImage}
                                                onRemove={this.removeButtonHandler}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {
                                                    voucherDetail.image &&
                                                    <Image
                                                        src={voucherDetail.image}
                                                        width={100}
                                                    />
                                                }
                                                <Row
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginTop: 10
                                                    }}>
                                                    <Button icon={<UploadOutlined/>}>
                                                        Upload
                                                    </Button>
                                                    <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                                </Row>
                                            </Upload>
                                        </Form.Item>
                                    )
                                case 'textarea':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[{
                                                required: item.required,
                                                message: item.placeholder,
                                            }]}
                                        >
                                            <TextArea rows={4} placeholder={item.placeholder}/>
                                        </Form.Item>
                                    )
                                case 'date':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[{
                                                required: !!voucherDetail[item.key] ? false : item.required,
                                                message: item.placeholder,
                                            }]}
                                        >
                                            <DatePicker/>
                                        </Form.Item>
                                    )
                                case 'select':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[{
                                                required: item.required,
                                                message: item.placeholder,
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{width: '100%'}}
                                                placeholder={item.placeholder}
                                            >
                                                {this.getOptionList(item)}
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'radio':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            onChange={this.onValuesChangeHandler}
                                            rules={[{
                                                required: item.required,
                                                message: item.placeholder,
                                            }]}
                                        >
                                            <Radio.Group>
                                                {
                                                    item.optionItem.map((item, index) => {
                                                        return (
                                                            <Radio style={{display: 'block',}} value={item.value}
                                                                   key={index.toString()}>{item.name}</Radio>
                                                        )
                                                    })
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                    )
                                case 'maxValue':
                                    return (
                                        <div key={index.toString()}>
                                            {
                                                isShowMaxValue &&
                                                <Form.Item
                                                    label={item.label}
                                                    name={item.key}
                                                    rules={[
                                                        {required: item.required, message: item.placeholder},
                                                    ]}
                                                >
                                                    <InputNumber placeholder={item.placeholder}/>
                                                </Form.Item>
                                            }
                                        </div>
                                    )
                                case 'number':
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[
                                                {required: item.required, message: item.placeholder},
                                            ]}
                                        >
                                            <InputNumber min="0" style={{width: 250}}
                                                         placeholder={item.placeholder}/>
                                        </Form.Item>
                                    )
                                case 'list':
                                    return (
                                        <Form.List key={index.toString()}
                                                   label={item.label}
                                                   name={item.key}
                                                   rules={[
                                                       {required: item.required, message: item.placeholder},
                                                   ]}>
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map(({key, name, ...restField}) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                marginBottom: 8,
                                                                paddingLeft: '33%'
                                                            }}
                                                            align="baseline"
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'id']}
                                                                style={{width: 400}}
                                                            >
                                                                <Select
                                                                    style={{width: 400}}
                                                                    placeholder={item.placeholder}
                                                                >
                                                                    {
                                                                        productList.map((item, index) => (
                                                                            <Option value={item.id}
                                                                                    key={index.toString()}>{item.name}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'qty']}
                                                            >
                                                                <Input placeholder="Quantity"/>
                                                            </Form.Item>
                                                            <MinusCircleOutlined onClick={() => remove(name)}/>
                                                        </Space>
                                                    ))}
                                                    <Form.Item key={index.toString()}
                                                               name={item.key}
                                                               style={{
                                                                   marginBottom: 8,
                                                                   paddingLeft: '33%'
                                                               }}
                                                               rules={[
                                                                   {required: item.required, message: item.placeholder},
                                                               ]}>
                                                        <Button type="dashed" onClick={() => add()} block
                                                                icon={<PlusOutlined/>}>
                                                            Tambahkan produk yang dapat menggunakan voucher
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            rules={[
                                                {
                                                    required: item.required,
                                                    message: item.placeholder,
                                                    whitespace: item.whitespace
                                                },
                                            ]}
                                        >
                                            <Input placeholder={item.placeholder}/>
                                        </Form.Item>
                                    )
                            }
                        })
                    }
                    <Form.Item wrapperCol={{offset: 8}}>
                        <div className="steps-action">
                            <Button type="primary" htmlType="submit">
                                Done
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

const layout = {labelCol: {span: 8}, wrapperCol: {span: 14}};

const mapStateToProps = (state) => {
    const {avostoreListReducer, productReducer} = state
    return {avostoreListReducer, productReducer};
}

export default connect(mapStateToProps, {getAvostoreListAll, getProductList})(VoucherFormPage);
