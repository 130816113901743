import React from "react";
import {Button, Card, Tabs} from "antd";
import AnalyticsGuaranteeHeaderComponent from "./analytics-guarantee-header.component";
import {connect} from "react-redux";
import {
    analyticsGuaranteeDispatcher,
    getDataAnalyticsGuaranteeList,
    getTotalGuaranteeData,
    getTotalGuaranteeDataPaid
} from "../../actions/analytics-guarantee.action";
import {Colors} from "../../ui-util/colors";
import {ANALYTICS_GUARANTEE} from "../../reducers/analytics-guarantee.reducer";
import ExportGuaranteeComponent from "./export-guarantee.component";
import {formatGuaranteeAnalyticsExport} from "../../ui-util/analytics-guarantee.util";
import AnalyticsGuaranteeListComponent from "./analytics-guarantee-list.component";
import {getUserListByLevel} from "../../actions/user.action";
import isEqual from "react-fast-compare";

const {TabPane} = Tabs;

class AnalyticsGuaranteePage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'COMPLETED',
                    tab: 'Complete'
                }, {
                    key: 'ACTIVE',
                    tab: 'Active'
                },
                {
                    key: 'paid',
                    tab: 'Paid'
                }
            ]
        }
    }

    componentDidMount() {
        this.props.getUserListByLevel('report')
        this.props.getDataAnalyticsGuaranteeList()
        this.props.getTotalGuaranteeData()
    }

    onTabChange = (tabId) => {
        let selectedGuaranteeType = {
            name: 'All',
            value: ''
        }
        this.props.analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {
            activeTab: tabId,
            guaranteeType: '',
            selectedGuaranteeType
        })
        this.props.getDataAnalyticsGuaranteeList()
        if (tabId === 'paid') {
            this.props.getTotalGuaranteeDataPaid()
        } else {
            this.props.getTotalGuaranteeData()
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(!isEqual(this.props.dropdownSellerReducer.seller, prevProps.dropdownSellerReducer.seller)){
            this.props.getDataAnalyticsGuaranteeList()
            this.props.getTotalGuaranteeData()
        }
    }

    render() {
        const {analyticsGuaranteeList, loading, activeTab} = this.props.analyticsGuaranteeReducer
        const {tabList} = this.state

        return (
            <Card>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen}}
                    type="card"
                    size={'large'}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <>
                                <AnalyticsGuaranteeHeaderComponent/>
                                <ExportGuaranteeComponent
                                    button={
                                        loading ?
                                            <Button disabled style={{marginLeft: 20}}>Export</Button> :
                                            <Button
                                                style={{
                                                    backgroundColor: Colors.darkseagreen,
                                                    color: 'white',
                                                    marginBottom: 20
                                                }}>Export</Button>
                                    }
                                    guaranteeOrderList={formatGuaranteeAnalyticsExport(analyticsGuaranteeList)}
                                    filename={'analytics-guarantee'}
                                />
                                <AnalyticsGuaranteeListComponent/>
                            </>
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {analyticsGuaranteeReducer, dropdownSellerReducer} = state

    return {analyticsGuaranteeReducer, dropdownSellerReducer}
}

export default connect(mapStateToProps, {
    getUserListByLevel,
    getDataAnalyticsGuaranteeList,
    analyticsGuaranteeDispatcher,
    getTotalGuaranteeData,
    getTotalGuaranteeDataPaid
})(AnalyticsGuaranteePage)
