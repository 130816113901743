import React, {Component} from 'react';
import {Button, Form, Input} from "antd";
import {Colors} from "../../ui-util/colors";

class FaqForm extends Component {
    formRef = React.createRef();

    componentDidMount() {
        const {selectedItem, isEdit} = this.props
        if (isEdit) {
            this.formRef.current.setFieldsValue(selectedItem)
        }
    }

    render() {
        const {onFinish} = this.props

        return (
            <Form ref={this.formRef} onFinish={onFinish}>
                <Form.Item
                    label="Question"
                    name={'question'}
                    rules={[{
                        required: true,
                        message: 'Please input the question',
                    }]}
                >
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    label="Answer"
                    name={'answer'}
                    rules={[{
                        required: true,
                        message: 'Please input the answer',
                    }]}
                >
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default FaqForm;