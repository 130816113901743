import {GenericDataModel} from "./generic-data.model";
import moment from 'moment';
import {randomNumber} from "../ui-util/general-variable";

interface SocialMediaObj {
    line: String;
    whatsApp: String;
    instagram: String;
    facebook: String;
}

interface MarketPlaceObj {
    shopee: String;
    tokopedia: String;
    website: String;
}

interface AddressObj {
    address: String;
    province: String;
    city: String;
    district: String;
    provinceId : Number;
    cityId : Number;
    districtId : Number;
    zipcode: Number;
}

export const BRONZE = 'BRONZE';
export const PLATINUM = 'PLATINUM';
export const PREMIUM_1 = 'PREMIUM_1';
export const PREMIUM_2 = 'PREMIUM_2';
export const END_CUSTOMER = 'END_CUSTOMER';
export const DISTRIBUTION_CHANNEL_1 = 'DISTRIBUTION_CHANNEL_1';
export const DISTRIBUTION_CHANNEL_2 = 'DISTRIBUTION_CHANNEL_2';
export const DISTRIBUTION_CHANNEL_3 = 'DISTRIBUTION_CHANNEL_3';

type UserLevel = BRONZE | PLATINUM | PREMIUM_1 | PREMIUM_2 | END_CUSTOMER;

export const REGISTERED = 'REGISTERED';
export const AUTHENTICATED = 'AUTHENTICATED';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

type UserStatus = REGISTERED | AUTHENTICATED | ACTIVE | INACTIVE;

export default class UserDetailModel extends GenericDataModel {
    name: String;
    email: String;
    phoneNumber: String;
    referralId: String;
    parentRef: String;
    joinDate: Number;
    address: AddressObj;
    addressDefault: String;
    identityCardNumber: String;
    birthDate: String;
    birthPlace: String;
    socialMediaAccount: SocialMediaObj;
    marketPlaceAccount: MarketPlaceObj;
    avostoreArea: String;
    avostoreAreaId: Number;
    isForgetPassword: Boolean;
    status: UserStatus;
    level: UserLevel;
    updatedLevelAt: Number;
    point: Number;
    image: String;
    bankAccountDefault: String;
    userToken: String;
    warehouseDefault: String;
    warehouseId: String;
    validForChallenge: Boolean;

    constructor(userDetailModel: UserDetailModel) {
        super()
        Object.assign(this, userDetailModel);
    }
}

export const UserDetailObject = {
    id: null,
    valid: true,
    validForChallenge: false,
    name: null,
    email: null,
    phoneNumber: null,
    referralId: randomNumber(20),
    parentRef: null,
    joinDate: moment().unix(), //epoch
    address: {
        address: null,
        province: null,
        city: null,
        district: null,
        provinceId: 0,
        cityId: 0,
        districtId: 0,
        zipcode: null
    },
    addressDefault: null,
    identityCardNumber: null,
    birthDate: null,
    birthPlace: null,
    socialMediaAccount: { //link
        line: '',
        whatsApp: '',
        instagram: '',
        facebook: ''
    },
    marketPlaceAccount: { //link
        shopee: null,
        tokopedia: null,
        blibli: null,
        lazada: null,
        website: null
    },
    avostoreArea: null,
    avostoreAreaId: 0,
    isForgetPassword: false,
    status: AUTHENTICATED,
    level: END_CUSTOMER,
    updatedLevelAt: null,
    point: 0,
    image: '',
    bankAccountDefault: null,
    userToken: null,
    warehouseDefault: null,
    warehouseId: null
}
