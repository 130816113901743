import {firestore} from "../index";

const usersCollection = firestore.collection('users');

const getUserDetailFromFirestore = (uid) => {
    return usersCollection.doc(uid).get()
}

const getUserListByRefferalIdFromFirestore = (parentRef) => {
    return usersCollection.where('parentRef', '==', parentRef).get()
}

const getUserDetailByParentRef: Promise = (parentRef) => {
    return usersCollection.where('parentRef', '==', parentRef).get()
}

const getUserDetailByReferral: Promise = (referralId) => {
    return usersCollection.where('referralId', '==', referralId).get()
}

const mergeDataUserDetailToFirestore: Promise = (uid, mergeObj) => {
    return usersCollection.doc(uid).set(mergeObj, {merge: true})
}

const getAvostoreListFromFirestore = () => {
    return usersCollection.get()
}

export {
    getUserDetailFromFirestore, getUserListByRefferalIdFromFirestore, getUserDetailByParentRef,
    getUserDetailByReferral, mergeDataUserDetailToFirestore, getAvostoreListFromFirestore
}
