import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Drawer, Row, Table, Timeline} from "antd";
import {getAuditEmailList} from "../../api/email.api";
import _ from 'lodash'
import {convertStartEndDate, formatUnixDateToReadable} from "../../ui-util/general-variable";
import moment from "moment";
import {Colors} from "../../ui-util/colors";

const {RangePicker} = DatePicker;

class AuditEmailPage extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Timestamp',
                    dataIndex: 'sentAt',
                    key: 'sentAt',
                    render: (sentAt) => <div>{formatUnixDateToReadable(sentAt, 'lll')}</div>
                },
                {
                    title: 'Mailgun ID',
                    dataIndex: 'mailgunId',
                    key: 'mailgunId'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status'
                },
                {
                    title: 'Sender',
                    dataIndex: 'sender',
                    key: 'sender'
                },
                {
                    title: 'Recipient',
                    dataIndex: 'recipients',
                    key: 'recipients',
                    render: (recipients) => <p>{recipients?.length > 0 ? recipients[0] : ''}</p>
                },
                {
                    title: 'Subject Email',
                    dataIndex: 'subject',
                    key: 'subject'
                },
            ],
            columnsRecipients: [
                {title: 'Recipient', dataIndex: 'recipient', key: 'recipient', width: '80%'},
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (record) => (
                        <React.Fragment>
                            <Button
                                type="link"
                                onClick={() => this.onClickHandler(record)}
                            >
                                Rincian
                            </Button>
                        </React.Fragment>
                    )
                }
            ],
            emailList: [],
            filterStartDate: moment().subtract(30, 'days'),
            filterEndDate: moment(),
            loading: false,
            isVisible: false,
            items: []
        }
    }

    onClickHandler = (value) => {
        this.setState({isVisible: true, items: value})
    }

    componentDidMount() {
        this.getEmailAuditListFromApi()
    }

    getEmailAuditListFromApi = () => {
        let {filterStartDate, filterEndDate} = this.state
        let requestBody = {
            startDate: convertStartEndDate(filterStartDate, 'start'),
            endDate: convertStartEndDate(filterEndDate)
        }

        this.setState({loading: true})
        getAuditEmailList(requestBody)
            .then(result => {
                this.setState({emailList: result})
                this.setState({loading: false})
            })
            .catch(err => {
                console.log("error getAuditEmailList :", err)
                this.setState({loading: false})
            })
    }

    onDatePickerChange = (dates) => {
        this.setState({filterStartDate: dates[0], filterEndDate: dates[1]})
    }

    onFilterHandler = () => {
        this.getEmailAuditListFromApi()
    }

    onDisplayRecipients = (record) => {
        const {columnsRecipients} = this.state
        return <Table rowKey={'recipient'} columns={columnsRecipients} dataSource={record} pagination={false}/>
    }

    render() {
        const {columns, emailList, filterStartDate, filterEndDate, loading, isVisible, items} = this.state

        return (
            <Card>
                <h5 style={{marginBottom: 20, marginLeft: 20}}>Audit Email</h5>
                <Row justify={'end'}>
                    <Col span={10} style={{marginBottom: 20}}>
                        Tgl. Pengiriman Email
                        <RangePicker
                            allowClear={false}
                            style={{width: '50%', marginLeft: 20, marginRight: 20}}
                            value={[filterStartDate, filterEndDate]}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                onClick={() => this.onFilterHandler()}>Filter</Button>
                    </Col>
                </Row>
                <div>
                    <Table
                        className="components-table-demo-nested"
                        columns={columns}
                        loading={loading}
                        dataSource={emailList}
                        expandedRowRender={(record) => this.onDisplayRecipients(record.logs)}
                        rowKey={'id'}
                        pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                    />
                </div>
                {
                    !!isVisible &&
                    <Drawer title="History Email Detail" placement="right"
                            width={"30%"}
                            onClose={() => this.setState({isVisible: false})}
                            visible={isVisible}>
                        <Timeline>
                            {
                                _.map(items.history, (i, k) =>
                                    <Timeline.Item
                                        key={k}>{`${i.status}, ${formatUnixDateToReadable(i.receivedAt, 'lll')}, ${i?.reason ? i.reason : ''}`}</Timeline.Item>)
                            }
                        </Timeline>
                    </Drawer>
                }
            </Card>
        );
    }
}

export default AuditEmailPage;