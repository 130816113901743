import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Drawer, Image, Row, Select, Table, Tabs} from "antd";
import {ProductDetailObj} from "../../models/product.model";
import {Colors} from "../../ui-util/colors";
import {getUserListByLevel} from "../../actions/user.action";
import {getProductStockListDc, productStockDispatcher} from "../../actions/product-stock.action";
import {getWarehouseDcListFromApi} from "../../actions/warehouse.action";
import ProductStockFormEditComponent from "../../components/product-stock-form-edit.component";
import EditStockFormComponent from "../product-edit-stock/edit-stock-form.component";
import {updateProductStockApi} from "../../api/product-stock";
import {SET_LOADING_PRODUCT_STOCK} from "../../reducers/product-stock.reducer";
import {getWarehouseDcListApi} from "../../api/warehouse.api";
import _ from "lodash";
import SearchComponent from "../../components/search.component";
import {isEqual} from "../../ui-util/general-variable";

const {Option} = Select;
const {TabPane} = Tabs;

class ProductEditDc extends Component {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'products',
                    tab: 'Avostore'
                },
                {
                    key: 'po_products',
                    tab: 'Pre Order'
                }
            ],
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image ? image : require('../../images/no-image.png')} width={100}
                                              height={100}/>
                }, {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                }, {
                    title: 'Kode SKU',
                    dataIndex: 'skuCode',
                    key: 'skuCode'
                }, {
                    title: 'Stok',
                    dataIndex: 'stock',
                    key: 'stock',
                    editable: true,
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => this.actionButton(record)
                }
            ],
            activeTab: 'products',
            editingId: '',
            editStockModalVisible: false,
            productDetail: {...ProductDetailObj},
            loadingTable: false,
            warehouseAvoStoreList: [],
            selectedName: '',
            searchKey: '',
            selectedWarehouseId: ''
        }
    }

    componentDidMount() {
        const {activeTab} = this.state
        getWarehouseDcListApi()
            .then(result => {
                this.setState({
                    warehouseAvoStoreList: result.data,
                    selectedWarehouseId: result.data[0].warehouseId
                })
                this.props.getProductStockListDc(result.data[0].warehouseId, activeTab)
            })
            .catch(err => {
                console.log("error get warehouse dc list :", err)
                alert("gagal mendapatkan data warehouse DC !!")
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const {activeTab, selectedWarehouseId} = this.state
        if (!isEqual(prevState.activeTab, this.state.activeTab) ||
            !isEqual(prevState.selectedWarehouseId, this.state.selectedWarehouseId)) {
            this.props.getProductStockListDc(selectedWarehouseId, activeTab)
        }
    }

    onFilterHandler = (warehouseId) => {
        const {warehouseAvoStoreList} = this.state
        let selectedWarehouse = _.find(warehouseAvoStoreList, v => v.warehouseId === warehouseId)
        this.setState({selectedName: selectedWarehouse.warehouseName, selectedWarehouseId: warehouseId})
    }

    EditableCell = () => ({editing, dataIndex, title, inputType, record, index, children, ...restProps}) => {
        const {productDetail} = this.state
        return (
            <td {...restProps}>
                {editing ? (
                    <ProductStockFormEditComponent productDetail={productDetail}/>
                ) : (
                    children
                )}
            </td>
        );
    };

    actionButton = (record) => {
        const {editingId} = this.state
        return (
            <Button
                disabled={editingId !== ''}
                style={{
                    marginRight: 10,
                    width: 100,
                    backgroundColor: editingId !== '' ? null : Colors.gainsboro,
                    borderColor: editingId !== '' ? null : Colors.darkseagreen,
                    borderRadius: 5
                }}
                onClick={() => this.editButtonHandler(record)}
            >Edit</Button>
        )
    }

    editButtonHandler = (record) => {
        this.setState({editStockModalVisible: true, productDetail: record})
    }

    onFinishHandler = (value) => {
        const {selectedWarehouseId, productDetail, activeTab} = this.state
        let body = {
            warehouseId: selectedWarehouseId,
            productListType: activeTab,
            ...value
        }

        updateProductStockApi(productDetail.id, body)
            .then(r => {
                this.setState({editStockModalVisible: false, productDetail: {...ProductDetailObj}})
                this.props.getProductStockListDc(selectedWarehouseId, activeTab)
                alert('Update produk berhasil !!')
            })
            .catch(error => {
                console.log("onFinishHandler updateProductStockApi", error)
                this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loading: false})
                this.setState({editStockModalVisible: false})
            })
    }

    onCloseButtonHandler = () => {
        this.setState({editStockModalVisible: false, productDetail: {...ProductDetailObj}})
    }

    onSearchVoucherHandler = (value) => {
        this.setState({searchKey: value})
    }

    getDataToDisplay = () => {
        const {productStockList} = this.props.productStockReducer
        const {searchKey} = this.state
        let dataToDisplay = productStockList

        if (!!searchKey) {
            dataToDisplay = _.filter(productStockList, item => item.skuCode.includes(searchKey))
        }
        return dataToDisplay
    }


    render() {
        const {
            columns,
            editStockModalVisible,
            productDetail,
            warehouseAvoStoreList,
            selectedName,
            activeTab,
            tabList
        } = this.state
        const {productStockReducer} = this.props
        const {loadingTable} = productStockReducer
        const warehouseDcName = warehouseAvoStoreList.length > 0 && !selectedName
            ? warehouseAvoStoreList[0].warehouseName
            : selectedName

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>{`Edit Produk DC - ${warehouseDcName}`}</h3>
                <Row style={{marginTop: 20, marginBottom: 20}} justify={'space-between'} align="center">
                    <SearchComponent searchItem={'kode SKU'} onSearchHandler={this.onSearchVoucherHandler}/>
                    <div>
                        Pilih DC
                        <Select style={{width: 250, marginLeft: 10, marginRight: 10}}
                                defaultValue={'None'}
                                onChange={(item) => this.onFilterHandler(item)}>
                            {
                                warehouseAvoStoreList.map((item, index) => (
                                    <Option value={item.warehouseId}
                                            key={index.toString()}>{item.warehouseName}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={(value) => this.setState({activeTab: value})}
                    tabBarStyle={{color: Colors.darkseagreen}}
                    type="card"
                    size={'large'}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <Table
                                components={{
                                    body: {
                                        cell: this.EditableCell(),
                                    },
                                }}
                                className="components-table-demo-nested"
                                columns={columns}
                                dataSource={this.getDataToDisplay()}
                                rowClassName="editable-row"
                                pagination={false}
                                rowKey={'id'}
                                loading={loadingTable}
                            />
                        </TabPane>
                    ))}
                </Tabs>
                {
                    editStockModalVisible &&
                    <Drawer
                        title={'Edit Stock'}
                        width={700}
                        onClose={this.onCloseButtonHandler}
                        visible={editStockModalVisible}
                    >
                        <EditStockFormComponent onFinish={this.onFinishHandler}
                                                productDetail={productDetail}
                        />
                    </Drawer>
                }
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {productStockReducer, warehouseReducer} = state
    return {productStockReducer, warehouseReducer};
}

export default connect(mapStateToProps, {
    getUserListByLevel,
    getProductStockListDc,
    getWarehouseDcListFromApi,
    productStockDispatcher
})(ProductEditDc);