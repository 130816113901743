import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class WarehouseModel extends GenericDataModel{
    name: String;
    province: String;
    city: String;
    district: String;
    provinceId: Number;
    cityId: Number;
    districtId: Number;
    zipcode: Number;
    phoneNumber: Number;
    address: String;
    userId: String;
    region: String;

    constructor(WarehouseModel: WarehouseModel) {
        super();
        Object.assign(this, WarehouseModel)
    }
}

export const WarehouseObject = {
    ...GenericDataObj,
    name: null,
    province: null,
    city: null,
    district: null,
    provinceId: '',
    cityId: '',
    districtId: '',
    zipcode: null,
    phoneNumber: null,
    address: null,
    userId: null,
    region: null
}
