import React, {Component} from 'react';
import {Button, Card, Dropdown, Menu, Row, Table} from "antd";
import {getProductStockInfo} from "../../api/product.api";
import {Colors} from "../../ui-util/colors";

class ProductStockInfo extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Nama Produk',
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 300
                },
                {
                    title: "Avoskin - Alokasi DC",
                    children: [
                        {
                            title: "Stok Tersedia",
                            dataIndex: "avoDcStockIn",
                            key: "avoDcStockIn",
                            width: 150,
                        },
                        {
                            title: "Stok Dipesan",
                            dataIndex: "avoDcStockOut",
                            key: "avoDcStockOut",
                            width: 150,
                        }
                    ]
                },
                {
                    title: "Avoskin - Alokasi Avostore",
                    children: [
                        {
                            title: "Stok Tersedia",
                            dataIndex: "avostoreStockIn",
                            width: 150,
                            key: "avostoreStockIn"
                        },
                        {
                            title: "Stok Dipesan",
                            dataIndex: "avostoreStockOut",
                            width: 150,
                            key: "avostoreStockOut"
                        }
                    ]
                }
            ],
            filterList: [
                {key: '1', cityId: 'jakarta', cityName: 'Jakarta'},
                {key: '2', cityId: 'jogjakarta', cityName: 'Jogjakarta'}
            ],
            selectedCity: {key: '1', cityId: 'jakarta', cityName: 'Jakarta'},
            productList: [],
            searchKey: '',
            loadingTable: false
        }
    }

    componentDidMount() {
        const {selectedCity} = this.state
        let reqBody = {city: selectedCity.cityId}
        this.getProductWithStockList(reqBody)
    }

    getProductWithStockList = (reqBody) => {
        const {columns} = this.state
        this.setState({loadingTable: true})
        getProductStockInfo(reqBody)
            .then(result => {
                let productList = result.data.finalProduct

                if(columns.length === 3){
                    let newColumn = [...columns,
                        {
                            title: "DC",
                            children: result.data.dcTableList
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'actualStock',
                            key: 'actualStock',
                            width: 150
                        }]
                    this.setState({columns: newColumn})
                }

                this.setState({productList, loadingTable: false})
            })
            .catch(err => {
                console.log("err get productStockInfo :", err)
                this.setState({loadingTable: false})
            })
    }

    onSelectHandler = (city) => {
        this.setState({selectedCity: city})
        let reqBody = {city: city.cityId}
        this.getProductWithStockList(reqBody)
    }

    dropDownHandler = () => {
        let {filterList} = this.state
        return (
            <Menu>
                {
                    filterList.map((city) => (
                        <Menu.Item key={city.key} onClick={() => this.onSelectHandler(city)}>
                            <Button type="link">{city.cityName}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }


    render() {
        const {productList, columns, selectedCity, loadingTable} = this.state

        return (
            <Card>
                <Row>
                    <div style={{marginRight: 30}}><h3 style={{marginBottom: 20}}>Info Stok Produk</h3></div>
                    <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                        <Button
                            style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                            size="large"
                            theme="filled"
                        >
                            {selectedCity.cityName}
                        </Button>
                    </Dropdown>
                </Row>
                <Table
                    columns={columns}
                    scroll={{x: true}}
                    dataSource={productList.map((d, i) => ({key: i, ...d}))}
                    pagination={false}
                    bordered={true}
                    loading={loadingTable}
                />
            </Card>
        );
    }
}

export default (ProductStockInfo);
