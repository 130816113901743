import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Descriptions, Modal} from "antd";
import {getDateEpochFormat, getPaymentMethodText, renameImageToDateFormat} from "../../ui-util/general-variable";
import {Colors} from "../../ui-util/colors";
import {updatePaymentDcAPi} from "../../api/order.api";
import UpdatePaymentDetailModal from "./update-payment-detail.modal";
import moment from 'moment';
import {storageTaskEvent} from "../../firebase";
import {paymentProofRef, putPaymentProofImage} from "../../firebase/storage/payment-image";
import {CANCELLED} from "../../models/order-detail.model";

class PaymentDetailComponent extends Component {
    constructor() {
        super();
        this.state = {
            isVisible: false
        }
    }

    onClickHandler = () => {
        this.setState({isVisible: true})
    }

    handleCancel = () => {
        this.setState({isVisible: false})
    }

    onFinish = (value, fileImage) => {
        const {orderDetail} = this.props

        let orderDetailToUpdate = {
         orderDetail: {
             ...orderDetail,
             orderState: orderDetail?.orderState,
             paymentMethod: value.paymentMethod,
             paymentDetail: {
                 transactionStatus: value.transactionStatus,
                 transactionId: value.transactionId,
                 transactionTime: moment(value.transactionTime).format('YYYY-MM-DD HH:mm:ss'),
                 vaNumber: value.vaNumber
             }
         },
         timestamp: getDateEpochFormat(value.transactionTime)
        }
        let paymentProofBefore = !!orderDetail?.paymentDetail.proofOfPayments ? orderDetail?.paymentDetail.proofOfPayments : []

        if (fileImage.length > 0) {
            let fileName = renameImageToDateFormat(fileImage[0].name, 'payment-')
            putPaymentProofImage(fileName, fileImage[0]).on(
                storageTaskEvent.STATE_CHANGED,
                snapshot => {
                },
                err => console.log('err', err),
                () => {
                    paymentProofRef(fileName).getDownloadURL()
                        .then(url => {
                            let paymentProofNow = [{
                                id: paymentProofBefore.length > 0 ? paymentProofBefore.length + 1 : 1,
                                image: url
                            }]
                            let paymentProof = paymentProofBefore.concat(paymentProofNow)
                            orderDetailToUpdate.orderDetail.paymentDetail.proofOfPayments = paymentProof
                            this.updatePaymentDC(orderDetailToUpdate)
                        })
                }
            )
        } else {
            this.updatePaymentDC(orderDetailToUpdate)
        }
    }

    updatePaymentDC = (orderDetailToUpdate) => {
        updatePaymentDcAPi(orderDetailToUpdate)
            .then(r => {
                this.setState({isVisible: false})
                alert('data berhasil disimpan !!')
                window.location.reload()
            })
            .catch(err => {
                console.log("err updateOrderDetailAPi order detail:", err)
                alert('gagal menyimpan data pembayaran !!')
            })
    }

    render() {
        const {orderDetail} = this.props
        const {isVisible} = this.state

        return (
            <Card style={{marginTop: 30}}>
                {
                    orderDetail?.isOrderDc && orderDetail.paymentDetail.transactionStatus !== 'paid' && orderDetail.orderState !== CANCELLED ?
                        <Descriptions title="Konfirmasi Pembayaran" layout="vertical" column={1}>
                            <div style={{marginBottom: 10}}>
                                <Button
                                    onClick={() => this.onClickHandler()}
                                    style={{
                                        backgroundColor: Colors.gainsboro,
                                        borderColor: Colors.darkseagreen,
                                        borderRadius: 5
                                    }}>Update Pembayaran Order
                                </Button>
                            </div>
                        </Descriptions>
                        :
                        <Descriptions title="Detail Pembayaran" layout="vertical" column={1}>
                            <Descriptions.Item label="Metode Pembayaran">
                                {getPaymentMethodText(orderDetail.paymentMethod)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Status Pembayaran">
                                {Boolean(orderDetail.paymentDetail.transactionStatus) && orderDetail.paymentDetail.transactionStatus.toUpperCase()}
                            </Descriptions.Item>
                            <Descriptions.Item label="No. Virtual Account">
                                {orderDetail.paymentDetail.vaNumber}
                            </Descriptions.Item>
                        </Descriptions>
                }

                <Modal
                    title={'Update Pembayaran Order DC'}
                    visible={isVisible}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <UpdatePaymentDetailModal orderDetail={orderDetail} onFinish={this.onFinish}/>
                </Modal>
            </Card>
        );
    }
}

const mapStateToProps = () => {
    return {};
}

export default connect(mapStateToProps)(PaymentDetailComponent);
