import {
    getAvostoreToDCSalesReportApi,
    getDCSalesReportApi,
    getMarginDcApi,
    getTopSellingProductAvostore,
    getTopSellingProductDc
} from "../api/dc-report.api";
import {REPORT_SALES_DC} from "../reducers/dc-report.reducer";
import {convertStartEndDate, userIdDC} from "../ui-util/general-variable";

const dcReportDispatcher = (type, payload) => {
    return {type, payload}
}

const getDcSalesReport = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, dcUserId} = getState().dcReportReducer
        const requestBody = {
            startDate:  convertStartEndDate(startDate, 'start'),
            endDate : convertStartEndDate(endDate),
            buyerId: dcUserId? dcUserId : userIdDC(),
            sellerId: dcUserId? dcUserId : userIdDC()
        }

        getDCSalesReportApi(requestBody)
            .then(result => {
                dispatch(dcReportDispatcher(REPORT_SALES_DC, {...result.data}))
            })
            .catch(err => {
                console.log('error getDcSalesReport', err)
            })
    }
}

const getDcAvostoreSalesReport = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, level, dcUserId} = getState().dcReportReducer
        const requestBody = {
            startDate:  convertStartEndDate(startDate, 'start'),
            endDate : convertStartEndDate(endDate),
            sellerId: dcUserId? dcUserId : userIdDC(),
            level
        }

        getAvostoreToDCSalesReportApi(requestBody)
            .then(result => {
                dispatch(dcReportDispatcher(REPORT_SALES_DC, {
                    orderTotalAvoDc: result.orderTotal,
                    grandSubTotalAvoDc: result.grandSubTotal,
                    productTotalAvoDc: result.productOrderTotal
                }))
            })
            .catch(err => {
                console.log('error getDcAvostoreSalesReport', err)
            })
    }
}

const getTopSellingProduct = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, dcUserId} = getState().dcReportReducer
        let requestBodyDC = {
            startDate:  convertStartEndDate(startDate, 'start'),
            endDate : convertStartEndDate(endDate),
            buyerId:dcUserId? dcUserId : userIdDC()
        }

        let requestBodyAvostore = {
            startDate:  convertStartEndDate(startDate, 'start'),
            endDate : convertStartEndDate(endDate),
            sellerId:dcUserId? dcUserId : userIdDC()
        }

        Promise.all([
            getTopSellingProductDc(requestBodyDC),
            getTopSellingProductAvostore(requestBodyAvostore)])
            .then(result => {
                dispatch(dcReportDispatcher(REPORT_SALES_DC, {
                    topProductListDc: result[0],
                    topProductListChain: result[1]
                }))
            })
            .catch(err => {
                console.log('error getTopSellingProductDC', err)
            })
    }
}

const getMarginData = () => {
    return (dispatch, getState) => {
        let {startDate, endDate, level, dcUserId} = getState().dcReportReducer
        const requestBody = {
            startDate:  convertStartEndDate(startDate, 'start'),
            endDate : convertStartEndDate(endDate),
            sellerId: dcUserId? dcUserId : userIdDC(),
            level
        }

        getMarginDcApi(requestBody)
            .then(result => {
                let marginData = [{...result}]
                dispatch(dcReportDispatcher(REPORT_SALES_DC, {marginData}))
            })
            .catch(err => {
                console.log('getMarginData', err)
            })
    }
}

export {
    getDcSalesReport,
    dcReportDispatcher,
    getTopSellingProduct,
    getDcAvostoreSalesReport,
    getMarginData
}
