import {PENDING} from "../models/order-detail.model";
import moment from "moment";

const INITIAL_STATE = {
    activeTab: PENDING,
    preOrderList: [],
    remainingPaymentList: [],
    preOrderListExport: [],
    loading: false,
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    searchKeyword: ''
}

export const PRE_ORDER_DETAIL = 'PRE_ORDER_DETAIL'

export const preOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRE_ORDER_DETAIL:
            return {...state, ...action.payload}
        default:
            return state
    }
}
