import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card} from "antd";
import {getAvostoreListAll} from "../../actions/user.action";
import {GiftCardModelObj} from "../../models/gift-card.model";
import {getDateEpochFormat, randomNumber} from "../../ui-util/general-variable";
import {createGiftCardApi, updateGiftCardApi, uploadGiftCardImageApi} from "../../api/gift-card.api";
import {getGiftItemListFromApi} from "../../actions/gift.action";
import _ from 'lodash'
import GiftCardFormComponent from "./gift-card-form.component";
import {getWarehouseDcAvoskinList} from "../../actions/warehouse.action";
import {getProductList} from "../../actions/product.action";

class GiftCardFormPage extends Component {
    constructor() {
        super();

        this.state = {
            giftCardForm: [
                {
                    key: 'name',
                    label: 'Nama Gift Card',
                    placeholder: 'Masukkan nama gift ',
                    required: true
                },
                {
                    key: 'image',
                    label: 'Image Gift Card',
                    placeholder: 'Masukkan image gift ',
                    type: 'image',
                    required: true,
                },
                {
                    key: 'description',
                    label: 'Description',
                    placeholder: 'Masukkan deskripsi gift',
                    type: 'textarea',
                    required: true
                },
                {
                    key: 'startDate',
                    label: 'Tanggal Mulai',
                    required: true,
                    placeholder: 'Masukkan Tanggal Mulai',
                    type: 'date',
                },
                {
                    key: 'endDate',
                    label: 'Tanggal Berakhir',
                    placeholder: 'Masukkan Tanggal Berakhir',
                    required: true,
                    type: 'date',
                },
                {
                    key: 'items',
                    label: 'Pilih Gift Item',
                    placeholder: 'Masukkan gift item',
                    required: true,
                    type: 'item'
                },
                {
                    key: 'isMultiply',
                    label: 'Berlaku Kelipatan',
                    required: true,
                    isShow: true,
                    type: 'isMultiply',
                    optionItem: [
                        {name: 'Ya', value: true},
                        {name: 'Tidak', value: false}
                    ]
                },
                {
                    key: 'multiplyType',
                    label: 'Tipe Kelipatan',
                    isShow: false,
                    type: 'multiplyType',
                    optionItem: [
                        {name: 'Kelipatan pembelian produk', value: 'PRODUCTS'},
                        {name: 'Kelipatan total nilai pembelanjaan', value: 'TOTAL_PURCHASE'}
                    ]
                },
                {
                    key: 'minimumProductOrdered',
                    label: 'Pilih produk',
                    placeholder: 'Pilih produk',
                    required: true,
                    isShow: false,
                    type: 'minimumProductOrdered'
                },
                {
                    key: 'minimumMultiplyValue',
                    label: 'Minimum Kelipatan',
                    isShow: false,
                    placeholder: 'Minimum kelipatan',
                    type: 'minimumMultiplyValue'
                },
                {
                    key: 'maximumUseCount',
                    label: 'Maksimal Penggunaan',
                    placeholder: 'Masukkan maksimal penggunaan',
                },
                {
                    key: 'maximumTotalUsePerUser',
                    label: 'Maksimal Penggunaan per User',
                    placeholder: 'Masukkan maksimal penggunaan per user',
                },
                {
                    key: 'minimumPurchasePerOrder',
                    label: 'Minimum Pembelanjaan',
                    placeholder: 'Masukkan minimal pembelanjaan. Cth: 50000',
                },
                {
                    key: 'userLevelList',
                    label: 'Level User',
                    placeholder: 'Pilih level user',
                    type: 'select',
                },
                {
                    key: 'emailList',
                    label: 'Email List',
                    placeholder: 'Masukkan daftar email yang dapat menggunakan kode promo',
                    type: 'select'
                },
                {
                    key: 'warehouseList',
                    label: 'List Gudang',
                    placeholder: 'Pilih list gudang',
                    type: 'select',
                },
            ],
            itemList: [
                {id: null, qty: 0}
            ],
            giftCardDetail: {...GiftCardModelObj},
            fileImage: [],
            giftItemList: [],
            warehouseList: [],
            giftToUpdate: {}
        }
    }

    componentDidMount() {
        this.props.getAvostoreListAll()
        this.props.getGiftItemListFromApi()
        this.props.getWarehouseDcAvoskinList()
        this.props.getProductList()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            window.location.reload()
        }
    }

    getItemList = (items) => {
        const {giftItemList} = this.props.giftReducer
        let itemList = []

        _.forEach(giftItemList, (gift, i) => {
            _.forEach(items, (item, index) => {
                if (gift.id === item.id) {
                    itemList.push({...item, ...gift, qty: Number(item.qty)})
                }
            })
        })

        return itemList
    }

    getProductOrderedList = (items) => {
        const {productReducer} = this.props
        let products = []

        _.forEach(productReducer.productList, (p, i) => {
            _.forEach(items, (item, index) => {
                if (p.id === item.id) {
                    products.push({
                        minQty: Number(item.minQty),
                        id: p.id,
                        name: p.name,
                        skuCode: p.skuCode,
                        minimumMultiplyValue: Number(item.minimumMultiplyValue)
                    })
                }
            })
        })

        return products
    }

    onFinish = (values) => {
        const {fileImage} = this.state
        let giftDetail = {...GiftCardModelObj}
        let items = this.getItemList(values.items)
        let minimumProductOrdered = this.getProductOrderedList(values.minimumProductOrdered)

        Object.assign(giftDetail, values)
        giftDetail.giftCode = randomNumber(6)
        giftDetail.items = items
        giftDetail.startDate = Number(getDateEpochFormat(values.startDate))
        giftDetail.endDate = Number(getDateEpochFormat(values.endDate))
        giftDetail.created_at = getDateEpochFormat()
        giftDetail.minimumProductOrdered = minimumProductOrdered

        if (fileImage.length > 0) {
            let body = {image: fileImage[0]}
            uploadGiftCardImageApi(body)
                .then(result => {
                    if (this?.props?.match?.params?.id) {
                        let image = result.data.image
                        this.onUpdateGiftCardHandler(values, image)
                    } else {
                        giftDetail.image = result.data.image
                        this.onCreateNewCardHandler(giftDetail)
                    }
                })
                .catch(err => {
                    alert("Gift Card gagal diperbaharui !")
                    console.log("err upload image :", err)
                })
        } else if (!this?.props?.match?.params?.id && fileImage.length === 0) {
            this.onCreateNewCardHandler(giftDetail)
        } else {
            this.onUpdateGiftCardHandler(values, null)
        }
    }

    onUpdateGiftCardHandler = (values, image) => {
        const {giftCardDetail} = this.state
        let newItems = this.getItemList(values.items)

        let giftCardToUpdate = {
            ...values,
            id: giftCardDetail.id,
            startDate: values?.startDate ? Number(getDateEpochFormat(values.startDate)) : giftCardDetail.startDate,
            endDate: values?.endDate ? Number(getDateEpochFormat(values.endDate)) : giftCardDetail.endDate,
            image: !!image ? image : giftCardDetail.image,
            items: values?.items?.length > 0 ? newItems : giftCardDetail.items
        }

        updateGiftCardApi(giftCardToUpdate)
            .then(result => {
                alert("Gift Card berhasil diperbaharui !")
                window.location.href = '/gift/gift-card'
            })
            .catch(err => {
                alert("Gift Card gagal diperbaharui !")
                console.log("error onUpdateGiftCardHandler:", err)
            })
    }

    onCreateNewCardHandler = (giftCardDetail) => {
        createGiftCardApi(giftCardDetail)
            .then(result => {
                alert("Gift Card berhasil dibuat")
                window.location.href = '/gift/gift-card'
            })
            .catch(err => {
                alert("Gift Card gagal diunggah !!")
                console.log("error createGiftCardApi :", err)
            })
    }

    removeButtonHandler = () => {
        this.setState({fileImage: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else if (fileImage.type !== 'image/jpeg' && fileImage.type !== 'image/png') {
            alert('Upload image tidak sesuai !!')
        } else {
            this.setState({fileImage: [fileImage], showImage: false});
        }
        return false
    }

    onUpdateGiftDetailToEdit = (giftCardDetail) => {
        this.setState({giftCardDetail})
    }

    render() {
        const {giftCardForm, fileImage, giftToUpdate, giftCardDetail} = this.state
        const {avostoreListReducer, giftReducer, warehouseReducer, productReducer} = this.props
        const {giftItemList} = giftReducer
        const {warehouseDcAvoskinList} = warehouseReducer
        const {id} = this.props.match.params

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>{!!this?.props?.match?.params?.id ? 'Edit Gift Card' : 'Buat Gift Card'}</h3>
                <GiftCardFormComponent giftCardForm={giftCardForm}
                                       fileImage={fileImage}
                                       giftItemList={giftItemList}
                                       productList={productReducer.productList}
                                       uid={id}
                                       giftCardDetail={giftCardDetail}
                                       avostoreListAll={avostoreListReducer.avostoreListAll}
                                       warehouseDcAvoskinList={warehouseDcAvoskinList}
                                       giftToUpdate={giftToUpdate}
                                       onFinish={this.onFinish}
                                       beforeUploadImage={this.beforeUploadImage}
                                       removeButtonHandler={this.removeButtonHandler}
                                       onUpdateGiftDetailToEdit={this.onUpdateGiftDetailToEdit}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {avostoreListReducer, giftReducer, warehouseReducer, productReducer} = state
    return {avostoreListReducer, giftReducer, warehouseReducer, productReducer};
}

export default connect(mapStateToProps, {
    getAvostoreListAll,
    getGiftItemListFromApi,
    getWarehouseDcAvoskinList,
    getProductList
})(GiftCardFormPage);