import React from "react";
import {Button, Divider, Table, Tag} from "antd";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    getDateEpochFormat,
    randomNumber
} from "../../ui-util/general-variable";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../../models/order-detail.model";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getNdnOrderList, saveOrderDispatcher} from "../../actions/save-order.action";
import isEqual from "react-fast-compare";
import {SAVE_ORDER_REDUCER} from "../../reducers/save-order.reducer";
import {updateRelatedDataOrderFromApi} from "../../api/pre-order.api";
import OrderAuditState from "../../models/OrderAuditState.json";
import {setOrderAuditApi} from "../../api/order.api";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class OrderListNdnComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => <div>{shippingAddress.receiver}</div>
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                }, {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    render: (text, record) => {
                        let color = 'red';
                        let label = 'BELUM DIBAYAR';
                        let keyAwb = 'false';
                        let colorAwb = 'red';
                        let labelAwb = 'BELUM RESI';

                        if (text === SUBMITTED) {
                            if (record.paymentDetail.transactionStatus === 'paid') {
                                color = 'yellow';
                                label = 'SUDAH DIBAYAR';
                            }
                        } else if (text === CANCELLED) {
                            color = 'black';
                            label = 'DIBATALKAN';
                        } else if (text === COMPLETED) {
                            color = 'green';
                            label = 'SELESAI';
                        } else if (text === DELIVERY) {
                            color = 'blue';
                            label = 'SEDANG DIKIRIM';
                        } else if (text === PACKAGING) {
                            color = 'orange';
                            label = 'SEDANG DIPROSES';
                        }

                        if (Boolean(record.awb)) {
                            keyAwb = 'true';
                            colorAwb = 'green';
                            labelAwb = 'SUDAH RESI';
                        }

                        return (
                            <React.Fragment>
                                <Tag color={color} key={text}>
                                    {label}
                                </Tag>
                                <Tag color={colorAwb} key={keyAwb}>
                                    {labelAwb}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {title: 'Jasa Kirim', dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {
                    title: '',
                    key: 'action',
                    render: (record, item, i) => (
                        <React.Fragment>
                            <Link to={`/order-detail/${record.id}`}>
                                Rincian
                            </Link>
                            {record.orderState === SUBMITTED && <Divider type="vertical"/>}
                            {record.orderState === SUBMITTED && this.approveButton(record, item, i)}
                        </React.Fragment>
                    )
                }
            ],
            loadingApproveButton: false,
            index: 0
        };
    }

    componentDidMount() {
        this.props.getNdnOrderList()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let {activeTab, currentPage, pageSize, keyword} = this.props.saveOrderReducer
        const {warehouseDetail} = this.props.warehouseReducer
        if (!isEqual(prevProps.saveOrderReducer.activeTab, activeTab) ||
            !isEqual(prevProps.saveOrderReducer.currentPage, currentPage) ||
            !isEqual(prevProps.saveOrderReducer.pageSize, pageSize) ||
            !isEqual(prevProps.saveOrderReducer.keyword, keyword) ||
            !isEqual(prevProps.warehouseReducer.warehouseDetail, warehouseDetail)
        ) {
            this.props.getNdnOrderList()
        }
    }

    approveButton = (record, item, i) => {
        const {loadingApproveButton, index} = this.state
        return (
            <Button loading={index === i ? loadingApproveButton : false}
                    onClick={() => this.approveButtonHandler(record, item, i)}
                    disabled={record.paymentDetail.transactionStatus !== 'paid'}
            >Approve</Button>

        )
    }

    approveButtonHandler = (orderDetail, item, i) => {
        this.setState({loadingApproveButton: true, index: i})
        let requestBody = {
            uid: orderDetail.sellerId,
            orderDetail: {
                id: orderDetail.id,
                orderState: COMPLETED,
                buyerId: orderDetail.buyerId,
                sellerId: orderDetail.sellerId,
                products: orderDetail.products,
                shippingService: orderDetail.shippingService
            }
        }

        updateRelatedDataOrderFromApi(requestBody)
            .then(() => {
                this.setOrderAudit(orderDetail)
            })
            .catch(err => {
                console.log("Error approveButtonHandler :", err)
            })
    }

    setOrderAudit = (orderDetail) => {
        let auditOrderDetail = {
            id: generateMd5Id(randomNumber(6)),
            orderId: orderDetail.id,
            actorId: orderDetail.sellerId,
            actorName: 'Avoskin',
            action: listOfKey[7],
            description: OrderAuditState.SELLER.DC_NDN_COMPLETED_BY_SELLER,
            timestamp: getDateEpochFormat()
        }

        setOrderAuditApi(auditOrderDetail)
            .then(() => {
                this.props.getNdnOrderList()
                this.setState({loadingApproveButton: false})
                alert('Order berhasil diselesaikan!')
            })
            .catch(error => {
                this.setState({loadingApproveButton: false})
                console.log("setOrderAuditApi :", error)
            })
    }

    render() {
        const {columns} = this.state
        const {selectedRowKeys, onSelectRow} = this.props
        const {orderList, dataCount, currentPage, loading} = this.props.saveOrderReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={orderList}
                rowKey="id"
                loading={loading}
                rowSelection={rowSelection}
                pagination={{
                    total: dataCount,
                    onChange: (current) => this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {currentPage: current}),
                    current: currentPage,
                    onShowSizeChange: (currentPage, pageSize) => this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {
                        currentPage,
                        pageSize
                    }),
                    showSizeChanger: true
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    const {saveOrderReducer, userReducer, warehouseReducer} = state;

    return {saveOrderReducer, userReducer, warehouseReducer}
}

export default connect(mapStateToProps, {saveOrderDispatcher, getNdnOrderList})(OrderListNdnComponent)
