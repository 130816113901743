const INITIAL_STATE = {
    orderList: [],
    totalData: 0,
    page: 1,
    size: 10,
    shippingMethod: '',
    warehouseLocation: 'jogjakarta',
    importResiModalVisibility: false,
    selectedRowToExport: [],
    selectedRowKeys: []
}

export const IMPORT_RESI_REDUCER = 'IMPORT_RESI_REDUCER';
export const FLUSH_IMPORT_RESI_REDUCER = 'FLUSH_IMPORT_RESI_REDUCER'

export const importResiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FLUSH_IMPORT_RESI_REDUCER:
            return {...state, selectedRowToExport: [], selectedRowKeys: [], shippingMethod: '', warehouseLocation: 'jogjakarta'}
        case IMPORT_RESI_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
