const INITIAL_STATE = {
    warehouseList: [],
    warehouseDetail: {},
    warehouseAnalytics: {
        id: "",
        name: "All",
    },
    warehouseAvoStoreList: [],
    warehouseDcAvoskinList: []
}

export const WAREHOUSE_LIST = 'WAREHOUSE_LIST'
export const WAREHOUSE_ANALYTICS = 'WAREHOUSE_ANALYTICS'

export const warehouseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WAREHOUSE_LIST:
        case WAREHOUSE_ANALYTICS:
            return {...state, ...action.payload}
        default:
            return state
    }
}
