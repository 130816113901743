import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportPreOrderComponent extends React.PureComponent{
    render(){
        const {selectedRow} = this.props;
        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="Jenis Order" value="orderType"/>
                    <ExcelColumn label="ID Payment Pre Order" value="poPaymentId"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Tanggal Approval" value="approvalDate"/>
                    <ExcelColumn label="Status Order" value="orderState"/>
                    <ExcelColumn label="Status Pre Order" value="status"/>
                    <ExcelColumn label="ID Pembeli" value="buyerId"/>
                    <ExcelColumn label="Nama Penerima" value="receiverName"/>
                    <ExcelColumn label="Nama" value="userName"/>
                    <ExcelColumn label="ID Penjual" value="sellerId"/>
                    <ExcelColumn label="No. Resi" value="awb"/>
                    <ExcelColumn label="Payment Method" value="paymentMethod"/>
                    <ExcelColumn label="Status Pembayaran" value="paymentStatus"/>
                    <ExcelColumn label="Waktu Pembayaran" value="paymentTime"/>
                    <ExcelColumn label="VA Number" value="vaNumber"/>
                    <ExcelColumn label="Shipping Method" value="shippingMethod"/>
                    <ExcelColumn label="Shipping Service" value="shippingService"/>
                    <ExcelColumn label="Shipping Price" value="shippingPrice"/>
                    {/*<ExcelColumn label="Voucher Code" value="voucherCode"/>*/}
                    <ExcelColumn label="Diskon Voucher" value="voucherDiscount"/>
                    <ExcelColumn label="Diskon Poin" value="discountPoint"/>
                    <ExcelColumn label="Cashback" value="grandCashback"/>
                    <ExcelColumn label="Denda" value="fines"/>
                    <ExcelColumn label="Jumlah Pembayaran PO" value="payAmount"/>
                    <ExcelColumn label="Status Pembayaran PO" value="transactionStatus"/>
                    <ExcelColumn label="Waktu Pembayaran PO" value="transactionTime"/>
                    <ExcelColumn label="VA Number PO" value="vaNumberPo"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="Grand Total Guarantee" value="grandTotalGuarantee"/>
                    <ExcelColumn label="Grand Total Pre Order" value="grandTotalPreOrder"/>
                    <ExcelColumn label="Product ID" value="productId"/>
                    <ExcelColumn label="Nama Product" value="productName"/>
                    <ExcelColumn label="Harga Product" value="productPrice"/>
                    <ExcelColumn label="Qty" value="productQty"/>
                    <ExcelColumn label="Subtotal" value="productsubTotal"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
