import React from "react";
import {Card, Row, Timeline} from "antd";
import {Colors} from "../../ui-util/colors";
import _ from "lodash";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import {connect} from "react-redux";
import ExportPointComponent from "./export-point.component";
import {formatPointExport} from "../../ui-util/history-point-table";

class AvostoreDetailHistoryPointComponent extends React.PureComponent {
    render() {
        const {auditPoint, avostoreDetail} = this.props.avostoreDetailReducer

        return (
            <Card style={{width: '48%', borderWidth: 1, borderColor: Colors.darkseagreen}}>
                <Row style={{justifyContent: 'space-between'}}>
                    <h5>History Point</h5>
                    <ExportPointComponent fileName={`History_Point_${avostoreDetail?.name}`}
                                          datPoint={formatPointExport(auditPoint)}/>
                </Row>
                <Timeline mode={'left'} style={{marginTop: 30}}>
                    {
                        _.map(auditPoint, (v, k) => {
                            return (
                                <Timeline.Item key={k} color={Colors.darkseagreen}>
                                    <Card style={{borderColor: Colors.darkseagreen, borderRadius: 15}}>
                                        <p style={{marginBottom: 0}}>{v.action}</p>
                                        <p style={{marginBottom: 0}}>{`${v.prevPoint} point --> ${v.currentPoint} point`}</p>
                                        <p style={{marginBottom: 0}}>{formatUnixDateToReadable(v.createdAt, "DD-MM-YYYY HH:mm:ss")}</p>
                                        <p style={{marginBottom: 0}}>{v.orderId}</p>
                                    </Card>
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {userChainReducer, warehouseReducer, avostoreDetailReducer} = state;

    return {userChainReducer, warehouseReducer, avostoreDetailReducer};
}

export default connect(mapStateToProps, {})(AvostoreDetailHistoryPointComponent)
