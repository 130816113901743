import React from "react";
import {Button, Form, Input, Modal} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {generalDispatcher} from "../actions/general.action";
import {MODAL_REDUCER} from "../reducers/modal.reducer";
import OrderAuditState from "../models/OrderAuditState.json";
import {updateOrderDetail} from "../firebase/database/orders";
import {OrderAuditObj} from "../models/order-audit.model";
import {avoskinId, generateMd5Id, getDateEpochFormat, randomNumber} from "../ui-util/general-variable";
import {setOrderAuditToFirestore} from "../firebase/database/order-audit";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class UpdateResiModal extends React.PureComponent{
    formRef = React.createRef();

    changeResiButtonHandler = () => {
        const {orderDetailToUpdateResi} = this.props.modalReducer
        const {editResiRecord} = this.state;
        let values = {awb: orderDetailToUpdateResi.awb}
        let orderAudit = {
            orderId: editResiRecord.id,
            action: listOfKey[4],
            description: OrderAuditState.SELLER.AWB_SUBMITTED_FROM_DASHBOARD,
            awb: editResiRecord.awb,
        }
        if (Boolean(editResiRecord.awb)) {
            updateOrderDetail(editResiRecord.id, values)
                .then((r) => {
                    this.orderAudit(orderAudit)
                    this.setModalAwbVisible(false, {});
                })
                .catch((err) => {
                    this.setModalAwbVisible(false, {});
                    alert('terjadi kesalahan, gagal menyimpan data!');
                });
        } else {
            alert('resi tidak boleh kosong!');
        }
    }

    onFinish = (values) => {
        const {orderDetailToUpdateResi} = this.props.modalReducer
        updateOrderDetail(orderDetailToUpdateResi.id, values)
            .then((r) => {
                this.createOrderAudit(values.awb)
            })
            .catch((err) => {
                this.setModalAwbVisible(false, {});
                alert('terjadi kesalahan, gagal menyimpan data!');
            });
    }

    createOrderAudit = (awb) => {
        const {afterUpdateHandler} = this.props
        const {orderDetailToUpdateResi} = this.props.modalReducer
            let auditOrderDetail = {
                ...OrderAuditObj,
                orderId: orderDetailToUpdateResi.id,
                action: listOfKey[4],
                description: OrderAuditState.SELLER.AWB_SUBMITTED_FROM_DASHBOARD,
                awb: awb,
                id: generateMd5Id(randomNumber(6)),
                actorId: avoskinId(),
                actorName: 'Avoskin',
                timestamp: getDateEpochFormat()
            }

            setOrderAuditToFirestore(auditOrderDetail)
                .then(() => {
                    if(!!afterUpdateHandler){
                        afterUpdateHandler(awb)
                    }
                    alert('Resi berhasil di update!')
                    this.props.generalDispatcher(MODAL_REDUCER, {modalVisibilityResi: false})
                })
                .catch(err => {
                    console.log("setOrderAuditToFirestore", err)
                })
    }

    closeModal = () => {
        this.props.generalDispatcher(MODAL_REDUCER, {modalVisibilityResi: false})
        this.formRef.current.setFieldsValue({awb: ''})
    }

    render() {
        const {modalVisibilityResi, orderDetailToUpdateResi} = this.props.modalReducer
        return (
            <Modal
                title={`Update Resi ${orderDetailToUpdateResi.orderId}`}
                style={{top: 20}}
                visible={modalVisibilityResi}
                onCancel={() => this.closeModal()}
                footer={null}
            >
                <Form ref={this.formRef} onFinish={this.onFinish}>
                    <Form.Item
                        label="Resi"
                        name={'awb'}
                        rules={[{
                            required: true,
                            message: 'Mohon masukkan resi',
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const {modalReducer} = state;

    return {modalReducer};
};

export default connect(mapStateToProps, {generalDispatcher})(UpdateResiModal)
