import {getUserDetailFromFirestore} from "../firebase/database/user"
import {AVOSTORE_DETAIL, AVOSTORE_POINT_HISTORY} from "../reducers/avostore-detail.reducer"
import {getAuditPointDetail} from "../api/audit-point.api";

const avostoreDetailDispatcher = (type, payload) => {
    return { type, payload }
}

const getAvostoreDetail = (uid) => {
    return (dispatch => {
        getUserDetailFromFirestore(uid)
            .then(result => {
                let avostoreDetail = result.data()
                dispatch(avostoreDetailDispatcher(AVOSTORE_DETAIL, {avostoreDetail}))
            })
            .catch(err => console.log("error get data avostore detail", err))
    })
}
const getAvostorePointAudit = (uid) => {
    return (dispatch => {
        getAuditPointDetail(uid)
            .then(result => {
                dispatch(avostoreDetailDispatcher(AVOSTORE_POINT_HISTORY, {auditPoint: result.data}))
            })
            .catch(err => console.log("error getAuditPointDetail", err))
    })
}

export { avostoreDetailDispatcher, getAvostoreDetail, getAvostorePointAudit }
