const setSalesDetailTable = (rows) => {
    let selectedRow = []
    rows.forEach((row) => {
        let data = {
            orderId: row.orderId,
            name: row.shippingAddress.receiver,
            grossAmount: row.grandTotal,
            totalPaymentReceived: row.isGuarantee ? row.grandTotalGuarantee : row.grandTotal,
            cashback: row.grandCashback,
            shipping: row.shippingPrice,
            voucher: row.voucherReduction,
            pointUsage: row.discountPoint,
            netSales: row.grandTotal - row.shippingPrice,
        }
        selectedRow.push(data)
    })
    return selectedRow
}

export {setSalesDetailTable}
