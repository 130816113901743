import React, {Component} from 'react';
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportPotentialLostComponent extends Component {
    render() {
        const {selectedRow} = this.props;

        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Id Produk" value="productId"/>
                    <ExcelColumn label="Nama Produk" value="productName"/>
                    <ExcelColumn label="Kode Sku" value="skuCode"/>
                    <ExcelColumn label="Total Produk" value="potentialLostCount"/>
                    <ExcelColumn label="Total Potensi Kerugian" value="potentialLostValue"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}