import React from "react";
import {Table} from "antd";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {connect} from "react-redux";
import {IMPORT_RESI_REDUCER} from "../../reducers/import-resi.reducer";
import {orderDispatcher} from "../../actions/order.action";

class ImportResiOrderListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (text) => (<div>{text.receiver}</div>)
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                },
                {
                    title: 'Jasa Kirim',
                    dataIndex: 'shippingMethod',
                    key: 'shippingMethod'
                }, {
                    title: 'Warehouse',
                    dataIndex: 'warehouseDetail',
                    key: 'warehouseDetail',
                    render: (record) => <div>{record.name}</div>
                },
            ]
        };
    }

    onChangePagination = (current) => {
        this.props.orderDispatcher(IMPORT_RESI_REDUCER, {page: current})
    }

    onShowSize = (current, pageSize) => {
        this.props.orderDispatcher(IMPORT_RESI_REDUCER, {size: pageSize, page: 1})
    }

    onSelectRow = (selectedRowKeys, selectedRows) => {
        this.props.orderDispatcher(IMPORT_RESI_REDUCER, {selectedRowToExport: selectedRows, selectedRowKeys})
    };

    render() {
        const {columns} = this.state;
        const {orderList, totalData, page, selectedRowKeys} = this.props.importResiReducer

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                rowSelection={rowSelection}
                dataSource={orderList}
                rowKey="id"
                pagination={{
                    total: totalData,
                    onChange: this.onChangePagination,
                    current: page,
                    onShowSizeChange: this.onShowSize,
                    showSizeChanger: true
                }}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const {importResiReducer} = state;

    return {importResiReducer};
};

export default connect(mapStateToProps, {orderDispatcher})(ImportResiOrderListComponent)
