import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Col, Menu, Row, Select, Table} from 'antd';
import {Colors} from "../../ui-util/colors";
import {getAvostoreListAll, userDispatcher} from '../../actions/user.action';
import {Link} from 'react-router-dom';
import {formatUnixDateToReadable, getLevelText, isEqual} from '../../ui-util/general-variable';
import _ from 'lodash'
import ExportAvostoreListToExcel from './export-avostore-list.component';
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from '../../models/user-detail.model';
import {FILTERED_AVOSTORE_LIST} from '../../reducers/avostore-list.reducer';
import {setAvostoreDataSelectedRows} from "../../ui-util/avostore-table-util";
import SearchComponent from "../../components/search.component";

const { Option } = Select;

class AvostoreList extends PureComponent {
    constructor() {
        super()

        this.state = {
            keyword: '',
            columns: [
                {
                    title: 'Nama',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email'
                },
                { title: 'Parent ID', dataIndex: 'parentRef', key: 'parentRef' },
                {
                    title: 'Level',
                    dataIndex: 'level',
                    key: 'level',
                    width: 220,
                    render: (text) => <div>{getLevelText(text)}</div>
                },
                {
                    title: 'Join date',
                    dataIndex: 'joinDate',
                    key: 'joinDate',
                    render: (text) => <div>{formatUnixDateToReadable(text, 'll')}</div>
                },
                { title: 'Poin', dataIndex: 'point', key: 'point' },
                { title: 'Avostore area', dataIndex: 'avostoreArea', key: 'avostoreArea' },
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, item) => (
                        <React.Fragment>
                            <Link to={`/avostore-detail/${item.id}`}>
                                Detail
                            </Link>
                        </React.Fragment>
                    ),
                    width: 80
                }
            ],
            selectedFilter: 'level',
            selectedFilterToDisplay: 'Level',
            filterValue: '',
            menu: (
                <Menu>
                    <Menu.Item key="0">
                        <Button type="link" onClick={() => this.getFilteredAvostoreList('level', 'Level')}>Level</Button>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="1">
                        <Button type="link" onClick={() => this.getFilteredAvostoreList('point', 'Poin')}>Poin</Button>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="2">
                        <Button type="link"
                            onClick={() => this.getFilteredAvostoreList('avostoreArea', 'Avostore area')}>Avostore area</Button>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="3">
                        <Button type="link" onClick={() => this.getFilteredAvostoreList('joinDate', 'Tanggal bergabung')}>Tanggal bergabung</Button>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="4">
                        <Button type="link" onClick={() => this.getFilteredAvostoreList('parentId', 'Parent ID')}>Parent ID</Button>
                    </Menu.Item>
                </Menu>
            ),
            selectedRowKeys: [],
            selectedRow: [],
            avostoreListToDisplay: []
        }
    }

    componentDidMount() {
        this.props.getAvostoreListAll()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const { userReducer } = this.props;
        if (!isEqual(userReducer.id, prevProps.userReducer.id)) {
            this.props.getAvostoreListAll()
        }
    }

    getFilteredAvostoreList = (value, valueToDisplay) => {
        this.setState({ selectedFilter: value, selectedFilterToDisplay: valueToDisplay })
    }

    getDataToDisplay = () => {
        const { avostoreListAll, filteredAvostoreList } = this.props.avostoreListReducer;
        let { filterValue } = this.state
        let tempList = filteredAvostoreList.length > 0 && filterValue.length > 0 ? filteredAvostoreList : avostoreListAll

        const tempAvostoreList = _.filter(tempList, item => {
            let name = item?.name?.toLowerCase()?.includes(this.state?.keyword?.toLowerCase())
            if (!!name) {
                return item?.name?.toLowerCase()?.includes(this.state?.keyword?.toLowerCase())
            } else {
                this.setState({ isExist: false })
            }
        });

        return tempAvostoreList
    }


    handleButtonFilterClick = () => {
        let { filterValue, selectedFilter } = this.state
        const { avostoreListAll } = this.props.avostoreListReducer;

        const tempFilter = _.filter(avostoreListAll, a => {
            switch (selectedFilter) {
                case 'level':
                    let userLevel = a.level.match(filterValue)
                    if (!!userLevel) {
                        return a.level.match(filterValue)
                    }
                    break;
                default:
                    return this.setState({ isExist: false })

            }
        })

        this.props.userDispatcher(FILTERED_AVOSTORE_LIST, tempFilter)
    }

    onSearchHandler = (value) => {
        this.setState({ keyword: value })
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setAvostoreDataSelectedRows(rows)
        this.setState({ selectedRow, selectedRowKeys });
    }

    onFilterHandler = (value) => {
        this.setState({ filterValue: value })
    }

    render() {
        const { columns, selectedRowKeys, selectedRow } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };
        const {loading} = this.props.avostoreListReducer

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Avostore</h3>
                <div style={{ paddingBottom: 30 }}>
                    <Row type="flex" justify={'space-between'} align="middle">
                        <Col span={16}>
                            <Row type="flex" align="middle">
                                <Col span={20} className="d-flex justify-content-around align-items-center">
                                    filtered by:
                                    <Select defaultValue={''} style={{width: 250}} onChange={(level) => { this.onFilterHandler(level) }}>
                                        <Option value={''}>ALL</Option>
                                        <Option value={PREMIUM_2}>PREMIUM 2</Option>
                                        <Option value={PREMIUM_1}>PREMIUM 1</Option>
                                        <Option value={PLATINUM} >PLATINUM</Option>
                                        <Option value={BRONZE}>BRONZE</Option>
                                        <Option value={END_CUSTOMER}>END CUSTOMER</Option>
                                        <Option value={DISTRIBUTION_CHANNEL_1}>DISTRIBUTION CHANNEL 1</Option>
                                        <Option value={DISTRIBUTION_CHANNEL_2}>DISTRIBUTION CHANNEL 2</Option>
                                        <Option value={DISTRIBUTION_CHANNEL_3}>DISTRIBUTION CHANNEL 3</Option>
                                    </Select>
                                    <Button style={{ backgroundColor: Colors.darkseagreen, color: 'white' }} onClick={() => this.handleButtonFilterClick()}>
                                        Filter
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} >
                            <Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <SearchComponent searchItem={'avostore'} onSearchHandler={this.onSearchHandler} />
                                {
                                    selectedRow.length > 0 ? <ExportAvostoreListToExcel selectedRow={selectedRow} /> :
                                        <Button style={{ marginRight: 20 }} disabled>Export</Button>
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={this.getDataToDisplay()}
                    rowKey="id"
                    rowSelection={rowSelection}
                    loading={loading}
                    pagination={{ pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true }}
                    scroll={{ x: 1500 }}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const { userReducer, avostoreListReducer } = state;

    return { userReducer, avostoreListReducer };
};

export default connect(mapStateToProps, { userDispatcher, getAvostoreListAll })(AvostoreList);
