import React from "react";
import {Card, Col, Row} from "antd";
import {getGuaranteeDcDetailApi, getGuaranteeDetailApi} from "../../api/guarantee.api";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {getOrderDetailByOrderIdApi} from "../../api/order.api";
import {Colors} from "../../ui-util/colors";
import PaymentTerminComponent from "./payment-termin.component";

class GuaranteeDetailPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            guaranteeDetailMenu: [
                {
                    guaranteeDetailList: [
                        {
                            label: 'No. Guarantee',
                            key: 'billingId'
                        }, {
                            label: 'Nama',
                            key: 'userGuaranteeName'
                        }, {
                            label: 'Parent',
                            key: 'parent'
                        }, {
                            label: 'Status',
                            key: 'status'
                        }
                    ],
                }, {
                    guaranteeDetailList: [
                        {
                            label: 'Grand Total',
                            key: 'orderGrandTotal',
                        }, {
                            label: 'Sisa Pembayaran',
                            key: 'remainingPaymentGuarantee'
                        }, {
                            label: 'Tanggal Jatuh Tempo',
                            key: 'expiredDate'
                        },
                    ]
                }
            ],
            isUpdateTermin: false,
            orderDetail: {},
            guaranteeDetail: {},
        }
    }

    componentDidMount() {
        let guaranteeType = this.props.match.params.type

        if (guaranteeType === 'DC_INSTALLMENT') {
            this.getGuaranteeDcDetail()
        } else {
            this.getGuaranteeDetail()
        }
    }

    getGuaranteeDetail = () => {
        let requestBody = {
            id: this.props.match.params.id
        }
        getGuaranteeDetailApi(requestBody)
            .then(result => {
                this.getOrderDetail(result?.data?.orderId)
                this.setState({guaranteeDetail: result?.data, isUpdateTermin: false})
            })
            .catch(err => console.log(err))
    }

    getGuaranteeDcDetail = () => {
        let requestBody = {
            guaranteeDcId: this.props.match.params.id
        }
        getGuaranteeDcDetailApi(requestBody)
            .then(result => {
                this.getOrderDetail(result?.data?.orderId)
                this.setState({guaranteeDetail: result?.data, isUpdateTermin: true})
            })
            .catch(err => console.log(err))
    }

    getOrderDetail = (orderId) => {
        getOrderDetailByOrderIdApi(orderId)
            .then(result => {
                this.setState({orderDetail: result})
            })
            .catch(err => console.log(err))
    }

    render() {
        const {guaranteeDetail, orderDetail, guaranteeDetailMenu} = this.state
        const {orderId, orderDate} = guaranteeDetail
        return (
            <>
                <Card style={{minWidth: '90%'}}>
                    <div className="d-flex justify-content-between">
                        <h5>Guarantee Detail </h5>
                    </div>
                    <Col span={24} style={{marginBottom: 50}}>
                        <Row justify="space-between"
                             style={{marginLeft: 20, marginTop: 20, padding: 5, backgroundColor: Colors.gainsboro}}>
                            <a href={`/order-detail/${orderDetail.id}`} target="_blank"  rel="noopener noreferrer">{orderId}</a>
                            <div>Tanggal Order : {formatUnixDateToReadable(orderDate)}</div>
                        </Row>
                        <Row justify="space-between">
                            {
                                guaranteeDetailMenu.map((item, index) => (
                                    <Col span={11} style={{marginLeft: 20}} key={index.toString()}>
                                        {
                                            item.guaranteeDetailList.map((item, index) => {
                                                switch (item.key) {
                                                    case 'expiredDate':
                                                        return (
                                                            <Row key={index.toString()}
                                                                 style={{
                                                                     alignItems: 'center',
                                                                     paddingTop: 30,
                                                                     justifyContent: 'space-between'
                                                                 }}>
                                                                <h6 style={{margin: 0}}>{item.label}</h6>
                                                                {formatUnixDateToReadable(guaranteeDetail[item.key])}
                                                            </Row>
                                                        )
                                                    case 'orderGrandTotal':
                                                        return (
                                                            <Row key={index.toString()}
                                                                 style={{
                                                                     alignItems: 'center',
                                                                     paddingTop: 30,
                                                                     justifyContent: 'space-between'
                                                                 }}>
                                                                <h6 style={{margin: 0}}>{item.label}</h6>
                                                                {doConvertNumberToRupiahFormat(guaranteeDetail[item.key])}
                                                            </Row>
                                                        )
                                                    case 'remainingPaymentGuarantee':
                                                        return (
                                                            <Row key={index.toString()}
                                                                 style={{
                                                                     alignItems: 'center',
                                                                     paddingTop: 30,
                                                                     justifyContent: 'space-between'
                                                                 }}>
                                                                <h6 style={{margin: 0}}>{item.label}</h6>
                                                                <h6 style={{color : guaranteeDetail[item.key] > 0 && 'red'}}>{doConvertNumberToRupiahFormat(guaranteeDetail[item.key])}</h6>
                                                            </Row>
                                                        )
                                                    default:
                                                        return (
                                                            <Row key={index.toString()}
                                                                 style={{
                                                                     alignItems: 'center',
                                                                     paddingTop: 30,
                                                                     justifyContent: 'space-between'
                                                                 }}>
                                                                <h6 style={{margin: 0}}>{item.label}</h6>
                                                                {guaranteeDetail[item.key]}
                                                            </Row>
                                                        )
                                                }
                                            })
                                        }
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    {!!guaranteeDetail?.orderId && <PaymentTerminComponent guaranteeDetail={guaranteeDetail}/>}
                </Card>
            </>
        )
    }
}

export default GuaranteeDetailPage
