import React, {PureComponent} from 'react'
import {Card} from "antd";
import BannerFormComponent from "./banner-form.component";
import {generateMd5Id, randomNumber} from "../../ui-util/general-variable";
import {createBannerApi} from "../../api/banner.api";

class CreateBannerPage extends PureComponent {

    constructor() {
        super();

        this.state = {
            loading: false
        }
    }

    createBanner = (bannerDetail) => {
        bannerDetail.id = generateMd5Id(randomNumber(8))
        bannerDetail.isValid = true
        bannerDetail.isAvailable = !!bannerDetail.isAvailable
        createBannerApi(bannerDetail)
            .then(result => {
                this.setState({loading: false})
                alert('Banner berhasil dibuat!')
                window.location.href = '/banner/list'
            })
            .catch(err => {
                this.setState({loading: false})
                alert('Banner gagal dibuat!')
                console.log('Error create banner', err)
            })
    }

    render() {
        const {loading} = this.state
        return (
            <Card>
                <BannerFormComponent slug={'add'} submitButtonHandler={this.createBanner} loading={loading} labelCol={4}
                                     wrapperCol={20} width={'60%'} setLoadingTrue={() => this.setState({loading: true})}/>
            </Card>
        )
    }
}

export default CreateBannerPage
