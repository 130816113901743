import React from 'react';
import {Button, Form, InputNumber} from "antd";
import {Colors} from "../../ui-util/colors";
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from "../../models/user-detail.model";
import _ from 'lodash'
import isEqual from "react-fast-compare";
import {connect} from "react-redux";

class EditStockFormComponent extends React.PureComponent {

    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            purchaseLimitForm: [
                {
                    label: 'End Customer',
                    name: ['purchaseLimit', END_CUSTOMER, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Bronze',
                    name: ['purchaseLimit', BRONZE, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Platinum',
                    name: ['purchaseLimit', PLATINUM, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Premium 1',
                    name: ['purchaseLimit', PREMIUM_1, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Premium 2',
                    name: ['purchaseLimit', PREMIUM_2, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Distribution Channel 1',
                    name: ['purchaseLimit', DISTRIBUTION_CHANNEL_1, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Distribution Channel 2',
                    name: ['purchaseLimit', DISTRIBUTION_CHANNEL_2, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                },
                {
                    label: 'Distribution Channel 3',
                    name: ['purchaseLimit', DISTRIBUTION_CHANNEL_3, 'purchaseLimit'],
                    placeholder: 'Cth: 10'
                }
            ]
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!isEqual(prevProps, this.props)) {
            const {productDetail} = this.props
            this.formRef.current.setFieldsValue(productDetail)
        }
    }

    componentDidMount() {
        const {productDetail} = this.props
        this.formRef.current.setFieldsValue(productDetail)
    }

    render() {
        const {onFinish} = this.props
        const {purchaseLimitForm} = this.state
        const {loading} = this.props.productStockReducer

        return (
            <Form ref={this.formRef} labelCol={{span: 9}} wrapperCol={{span: 15}} labelAlign={'left'}
                  onFinish={onFinish}>
                <Form.Item
                    label="Stock"
                    name={'stock'}
                    rules={[{
                        required: true,
                        message: 'Please input stock',
                    }]}
                >
                    <InputNumber min={0} style={{width: 300}}
                                 placeholder={'Cth: 10'}
                    />
                </Form.Item>
                <div style={{marginBottom: '10px'}}><h6>Limit Purchase</h6></div>
                {
                    _.map(purchaseLimitForm, p => {
                        return (
                            <Form.Item
                                key={p.name}
                                label={p.label}
                                name={p.name}
                                rules={[{
                                    required: true,
                                    message: `Please input purchase limit ${p.label}`,
                                }]}
                            >
                                <InputNumber min={0} style={{width: 300}}
                                             placeholder={p.placeholder}
                                />
                            </Form.Item>
                        )
                    })
                }
                <Form.Item wrapperCol={{
                    sm: {span: 20, offset: 20},
                }}>
                    <Button
                        style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                        htmlType="submit"
                        loading={loading}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    const {productStockReducer} = state;

    return {productStockReducer};
};

export default connect(mapStateToProps,)(EditStockFormComponent);
