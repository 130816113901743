import React, {Component} from 'react';
import {Layout, Menu} from "antd";
import {Colors} from "../ui-util/colors";
import {Link} from "react-router-dom";

const {Sider} = Layout;
const {SubMenu} = Menu;

class Sidebar extends Component {
    constructor() {
        super();

        this.state = {
            menuList: [
                {
                    label: 'Pesanan',
                    name: 'orders',
                    menu: [
                        {
                            key: 'list',
                            label: 'Pesanan Saya'
                        }
                    ]
                },
                {
                    label: 'Pre Order',
                    name: 'po',
                    menu: [
                        {
                            key: 'list',
                            label: 'Daftar PO'
                        }
                    ]
                },
            ],
            operationMenuList: [
                {
                    label: 'Manifest',
                    name: 'manifest',
                    menu: [
                        {
                            key: 'create',
                            label: 'Buat Surat Jalan'
                        }, {
                            key: 'list',
                            label: 'Daftar Surat Jalan'
                        }, {
                            key: 'resi',
                            label: 'Import Resi'
                        }
                    ]
                },
            ],
            salesMenuList: [
                {
                    label: 'Penjualan Avostore',
                    name: 'orders-avostore',
                    menu: [
                        {
                            key: '',
                            label: 'Penjualan Avostore'
                        }
                    ]
                },
                {
                    name: 'gift',
                    label: 'Gift',
                    menu: [
                        {
                            key: 'create',
                            label: 'Buat Produk Gift'
                        }, {
                            key: 'list',
                            label: 'Daftar Produk Gift'
                        }, {
                            key: 'create-gift-card',
                            label: 'Buat Gift Card'
                        }, {
                            key: 'gift-card',
                            label: 'Daftar Gift Card'
                        }
                    ]
                },
                {
                    name: 'voucher',
                    label: 'Voucher',
                    menu: [
                        {
                            key: 'create',
                            label: 'Buat Voucher'
                        }, {
                            key: 'list',
                            label: 'Daftar Voucher'
                        }
                    ]
                },
                {
                    name: 'banner',
                    label: 'Banner',
                    menu: [
                        {
                            key: 'create',
                            label: 'Buat Banner'
                        }, {
                            key: 'list',
                            label: 'Daftar Banner'
                        }
                    ]
                },
                {
                    name: 'avostore-list',
                    label: 'AVO Store',
                    menu: [
                        {
                            key: 'list',
                            label: 'Daftar Avostore',
                        }, {
                            key: 'parent-ref',
                            label: 'Update Parent Ref',
                        }
                    ]
                },
                {
                    name: 'product',
                    label: 'Pengaturan Produk',
                    menu: [
                        {
                            key: 'list',
                            label: 'Daftar Produk'
                        },
                        {
                            key: 'product-stock-info',
                            label: 'Info Stok Produk'
                        },
                        {
                            key: 'create',
                            label: 'Tambah Produk'
                        },
                        {
                            key: 'product-category',
                            label: 'Kategori Produk'
                        },
                        {
                            key: 'edit-stock-product',
                            label: 'Edit Stok Produk'
                        },
                        {
                            key: 'edit-stock-product-dc',
                            label: 'Edit Stok Produk DC'
                        }
                    ]
                },
                {
                    name: 'guarantee',
                    label: 'Guarantee',
                    menu: [
                        {
                            key: 'list',
                            label: 'Guarantee List',
                        }
                    ]
                },
                {
                    name: 'notification',
                    label: 'Notifikasi',
                    menu: [
                        {
                            key: 'create',
                            label: 'Buat Notifikasi',
                        }
                    ]
                },
                {
                    name: 'potential-lost',
                    label: 'Potential Lost',
                    menu: [
                        {
                            key: 'list',
                            label: 'Potential Lost',
                        }
                    ]
                },
                {
                    name: 'analytic',
                    label: 'Analytics',
                    menu: [{
                        key: 'revenue',
                        label: 'Revenue'
                    }, {
                        key: 'product',
                        label: 'Produk'
                    }, {
                        key: 'analytics-user',
                        label: 'User'
                    }, {
                        key: 'guarantee',
                        label: 'Guarantee'
                    }, {
                        key: 'target-sales',
                        label: 'Target Sales'
                    }, {
                        key: 'top-spender',
                        label: 'Top Spender'
                    }]
                },
                {
                    name: 'settings',
                    label: 'Settings',
                    menu: [
                        {
                            key: 'minimum-order',
                            label: 'Minimum Pembelanjaan'
                        },
                        {
                            key: 'new-leads',
                            label: 'New Leads'
                        },
                        {
                            key: 'pre-order',
                            label: 'Pre Order'
                        },
                        {
                            key: 'faq',
                            label: 'FAQ'
                        },
                        {
                            key: 'progressive-margin',
                            label: 'Progressive Margin'
                        }
                    ]
                },
                {
                    name: 'email',
                    label: 'Email',
                    menu: [
                        {
                            key: 'email-audit',
                            label: 'Audit email'
                        },
                        {
                            key: 'email-setting',
                            label: 'Pengaturan email'
                        }
                    ]
                }
            ],
            financeMenuList: [
                {
                    name: 'disbursement',
                    label: 'Disbursement',
                    menu: [
                        {
                            key: 'list',
                            label: 'Daftar Disbursement',
                        }
                    ]
                },
                {
                    name: 'tax',
                    label: 'Faktur Pajak',
                    menu: [{
                        key: 'list',
                        label: 'Daftar Faktur Pajak'
                    }]
                }
            ],
            path: '/orders/'
        }
    }

    onClickMenu = (item) => {
        this.setState({path: item.key})
    }

    defineMenuList = () => {
        const {operationMenuList, salesMenuList, menuList, financeMenuList} = this.state
        const role = localStorage.getItem('AVOSTORE_ROLE');
        let productMenu = [
            {
                name: 'product',
                label: 'Pengaturan Produk',
                menu: [
                    {
                        key: 'product-stock-info',
                        label: 'Info Stok Produk'
                    }
                ]
            }
        ]
        let dcMenu = [
            {
                label: 'Penjualan DC',
                name: 'dc',
                menu: [
                    {
                        key: 'orders',
                        label: 'Penjualan DC'
                    }
                ]
            },
        ]
        let productRoleMenuList =  [
            {
                name: 'product',
                label: 'Pengaturan Produk',
                menu: [
                    {
                        key: 'list',
                        label: 'Daftar Produk'
                    },
                    {
                        key: 'product-stock-info',
                        label: 'Info Stok Produk'
                    },
                    {
                        key: 'create',
                        label: 'Tambah Produk'
                    },
                    {
                        key: 'edit-stock-product',
                        label: 'Edit Stok Produk'
                    },
                    {
                        key: 'edit-stock-product-dc',
                        label: 'Edit Stok Produk DC'
                    }
                ]
            }
        ]
        switch (role) {
            case 'T1BFUkFUSU9OQUw=':
                return menuList.concat(dcMenu.concat(operationMenuList.concat(productMenu)))
                break;
            case 'U0FMRVM=':
                return menuList.concat(salesMenuList)
                break;
            case 'RklOQU5DRQ==':
                return financeMenuList
                break;
            case 'SU5WRU5UT1JZ':
                return productRoleMenuList
                break;
            case 'QURNSU5JU1RSQVRPUg==':
                return menuList.concat(operationMenuList.concat(salesMenuList.concat(financeMenuList)))
                break;
            default:
                return []
        }
    }

    render() {
        const {path} = this.state
        const pathRaw = window.location.pathname.split('/')
        const pathName = pathRaw[1]

        return (
            <Sider style={{
                backgroundColor: Colors.gainsboro,
                paddingTop: 60,
                marginBottom: 100,
                overflow: 'auto',
                height: '93vh',
                position: 'fixed',
                left: 0,
                top: 0,
            }}>
                <Menu mode="inline" defaultOpenKeys={[pathName]} selectedKeys={[path]} onClick={this.onClickMenu}
                      style={{backgroundColor: Colors.gainsboro, paddingBottom: 100}}>
                    {this.defineMenuList().map((menu) => (
                        <SubMenu key={menu.name} title={menu.label} multiple={true}>
                            {menu.menu.map((submenu, index) => (
                                <Menu.Item key={submenu.label}>
                                    <Link to={'/' + menu.name + '/' + submenu.key}>
                                        {submenu.label}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ))}
                </Menu>
            </Sider>
        );
    }
}

export default Sidebar
