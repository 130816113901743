import React from "react";
import {Button, Card, DatePicker, Divider, Form, Input, Row, Typography, Col} from "antd";
import moment from "moment";
import {connect} from "react-redux";
import {getAnalyticsDataAction, getTargetSales, revenueDispatcher} from "../../actions/revenue.action";
import {REVENUE_DETAIL} from "../../reducers/revenue.reducer";
import {
    doConvertNumberToRupiahFormat,
    getDateEpochFormat,
    getMonthAndYear,
    percentFormat
} from "../../ui-util/general-variable";
import {setTargetSalesToFirestore} from "../../firebase/database/target-sales";
import {Colors} from "../../ui-util/colors";
import DropdownSellerComponent from "../../components/dropdown-seller.component";

const {RangePicker} = DatePicker;
const {Text} = Typography;

class RevenueHeaderComponent extends React.PureComponent {

    componentDidMount(): void {
        this.props.getTargetSales()
    }

    onFinishAddTargetSales = (values) => {
        let currentMonth = getMonthAndYear('current')
        let targetSalesDetail = {
            id: currentMonth,
            createdAt: getDateEpochFormat(),
            lastUpdate: getDateEpochFormat(),
            targetValue: values.value,
            targetAchieve: 0,
            valid: true
        }

        setTargetSalesToFirestore(currentMonth, targetSalesDetail)
            .then(result => {
                alert('Input target sale berhasil!')
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
            })
    }

    onDatePickerChange = (dates) => {
        this.props.revenueDispatcher(REVENUE_DETAIL, {startDate: dates[0], endDate: dates[1]})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {startDate, endDate, currentTargetSales, prevTargetSales, growthSale} = this.props.revenueReducer
        const {grossSales} = growthSale

        return (
            <div>
                <h3>Analytic Revenue</h3>
                <Row justify={'space-between'} align="center" style={{marginTop: 30}}>
                    <DropdownSellerComponent/>
                    <div>
                        <Text style={{marginRight: 10}}>Date Range</Text>
                        <RangePicker
                            style={{marginRight: 10}}
                            allowClear={false}
                            defaultValue={[startDate, endDate]}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            format={'DD/MM/YYYY'}/>
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                                onClick={() => this.props.getAnalyticsDataAction()}>Filter</Button>
                    </div>
                </Row>

                <Row style={{marginTop: 20}}>
                    <Col span={12}>
                        <Card style={{
                            maxWidth: '90%',
                            borderWidth: 1,
                            borderColor: Colors.gainsboro,
                            marginLeft: 30,
                            borderRadius: 7
                        }}>
                            <Row align={'start'}>
                                <div style={{marginRight: 20}}>
                                    <Text style={{fontSize: 16}}>Revenue Growth</Text>
                                    <div align={'center'} style={{marginTop: 10}}>
                                        <h5>{grossSales && percentFormat(((grossSales.current - grossSales.prev) / (grossSales.prev * 1)) * 100)}</h5>
                                    </div>
                                </div>
                                <Divider style={{height: 75, borderWidth: 1, borderColor: 'black'}} type={"vertical"}/>
                                <div>
                                    <div style={{fontSize: 16}}>This
                                        Month: {grossSales && doConvertNumberToRupiahFormat(grossSales.current)}</div>
                                    <br/>
                                    <div style={{fontSize: 16}}>Prev.
                                        Month: {grossSales && doConvertNumberToRupiahFormat(grossSales.prev)}</div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{
                            maxWidth: '90%',
                            borderWidth: 1,
                            borderColor: Colors.gainsboro,
                            marginLeft: 30,
                            borderRadius: 7
                        }}>
                            <Row align={'start'}>
                                <div style={{marginRight: 20}}>
                                    <Text style={{fontSize: 16}}>Target Sales</Text>
                                    <div align={'center'} style={{marginTop: 10}}>
                                        <h5>{grossSales && currentTargetSales && percentFormat(((grossSales.current / currentTargetSales.targetValue) * 100), 'twoDigits')}</h5>
                                    </div>
                                </div>
                                <Divider style={{height: 75, borderWidth: 1, borderColor: 'black'}} type={"vertical"}/>
                                <div>
                                    {
                                        currentTargetSales ?
                                            <>
                                                <div style={{fontSize: 16}}>This
                                                    Month: {currentTargetSales && currentTargetSales.targetValue && doConvertNumberToRupiahFormat(currentTargetSales.targetValue)}</div>
                                                <br/>
                                            </> :
                                            <Form onFinish={this.onFinishAddTargetSales}>
                                                <Row>
                                                    <Form.Item
                                                        name={'value'}
                                                        required={true}
                                                    >
                                                        <Input style={{width: 125}}/>
                                                    </Form.Item>
                                                    <Button htmlType="submit" style={{marginLeft: 10}}>OK</Button>
                                                </Row>
                                            </Form>
                                    }
                                    <div style={{fontSize: 16}}>Prev.
                                        Month: {prevTargetSales && prevTargetSales.targetValue ? doConvertNumberToRupiahFormat(prevTargetSales.targetValue) : doConvertNumberToRupiahFormat(0)}</div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {revenueReducer} = state

    return {revenueReducer}
}

export default connect(mapStateToProps, {revenueDispatcher, getTargetSales, getAnalyticsDataAction})(RevenueHeaderComponent)
