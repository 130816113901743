import {UserDetailObject} from '../models/user-detail.model';

const INITIAL_STATE = {
    parentReducer: {...UserDetailObject},
    referralReducer: [],
}

export const USER_CHAIN = 'USER_CHAIN';

export const userChainReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_CHAIN:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
