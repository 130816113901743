import React from "react";
import {Button, Card, Divider, Modal, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import {createFaqApi, getListFaqApi, updateFaqApi} from "../../api/faq.api";
import FaqForm from "./faq-form";
import {generateMd5Id, randomNumber} from "../../ui-util/general-variable";

class FaqScreen extends React.Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    key: 'question',
                    title: 'Question',
                    dataIndex: 'question',
                    width: '30%'
                }, {
                    key: 'answer',
                    title: 'Answer',
                    dataIndex: 'answer',
                    width: '50%'
                }, {
                    key: 'action',
                    render: (record) => (
                        <div>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.onEditButtonHandler(record)}>
                                Edit
                            </Button>
                            <Divider type="vertical"/>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.onRemoveButtonHandler(record)}>
                                Hapus
                            </Button>
                        </div>
                    )
                }
            ],
            faqList: [],
            isVisible: false,
            isEdit: false,
            selectedItem: {},
            isDeleteModalVisible : false
        }
    }

    componentDidMount() {
        getListFaqApi()
            .then(result => {
                this.setState({faqList: result})
            })
            .catch(err => {
                console.log("err getListFaqApi :", err)
                alert("Gagal mendapatkan data FAQ !")
            })
    }

    onEditButtonHandler = (item) => {
        this.setState({isVisible: true, selectedItem: item, isEdit: true})
    }

    onRemoveButtonHandler = (item) => {
        this.setState({isDeleteModalVisible: true, selectedItem: item})
    }

    onFinishButtonHandler = (values) => {
        const {isEdit, selectedItem} = this.state
        let tempData = {}
        if (!!isEdit) {
            tempData = {
                ...selectedItem,
                answer: values.answer,
                question: values.question
            }
        } else {
            tempData = {
                id: generateMd5Id(randomNumber(6)),
                valid: true,
                question: values.question,
                answer: values.answer
            }
        }

        if (isEdit) {
            this.doUpdateData(tempData)
        } else {
            this.onCreateNewDataFaq(tempData)
        }
    }

    onCreateNewDataFaq = (newData) => {
        createFaqApi(newData)
            .then(result => {
                alert("Faq baru berhasil dibuat !")
                this.setState({isVisible: false, selectedItem: {}, isEdit: false})
                window.location.reload()
            })
            .catch(err => {
                console.log("err createFaqApi :", err)
                alert("Gagal menyimpan data !")
            })
    }

    doUpdateData = (dataToUpdate) => {
        updateFaqApi(dataToUpdate)
            .then(result => {
                alert("Update data berhasil !")
                this.setState({isVisible: false, selectedItem: {}, isEdit: false, isDeleteModalVisible: false})
                window.location.reload()
            })
            .catch(err => {
                console.log("err updateFaqApi :", err)
                alert("Gagal update data !")
            })
    }

    onOkHandler = () => {
        const {selectedItem} = this.state
        let dataDelete = {
            ...selectedItem,
            valid: false
        }
        this.doUpdateData(dataDelete)
    }


    render() {
        const {columns, faqList, isEdit, isVisible, selectedItem, isDeleteModalVisible} = this.state

        return (
            <Card>
                <h5>Daftar FAQ</h5>
                <div align={'end'} style={{marginBottom: 20}}>
                    <Button style={{color: 'white', backgroundColor: Colors.darkseagreen}}
                            onClick={() => this.setState({
                                isVisible: true, isEdit: false
                            })}>Add New FAQ</Button>
                </div>
                <Table columns={columns} dataSource={faqList} rowKey='id'/>
                {
                    isVisible &&
                    <Modal
                        title={isEdit ? `Edit FAQ` : 'Create FAQ'}
                        visible={isVisible}
                        onCancel={() => this.setState({isVisible: false})}
                        footer={null}
                    >
                        <FaqForm onFinish={this.onFinishButtonHandler} isEdit={isEdit} selectedItem={selectedItem}/>
                    </Modal>
                }
                {
                    isDeleteModalVisible &&
                    <Modal
                        title={'Hapus FAQ'}
                        visible={isDeleteModalVisible}
                        onCancel={() => this.setState({isDeleteModalVisible: false})}
                        onOk={() => this.onOkHandler()}
                    >
                        <p>Apakah anda yakin akan menghapus faq ini ?</p>
                    </Modal>
                }
            </Card>
        );
    }
}

export default FaqScreen