import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

export const getListTransactionOfTaxApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/tax/get-transaction-list`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data.data)
        }).catch((err) => {
            reject(err)
        })
    })
}