import React, {Component} from 'react';
import {Button, Form, InputNumber, Modal, Select} from "antd";
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from "../../models/user-detail.model";

const {Option} = Select;

class ProgressiveMarginFormComponent extends Component {
    render() {
        const {modalVisibilityForm, onCancelHandler, onFinish, selectedItem, slug} = this.props

        return (
            <Modal
                title={slug === 'edit' ? 'Update Progressive Margin' : 'Create Progressive Margin'}
                visible={modalVisibilityForm}
                onCancel={onCancelHandler}
                footer={null}
            >
                <Form onFinish={onFinish}
                      initialValues={selectedItem}
                      ref={this.formRef}>
                    <Form.Item
                        key={'level'}
                        label={'Level'}
                        name={'level'}
                        rules={[{
                            required: true,
                            message: 'masukkan level',
                        }]}
                    >
                        <Select style={{width: 220}}>
                            <Option value={PREMIUM_2}>PREMIUM 2</Option>
                            <Option value={PREMIUM_1}>PREMIUM 1</Option>
                            <Option value={PLATINUM}>PLATINUM</Option>
                            <Option value={BRONZE}>BRONZE</Option>
                            <Option value={END_CUSTOMER}>END CUSTOMER</Option>
                            <Option value={DISTRIBUTION_CHANNEL_1}>DISTRIBUTION CHANNEL 1</Option>
                            <Option value={DISTRIBUTION_CHANNEL_2}>DISTRIBUTION CHANNEL 2</Option>
                            <Option value={DISTRIBUTION_CHANNEL_3}>DISTRIBUTION CHANNEL 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        key={'minPurchaseValue'}
                        label={'Minimum Pembelian'}
                        name={'minPurchaseValue'}
                        rules={[{
                            required: true,
                            message: 'masukkan minimum pembelanjaan',
                        }]}
                    >
                        <InputNumber min={0} style={{width: 300}} placeholder={'masukkan nilai minimum pembelanjaan'}/>
                    </Form.Item>
                    <Form.Item
                        extra={'Nilai margin 0 s.d 1, contoh : 0.25'}
                        key={'margin'}
                        label={'Margin'}
                        name={'margin'}
                        rules={[{
                            required: true,
                            message: 'masukkan nilai margin',
                        }]}
                    >
                        <InputNumber min="0" max="1" style={{width: 300}} placeholder={'masukkan nilai margin'}/>
                    </Form.Item>
                    <Form.Item style={{alignItems: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Simpan
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default ProgressiveMarginFormComponent;
