import React, {Component} from 'react';
import {Button, Form, Input, Modal} from "antd";
import _ from "lodash";

class OrderRequirementFormComponent extends Component {
    formRef = React.createRef();
    constructor() {
        super();
        this.state = {
            formUpdate : [
                {
                    label: 'Minimum Pembelian Produk',
                    name: 'minProductCount',
                    required: true,
                    messageAlert: 'masukkan minimum jumlah produk'
                },
                {
                    label: 'Minimum Total Pembelanjaan',
                    name: 'minValue',
                    required: true,
                    messageAlert: 'masukkan minimum total belanja'
                },
            ]
        }
    }

    render() {
        const {formUpdate} = this.state
        const {onFinish, modalVisibilityForm, onCancelHandler} = this.props

        return (
            <Modal
                title={'Update syarat minimum pembelanjaan'}
                visible={modalVisibilityForm}
                onCancel={onCancelHandler}
                footer={null}
            >
                <Form onFinish={onFinish}
                      ref={this.formRef}>
                    {
                        _.map(formUpdate, (v, k) => {
                            return (
                                <Form.Item
                                    key={k}
                                    label={v.label}
                                    name={v.name}
                                    rules={[{
                                        required: v.required,
                                        message: v.messageAlert,
                                    }]}
                                >
                                    <Input/>
                                </Form.Item>
                            )
                        })
                    }
                    <Form.Item style={{alignItems: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Simpan
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default OrderRequirementFormComponent;