import React, {PureComponent} from 'react'
import {Button, Card, Drawer, List} from "antd";
import {getLevelApi, updateRewardApi} from "../../api/level.api";
import RewardFormComponent from "./reward-form.component";
import {convertStartEndDate} from "../../ui-util/general-variable";
import {BRONZE, END_CUSTOMER} from "../../models/user-detail.model";

class RewardPage extends PureComponent {

    constructor() {
        super();

        this.state = {
            levelList: [],
            levelDetail: {},
            levelSelected: '',
            pointSelected: '',
            levelPointDrawerVisibility: false,
            addLevelDrawerVisibility: false,
        }
    }

    componentDidMount() {
        this.getLevel()
    }

    getLevel = () => {
        getLevelApi()
            .then(result => {
                this.setState({levelList: result})
            })
            .catch(err => console.log('Error getLevelApi', err))
    }

    submitButtonHandler = (rewardDetail) => {
        const {pointSelected, levelSelected} = this.state
        rewardDetail.startDate = convertStartEndDate(rewardDetail.startDate, 'start')
        rewardDetail.endDate = convertStartEndDate(rewardDetail.endDate)
        let bodyRequest = {
            levelId: levelSelected,
            rewardId: pointSelected,
            newReward: rewardDetail
        }
        updateRewardApi(bodyRequest)
            .then(() => {
                alert('Reward berhasil di update!')
                this.getLevel()
                this.setState({addLevelDrawerVisibility: false, levelPointDrawerVisibility: false})
            })
            .catch(err => {
                alert('Gagal melakukan update reward')
                console.log('Error updateRewardApi', err)
            })
    }

    render() {
        const {levelList, addLevelDrawerVisibility, levelPointDrawerVisibility, pointSelected, levelDetail} = this.state
        return (
            <Card>
                <h5 style={{marginBottom: 20, marginLeft: 20}}>User Level List</h5>
                <List
                    size="large"
                    dataSource={levelList}
                    renderItem={item => (
                        <List.Item>
                            <p>{item.name}</p>
                            <Button disabled={item.id === BRONZE || item.id === END_CUSTOMER ? true : false} onClick={() => this.setState({
                                levelPointDrawerVisibility: true,
                                levelSelected: item.id,
                                levelDetail: item
                            })}>Add Reward</Button>
                        </List.Item>
                    )}
                />
                {
                    !!levelDetail.avopoint &&
                    <Drawer
                        title={`Level Point List`}
                        width={800}
                        visible={levelPointDrawerVisibility}
                        closable={true}
                        onClose={() => this.setState({levelPointDrawerVisibility: false})}
                    >
                        <List
                            size="large"
                            dataSource={levelDetail.avopoint.points}
                            renderItem={item => (
                                <List.Item>
                                    <p>{item}</p>
                                    <Button onClick={() => this.setState({
                                        addLevelDrawerVisibility: true,
                                        pointSelected: item
                                    })}>Pilih Poin</Button>
                                    </List.Item>)}
                        />
                    </Drawer>
                }
                {
                    !!pointSelected &&
                    <Drawer
                        title={`Add Reward`}
                        width={600}
                        visible={addLevelDrawerVisibility}
                        closable={true}
                        onClose={() => {
                            this.setState({addLevelDrawerVisibility: false, pointSelected: ''})
                        }}
                    >
                        <RewardFormComponent submitButtonHandler={this.submitButtonHandler}
                                             rewardDetail={levelDetail.reward[pointSelected]}/>
                    </Drawer>
                }
            </Card>
        )
    }
}

export default RewardPage
