import {PRE_ORDER_DETAIL} from "../reducers/pre-order.reducer";
import {getPaymentPreOrderListApi, getPreOrderListApi} from "../api/pre-order.api";
import {PRE_ORDER_PAYMENT} from "../reducers/pre-order-payment.reducer";
import {convertStartEndDate} from "../ui-util/general-variable";
import {APPROVED, SUBMITTED} from "../models/order-detail.model";

const preOrderDispatcher = (type, payload) => {
    return {type, payload}
}

const getPreOrderList = () => {
    return (dispatch, getState) => {
        dispatch(preOrderDispatcher(PRE_ORDER_DETAIL, {loading: true}))
        let {activeTab, startDate, endDate} = getState().preOrderReducer

        if (activeTab === APPROVED) {
            // for remaining payment data from collection remaining_payment
            let body = {
                startDate: convertStartEndDate(startDate, 'start'),
                endDate: convertStartEndDate(endDate)
            }

            getPaymentPreOrderListApi(body)
                .then(result => {
                    dispatch(preOrderDispatcher(PRE_ORDER_DETAIL, {
                        loading: false,
                        preOrderList: [],
                        remainingPaymentList: result
                    }))
                })
                .catch(err => {
                    console.log("err :", err)
                })

        } else {
            //for PO order data from collection Order
            let requestBody = {
                orderState: activeTab === APPROVED ? SUBMITTED : activeTab, // new rule, remove approve and replace with submitted.
                startDate: convertStartEndDate(startDate, 'start'),
                endDate: convertStartEndDate(endDate)
            }

            getPreOrderListApi(requestBody)
                .then(result => {
                    dispatch(preOrderDispatcher(PRE_ORDER_DETAIL, {
                        loading: false,
                        remainingPaymentList: [],
                        preOrderList: result
                    }))
                })
                .catch(err => console.log(err))
        }
    }
}

const getPreOrderPaymentList = () => {
    return (dispatch, getState) => {
        dispatch(preOrderDispatcher(PRE_ORDER_PAYMENT, {loading: true}))
        let {activeTab, startDate, endDate} = getState().preOrderPaymentReducer
        let requestBody = {
            status: [activeTab],
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate)
        }
        getPaymentPreOrderListApi(requestBody)
            .then(result => {
                dispatch(preOrderDispatcher(PRE_ORDER_PAYMENT, {loading: false, paymentPreOrderList: result}))
            })
            .catch(err => console.log(err))
    }
}

export {preOrderDispatcher, getPreOrderList, getPreOrderPaymentList}
