import React from "react";
import ProductList from "../orders/product-list.component";
import {Button, Divider, Table, Tag} from "antd";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    getDateEpochFormat,
    randomNumber
} from "../../ui-util/general-variable";
import {Link} from "react-router-dom";
import ApproveOrderModal from "./approve-order.modal";
import {setOrderAuditApi, updateOrderDetailAPi} from "../../api/order.api";
import {APPROVED, CANCELLED, PENDING} from "../../models/order-detail.model";
import {connect} from "react-redux";
import {getDeliveryOrderList} from "../../actions/delivery-order.action";
import OrderAuditState from "../../models/OrderAuditState.json";
import {sendEmailApi} from "../../api/email.api";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import ExportExcel from "../../components/export-excel.component";
import _ from "lodash";
import {updateRelatedDataOrderFromApi} from "../../api/pre-order.api";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class DeliveryOrderListComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId', align: 'center',},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    align: 'center',
                    render: (text) => (<div>{text.receiver}</div>)
                },
                {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    align: 'center',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    align: 'center',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                },
                {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    align: 'center',
                    render: (orderState) => {
                        return (
                            <React.Fragment>
                                <Tag color={'orange'} key={orderState}>
                                    {orderState}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {
                    title: 'Aksi',
                    key: 'operation',
                    align: 'center',
                    render: (text, record) => (
                        <div>
                            <Button
                                type="link"
                                onClick={() => this.setState({
                                    orderDetail: record,
                                    modalVisible: true,
                                    modalMessage: 'Approve this order?'
                                })}
                                disabled={record.orderState !== PENDING}
                            >
                                Approve
                            </Button>
                            <Divider type="vertical"/>
                            <Button
                                type="link"
                                onClick={() => this.setState({
                                    orderDetail: record,
                                    modalVisible: true,
                                    modalMessage: 'Reject this order?'
                                })}
                                disabled={record.orderState !== PENDING}
                            >
                                Cancel
                            </Button>
                        </div>
                    )
                },
                {
                    title: 'Rincian',
                    key: 'rincian',
                    align: 'center',
                    render: (text, record) => <React.Fragment>
                        <Link to={`/order-detail/${record.id}`} target="_blank">
                            Rincian
                        </Link>
                    </React.Fragment>
                },
            ],
            columnsApproveReject: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId', align: 'center',},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    align: 'center',
                    render: (text) => (<div>{text.receiver}</div>)
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    align: 'center',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    align: 'center',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                }, {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    align: 'center',
                    render: (orderState) => {
                        return (
                            <React.Fragment>
                                <Tag color={'orange'} key={orderState}>
                                    {orderState}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {
                    title: 'Rincian',
                    key: 'rincian',
                    align: 'center',
                    render: (text, record) => <React.Fragment>
                        <Link to={`/order-detail/${record.id}`} target="_blank">
                            Rincian
                        </Link>
                    </React.Fragment>
                },
            ],
            deliveryOrderList: [],
            modalVisible: false,
            orderDetail: {},
            modalMessage: '',
            selectedRow: [],
            selectedRowKeys: []
        }
    }

    componentDidMount() {
        this.props.getDeliveryOrderList()
    }

    approveButtonHandler = () => {
        let {orderDetail, modalMessage} = this.state
        if (modalMessage.includes('Approve')) {
            orderDetail.orderState = APPROVED
            orderDetail.approvalDate = getDateEpochFormat()
            this.approvalOrderHandler(orderDetail)
        } else if (modalMessage.includes('Reject')) {
            orderDetail.orderState = CANCELLED
            this.cancelOrderHandler(orderDetail)
        }
        this.sendEmail(orderDetail)
    }

    approvalOrderHandler = (orderDetail) => {
        updateOrderDetailAPi(orderDetail)
            .then(() => {
                this.setOrderAudit(orderDetail)
                this.setModalVisibility(false)
                this.props.getDeliveryOrderList()
            })
            .catch(err => console.log("updateOrderDetailAPi :", err))
    }

    cancelOrderHandler = (orderDetail) => {
        let reqBody = {
            uid: orderDetail.sellerId,
            orderDetail
        }

        updateRelatedDataOrderFromApi(reqBody)
            .then(() => {
                this.setOrderAudit(orderDetail)
                this.setModalVisibility(false)
                this.props.getPreOrderList()
            })
            .catch(err => {
                this.setModalVisibility(false)
                console.log("updateRelatedDataOrderFromApi :", err)
            })
    }

    setOrderAudit = (orderDetail) => {
        let auditOrderDetail = {
            id: generateMd5Id(randomNumber(6)),
            orderId: orderDetail.id,
            actorId: orderDetail.buyerId,
            action: orderDetail.orderState === APPROVED ? listOfKey[5] : listOfKey[2],
            description: orderDetail.orderState === APPROVED ? OrderAuditState.SELLER.DELIVERY_ORDER_APPROVED_BY_SELLER : OrderAuditState.SELLER.ORDER_CANCELED_BY_SELLER,
            timestamp: getDateEpochFormat()
        }

        setOrderAuditApi(auditOrderDetail)
            .catch(error => {
                console.log("setOrderAuditApi :", error)
            })
    }

    sendEmail = (orderDetail) => {
        sendEmailApi(orderDetail)
            .catch(err => console.log(err))
    }

    setModalVisibility = (value) => {
        this.setState({modalVisible: value})
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setOrderDataSelectedRows(rows);
        this.setState({selectedRow, selectedRowKeys});
    };

    dataToDisplay = () => {
        const {deliveryOrderList, searchKeyword} = this.props.deliveryOrderReducer
        const deliveryOrderFilteredList = _.filter(deliveryOrderList, item =>
            item.orderId.toLowerCase().includes(searchKeyword.toLowerCase()))
        return deliveryOrderFilteredList
    }

    render() {
        const {columns, modalVisible, modalMessage, columnsApproveReject, selectedRowKeys, selectedRow} = this.state
        const {activeTab, loading} = this.props.deliveryOrderReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };
        return (
            <>
                <div style={{marginBottom: 20}} className="d-flex flex-column align-items-end">
                    {activeTab !== CANCELLED ? selectedRow.length > 0 ? <ExportExcel selectedRow={selectedRow}/> :
                        <Button disabled>Export</Button> : null}
                </div>
                <Table
                    className="components-table-demo-nested"
                    columns={activeTab === PENDING ? columns : columnsApproveReject}
                    expandedRowRender={(record) => <ProductList productList={record.products}/>}
                    dataSource={this.dataToDisplay()}
                    rowKey="id"
                    loading={loading}
                    rowSelection={activeTab !== CANCELLED && rowSelection}
                    pagination={{pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true}}
                />
                <ApproveOrderModal modalVisibility={modalVisible}
                                   setModalVisibility={this.setModalVisibility}
                                   handleOK={this.approveButtonHandler}
                                   modalMessage={modalMessage}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {deliveryOrderReducer} = state;

    return {deliveryOrderReducer};
};

export default connect(mapStateToProps, {getDeliveryOrderList})(DeliveryOrderListComponent)
