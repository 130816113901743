import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

interface UserValidateRequest {
    parentRef: String
}

export const userValidation = (requestBody: UserValidateRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/user/validate`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization' : `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data.data)
        }).catch((err) => {
            reject(err)
        })
    })
}