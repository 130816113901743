import React from "react";
import {connect} from "react-redux";
import {getVoucherList, voucherDispatcher} from "../../actions/voucher.action";
import {Card, Row} from "antd";
import VoucherListComponent from "./voucherList.component";
import VoucherDetail from "../voucher-detail";
import {VoucherDetailObj} from "../../models/voucher.model";
import SearchComponent from "../../components/search.component";
import {setVoucherAPi} from "../../api/voucher.api";

class VoucherPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            searchKey: '',
            isVoucherDetailVisible: false,
            isVoucherUpdateFormVisible: false,
            voucherDetail: {...VoucherDetailObj},
        }
    }

    componentDidMount(): void {
        this.props.getVoucherList()
    }

    onSearchVoucherHandler = (value) => {
        this.setState({searchKey: value})
    }

    onSelectSearchField = (value) => {
        this.setState({searchField: value})
    }

    setVoucherDetailVisibility = (isVoucherDetailVisible) => {
        this.setState({isVoucherDetailVisible})
    }

    deleteHandler = () => {
        const {voucherDetail } = this.props.voucherDetailReducer
        voucherDetail.valid = false
        setVoucherAPi(voucherDetail)
            .then(() => {
                window.location.href = '/voucher/list'
                this.props.getVoucherList()
                alert("Voucher berhasil di hapus !!")
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    cancelDeleteHandler = () => {
        console.log("cancel delete")
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {searchKey, isVoucherDetailVisible} = this.state

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Voucher</h3>
                <Row>
                    <SearchComponent searchItem={'voucher'} onSearchHandler={this.onSearchVoucherHandler}/>
                </Row>
                <VoucherListComponent
                    searchKey={searchKey}
                    setVoucherDetailVisibility={this.setVoucherDetailVisibility}
                    deleteHandler={this.deleteHandler}
                    cancelDeleteHandler={this.cancelDeleteHandler}
                />
                {
                    isVoucherDetailVisible &&
                    <VoucherDetail
                        isVoucherDetailVisible={isVoucherDetailVisible}
                        setVoucherDetailVisibility={this.setVoucherDetailVisibility}
                    />
                }
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {voucherDetailReducer} = state

    return {voucherDetailReducer}
}

export default connect(mapStateToProps, {getVoucherList, voucherDispatcher})(VoucherPage)
