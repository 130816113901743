import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Col} from "antd";
import moment from "moment";
import Chart from "chart.js";
import {getAnalyticsUserChartFromApi} from "../../api/analytics-user.api";
import {isEqual} from "../../ui-util/general-variable";

class AnalyticsUserChartComponent extends Component {
    constructor() {
        super();
        this.chartRef = React.createRef();

        this.state={
            startDateChart: moment().startOf('month'),
            endDateChart: moment(),
            labels: [],
            dataUsers: []
        }
    }

    componentDidMount() {
        this.fetchAnalyticsUserChart()
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if(!isEqual(this.props.analyticsUserReducer.startDate, nextProps.analyticsUserReducer.startDate) ||
            !isEqual(this.props.analyticsUserReducer.endDate, nextProps.analyticsUserReducer.endDate)){
            return true
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(!isEqual(this.props.analyticsUserReducer.startDate, prevProps.analyticsUserReducer.startDate) ||
            !isEqual(this.props.analyticsUserReducer.endDate,prevProps.analyticsUserReducer.endDate)){
            this.fetchAnalyticsUserChart()
        }
    }

    fetchAnalyticsUserChart = () => {
        const {startDate, endDate} = this.props.analyticsUserReducer
        let reqBody = {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        }

        getAnalyticsUserChartFromApi(reqBody)
            .then(result => {
                const data = result.data
                this.setState({labels: data.label, dataUsers: data.data})
                this.initiateChartData()
            })
            .catch(err => console.log("error get analytics user:", err))
    }


    initiateChartData = () => {
        const {labels, dataUsers} = this.state

        if(window.userAnalyticChart && window.userAnalyticChart !== null){
            window.userAnalyticChart.destroy()
        }

        if (dataUsers.length > 0) {
            window.userAnalyticChart = new Chart(this.chartRef.current, {
                type: 'pie',
                data : {
                    datasets: [{
                        data: dataUsers,
                        fill: true,
                        backgroundColor: [
                            '#83ae4d',
                            '#f8e04d',
                            '#ff4c20',
                            '#9acdfe',
                        ],
                        borderColor:	[
                            '#83ae4d',
                            '#f8e04d',
                            '#ff4c20',
                            '#9acdfe',
                        ],
                        borderWidth: [2,2,2,2]
                    }],
                    labels,
                }
            });
        }
    }

    render() {
        return (
            <Col span={12} >
                <Card>
                    <canvas style={{maxWidth: 600, minHeight: 200}} ref={this.chartRef}/>
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsUserReducer} = state
    return {analyticsUserReducer};
}

export default connect(mapStateToProps,)(AnalyticsUserChartComponent);
