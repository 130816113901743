import React from "react";
import {Button, Col, Drawer, Dropdown, Image, Menu, Row, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import ProductStockFormEditComponent from "../../components/product-stock-form-edit.component";
import {connect} from "react-redux";
import {
    getProductStockList,
    getWarehouseProductStockList,
    productStockDispatcher
} from "../../actions/product-stock.action";
import {PRODUCT_STOCK_LIST, SET_LOADING_PRODUCT_STOCK} from "../../reducers/product-stock.reducer";
import {updateProductStockApi} from "../../api/product-stock";
import isEqual from "react-fast-compare";
import EditStockFormComponent from "./edit-stock-form.component";
import {ProductDetailObj} from "../../models/product.model";
import _ from "lodash";
import SearchComponent from "../../components/search.component";

class ProductStockListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',

                    render: (image) => <Image src={image ? image : require('../../images/no-image.png')} width={100} height={100}/>
                }, {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                }, {
                    title: 'Stok',
                    dataIndex: 'stock',
                    key: 'stock',
                    editable: true,
                }, {
                    title: 'Action',
                    key: 'action',
                    render: (record) => this.actionButton(record)
                }
            ],
            editingId: '',
            purchaseLimitObj: {
                BRONZE: {purchaseLimit: 0},
                PLATINUM: {purchaseLimit: 0},
                PREMIUM_1: {purchaseLimit: 0},
                PREMIUM_2: {purchaseLimit: 0},
                DISTRIBUTION_CHANNEL_1: {purchaseLimit: 0},
                DISTRIBUTION_CHANNEL_2: {purchaseLimit: 0},
                DISTRIBUTION_CHANNEL_3: {purchaseLimit: 0},
                END_CUSTOMER: {purchaseLimit: 0},
            },
            editStockModalVisible: false,
            productDetail: {...ProductDetailObj},
            keyword: '',
        }
    }

    componentDidMount() {
        const {productListType} = this.props
        this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loadingTable: true})
        this.props.getWarehouseProductStockList(productListType)
    }

    actionButton = (record) => {
        const {editingId} = this.state
        return (
            <Button
                disabled={editingId !== ''}
                style={{
                    marginRight: 10,
                    width: 100,
                    backgroundColor: editingId !== '' ? null : Colors.gainsboro,
                    borderColor: editingId !== '' ? null : Colors.darkseagreen,
                    borderRadius: 5
                }}
                onClick={() => this.editButtonHandler(record)}
            >Edit</Button>
        )
    }

    editButtonHandler = (record) => {
        if(!record.purchaseLimit){
            record.purchaseLimit = {...this.state.purchaseLimitObj}
        }
        this.setState({editStockModalVisible: true, productDetail: record})
    }

    isEditing = (record) => {
        let {editingId} = this.state
        return record.id === editingId
    }

    EditableCell = () => ({editing, dataIndex, title, inputType, record, index, children, ...restProps}) => {
        const {productDetail} = this.state
        return (
            <td {...restProps}>
                {editing ? (
                    <ProductStockFormEditComponent productDetail={productDetail}/>
                ) : (
                    children
                )}
            </td>
        );
    };

    dropDownWarehouse = () => {
        let {warehouseList} = this.props.warehouseReducer
        return (
            <Menu>
                {
                    warehouseList.map((warehouse) => (
                        <Menu.Item key={warehouse.id}>
                            <Button type="link"
                                    onClick={() => this.warehouseButtonHandler(warehouse)}>{warehouse.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    warehouseButtonHandler = (warehouseDetail) => {
        this.props.productStockDispatcher(PRODUCT_STOCK_LIST, {warehouseDetail: warehouseDetail, loadingTable: true})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {productListType} = this.props
        if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)){
            this.props.getProductStockList(productListType)
        }
    }

    onFinishHandler = (value) => {
        this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loading: true})
        const {productListType, productStockReducer} = this.props
        const {id} = productStockReducer.warehouseDetail
        const {productDetail} = this.state
        let bodyRequest = {
            warehouseId: id,
            productListType,
            ...value
        }

        updateProductStockApi(productDetail.id, bodyRequest)
            .then(r => {
                this.setState({editStockModalVisible: false})
                this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loadingTable: true, loading: false})
            })
            .catch(error => {
                console.log("onFinishHandler updateProductStockApi", error)
                this.props.productStockDispatcher(SET_LOADING_PRODUCT_STOCK, {loading: false})
                this.setState({editStockModalVisible: false})
            })
    }

    onSearchProductHandler = (value) => {
        this.setState({keyword: value})
    }

    getDataToDisplay = () => {
        const {productStockList} = this.props.productStockReducer
        const productStockListToDisplay = _.filter(productStockList, item => {
            let name = item.name.toLowerCase().includes(this.state.keyword.toLowerCase())
            if (!!name) {
                return item.name.toLowerCase().includes(this.state.keyword.toLowerCase())
            }
        });
        return productStockListToDisplay
    }

    onCloseButtonHandler = () => {
        this.setState({editStockModalVisible: false})
    }

    render() {
        const {columns, editStockModalVisible, productDetail} = this.state
        const {loadingTable} = this.props.productStockReducer
        let productStockList = this.getDataToDisplay()

        const mergedColumns = columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === 'stock' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
        const {warehouseDetail} = this.props.productStockReducer

        return (
            <>
                <Row justify={'space-between'}>
                    <div style={{paddingTop: 10}}>
                        <Dropdown overlay={this.dropDownWarehouse()} trigger={['click']}>
                            <Button
                                style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 20}}
                                size="large"
                                theme="filled"
                            >
                                {warehouseDetail.name}
                            </Button>
                        </Dropdown>
                    </div>
                    <SearchComponent searchItem={'produk'} onSearchHandler={this.onSearchProductHandler} />
                </Row>
                <Table
                    components={{
                        body: {
                            cell: this.EditableCell(),
                        },
                    }}
                    className="components-table-demo-nested"
                    columns={mergedColumns}
                    dataSource={productStockList}
                    rowClassName="editable-row"
                    pagination={false}
                    rowKey={'id'}
                    loading={loadingTable}
                />
                <Drawer
                    title={'Edit Stock'}
                    width={700}
                    onClose={this.onCloseButtonHandler}
                    visible={editStockModalVisible}
                >
                    <EditStockFormComponent onFinish={this.onFinishHandler}
                                            productDetail={productDetail}
                    />
                </Drawer>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {productStockReducer, warehouseReducer} = state;

    return {productStockReducer, warehouseReducer};
};

export default connect(mapStateToProps, {
    getWarehouseProductStockList,
    productStockDispatcher,
    getProductStockList
})(ProductStockListComponent)
