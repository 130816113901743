import React from "react";
import {Button, Col, Drawer, Image, Row} from "antd";
import {connect} from "react-redux";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import EmailListComponent from "./email-list.component";

class VoucherDetail extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            voucherDetailList: [
                {key: 'image', name: 'Gambar'},
                {key: 'voucherName', name: 'Nama Voucher'},
                {key: 'voucherCode', name: 'Kode Voucher'},
                {key: 'startDate', name: 'Tanggal Mulai'},
                {key: 'endDate', name: 'Tanggal Berakhir'},
                {key: 'voucherValue', name: 'Nilai Voucher'},
                {key: 'voucherValueType', name: 'Tipe Nilai Voucher'},
                {key: 'voucherType', name: 'Jenis Voucher'},
                {key: 'maximumTotalLimit', name: 'Total Max. Penggunaan'}, //for general promo
                {key: 'maximumTotalLimitPerUser', name: 'Total Max. Penggunaan (per user)'},
                {key: 'minimumPurchasePerOrder', name: 'Min. Pembelian (per order)'},
                {key: 'minimumPurchasePerMonth', name: 'Min. Pembelian (per month)'},
                {key: 'minimumProductCountPerOrder', name: 'Min. Jumlah Produk (per order)'},
                {key: 'maximumProductCountPerOrder', name: 'Max. Jumlah Produk (per order)'},
                {key: 'description', name: 'Deskripsi'},
                {key: 'paymentMethod', name: 'paymentMethod'},
                {key: 'userLevelList', name: 'userLevelList'},
                {key: 'maximumUseCount', name: 'Jumlah Max. Penggunaan'}, //for general promo
            ],
            isEmailListVisible: false
        }
    }

    setEmailListVisibility = (isEmailListVisible) => {
        this.setState({isEmailListVisible})
    }

    renderItemValue(item) {
        const {voucherDetail} = this.props.voucherDetailReducer;

        switch (item.key) {
            case 'image':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <Image src={voucherDetail[item.key]} width={100} height={100}/>
                        </Col>
                    </Row>
                )
            case 'startDate':
            case 'endDate':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{formatUnixDateToReadable(voucherDetail[item.key], 'ddd, DD MMM YYYY HH:mm')}</p>
                        </Col>
                    </Row>
                )
            case 'voucherValue':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>
                                {voucherDetail['voucherType'] === 'nominal' || voucherDetail['voucherType'] === 'free_shipping_nominal' ?
                                    doConvertNumberToRupiahFormat(voucherDetail[item.key]) :
                                    `${voucherDetail[item.key]}%`}
                            </p>
                        </Col>
                    </Row>
                )
            case 'minimumPurchasePerOrder':
                return (
                    <Row key={item.key} gutter={16}>
                        {
                            voucherDetail[item.key] > 0 &&
                            <>
                                <Col className={'gutter-row'} span={10}>
                                    <p>{item.name}</p>
                                </Col>
                                <Col className={'gutter-row'} span={14}>
                                    <p>
                                        {doConvertNumberToRupiahFormat(voucherDetail[item.key])}
                                    </p>
                                </Col>
                            </>
                        }
                    </Row>
                )
            case 'maximumTotalLimitPerUser':
            case 'maximumTotalLimit':
            case 'maximumProductCountPerOrder':
            case 'minimumProductCountPerOrder':
                return (
                    <Row key={item.key} gutter={16}>
                        {
                            voucherDetail[item.key] && <>
                                <Col className={'gutter-row'} span={10}>
                                    <p>{item.name}</p>
                                </Col>
                                <Col className={'gutter-row'} span={14}>
                                    <p>
                                        {voucherDetail[item.key]}
                                    </p>
                                </Col>
                            </>
                        }
                    </Row>
                )
            case 'maximumUseCount':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{voucherDetail[item.key]}</p>
                        </Col>
                    </Row>
                )
            default:
                if(!!voucherDetail[item.key]){
                    return (
                        <Row key={item.key} gutter={16}>
                            <Col className={'gutter-row'} span={10}>
                                <p>{item.name}</p>
                            </Col>
                            <Col className={'gutter-row'} span={14}>
                                <p>{voucherDetail[item.key]}</p>
                            </Col>
                        </Row>
                    )
                } else {
                    return null
                }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {setVoucherDetailVisibility, isVoucherDetailVisible,} = this.props
        const {voucherDetailList, isEmailListVisible} = this.state;
        const {voucherDetail} = this.props.voucherDetailReducer;

        return (
            <Drawer
                title={'Voucher Detail'}
                width={700}
                onClose={() => setVoucherDetailVisibility(false)}
                visible={isVoucherDetailVisible}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        {voucherDetailList.map(item => this.renderItemValue(item))}
                    </Col>
                </Row>
                <EmailListComponent
                    emailList={voucherDetail.emailList}
                    isEmailListVisible={isEmailListVisible}
                    setEmailListVisibility={this.setEmailListVisibility}
                />
                {
                    voucherDetail.emailList.length > 0 &&
                    <div>
                        <Button onClick={() => this.setEmailListVisibility(true)}>Show Email</Button>
                    </div>
                }
            </Drawer>
        )
    }
}

const mapStateToProps = (state) => {
    const {voucherDetailReducer} = state

    return {voucherDetailReducer}
}

export default connect(mapStateToProps, {})(VoucherDetail)
