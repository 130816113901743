import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const uploadGiftItemImageApi = (requestBody) => {
    //reform multipart data to do upload image and set data
    const newReqBody = new FormData()
    newReqBody.set('id', requestBody.id)
    newReqBody.append('image', requestBody.image)

    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/upload-gift-product-image`,
            newReqBody,
            {
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-type': 'multipart/form-data',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const createGiftItemApi = (requestBody) => {
    //reform multipart data to do upload image and set data
    const newReqBody = new FormData()
    newReqBody.set('id', requestBody.id)
    newReqBody.set('valid', requestBody.valid)
    newReqBody.set('itemName', requestBody.itemName)
    newReqBody.set('description', requestBody.description)
    newReqBody.set('stock', requestBody.stock)
    newReqBody.set('weight', requestBody.weight)
    newReqBody.set('created_at', requestBody.created_at)
    newReqBody.append('image', requestBody.image)

    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/create-gift-product`,
            newReqBody,
            {
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-type': 'multipart/form-data',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const updateGiftItemApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/update-gift-product`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const getGiftItemListApi = () => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/get-list-gift-product`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const deleteGiftItemApi = (reqBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/delete-gift-product`,
            reqBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

const getDetailGiftItemApi = (reqBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/admin/gift/get-detail-gift-product`,
            reqBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
            }
        ).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export {
    uploadGiftItemImageApi,
    createGiftItemApi,
    updateGiftItemApi,
    getGiftItemListApi,
    deleteGiftItemApi,
    getDetailGiftItemApi
}
