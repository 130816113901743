import React, {Component} from 'react';
import {Button, Form, InputNumber, Select, Space} from "antd";
import _ from "lodash";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const {Option} = Select;

class GiftProductListFormComponent extends Component {
    render() {
        const {item, id, giftCardDetail, index, productList} = this.props

        return (
            <Form.List style={{border: '2px solid red'}} label={item.label} name={item.key} key={index.toString()}>
                {(itemList, {add, remove}) => (
                    <>
                        {
                            !!id &&
                            <Form.Item
                                key={index.toString()}
                                label={'Daftar Minimum Produk'}
                                name={'productEdit'}
                            >
                                {_.map(giftCardDetail.minimumProductOrdered, item => {
                                    return (
                                        <div style={{width: 700}}
                                            key={item.id}>{`${item.name}, min qty : ${item.minQty}, kelipatan : ${item.minimumMultiplyValue}`}</div>
                                    )
                                })}
                            </Form.Item>
                        }
                        {itemList.map(({key, name, fieldKey}) => (
                            <Space key={key} style={{
                                marginBottom: 8,
                                marginLeft: '15%',
                                width: '100%'
                            }}
                                   align="baseline">
                                <Form.Item
                                    style={{width: 500}}
                                    label={item.label}
                                    name={[name, 'id']}
                                    fieldKey={[fieldKey, 'id']}
                                    rules={[{
                                        required: !id ? true : false,
                                        message: item.placeholder,
                                    }]}
                                >
                                    <Select
                                        style={{marginRight: 10, marginLeft: 10}}
                                        placeholder={item.placeholder}
                                    >
                                        {
                                            productList.map((item, index) => (
                                                <Option value={item?.id}
                                                        key={index.toString()}>{item?.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={'Qty'}
                                    style={{width: 150}}
                                    name={[name, 'minQty']}
                                    fieldKey={[fieldKey, 'minQty']}
                                    rules={[{
                                        required: true,
                                        message: item.placeholder,
                                    }]}
                                >
                                    <InputNumber min="0" />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 250}}
                                    label={'Kelipatan'}
                                    name={[name, 'minimumMultiplyValue']}
                                    fieldKey={[fieldKey, 'minimumMultiplyValue']}
                                    rules={[{
                                        required: true,
                                        message: item.placeholder,
                                    }]}
                                >
                                    <InputNumber min="0" style={{marginLeft: 25}}/>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)}/>
                            </Space>
                        ))}
                        <Form.Item wrapperCol={{offset: 8}}>
                            <Button type="dashed" onClick={() => add()}
                                    icon={<PlusOutlined/>}>
                                {!!id ? `Edit Produk` : `Tambahkan Produk`}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        );
    }
}

export default GiftProductListFormComponent;