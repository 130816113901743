import {
    getAvostoreListFromFirestore,
    getUserDetailByParentRef,
    getUserDetailByReferral
} from '../firebase/database/user'
import {extractQuerySnapshotToArray} from '../ui-util/general-variable'
import {AVO_STORE_LIST_REDUCER, AVOSTORE_LIST_ALL, SET_LOADING_AVOSTORE_LIST} from '../reducers/avostore-list.reducer';
import {USER_CHAIN} from '../reducers/user-chain.reducer';
import {DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3} from "../models/user-detail.model";
import {getUserListApi} from "../api/user.api";

const userDispatcher = (type, payload) => {
    return {type, payload}
}

const getAvostoreListAll = () => {
    return (dispatch) => {
        dispatch(userDispatcher(SET_LOADING_AVOSTORE_LIST, true))
        getAvostoreListFromFirestore()
            .then(querySnapshot => {
                const result = extractQuerySnapshotToArray(querySnapshot)
                dispatch(userDispatcher(AVOSTORE_LIST_ALL, result))
                dispatch(userDispatcher(SET_LOADING_AVOSTORE_LIST, false))
            })
            .catch(error => {
                dispatch(userDispatcher(SET_LOADING_AVOSTORE_LIST, false))
                console.log("error get avostore list from firestore", error)
            })
    }
}

const getAvostoreChain = (parentRef, referralId) => {
    return (dispatch) => {
        Promise.all([
            getUserDetailByReferral(parentRef),
            getUserDetailByParentRef(referralId)
        ])
            .then(chain => {
                const parent = extractQuerySnapshotToArray(chain[0])
                const child = extractQuerySnapshotToArray(chain[1])

                if (parent.length > 0) {
                    dispatch(userDispatcher(USER_CHAIN, {parentReducer: parent[0], referralReducer: child}))
                }
            })
            .catch(err => console.log("error get avostore chain data :", err))
    }
}

const getUserListByLevel = (slug) => {
    return (dispatch) => {
        //create api to provide user id, user name for DC level.
        getUserListApi({level: [DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_1, DISTRIBUTION_CHANNEL_3]})
            .then(result => {
                let avoStoreDcList = slug === 'report' ? result : [{id: '', name: 'ALL'}, ...result]
                dispatch(userDispatcher(AVO_STORE_LIST_REDUCER, {avoStoreDcList}))
            })
            .catch(err => console.log("error get avostore chain data :", err))
    }
}

export {userDispatcher, getAvostoreChain, getAvostoreListAll, getUserListByLevel}
