import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {MANIFEST_REDUCER} from "../reducers/manifest.reducer";
import {generalDispatcher} from "../actions/general.action";

class DropdownAvoskinDcComponent extends React.PureComponent{

    dropDownUser = () => {
        return (
            <Menu>
                {
                    ['Avoskin', 'DC'].map((user) => (
                        <Menu.Item key={user}
                                   onClick={() => this.props.generalDispatcher(MANIFEST_REDUCER, {
                                       manifestUser: user,
                                       selectedRowKeys: [],
                                       selectedPrint: [],
                                       selectedRowExcel: [],
                                       shippingMethod: 'Semua'
                                   })}>
                            <div>{user}</div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    render() {
        const {manifestUser} = this.props.manifestReducer
        return (
            <Dropdown overlay={() => this.dropDownUser()} trigger={['click']}>
                <Button
                    style={{backgroundColor: Colors.darkseagreen, color: 'white', width: '10%'}}
                    theme="filled"
                >
                    {manifestUser}
                </Button>
            </Dropdown>
        )
    }
}

const mapStateToProps = state => {
    const {manifestReducer} = state;

    return {manifestReducer}
}

export default connect(mapStateToProps, {generalDispatcher})(DropdownAvoskinDcComponent)
