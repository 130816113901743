import React from "react";
import {Button, Card, DatePicker, Row, Table} from 'antd';
import {Colors} from "../../ui-util/colors";
import {
    convertStartEndDate,
    doConvertNumberToRupiahFormat,
    getLevelText,
    isEqual
} from "../../ui-util/general-variable";
import moment from "moment";
import {getUserTopSpender} from "../../api/analytics-user.api";
import ExportTopSpenderComponent from "./export-top-spender.component";
import {formatTopSpenderExport} from "../../ui-util/top-spender-table.util";
import {Link} from "react-router-dom";

const {RangePicker} = DatePicker;

class TopSpenderPage extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'userDetail',
                    key: 'name',
                    render: (userDetail) => userDetail && userDetail.name
                }, {
                    title: 'Level',
                    dataIndex: 'userDetail',
                    key: 'level',
                    render: (userDetail) => userDetail && getLevelText(userDetail.level)
                }, {
                    title: 'Avostore Area',
                    dataIndex: 'userDetail',
                    key: 'avostoreArea',
                    render: (userDetail) => userDetail && userDetail.avostoreArea
                }, {
                    title: 'Pay Amount',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (grandTotal) => doConvertNumberToRupiahFormat(grandTotal)
                },
                {
                    title: 'Top 3 Products',
                    dataIndex: 'topProducts',
                    key: 'products',
                    render: (topProducts) => (
                        <div>
                            {topProducts.map((item, index) => <div
                                key={index}>{index + 1}. {item.name} ({item.count})</div>)}
                        </div>
                    )
                },
                {
                    title: 'Aksi',
                    dataIndex: 'buyerId',
                    key: 'operation',
                    render: (buyerId) => (
                        <React.Fragment>
                            <Link to={`/avostore-detail/${buyerId}`}>
                                Detail
                            </Link>
                        </React.Fragment>
                    ),
                    width: 80,
                }
            ],
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            topSpenderList: [],
            formattedTopSpender: [],
            showPerPage: 10,
            page: 0,
            dataCount: 0,
            currentPage: 1,
            loading: false
        }
    }

    componentDidMount() {
        this.getTopSpenderData()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!isEqual(this.state.page, prevState.page)
            || !isEqual(this.state.showPerPage, prevState.showPerPage)
            || !isEqual(this.state.currentPage, prevState.currentPage)
        ) {
            this.getTopSpenderData()
        }
    }

    getTopSpenderData = () => {
        const {startDate, endDate, showPerPage, currentPage} = this.state
        let bodyRequest = {
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate)
        }
        const params = {showPerPage, page: currentPage - 1}

        this.setState({loading: true})
        getUserTopSpender(bodyRequest, params)
            .then(result => {
                const formattedTopSpender = formatTopSpenderExport(result.data.topSpenders)
                this.setState({
                    topSpenderList: result.data.topSpenders,
                    dataCount: result.data.dataCount,
                    formattedTopSpender,
                    loading: false
                })
            })
            .catch(err => {
                console.log("err get data :", err)
                this.setState({loading: false})
            })
    }

    onDatePickerChange = (dates) => {
        this.setState({startDate: dates[0], endDate: dates[1]})
    }

    onChangePagination = (current) => {
        this.setState({currentPage: current})
    }

    onShowSize = (current, pageSize) => {
        this.setState({showPerPage: pageSize, currentPage: 1})
    }

    render() {
        const {columns, startDate, endDate, topSpenderList, formattedTopSpender, dataCount, currentPage, loading} = this.state
        return (
            <Card>
                <div>
                    <div style={{marginBottom: 10}}>Date range</div>
                    <Row justify="space-between">
                        <Row style={{marginBottom: 20}}>
                            <RangePicker
                                defaultValue={[startDate, endDate]}
                                format={'DD/MM/YYYY'}
                                disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                                onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            />
                            <Button style={{
                                marginLeft: 20,
                                color: 'white',
                                backgroundColor: Colors.darkseagreen
                            }} onClick={() => this.getTopSpenderData()}>Filter</Button>
                        </Row>
                        <ExportTopSpenderComponent button={<Button
                            style={{color: 'white', backgroundColor: Colors.darkseagreen}}>Export</Button>}
                                                   topSpenderList={formattedTopSpender}/>
                    </Row>
                </div>
                <Table
                    dataSource={topSpenderList}
                    extraData={topSpenderList}
                    columns={columns}
                    loading={loading}
                    rowKey={'buyerId'}
                    pagination={{
                        total: dataCount,
                        onChange: this.onChangePagination,
                        current: currentPage,
                        onShowSizeChange: this.onShowSize,
                        showSizeChanger: true
                    }}
                />
            </Card>
        )
    }
}

export default TopSpenderPage
