import React from 'react';
import {connect} from 'react-redux';
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../../models/order-detail.model";
import {Table, Tag} from "antd";
import {Link} from "react-router-dom";
import ProductList from "../orders/product-list.component";
import {ORDER_LIST} from "../../reducers/order.reducer";
import {orderDispatcher} from "../../actions/order.action";

class OrderAvostoreListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Pembeli',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (text) => <div>{text.receiver}</div>
                },
                {
                    title: 'Penjual',
                    dataIndex: 'sellerName',
                    key: 'sellerName',
                },
                {
                    title: 'Email Penjual',
                    dataIndex: 'sellerEmail',
                    key: 'sellerEmail',
                },
                {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (orderDate) => <div>{formatUnixDateToReadable(orderDate, 'll')}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (grandTotal) => <div>{doConvertNumberToRupiahFormat(grandTotal)}</div>
                }, {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    render: (text, record) => {
                        let color = 'red';
                        let label = 'BELUM DIBAYAR';
                        let keyAwb = 'false';
                        let colorAwb = 'red';
                        let labelAwb = 'BELUM RESI';

                        if (text === SUBMITTED) {
                            if (record.paymentDetail.transactionStatus === 'paid') {
                                color = 'yellow';
                                label = 'SUDAH DIBAYAR';
                            }
                        } else if (text === CANCELLED) {
                            color = 'black';
                            label = 'DIBATALKAN';
                        } else if (text === COMPLETED) {
                            color = 'green';
                            label = 'SELESAI';
                        } else if (text === DELIVERY) {
                            color = 'blue';
                            label = 'SEDANG DIKIRIM';
                        } else if (text === PACKAGING) {
                            color = 'orange';
                            label = 'SEDANG DIPROSES';
                        }

                        if (Boolean(record.awb)) {
                            keyAwb = 'true';
                            colorAwb = 'green';
                            labelAwb = 'SUDAH RESI';
                        }

                        return (
                            <React.Fragment>
                                <Tag color={color} key={text}>
                                    {label}
                                </Tag>
                                <Tag color={colorAwb} key={keyAwb}>
                                    {labelAwb}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {title: 'Jasa Kirim', dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, record) => (
                        <React.Fragment>
                            <Link to={`/order-detail/${record.id}`}>
                                Rincian
                            </Link>
                        </React.Fragment>
                    )
                }
            ],
            orderList: [],
            modalAwbVisible: false,
            editResiRecord: {},
            manifestData: {},
            currentPage: 1
        };
    }

    onShowSize = (current, pageSize) => {
        this.props.orderDispatcher(ORDER_LIST, {pageSize: pageSize, currentPage: 1})
    }

    render() {
        const {onSelectRow, selectedRowKeys, changePage, orderReducer, orderList} = this.props;
        const {columns} = this.state;
        const {currentPage, dataCount, loading} = orderReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                expandedRowRender={(record) => <ProductList productList={record.products}/>}
                dataSource={orderList}
                rowKey="id"
                loading={loading}
                rowSelection={rowSelection}
                pagination={{
                    total: dataCount,
                    onChange: changePage,
                    current: currentPage,
                    onShowSizeChange: this.onShowSize,
                    showSizeChanger: true
                }}
                scroll={{x: 1500}}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const {orderReducer} = state
    return {orderReducer};
}

export default connect(mapStateToProps, {orderDispatcher})(OrderAvostoreListComponent);
