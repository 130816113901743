import React, {Component} from 'react';
import {Button, Form, InputNumber, Modal} from "antd";
import _ from "lodash";

class NewLeadsEditFormComponent extends Component {
    formRef = React.createRef();
    constructor() {
        super();
        this.state = {
            formNewLead : [
                {
                    label: 'Minimum Pembelian Produk',
                    name: 'minProductCount',
                    required: true,
                    messageAlert: 'masukkan minimum jumlah produk'
                },
                {
                    label: 'Minimum Total Pembelanjaan',
                    name: 'minValue',
                    required: true,
                    messageAlert: 'masukkan minimum total belanja'
                },
            ],
            formPreOrder : [
                {
                    label: 'Minimum Pembelian Produk',
                    name: 'minProductCount',
                    required: true,
                    messageAlert: 'masukkan minimum jumlah produk'
                }
            ]
        }
    }

    render() {
        const {onFinish, modalVisibilityForm, onCancelHandler, slug} = this.props
        const {formNewLead, formPreOrder} = this.state
        const formItem = slug === "pre-order" ? formPreOrder : formNewLead

        return (
            <Modal
                title={'Update minimum pembelanjaan New Leads'}
                visible={modalVisibilityForm}
                onCancel={onCancelHandler}
                footer={null}
            >
                <Form onFinish={onFinish}
                      ref={this.formRef}>
                    {
                        _.map(formItem, (v, k) => {
                            return (
                                <Form.Item
                                    key={k}
                                    label={v.label}
                                    name={v.name}
                                    rules={[{
                                        required: v.required,
                                        message: v.messageAlert,
                                    }]}
                                >
                                    <InputNumber min="0" style={{width: 250}}
                                                 placeholder={v.placeholder}/>
                                </Form.Item>
                            )
                        })
                    }
                    <Form.Item style={{alignItems: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Simpan
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default NewLeadsEditFormComponent;