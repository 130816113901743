import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {DROPDOWN_SELLER_REDUCER} from "../reducers/dropdown-seller.reducer";
import {orderDispatcher} from "../actions/order.action";

class DropdownSellerComponent extends React.PureComponent{

    dropDownUser = () => {
        return (
            <Menu>
                {
                    ['All', 'DC', 'Avoskin'].map((user) => (
                        <Menu.Item key={user} onClick={() => {
                            this.props.orderDispatcher(DROPDOWN_SELLER_REDUCER, {seller: user})
                        }}>
                            <div>{user}</div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    componentWillUnmount() {
        this.props.orderDispatcher(DROPDOWN_SELLER_REDUCER, {seller: 'All'})
    }

    render() {
        const {seller} = this.props.dropdownSellerReducer
        return (
            <Dropdown overlay={() => this.dropDownUser()} trigger={['click']}>
                <Button
                    style={{backgroundColor: Colors.darkseagreen, color: 'white', width: '10%'}}
                    theme="filled"
                >
                    {seller}
                </Button>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state) => {
    const {dropdownSellerReducer} = state;

    return {dropdownSellerReducer};
};

export default connect(mapStateToProps, {orderDispatcher})(DropdownSellerComponent)
