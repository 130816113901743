import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().startOf('month'),
    endDate: moment(),
    currentTargetSales: {},
    prevTargetSales: {},
    targetSalesList: [],
    targetSalesDate: '',
    revenueData: {},
    chartData: null,
    salesDataList: [],
    growthSale: {}
}

export const REVENUE_DETAIL = 'REVENUE_DETAIL'
export const TARGET_SALES = 'TARGET_SALES'
export const TARGET_SALES_DATE = 'TARGET_SALES_DATE'
export const REVENUE_REDUCER = 'REVENUE_REDUCER'

export const revenueReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REVENUE_REDUCER:
        case TARGET_SALES:
        case TARGET_SALES_DATE:
        case REVENUE_DETAIL:
            return {...state, ...action.payload}
        default:
            return state
    }
}
