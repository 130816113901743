import React, {Component} from 'react';
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportRemainingPaymentComponent extends Component {
    render(){
        const {selectedRow} = this.props;
        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="Status" value="status"/>
                    <ExcelColumn label="Tipe Order" value="orderType"/>
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="ID Payment Pre Order" value="poPaymentId"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Tanggal Approval" value="approvalDate"/>
                    <ExcelColumn label="Tanggal Berakhir" value="expiredDate"/>
                    <ExcelColumn label="Order Grand Total" value="grandTotal"/>
                    <ExcelColumn label="Pembayaran 2" value="payAmount"/>
                    <ExcelColumn label="Denda" value="fines"/>
                    <ExcelColumn label="Nama" value="userName"/>
                    <ExcelColumn label="Metode Pembayaran" value="paymentMethod"/>
                    <ExcelColumn label="Status Pembayaran" value="paymentStatus"/>
                    <ExcelColumn label="Tanggal Pembayaran" value="paymentTime"/>
                    <ExcelColumn label="Payment Receipt" value="paymentReceipt"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export default ExportRemainingPaymentComponent;