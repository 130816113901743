import React from "react";
import {Card, Col, Row} from "antd";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";

class RevenueItemComponent extends React.PureComponent {
    constructor() {
        super()

        this.state = {
            itemList: [
                {
                    id: 1,
                    key: 'grossAmount',
                    label: 'Gross Amount'
                },
                {
                    id: 2,
                    key: 'netSales',
                    label: 'Net Sales'
                },
                {
                    id: 3,
                    key: 'shipping',
                    label: 'Shipping'
                },
                {
                    id: 4,
                    key: 'pointUsage',
                    label: 'Point'
                },
                {
                    id: 5,
                    key: 'voucher',
                    label: 'Voucher'
                },
                {
                    id: 6,
                    key: 'cashback',
                    label: 'Cashback'
                },
                {
                    id: 7,
                    key: 'progressiveMargin',
                    label: 'Progressive Margin'
                },
                {
                    id: 8,
                    key: 'totalTax',
                    label: 'Total Pajak'
                },
                // {
                //     id: 9,
                //     key: 'subTotalBeforeTax',
                //     label: 'Total Sebelum Pajak'
                // },
                {
                    id: 10,
                    key: 'additionalMargin',
                    label: 'Additional Margin'
                },
            ]
        }
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {itemList} = this.state
        const {revenueData} = this.props.revenueReducer

        return (
            <Row style={{borderWidth: 1, borderColor: Colors.gainsboro, backgroundColor: 'white', marginTop: 20, padding: 10}} align={'center'}>
                {
                    itemList.map((item, index) => (
                        <Card key={index}
                            title={item.label} style={{margin: 10, minWidth: '30%', borderRadius: 7}} headStyle={{backgroundColor: Colors.gainsboro, fontSize: 16}}>
                            <Row justify={'space-between'}>
                                <Col style={{marginRight: 10}}>
                                    <div>Current</div>
                                    <div style={{fontSize: 16}}>{revenueData[item.key] && doConvertNumberToRupiahFormat(revenueData[item.key].current)}</div>
                                </Col>
                                <Col style={{marginLeft: 10}}>
                                    <div>Previous</div>
                                    <div style={{fontSize: 16}}>{revenueData[item.key] && doConvertNumberToRupiahFormat(revenueData[item.key].prev)}</div>
                                </Col>
                            </Row>
                        </Card>
                    ))
                }
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {revenueReducer} = state

    return {revenueReducer}
}

export default connect(mapStateToProps, {})(RevenueItemComponent)
