import React, {PureComponent} from 'react'
import {Button, Form, Image, Input, List, Switch, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {BannerDetailObj} from "../../models/banner.model";
import {renameImageToDateFormat} from "../../ui-util/general-variable";
import {bannerStorageRef, putBannerImage} from "../../firebase/storage/banner-image";
import {storageTaskEvent} from "../../firebase";
import isEqual from "react-fast-compare";

const {TextArea} = Input;

class BannerFormComponent extends PureComponent {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            bannerForm: [{
                key: 'name',
                label: 'Nama Banner',
                required: true,
                messageAlert: 'Masukkan nama banner'
            }, {
                key: 'description',
                label: 'Deskripsi Banner',
                required: true,
                messageAlert: 'Masukkan deskripsi banner',
                type: 'textarea',
            }, {
                key: 'image',
                label: 'Gambar Banner',
                required: true,
                messageAlert: 'Masukkan gambar banner',
                type: 'image'
            }, {
                key: 'isAvailable',
                label: 'Aktivasi Banner',
                type: 'switch'
            }],
            fileImageList: [],
            fileImage: {},
            bannerDetail: {...BannerDetailObj},
            loading: false,
            showImage: false
        }
    }

    componentDidMount() {
        const {bannerDetail} = this.props
        this.formRef.current.setFieldsValue(bannerDetail)
        this.setState({showImage: !!bannerDetail?.image})
    }

    onFinish = (values) => {
        this.setState({loading: true})
        let {fileImageList} = this.state
        let {slug, submitButtonHandler, bannerDetail, setLoadingTrue} = this.props
        bannerDetail = {...bannerDetail, ...values}
       setLoadingTrue()
        if (fileImageList.length > 0) {
            let fileName = renameImageToDateFormat(fileImageList[0].name, 'banner-')
            this.setState({bannerDetail})
            this.uploadImageToStorage(fileName, fileImageList[0])
        } else if (slug === 'edit' && fileImageList.length === 0) {
            submitButtonHandler(bannerDetail)
        } else {
            this.setState({loading: false})
            alert('Masukkan gambar banner!')
        }
    }

    removeButtonHandler = () => {
        this.setState({fileImageList: []})
    }

    beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            this.setState({fileImageList: [fileImage], showImage: false});
        }
        return false
    }

    uploadImageToStorage = (fileName, fileImage) => {
        let {bannerDetail} = this.state
        const {submitButtonHandler} = this.props
        putBannerImage(fileName, fileImage).on(
            storageTaskEvent.STATE_CHANGED,
            snapshot => {
            },
            err => {
                this.setState({loading: false})
                alert('Gagal mengunggah gambar banner')
                console.log('err', err)
            },
            () => {
                bannerStorageRef(fileName).getDownloadURL()
                    .then(url => {
                        bannerDetail.image = url
                        submitButtonHandler(bannerDetail)
                    })
            }
        )
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {bannerDetail} = this.props
        if (!isEqual(this.props.bannerDetail, prevProps.bannerDetail)) {
            this.setState({fileImageList: []})
            this.setState({showImage: !!bannerDetail?.image})
            this.formRef.current.setFieldsValue(bannerDetail)
        }
    }

    render() {
        const {bannerForm, fileImageList, showImage} = this.state
        const {bannerDetail, labelCol, wrapperCol, width, loading} = this.props

        return (
            <Form style={{width: width}} onFinish={this.onFinish} ref={this.formRef} labelCol={{span: labelCol}}
                  wrapperCol={{span: wrapperCol}}>
                <List
                    dataSource={bannerForm}
                    renderItem={(item, index) => {
                        switch (item.type) {
                            case 'textarea':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <TextArea rows={4} placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                            case 'image':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Upload fileList={fileImageList} beforeUpload={this.beforeUploadImage}
                                                onRemove={this.removeButtonHandler}>
                                            {
                                                showImage &&
                                                <Image
                                                    src={bannerDetail.image}
                                                    width={100}
                                                />
                                            }
                                            <div style={{marginTop: 10}}>
                                                <Button>
                                                    <UploadOutlined/> Select File
                                                </Button>
                                                <p style={{marginTop: 5}}>Ukuran gambar maksimal 2 MB</p>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                )
                            case 'switch':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                        valuePropName="checked"
                                    >
                                        <Switch checked={bannerDetail?.valid}/>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Input placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    }}
                />
                <Form.Item wrapperCol={{
                    span: 2,
                    offset: 4,
                }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default BannerFormComponent
