import React from "react";
import {Button, Row, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {Colors} from "../../ui-util/colors";
import {ExcelRenderer} from "react-excel-renderer";
import {bulkUpdateAwbApi} from "../../api/order.api";

class ImportResiHandlerComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            excelFile: [],
            cols: [],
            rows: [],
        }
    }

    beforeUpload = (file) => {
        const isExcel =
            file.type === "application/vnd.ms-excel" ||
            file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        if (!!isExcel) {
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    console.log(err);
                } else {
                    let newRows = [];
                    resp.rows.slice(1).map((row, index) => {
                        if (row && row !== "undefined") {
                            newRows.push({
                                id: row[0],
                                orderId: row[1],
                                awb: row[5]
                            });
                        }
                    });
                    if (newRows.length === 0) {
                        alert('Data tidak ditemukan di dalam file')
                    } else {
                        this.setState({
                            cols: resp.cols,
                            rows: newRows,
                            excelFile: [file]
                        });
                    }
                }
            });
        } else {
            alert('Mohon masukkan file excel')
        }
    }

    dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    handleUpload = () => {
        const {rows} = this.state
        bulkUpdateAwbApi({orders: rows})
            .then(result => {
                if(!!result?.message?.isError){
                    alert(result.message.message)
                } else if (result.data.notUpdated.length > 0) {
                    alert('Ada resi tidak terupdate')
                }else {
                    alert('Resi berhasil di update!')
                    window.location.reload()
                }
            })
            .catch(err => {
                console.log('Error bulkUpdatePointPerProductApi', err)
            })
    }

    render() {
        const {excelFile} = this.state
        return (
            <Row>
                <div style={{width: 180}}>
                    <Upload
                        name="file"
                        fileList={excelFile}
                        customRequest={this.dummyRequest}
                        beforeUpload={this.beforeUpload}
                        onRemove={() => this.setState({excelFile: []})}>
                        <Button icon={<UploadOutlined/>}>Select File</Button>
                    </Upload>
                </div>
                <Button
                    disabled={excelFile.length === 0}
                    onClick={this.handleUpload}
                    style={{
                        backgroundColor: excelFile.length !== 0 && Colors.darkseagreen,
                        color: excelFile.length !== 0 && 'white'
                    }}
                >
                    Upload
                </Button>
            </Row>
        )
    }
}

export default ImportResiHandlerComponent
