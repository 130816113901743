import React, {Component} from 'react';
import {Button, Card, Table} from "antd";
import {doConvertNumberToRupiahFormat, getLevelText} from "../../ui-util/general-variable";
import {EditOutlined} from "@ant-design/icons";
import OrderRequirementFormComponent from "./order-requirement-form.component";
import {getOrderRequirementApi, updateOrderRequirementApi} from "../../api/order-requirement.api";
import _ from 'lodash'

class OrderRequirementPage extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    title: 'Avostore Level',
                    dataIndex: 'level',
                    key: 'level',
                    render: (level) => getLevelText(level)
                },
                {
                    title: 'Minimum Produk',
                    dataIndex: 'minProductCount',
                    key: 'minProductCount',
                },
                {
                    title: 'Minimum Total Belanja',
                    dataIndex: 'minValue',
                    key: 'minValue',
                    render: (minValue) => doConvertNumberToRupiahFormat(minValue)
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (record) => <Button icon={<EditOutlined/>} style={{margin: 5}}
                                                onClick={() => this.onEditHandler(record)}/>
                },
            ],
            listItem: [],
            modalVisible: false,
            selectedItem: {}
        }
    }

    onEditHandler = (itemDetail) => {
        this.setState({modalVisible: true, selectedItem: itemDetail})
    }

    onFinishHandler = (values) => {
        const {selectedItem} = this.state;
        const dataToUpdate = {
            ...selectedItem,
            minProductCount: values.minProductCount ? Number(values.minProductCount) : selectedItem.minProductCount,
            minValue: values.minValue ? Number(values.minValue) : selectedItem.minValue,
        }

        this.setState({modalVisible: false})
        updateOrderRequirementApi(dataToUpdate)
            .then(result => {
                alert("Data berhasil diperbaharui !")
                window.location.reload()
            })
            .catch(err => {
                console.log("error updateOrderRequirementApi data :", err)
                alert("Gagal mengubah data !")
            })
    }

    onCancelHandler = () => {
        this.setState({modalVisible: false})
    }

    componentDidMount() {
        getOrderRequirementApi()
            .then(result => {
                let listItem = _.sortBy(result.data.orderRequirementList, item => item.level)
                this.setState({listItem})
            })
            .catch(err => {
                console.log("error getOrderRequirementApi :", err)
                alert("some thing went wrong !!")
            })
    }


    render() {
        const {columns, listItem, modalVisible} = this.state;

        return (
            <Card>
                <h5 style={{marginBottom: 20}}>Order Requirement</h5>
                <Table columns={columns}
                       pagination={false}
                       rowKey="id"
                       dataSource={listItem}
                />
                {
                    modalVisible &&
                    <OrderRequirementFormComponent onFinish={this.onFinishHandler}
                                                   modalVisibilityForm={modalVisible}
                                                   onCancelHandler={this.onCancelHandler}/>
                }
            </Card>
        );
    }
}

export default OrderRequirementPage;