import {doConvertNumberToRupiahFormat, getLevelText} from "./general-variable";

const formatTopSpenderExport = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        item.topProducts.forEach((product, idx) => {
            let data = {};
            if (idx === 0 && item.userDetail) {
                data = {
                    name: item.userDetail.name,
                    level: getLevelText(item.userDetail.level),
                    avostoreArea: item.userDetail.avostoreArea,
                    grandTotal: doConvertNumberToRupiahFormat(item.grandTotal),
                    skuCode: product.skuCode,
                    productName: product.name,
                    productQty: product.count,
                };
            } else {
                data = {
                    name: '',
                    avostoreArea: '',
                    level: '',
                    grandTotal: '',
                    skuCode: product.skuCode,
                    productName: product.name,
                    productQty: product.count,
                };
            }
            selectedRow.push(data);
        });
    });
    return selectedRow;
};

export {formatTopSpenderExport}
