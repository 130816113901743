import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment()
}

export const FILTER_DATE_REDUCER = 'FILTER_DATE_REDUCER'
export const FLUSH_FILTER_DATE_REDUCER = 'FLUSH_FILTER_DATE_REDUCER'

export const filterDateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case FLUSH_FILTER_DATE_REDUCER:
            return {...INITIAL_STATE}
        case FILTER_DATE_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
