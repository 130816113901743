import {formatUnixDateToReadable} from "./general-variable";

const setSalesListTable = (rows) => {
    let selectedRow = []
    rows.forEach((row) => {
        let data = {
            date: formatUnixDateToReadable(row.date, 'll'),
            orderCount: row.orderCount,
            grossAmount: row.grossAmount,
            totalPaymentReceived: row.totalPaymentReceived,
            cashback: row.cashback,
            shipping: row.shipping,
            voucher: row.voucher,
            pointUsage: row.pointUsage,
            netSales: row.netSales,
        }
        selectedRow.push(data)
    })
    return selectedRow
}

export {setSalesListTable}
