import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Col, DatePicker, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import AnalyticsUserHeaderComponent from "./analytics-user-header.component";
import AnalyticsUserChartComponent from "./analytics-user-chart.component";
import AnalyticsUserListComponent from "./analytics-user-list.component";
import AnalyticsUserAuthListComponent from "./analytics-user-auth-list.component";
import moment from "moment";
import {analyticsUserDispatcher} from '../../actions/analytics-user.action'
import {ANALYTICS_USER} from "../../reducers/analytics-user.reducer";

const {RangePicker} = DatePicker;

class AnalyticsUser extends Component {
    constructor() {
        super()

        this.state = {
            startDate: moment().startOf('month'),
            endDate: moment(),
        }
    }

    onDatePickerChange = (dates) => {
        this.setState({startDate: dates[0], endDate: dates[1]})
    }

    filterButtonHandler = () => {
        const {startDate, endDate} = this.state
        this.props.analyticsUserDispatcher(ANALYTICS_USER, {startDate, endDate})
    }

    render() {
        const {startDate, endDate} = this.state

        return (
            <Card style={{backgroundColor: 'transparent'}}>
                <Row justify={'space-between'} align="center">
                    <Col>
                        <h3 style={{marginRight: 20}}>Analytic User</h3>
                    </Col>
                    <Col>
                        <Row>
                            <div style={{marginRight: 10}}>Date range :</div>
                            <div style={{flexDirection: 'row'}}>
                                <RangePicker
                                    style={{marginRight: 10}}
                                    defaultValue={[startDate, endDate]}
                                    disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                                    onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                                    format={'DD/MM/YYYY'}/>
                                <Button style={{
                                    backgroundColor: Colors.darkseagreen,
                                    color: 'white',
                                    marginLeft: 20
                                }}
                                        onClick={() => this.filterButtonHandler()}>Filter</Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row justify={'space-between'} style={{marginTop: 30}}>
                    <AnalyticsUserChartComponent/>
                    <AnalyticsUserHeaderComponent/>
                </Row>
                <AnalyticsUserListComponent/>
                <AnalyticsUserAuthListComponent />
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsUserReducer} = state
    return {analyticsUserReducer};
}

export default connect(mapStateToProps, {analyticsUserDispatcher})(AnalyticsUser);
