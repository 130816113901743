import {getOrderListQuery} from "../api/order.api";
import {convertStartEndDate} from "../ui-util/general-variable";
import {DC_PAYMENT_REDUCER} from "../reducers/dc-payment.reducer";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../models/order-detail.model";

const dcPaymentDispatcher = (type, payload) => {
    return {type, payload}
}

const getDcOrderList = () => {
    return (dispatch, getState) => {
        dispatch(dcPaymentDispatcher(DC_PAYMENT_REDUCER, {
            loading: true
        }))
        let {startDate, endDate, keyword, currentPage, pageSize, activeTab} = getState().dcPaymentReducer
        let requestBody = {
            isOrderDc: true,
            startDate: convertStartEndDate(startDate, 'start'),
            tillDate: convertStartEndDate(endDate),
            sort: "desc",
        }

        let params = {
            showPerPage: pageSize,
            page: currentPage - 1,
            keyword
        }

        if(activeTab === 'PAID'){
            requestBody = {
                ...requestBody,
                paymentDetail: {transactionStatus: 'paid'},
                orderState: [SUBMITTED, PACKAGING, DELIVERY, COMPLETED, CANCELLED],
            }
        } else {
            requestBody = {
                ...requestBody,
                paymentDetail: {transactionStatus: ""},
                orderState: [SUBMITTED]
            }
        }

        getOrderListQuery(requestBody, params)
            .then(result => {
                dispatch(dcPaymentDispatcher(DC_PAYMENT_REDUCER, {
                    orderList: result.orders,
                    dataCount: result.dataCount,
                    loading: false
                }))
            })
            .catch(err => {
                console.log('getNdnOrderList', err)
                dispatch(dcPaymentDispatcher(DC_PAYMENT_REDUCER, {
                    loading: false
                }))
            })
    }
}

export {
    dcPaymentDispatcher,
    getDcOrderList
}
