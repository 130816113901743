import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Button, Popconfirm, Table} from "antd";
import _ from 'lodash';
import {DeleteOutlined, FileOutlined, EditOutlined} from '@ant-design/icons'
import {voucherDispatcher} from "../../actions/voucher.action";
import {SELECT_VOUCHER, SELECT_VOUCHER_BULK} from "../../reducers/voucher-detail.reducer";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {Link} from "react-router-dom";

class VoucherListComponent extends PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Nama Voucher',
                    dataIndex: 'voucherName',
                    key: 'voucherName',
                },
                {
                    title: 'Deskripsi Voucher',
                    dataIndex: 'description',
                    key: 'description',
                    width: 250
                },
                {
                    title: 'Nilai Voucher',
                    dataIndex: 'voucherValue',
                    key: 'voucherValue',
                    render: (voucherValue, record) => {
                        if(record.voucherType === 'nominal' || record.voucherType === 'free_shipping_nominal'){
                            return (doConvertNumberToRupiahFormat(voucherValue))
                        }else {
                            return (`${voucherValue} %`)
                        }
                    }
                },
                {
                    title: 'Tgl. Mulai',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    render: text => (formatUnixDateToReadable(text, 'll'))
                },
                {
                    title: 'Tgl. Selesai',
                    dataIndex: 'endDate',
                    key: 'endDate',
                    render: text => (formatUnixDateToReadable(text, 'll'))
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <React.Fragment>
                            <Button icon={<FileOutlined/>} style={{margin: 5}} onClick={() => {
                                this.props.setVoucherDetailVisibility(true)
                                this.props.voucherDispatcher(SELECT_VOUCHER, record)
                            }}/>
                            <Link to={`/voucher-update-form/${record.id}`}>
                                <Button icon={<EditOutlined/>} style={{margin: 5}}/>
                            </Link>
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={this.props.deleteHandler}
                                onCancel={this.props.cancelDeleteHandler}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined/>} style={{margin: 5}} onClick={() => {
                                    this.props.voucherDispatcher(SELECT_VOUCHER, record)
                                }}/>
                            </Popconfirm>
                        </React.Fragment>
                    ),
                },
            ],
            selectedRowKeys: []
        }
    }

    onSelectRow = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys});
        this.props.voucherDispatcher(SELECT_VOUCHER_BULK, selectedRows)
    }

    getDataToDisplay = () => {
        const {searchKey, voucherReducer} = this.props
        const {voucherList} = voucherReducer
        let dataToDisplay = voucherList

        if (!!searchKey) {
            dataToDisplay = _.filter(voucherList, item => item.voucherName.toLowerCase().includes(searchKey))
        }
        return dataToDisplay
    }

    render() {
        const {columns, selectedRowKeys} = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Table
                scroll={{x: 1500}}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.getDataToDisplay()}
                rowKey="id"
            />
        )
    }
}

const mapStateToProps = (state) => {
    const {voucherReducer} = state
    return {voucherReducer}
}

export default connect(mapStateToProps, {voucherDispatcher})(VoucherListComponent)
