import {ORDER_LIST, ORDER_LIST_AVOSTORE} from "../reducers/order.reducer";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../models/order-detail.model";
import {avoskinId, convertStartEndDate} from "../ui-util/general-variable";
import {generalDispatcher} from "./general.action";
import {TAB_REDUCER} from "../reducers/tab.reducer";
import {
    getOrderAvostoreListQuery,
    getOrderListDcApi,
    getOrderListQuery,
    getOrderListToImportResiApi
} from "../api/order.api";
import {IMPORT_RESI_REDUCER} from "../reducers/import-resi.reducer";

const orderDispatcher = (type, payload) => {
    return {type, payload}
}

const getOrderListAction = () => {
    return (dispatch, getState) => {
        const {
            currentPage,
            searchKey,
            pageSize,
            buyerLevel
        } = getState().orderReducer;
        const {activeTab} = getState().tabReducer
        const {id: warehouseId} = getState().warehouseReducer.warehouseDetail
        const {startDate, endDate} = getState().filterDateReducer

        let orderState = []
        let paymentDetail = {}
        let sort = 'desc'

        switch (activeTab){
            case SUBMITTED:
                paymentDetail = {transactionStatus: null}
                orderState = [SUBMITTED]
                break;
            case '':
                orderState = [SUBMITTED, PACKAGING, DELIVERY, COMPLETED, CANCELLED]
                break;
            case 'waitingForDelivery':
                orderState = [SUBMITTED, PACKAGING]
                sort = 'asc'
                paymentDetail = {transactionStatus: 'paid'}
                break;
            default:
                orderState = [activeTab]
        }

        dispatch(generalDispatcher(ORDER_LIST, {
            loading: true
        }))
        dispatch(generalDispatcher(TAB_REDUCER, {
            disabled: true
        }))

        let requestBody = {
            sellerId: avoskinId(),
            warehouseId,
            orderState,
            startDate: convertStartEndDate(startDate, 'start'),
            tillDate: convertStartEndDate(endDate),
            sort,
            paymentDetail,
            isNdnOrder: true,
            buyerLevel
        }

        const params = {showPerPage: pageSize, page: currentPage - 1, keyword: searchKey}

        getOrderListQuery(requestBody, params)
            .then(result => {
                dispatch(generalDispatcher(ORDER_LIST, {
                    orderList: result.orders,
                    dataCount: result.dataCount,
                    loading: false
                }))
                dispatch(generalDispatcher(TAB_REDUCER, {
                    disabled: false
                }))
            })
            .catch(err => {
                dispatch(generalDispatcher(ORDER_LIST, {
                    loading: false
                }))
                dispatch(generalDispatcher(TAB_REDUCER, {
                    disabled: false
                }))
                console.log('getOrderListQuery', err)
            })

    }
}

const getOrderList = (slug) => {
    return (dispatch, getState) => {
        dispatch(orderDispatcher(ORDER_LIST, {
            loading: true
        }))
        const {id: warehouse} = getState().warehouseReducer.warehouseDetail
        const {
            filterStartDate,
            filterEndDate,
            currentPage,
            searchKey,
            pageSize,
            activeTab,
            sellerId,
            warehouseDcId
        } = getState().orderReducer;
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerIdDc = []
        avoStoreDcList.forEach((item, index) => {
            if (item.id !== null) {
                sellerIdDc.push(item.id)
            }
        })

        let orderState = []
        let paymentDetail = {}
        let sort = 'desc'

        switch (activeTab) {
            case 'waitingForPayment':
                orderState = [SUBMITTED]
                paymentDetail = {transactionStatus: null}
                break;
            case 'waitingForDelivery':
                orderState = [SUBMITTED, PACKAGING]
                sort = 'asc'
                paymentDetail = {transactionStatus: 'paid'}
                break;
            case 'delivery':
                orderState = [DELIVERY]
                break;
            case 'completed':
                orderState = [COMPLETED]
                break;
            case 'cancelled':
                orderState = [CANCELLED]
                break;
            default:
                orderState = [SUBMITTED, PACKAGING, DELIVERY, COMPLETED, CANCELLED]
        }

        let requestBodyDc = {
            sellerId: sellerIdDc.slice(1, sellerIdDc.length),
            orderState,
            startDate: convertStartEndDate(filterStartDate, 'start'),
            endDate: convertStartEndDate(filterEndDate),
            warehouseId: warehouseDcId,
            paymentDetail,
            sort
        }

        let requestBody = {
            buyerId: '',
            sellerId: !!sellerId ? sellerId : avoskinId(),
            warehouseId: warehouse,
            orderState,
            startDate: convertStartEndDate(filterStartDate, 'start'),
            tillDate: convertStartEndDate(filterEndDate),
            sort,
            paymentDetail,
            isNdnOrder: true
        }

        const params = {showPerPage: pageSize, page: currentPage - 1, keyword: searchKey}

        let getOrderListApi = slug === 'DC' ? getOrderListDcApi(requestBodyDc, params) : getOrderListQuery(requestBody, params)

        getOrderListApi
            .then(result => {
                dispatch(orderDispatcher(ORDER_LIST, {
                    orderList: result.orders,
                    dataCount: result.dataCount,
                    loading: false
                }))
            })
            .catch(err => {
                dispatch(orderDispatcher(ORDER_LIST, {
                    loading: false
                }))
                console.log('getOrderListQuery', err)
            })
    }
}

const getOrderAvostoreList = () => {
    return (dispatch, getState) => {
        const {filterStartDate, filterEndDate, currentPage, pageSize, activeTab} = getState().orderReducer;

        let requestBody =
            {
                orderState: [SUBMITTED, PACKAGING, DELIVERY, COMPLETED, CANCELLED],
                startDate: convertStartEndDate(filterStartDate, 'start'),
                endDate: convertStartEndDate(filterEndDate),
                sort: 'desc'
            }

        const params = {page: currentPage - 1, size: pageSize}

        switch (activeTab) {
            case 'waitingForPayment':
                requestBody = {...requestBody, orderState: [SUBMITTED], paymentDetail: {transactionStatus: null}}
                break;
            case 'waitingForDelivery':
                requestBody = {
                    ...requestBody,
                    orderState: [SUBMITTED, PACKAGING],
                    sort: 'asc',
                    paymentDetail: {transactionStatus: 'paid'}
                }
                break;
            case 'delivery':
                requestBody = {...requestBody, orderState: [DELIVERY]}
                break;
            case 'completed':
                requestBody = {...requestBody, orderState: [COMPLETED]}
                break;
            case 'cancelled':
                requestBody = {...requestBody, orderState: [CANCELLED]}
                break;
            default:
                return requestBody
        }

        dispatch(orderDispatcher(ORDER_LIST_AVOSTORE, {loading: true}))

        getOrderAvostoreListQuery(requestBody, params)
            .then(result => {
                dispatch(orderDispatcher(ORDER_LIST_AVOSTORE, {
                    orderAvostoreList: result.data,
                    dataCount: result.totalDatas,
                    loading: false
                }))
            })
            .catch(err => {
                dispatch(orderDispatcher(ORDER_LIST_AVOSTORE, {loading: false}))
                console.log('getOrderAvostoreListQuery', err)
            })
    }
}

const getOrderListImportResiAction = () => {
    return (dispatch, getState) => {

        let {page, size, shippingMethod, warehouseLocation } = getState().importResiReducer

        let requestBody = {
            shippingMethod,
            warehouseLocation
        }

        let params = {
            page: page - 1,
            size
        }

        getOrderListToImportResiApi(requestBody, params)
            .then(result => {
                dispatch(orderDispatcher(IMPORT_RESI_REDUCER, {orderList: result.data, totalData: result.totalDatas}))
            })
            .catch(err => {
                dispatch(orderDispatcher(ORDER_LIST_AVOSTORE, {loading: false}))
                console.log('getOrderAvostoreListQuery', err)
            })
    }
}

export {getOrderList, orderDispatcher, getOrderAvostoreList, getOrderListAction, getOrderListImportResiAction};
