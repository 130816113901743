import React, {Component} from 'react';
import {Button, Form, Modal, Select} from "antd";
import {Colors} from "../../ui-util/colors";

class UpdateEmailSettingFormComponent extends Component {
    formRef = React.createRef();
    render() {
        const {isVisible, onCloseHandler, item} = this.props

        return (
            <Modal
                title={'Update Email Recipients'}
                visible={isVisible}
                onCancel={onCloseHandler}
                footer={null}
            >
                <Form ref={this.formRef} onFinish={(e) => this.props.onFinish(e)} initialValues={{recipients: item.recipients}}>
                    <Form.Item
                        label="Email List"
                        name={'recipients'}
                    >
                        <Select mode="tags"
                                style={{width: '100%'}}
                                placeholder={"masukkan email"}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default UpdateEmailSettingFormComponent;