import  React, {Component} from 'react';
import {Layout} from "antd";
import HeaderAvoStore from "../../components/header.component";
import Sidebar from "../../components/sidebar.component";
import {Colors} from "../../ui-util/colors";
import {getUserDetailFromFirestore} from "../../firebase/database/user";
import {connect} from "react-redux";
import {userDispatcher} from "../../actions/user.action";
import {USER_DETAIL} from "../../reducers/user.reducer";

const {Footer, Content} = Layout;

class Dashboard extends Component {
    constructor() {
        super();

        this.state = {
            isShowComponent: false
        }
    }

    componentDidMount() {
        const uid = localStorage.getItem('AVOSTORE_UID')
        if (!!uid) {
            getUserDetailFromFirestore(uid)
                .then(userDetail => {
                    this.props.userDispatcher(USER_DETAIL, userDetail.data())
                    this.setState({isShowComponent: true})
                })
                .catch(err => {
                    alert('Gagal mendapatkan data pengguna')
                })
        }
    }

    render() {
        const {content} = this.props;
        const {isShowComponent} = this.state

        return (
            <Layout>
                {
                    isShowComponent ?
                        <>
                            <HeaderAvoStore/>
                            <Layout>
                                <Sidebar/>
                                <Content style={{minHeight: 450, padding: 30,  marginTop: 50, marginLeft: 190, marginBottom: 70}}>
                                    {content}
                                </Content>
                            </Layout>
                            <Footer style={{backgroundColor: Colors.darkseagreen, color: 'white',  position: 'fixed',
                                left: 0,
                                bottom: 0,
                                width: '100%'}}
                                    className="d-flex justify-content-center align-items-center">
                                IT Arena 2020
                            </Footer>
                        </>
                        :
                        <Content style={{minHeight: 450, padding: 30,  marginTop: 50}}>
                            {content}
                        </Content>
                }
            </Layout>
        );
    }
}

export default connect(null, {userDispatcher})(Dashboard);
