const INITIAL_STATE = {
    productStockList: [],
    warehouseDetail: {},
    searchKey: '',
    loading: false,
    loadingTable: false
}

export const PRODUCT_STOCK_LIST = 'PRODUCT_STOCK_LIST'
export const SET_LOADING_PRODUCT_STOCK = 'SET_LOADING_PRODUCT_STOCK'

export const productStockReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case PRODUCT_STOCK_LIST:
            return {...state, ...action.payload}
        case SET_LOADING_PRODUCT_STOCK:
            return {...state, ...action.payload}
        default:
            return state
    }
}
