import React from "react";
import {Colors} from "../ui-util/colors";
import {IMPORT_RESI_REDUCER} from "../reducers/import-resi.reducer";
import {Button, Modal} from "antd";
import {connect} from "react-redux";
import {orderDispatcher} from "../actions/order.action";
import ExportOrderWithoutAwbComponent from "./export-order-without-awb.component";
import {setOrderToUpdateResi} from "../ui-util/order-list-update-resi.util";
import ImportResiHandlerComponent from "../pages/import-resi/import-resi-handler.component";

class ImportResiModal extends React.PureComponent {
    render() {
        const {importResiModalVisibility, selectedRowToExport} = this.props.importResiReducer
        return (
            <>
                <div>
                    <ExportOrderWithoutAwbComponent selectedRow={setOrderToUpdateResi(selectedRowToExport)}/>
                    <Button style={{
                        height: 40,
                        backgroundColor: Colors.darkseagreen,
                        color: 'white',
                        fontSize: 15,
                        marginLeft: 30
                    }}
                            onClick={() => this.props.orderDispatcher(IMPORT_RESI_REDUCER, {importResiModalVisibility: true})}>
                        Import Resi</Button>
                </div>
                <Modal
                    title={'Import Resi'}
                    visible={importResiModalVisibility}
                    onCancel={() => this.props.orderDispatcher(IMPORT_RESI_REDUCER, {importResiModalVisibility: false})}
                    footer={null}
                >
                    <ImportResiHandlerComponent/>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {importResiReducer} = state;

    return {importResiReducer};
};

export default connect(mapStateToProps, {orderDispatcher})(ImportResiModal)
