import axios from "axios";
import {cloudFunctionBaseUrl, formatUnixDateToReadable} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const getAnalyticsGuaranteeList = (bodyRequest, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/guarantee/list-guarantee`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getTotalGuaranteeActiveComplete = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/guarantee-active-complete`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getTotalGuaranteePaid = (startDate, endDate, guaranteeType) => {
    let bodyRequest = {
        "startDate": formatUnixDateToReadable(startDate, 'YYYY-MM-DD'),
        "endDate": formatUnixDateToReadable(endDate, 'YYYY-MM-DD'),
        "startDateEpoch": startDate,
        "endDateEpoch": endDate,
        guaranteeType
    }

    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/guarantee-paid`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}



export {
    getAnalyticsGuaranteeList,
    getTotalGuaranteeActiveComplete,
    getTotalGuaranteePaid
}

