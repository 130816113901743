import {firestore} from "../index";

const orderAuditRef = firestore.collection('order_audit');

const getOrderAuditFromFirestore = (orderId) => {
    return orderAuditRef.where('orderId', '==', orderId).get();
}

const setOrderAuditToFirestore = (orderAudit) => {
    return  orderAuditRef.doc(orderAudit.id).set(orderAudit)
}

export {
    getOrderAuditFromFirestore,
    setOrderAuditToFirestore
}
