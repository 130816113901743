import React from "react";
import {Button, Card, Dropdown, Menu, Row} from "antd";
import {connect} from "react-redux";
import {getOrderListImportResiAction} from "../../actions/order.action";
import ImportResiOrderListComponent from "./import-resi-order-list.component";
import CityDropdownComponent from "../../components/city-dropdown.component";
import {Colors} from "../../ui-util/colors";
import {DownOutlined} from "@ant-design/icons";
import {FLUSH_IMPORT_RESI_REDUCER, IMPORT_RESI_REDUCER} from "../../reducers/import-resi.reducer";
import isEqual from "react-fast-compare";
import ImportResiModal from "../../components/import-resi.modal";
import {generalDispatcher} from "../../actions/general.action";

class ImportResiPage extends React.PureComponent {

    componentDidMount() {
        this.props.getOrderListImportResiAction()
    }

    constructor() {
        super();

        this.state = {
            menuList: [
                'POS Indonesia (POS)',
                'RPX Holding (RPX)',
                'Jalur Nugraha Ekakurir (JNE)',
                'J&T Express',
                'Porter',
                'SiCepat Express',
                'Self Service',
                'Semua'
            ],
        }
    }

    OrderListByShippingMethod = (shippingMethod) => {
        let shipping = shippingMethod === 'Semua' ? '' : shippingMethod
        this.props.generalDispatcher(IMPORT_RESI_REDUCER, {shippingMethod: shipping})
    }

    dropDownItem = () => {
        const {menuList} = this.state
        return (
            <Menu>
                {
                    menuList.map((menuItem, index) => (
                        <Menu.Item key={index} onClick={() => this.OrderListByShippingMethod(menuItem)}>
                            <Button type="link">{menuItem}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {shippingMethod, page, size, warehouseLocation} = this.props.importResiReducer
        const {importResiReducer} = prevProps
        if (!isEqual(shippingMethod, importResiReducer?.shippingMethod) ||
            !isEqual(page, importResiReducer?.page) ||
            !isEqual(size, importResiReducer?.size) ||
            !isEqual(warehouseLocation, importResiReducer?.warehouseLocation)
        ) {
            this.props.getOrderListImportResiAction()
        }
    }

    componentWillUnmount() {
        this.props.generalDispatcher(FLUSH_IMPORT_RESI_REDUCER)
    }

    render() {
        const {shippingMethod} = this.props.importResiReducer

        return (
            <Card>
                <Row style={{paddingTop: 30, paddingBottom: 50}} justify={'space-between'}>
                    <Row>
                        <CityDropdownComponent/>
                        <Row style={{alignItems: 'center', marginLeft: 30}}>
                            <h5>Jasa Pengiriman : </h5>
                            <Dropdown overlay={this.dropDownItem} trigger={['click']}>
                                <Button
                                    style={{backgroundColor: Colors.darkseagreen, color: 'white', marginLeft: 10}}
                                    icon={<DownOutlined/>}
                                    size="large"
                                    theme="filled"
                                >
                                    {shippingMethod.length > 0 ? shippingMethod : 'Semua'}
                                </Button>
                            </Dropdown>
                        </Row>
                    </Row>
                    <ImportResiModal />
                </Row>
                <ImportResiOrderListComponent/>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {importResiReducer} = state;

    return {importResiReducer};
};

export default connect(mapStateToProps, {getOrderListImportResiAction, generalDispatcher})(ImportResiPage)
