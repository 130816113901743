import React from "react";
import {Button, Image, Popconfirm, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import _ from "lodash";
import {connect} from "react-redux";
import {getProductList, productDispatcher} from "../../actions/product.action";
import {updateProductDetailApi} from "../../api/product.api";
import {Link} from "react-router-dom";
import {PRODUCT_DETAIL, PRODUCT_REDUCER} from "../../reducers/product.reducer";

class ProductListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Image',
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => <Image src={image ? image : require('../../images/no-image.png')} width={100}
                                              height={100}/>
                }, {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                }, {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                    width: '40%'
                }, {
                    title: 'Visible',
                    dataIndex: 'isVisible',
                    key: 'isVisible',
                    render: (isVisible) => <p>{isVisible ? 'Ya' : 'Tidak'}</p>
                }, {
                    title: 'Action',
                    key: 'action',
                    render: (record) =>
                        <>
                            <Link to={`/product/update`}>
                                <Button
                                    onClick={() => this.props.productDispatcher(PRODUCT_DETAIL, {productDetail: record})}
                                    style={{
                                        marginRight: 10,
                                        width: 100,
                                        backgroundColor: Colors.gainsboro,
                                        borderColor: Colors.darkseagreen,
                                        borderRadius: 5
                                    }}>Edit</Button>
                            </Link>
                            <Popconfirm title={"Apakah anda ingin menghapus produk ini?"}
                                        onConfirm={() => this.deleteProductHandler(record)}
                            >
                                <Button style={{
                                    width: 100,
                                    backgroundColor: Colors.gainsboro,
                                    borderColor: Colors.darkseagreen,
                                    borderRadius: 5
                                }}>Delete</Button>
                            </Popconfirm>
                        </>
                }
            ],
            productList: [],
            searchKey: '',
            selectedRowKeys: []
        }
    }

    componentDidMount() {
        const {selectedTab, selectedCategories} = this.props
        const categoryIds = selectedCategories?.id ? [selectedCategories?.id] : []
        this.props.getProductList(selectedTab.key, categoryIds)
    }

    deleteProductHandler = (record) => {
        let requestBody = {
            valid: false
        }
        updateProductDetailApi(record.id, requestBody)
            .then(() => this.props.getProductList())
            .catch(err => console.log(err))
    }

    dataToDisplay = () => {
        const {productList, searchKey} = this.props.productReducer
        let productListFiltered = []
        if (!!searchKey) {
            productListFiltered = _.filter(productList, item => item.name.toLowerCase().includes(searchKey.toLowerCase()))
        } else {
            productListFiltered = productList
        }

        return productListFiltered
    }

    onSelectRow = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys});
        this.props.productDispatcher(PRODUCT_REDUCER, {productSelected: selectedRows})
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {selectedTab, selectedCategories} = this.props
        if (prevProps.selectedTab !== selectedTab || prevProps.selectedCategories !== selectedCategories) {
            const categoryIds = selectedCategories?.id ? [selectedCategories?.id] : []
            this.props.getProductList(selectedTab.key, categoryIds)
        }
    }

    render() {
        const {columns, selectedRowKeys} = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };
        return (
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={this.dataToDisplay()}
                rowKey={'id'}
                rowSelection={rowSelection}
                pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const {productReducer} = state

    return {productReducer}
}

export default connect(mapStateToProps, {getProductList, productDispatcher})(ProductListComponent)
