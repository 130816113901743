import {getAnalyticsGuaranteeList} from "../api/analytics-guarantee.api";
import {convertStartEndDate} from "../ui-util/general-variable";
import {GUARANTEE_REDUCER} from "../reducers/guarantee.reducer";

const guaranteeDispatcher = (type, payload) => {
    return {type, payload}
}

const getGuaranteeListAction = () => {
    return (dispatch, getState) => {
        dispatch(guaranteeDispatcher(GUARANTEE_REDUCER, {loading: true}))
        let {startDate, endDate, activeTab, currentPage, pageSize, guaranteeType, sellerId} = getState().guaranteeReducer
        let bodyRequest = {
            startDateEpoch: convertStartEndDate(startDate, 'start'),
            endDateEpoch: convertStartEndDate(endDate),
            sellerId,
            guaranteeType: guaranteeType?.value,
            status: activeTab
        }

        let params = {
            page: currentPage - 1,
            size: pageSize
        }

        getAnalyticsGuaranteeList(bodyRequest, params)
            .then(result => {
                dispatch(guaranteeDispatcher(GUARANTEE_REDUCER, {
                    guaranteeList: result.data,
                    totalData: result.totalDatas,
                    loading: false
                }))
            })
            .catch(err => {
                dispatch(guaranteeDispatcher(GUARANTEE_REDUCER, {
                    guaranteeList: [],
                    totalData: 0,
                    loading: false
                }))
                console.log(err)
            })
    }
}

export {guaranteeDispatcher, getGuaranteeListAction}
