import React, {Component} from 'react';
import {Button, Layout} from "antd";
import {Colors} from "../ui-util/colors";
import { Link } from 'react-router-dom';
import {signOut} from '../firebase/emailAuth/authentication';

const {Header} = Layout;

class HeaderAvoStore extends Component {
    logoutHandler = () => {
        localStorage.removeItem('AVOSTORE_UID')
        localStorage.removeItem('AVOSTORE_ROLE')
        signOut();
        window.location.reload()
    }

    render() {
        return (
            <Header style={{backgroundColor: Colors.darkseagreen, position:'fixed', width: '100%', zIndex: 1}}
                    className="d-flex align-items-center justify-content-between">
                <Link to="/"><img src={require('../images/avo_icon.png')} alt="Smiley face" width={468 / 2} height={76 / 2}/></Link>
                <Button onClick={this.logoutHandler} style={{color: 'white'}} type="link" size="large">Logout</Button>
            </Header>
        );
    }
}


export default HeaderAvoStore;
