import React, {Component} from 'react';
import {Col, Row, Table} from 'antd';
import {formatUnixDateToReadable} from '../ui-util/general-variable';
import isEqual from "react-fast-compare";
import {connect} from "react-redux";

class PrintManifestComponent extends Component {

	constructor() {
		super();

		this.state = {
			orderList: [],
			columns : [
				{ title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId' },
				{
					title: 'Nama',
					dataIndex: 'shippingAddress',
					key: 'shippingAddress',
					render: (text) => text.receiver
				},
				{ title: 'Ekspedisi', dataIndex: 'shippingMethod', key: 'shippingMethod' },
				{ title: 'Service ', dataIndex: 'shippingService', key: 'shippingService' },
				{
					title: 'Resi ',
					dataIndex: 'awb',
					key: 'awb',
					render: (text) => Boolean(text) ? text : ""
				}
			]
		}
	}

	shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
		if(!isEqual(this.props.data, nextProps.data)){
			return true
		}else {
			return false
		}
	}

	render() {
		const {columns} = this.state
		const { data, orderList } = this.props;
		const cmToPx = 55;

		return (
			<div style={{ width: 19 * cmToPx, paddingLeft: cmToPx }}>
				<Row style={{backgroundColor:'#E6F7FF'}}>
					<Col span={24} >
						<p align="center">
                            AVO MANIFEST
                        </p>
					</Col>
				</Row>
                <Row style={{marginTop:50}}>
                    <Col>
                        <p>
                            Manifest ID: {data.manifestId}
                        </p>
                        <p>
                            Tanggal manifest:  {formatUnixDateToReadable(data.createdAt)}
                        </p>
                    </Col>
                </Row>
				<Row style={{marginTop:50}}>
					<Col span={20} offset={2}>
						<Table columns={columns} dataSource={orderList} rowKey="id" pagination={false} />
					</Col>
				</Row>
				<Row justify="space-around"  style={{marginTop:50}}>
					<Col span={3} >
						<p align="center">
							Kurir
							<br />
							<br />
							<br />
							<br />
							<br />
							............................
						</p>
					</Col>
					<Col span={3}>
						<p align="center">
							Admin
							<br />
							<br />
							<br />
							<br />
							<br />
							............................
						</p>
					</Col>
				</Row>
			</div>
		);
	}
}

export default PrintManifestComponent
