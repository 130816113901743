import React from "react";
import ProductList from "../orders/product-list.component";
import {Button, Divider, Table, Tag} from "antd";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    getDateEpochFormat,
    randomNumber
} from "../../ui-util/general-variable";
import {CANCELLED, COMPLETED, DELIVERY, PACKAGING, SUBMITTED} from "../../models/order-detail.model";
import {Link} from "react-router-dom";
import ReactToPrint from "react-to-print";
import {Colors} from "../../ui-util/colors";
import {FileTextOutlined} from '@ant-design/icons';
import {updateOrderDetail} from "../../firebase/database/orders";
import PrintManifestComponent from "../../components/print-manifest.component";
import {ManifestDetailObj} from "../../models/manifest.model";
import OrderAuditState from "../../models/OrderAuditState";
import {getOrderList, orderDispatcher} from "../../actions/order.action";
import {connect} from "react-redux";
import isEqual from "react-fast-compare";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import {MANIFEST_REDUCER} from "../../reducers/manifest.reducer";
import {generalDispatcher} from "../../actions/general.action";
import GiftListComponent from "../orders/gift-list.component";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class CreateManifestComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (text) => <div>{text.receiver}</div>
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (text) => <div>{formatUnixDateToReadable(text)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (text) => <div>{doConvertNumberToRupiahFormat(text)}</div>
                }, {
                    title: 'Status',
                    dataIndex: 'orderState',
                    key: 'orderState',
                    render: (text, record) => {
                        let color = 'red';
                        let label = 'BELUM DIBAYAR';
                        let keyAwb = 'false';
                        let colorAwb = 'red';
                        let labelAwb = 'BELUM RESI';

                        if (text === SUBMITTED) {
                            if (record.paymentDetail.transactionStatus === 'paid') {
                                color = 'yellow';
                                label = 'SUDAH DIBAYAR';
                            }
                        } else if (text === CANCELLED) {
                            color = 'black';
                            label = 'DIBATALKAN';
                        } else if (text === COMPLETED) {
                            color = 'green';
                            label = 'SELESAI';
                        } else if (text === DELIVERY) {
                            color = 'blue';
                            label = 'SEDANG DIKIRIM';
                        } else if (text === PACKAGING) {
                            color = 'orange';
                            label = 'SEDANG DIPROSES';
                        }

                        if (Boolean(record.awb)) {
                            keyAwb = 'true';
                            colorAwb = 'green';
                            labelAwb = 'SUDAH RESI';
                        }

                        return (
                            <React.Fragment>
                                <Tag color={color} key={text}>
                                    {label}
                                </Tag>
                                <Tag color={colorAwb} key={keyAwb}>
                                    {labelAwb}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {title: 'Jasa Kirim', dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, record) => (
                        <React.Fragment>
                            <Link to={`/order-detail/${record.id}`} target="_blank">
                                Rincian
                            </Link>
                            <Divider type="vertical"/>
                            <Button
                                type="link"
                                onClick={() => this.props.generalDispatcher(MODAL_REDUCER, {modalVisibilityResi: true, orderDetailToUpdateResi: record})}
                            >
                                Update Resi
                            </Button>
                        </React.Fragment>
                    )
                }
            ],
            manifestData: {...ManifestDetailObj},
            modalVisible: false,
            editResiRecord: {},
            selectedPrint: [],
            selectedRowKeys: [],
        }
    }

    setModalAwbVisible = (value, orderDetail) => {
        this.setState({modalVisible: value, editResiRecord: orderDetail})
    }

    dataChange = (e) => {
        const {editResiRecord} = this.state;
        let data = editResiRecord;
        data.awb = e.target.value;
        this.setState({editResiRecord: data});
    };

    changeResiButtonHandler = () => {
        const {editResiRecord} = this.state;
        let values = {awb: editResiRecord.awb}
        let orderAudit = {
            orderId: editResiRecord.id,
            action: listOfKey[4],
            description: OrderAuditState.SELLER.AWB_SUBMITTED_FROM_DASHBOARD,
            awb: editResiRecord.awb,
        }
        if (Boolean(editResiRecord.awb)) {
            updateOrderDetail(editResiRecord.id, values)
                .then((r) => {
                    this.orderAudit(orderAudit)
                    this.setModalAwbVisible(false, {});
                })
                .catch((err) => {
                    this.setModalAwbVisible(false, {});
                    alert('terjadi kesalahan, gagal menyimpan data!');
                });
        } else {
            alert('resi tidak boleh kosong!');
        }
    }

    showButtonDownload = () => {
        const {selectedPrint, orderReducer} = this.props;
        const {shippingMethod, userIdManifestSelected} = orderReducer;
        const manifestData = {
            id: generateMd5Id(randomNumber(6)),
            userId: userIdManifestSelected.id,
            manifestId: `MNF-${getDateEpochFormat()}-${randomNumber(3)}`,
            createdAt: getDateEpochFormat(),
            lastUpdate: getDateEpochFormat(),
            orderList: selectedPrint,
            state: 'PROCEED'
        }
        return (
            <React.Fragment>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            style={Boolean(shippingMethod) && Boolean(selectedPrint) && selectedPrint.length > 0 ? {
                                backgroundColor: Colors.darkseagreen,
                                color: 'white'
                            } : null}
                            icon={<FileTextOutlined/>}
                            size="large"
                            theme="filled"
                            disabled={Boolean(shippingMethod) && Boolean(selectedPrint) && selectedPrint.length > 0 ? false : true}
                        >
                            Create Manifest
                        </Button>
                    )}
                    content={(() => this.manifestRef)}
                    onAfterPrint={() => this.setManifestData(manifestData)}
                />
                <div style={{display: 'none'}}>
                    <PrintManifestComponent
                        data={manifestData}
                        orderList={selectedPrint}
                        ref={(el) => (this.manifestRef = el)}
                    />
                </div>
            </React.Fragment>
        )
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        let selectedRow = setOrderDataSelectedRows(selectedRows);
        this.props.generalDispatcher(MANIFEST_REDUCER, {selectedRowExcel: selectedRow, selectedPrint: selectedRows, selectedRowKeys})
    };

    start = () => {
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
            });
        }, 1000);
        this.props.clearSelectedRow()
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {userIdManifestSelected} = this.props.orderReducer;
        if(!isEqual(userIdManifestSelected, prevProps.orderReducer.userIdManifestSelected)){
            this.setState({selectedRowKeys: []})
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns} = this.state
        const {loading, orderListForManifest, selectedRowKeys} = this.props.manifestReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        }

        return (
            <div>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    expandedRowRender={(record) => {
                        return(
                            <>
                                <ProductList productList={record.products}/>
                                {record?.gifts?.length > 0 && <GiftListComponent giftList={record.gifts} /> }
                            </>
                        )
                    }}
                    dataSource={orderListForManifest}
                    rowKey="id"
                    rowSelection={rowSelection}
                    pagination={{pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true}}
                    loading={loading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {orderReducer, manifestReducer} = state;

    return {orderReducer, manifestReducer};
};

export default connect(mapStateToProps, {getOrderList, orderDispatcher, generalDispatcher})(CreateManifestComponent);
