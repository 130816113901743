import React from 'react';
import {Card, Row} from "antd";
import ExportProductDailyCountComponent from "./export-product-daily-count.component";
import {formatUnixDateToReadable, isEqual} from "../../ui-util/general-variable";
import {connect} from "react-redux";
import _ from 'lodash'
import Chart from "chart.js";
import {Colors} from "../../ui-util/colors";

class AnalyticsProductDailyCountComponent extends React.PureComponent {
    constructor() {
        super()
        this.chartRef = React.createRef();

        this.state = {
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'date',
                    width: 150,
                    render: (dates) => (formatUnixDateToReadable(dates, 'll'))
                },
                {
                    title: 'Produk Terjual',
                    dataIndex: 'count',
                },
            ],
            productSoldPerDay: []
        }
    }

    componentDidMount() {
        this.initiateChartData()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(!isEqual(this.props.analyticsProductReducer.productSoldPerDay, prevProps.analyticsProductReducer.productSoldPerDay)){
            this.initiateChartData()
        }
    }

    changeFormatDate = (dataList) => {
        let chartData = []
        dataList.forEach((item) => {
            item = formatUnixDateToReadable(item.x, 'DD/MM/YY')
            chartData.push(item)
        })
        return chartData
    }

    getChartDataList = () => {
        const {productSoldPerDay} = this.props.analyticsProductReducer
        let dailyProductChartList = []
        _.forEach(productSoldPerDay, item => {
            let newItem = {
                x: item.date,
                y: item.count
            }

            dailyProductChartList.push(newItem)
        })

        return dailyProductChartList
    }

    initiateChartData = () => {
        let dataList = this.getChartDataList()

        if (window.myChart && window.myChart !== null) {
            window.myChart.destroy()
        }

        if (dataList.length > 0) {
            let productSoldPerDayToDisplay = dataList
            window.myChart = new Chart(this.chartRef.current, {
                type: 'line',
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    color: 'rgba(0, 0, 0, 0)'
                                },
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    min: 0
                                },
                                gridLines: {
                                    color: 'rgba(0, 0, 0, 0.05)'
                                },
                            }
                        ]
                    }
                },
                data: {
                    labels: this.changeFormatDate(dataList),
                    datasets: [
                        {
                            label: 'Current Month',
                            data: productSoldPerDayToDisplay,
                            borderColor: Colors.darkseagreen,
                            backgroundColor: 'transparent',
                            pointBackgroundColor: Colors.darkseagreen,
                            pointBorderColor: Colors.darkseagreen
                        }
                    ]
                },
            });
        }
    }

    getDataToExport = () => {
        const {productSoldPerDay: productSoldToExport} = this.props.analyticsProductReducer
        _.forEach(productSoldToExport, item => item.dateToStr = formatUnixDateToReadable(item.date, 'DD/MM/YYYY'))
        return productSoldToExport
    }


    render() {
        let productSoldToExport = this.getDataToExport()

        return (
            <>
                <Row justify={'space-between'}>
                    <h5>Total Produk Terjual Per Hari</h5>
                    <div style={{marginBottom: 10}}>
                        <ExportProductDailyCountComponent productDailyCount={productSoldToExport}/>
                    </div>
                </Row>
                <Card style={{alignItems: 'center'}}>
                    <canvas id={'chart'} style={{height: 350}} ref={this.chartRef}/>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsProductReducer} = state;

    return {analyticsProductReducer};
};

export default connect(mapStateToProps, {})(AnalyticsProductDailyCountComponent);
