import React, {Component} from 'react';
import {Table} from "antd";

class GiftListComponent extends Component {
    constructor() {
        super()

        this.state = {
            columns: [
                { title: 'Gift Produk', dataIndex: 'itemName', key: 'itemName', width: 750 },
                { title: 'Jumlah', dataIndex: 'count', key: 'count' },
                { title: 'Total Berat', dataIndex: 'weight', key: 'weight' },
            ]
        }
    }
    render() {
        const { columns } = this.state;
        const {giftList} = this.props;

        return (
            <Table columns={columns} dataSource={giftList} pagination={false} rowKey='id' />
        );
    }
}

export default GiftListComponent;