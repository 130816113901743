import React, {PureComponent} from 'react';

class OrderCount extends PureComponent {
    render() {
        return (
            <h5 style={{textAlign: "right"}}>{this.props.count} Pesanan</h5>
        );
    }
}

export default OrderCount;
