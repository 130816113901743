import React from 'react';
import {connect} from 'react-redux';
import {Card, Collapse, Timeline} from "antd";
import _ from "lodash";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import {getAuditStockDetail} from "../../api/audit-stock.api";

const {Panel} = Collapse;

class OrderStockHistoryTimelineComponent extends React.PureComponent {
    constructor() {
        super()

        this.state = {
            stockAudit: null
        }
    }

    panelHeader = () => {
        return (<h5>Riwayat Stock</h5>)
    }

    onTimelineCollapse = () => {
        const {orderId} = this.props
        getAuditStockDetail(orderId)
            .then(result => {
                let auditStock = _.orderBy(result.data, 'createdAt', 'asc')
                this.setState({stockAudit: auditStock})
            })
            .catch(err => console.log("error getAuditStockDetail", err.code))
    }

    render() {
        const {stockAudit} = this.state;

        return (
            <Card style={{marginTop: 30}}>
                <Collapse bordered={false} expandIconPosition="right" onChange={() => this.onTimelineCollapse()}>
                    <Panel header={this.panelHeader()} key="1">
                        <Timeline>
                            {
                                _.map(stockAudit, (v, k) => (
                                    <Timeline.Item key={k}>
                                        <p style={{marginBottom: 0}}>
                                            {formatUnixDateToReadable(
                                                v.createdAt,
                                                "DD-MM-YYYY HH:mm:ss"
                                            )}
                                        </p>
                                        <p>{v.action}</p>
                                        {
                                            _.map(v.products, (p, i) => (
                                                <p key={i}>
                                                    {`nama: ${p.productName}, stock: ${p.prevStockAmount}, minus: ${p.minusStockAmount}, add: ${p.addStockAmount}, current: ${p.currentStockAmount}`}
                                                </p>
                                            ))
                                        }
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </Panel>
                </Collapse>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}

export default connect(
    mapStateToProps,
)(OrderStockHistoryTimelineComponent);
