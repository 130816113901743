const INITIAL_STATE = {
    seller: 'All'
}

export const DROPDOWN_SELLER_REDUCER = 'DROPDOWN_SELLER_REDUCER';

export const dropdownSellerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DROPDOWN_SELLER_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
