import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class GiftDetailModel extends GenericDataModel{
    itemName: String
    description: String
    stock: Number
    image: String
    weight: Number
    created_at: Number

    constructor(giftDetailModel : GiftDetailModel){
        super()
        Object.assign(this, giftDetailModel)
    }
}

export const GiftDetailObj = {
    ...GenericDataObj,
    itemName: null,
    description: null,
    stock: null,
    image: null,
    weight: null,
    created_at: null,
}


