import React, {PureComponent} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from 'antd';
import {connect} from 'react-redux';
import _ from 'lodash'
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from '../../models/user-detail.model';
import moment from 'moment';
import {userValidation} from "../../api/user-validation.api";
import {generateMd5Id, getDateEpochFormat, randomNumber} from "../../ui-util/general-variable";
import {setHistoryUpdateUserLevelToFirestore} from "../../firebase/database/history-update-level";
import {mergeDataUserDetailToFirestore} from "../../firebase/database/user";
import {setAuditPointApi} from "../../api/audit-point.api";
import {AVOSTORE_DETAIL} from "../../reducers/avostore-detail.reducer";
import {orderDispatcher} from "../../actions/order.action";
import {getAvostoreDetail} from "../../actions/avostore-detail.action";

const {Option} = Select;

class FormEditModal extends PureComponent {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            updateLevelForm: {
                title: 'Form Update Level',
                listItem: [
                    {
                        label: 'Level',
                        name: 'level',
                        type: 'options',
                        required: true,
                        messageAlert: 'masukkan level'
                    },
                    {
                        label: 'Tanggal update terakhir',
                        name: 'prevUpdatedLevel',
                        type: 'date',
                        disabled: true
                    },
                    {
                        label: 'Tanggal Update',
                        name: 'updatedLevelAt',
                        type: 'date',
                        required: true,
                        messageAlert: 'masukkan tanggal update'
                    }
                ]
            },
            updateWarehouseForm: {
                title: 'Update Warehouse',
                listItem: [
                    {
                        label: 'Pilih Warehouse',
                        name: 'warehouse',
                        type: 'optionWarehouses',
                        required: true,
                        messageAlert: 'masukkan warehouse'
                    }
                ]
            },
            updateUserRole: {
                title: 'User Role',
                listItem: [
                    {
                        label: 'Pilih Role',
                        name: 'userRole',
                        type: 'optionUserRole',
                        required: true,
                        messageAlert: 'Pilih user role'
                    }
                ]
            },
            updatePointForm: {
                title: 'Form Update Point',
                listItem: [
                    {
                        label: 'Poin',
                        name: 'point',
                        type: 'number',
                        required: true,
                        messageAlert: 'masukkan poin user'
                    }
                ]
            },
            updateParentRefForm: {
                title: 'Form Update Parent Referral',
                listItem: [
                    {
                        label: 'Parent Referral',
                        name: 'parentRef',
                        required: true,
                        messageAlert: 'masukkan no referensi'
                    }
                ]
            },
            updateValidChallengeForm: {
                title: 'Update User Valid For Challenge',
                listItem: [
                    {
                        label: 'Valid For Challenge',
                        name: 'validForChallenge',
                        type: 'optionBoolean',
                        required: true,
                        messageAlert: 'update user challenge status'
                    }
                ]
            },
            updateValidProgressiveMarginForm: {
                title: 'Update User Valid For Progressive Margin',
                listItem: [
                    {
                        label: 'Valid For Progressive Margin',
                        name: 'validForProgressiveMargin',
                        type: 'optionBoolean',
                        required: true,
                        messageAlert: 'update user progressive margin'
                    }
                ]
            },
            updateJoinDateForm: {
                title: 'Form Update Tanggal Bergabung',
                listItem: [
                    {
                        label: 'Join date',
                        name: 'joinDate',
                        type: 'date',
                        required: true,
                        messageAlert: 'masukkan tanggal bergabung'
                    }
                ]
            },
            updateLevelDateForm: {
                title: 'Form Update Tanggal Terakhir Perubahan Level',
                listItem: [
                    {
                        label: 'Tanggal perubahan level sebelumnya',
                        name: 'updatedLevelAt',
                        type: 'date',
                        required: true,
                        messageAlert: 'masukkan tanggal terakhir update'
                    }
                ]
            }
        }
    }

    onFinish = (values) => {
        const {parentRef} = this.props.avostoreDetailReducer.avostoreDetail
        let key = Object.keys(values)

        switch (key[0]) {
            case 'point':
                this.updateUserDetail({point: Number(values.point)}, 'point')
                break;
            case 'parentRef':
                if (values.parentRef.length >= 20) {
                    if (parentRef === values.parentRef) {
                        this.closeFormModal()
                    } else {
                        userValidation({parentRef: values.parentRef})
                            .then(result => {
                                if (result.isValid) {
                                    let requestBody = {
                                        parentRef: values.parentRef,
                                        warehouseDefault: null
                                    }
                                    this.updateUserDetail(requestBody)
                                } else {
                                    alert('Parent Referral tidak valid')
                                }
                            })
                            .catch(err => {
                                alert('Error validate parent id')
                                console.log('Error validate parent id', err)
                            })
                    }
                } else {
                    alert('minimum 20 karakter !')
                }
                break;
            case 'joinDate':
                this.updateUserDetail({joinDate: getDateEpochFormat(values.joinDate)})
                break;
            case 'updatedLevelAt':
                this.setModifiedLevelToFirestore({updatedLevelAt: getDateEpochFormat(values.updatedLevelAt)})
                break;
            case 'level':
                let bodyRequest = {
                    level: values.level,
                    updatedLevelAt: getDateEpochFormat(values.updatedLevelAt)
                }
                this.setModifiedLevelToFirestore(bodyRequest)
                break;
            case 'validForProgressiveMargin':
            case 'validForChallenge':
            case 'userRole':
                this.updateUserDetail(values)
                break;
            default:
                return 0
        }
    }

    setModifiedLevelToFirestore = (requestBody) => {
        const {avostoreDetail} = this.props.avostoreDetailReducer
        let historyUpdateLevelDetail = {
            id: generateMd5Id(randomNumber(6)),
            userId: avostoreDetail.id,
            previousLevel: avostoreDetail.level,
            previousUpdatedLevelAt: avostoreDetail.updatedLevelAt,
            currentLevel: !!requestBody.level ? requestBody.level : avostoreDetail.level,
            updatedAt: requestBody.updatedLevelAt
        }

        setHistoryUpdateUserLevelToFirestore(historyUpdateLevelDetail)
            .then(() => {
                this.updateUserDetail(requestBody)
            })
            .catch(err => console.log("error update history user level", err))
    }

    updateUserDetail = (requestBody, key) => {
        const {avostoreDetail} = this.props.avostoreDetailReducer
        mergeDataUserDetailToFirestore(avostoreDetail.id, requestBody)
            .then(() => {
                if (key === 'point') {
                    this.setPointAudit(requestBody.point)
                }
                this.props.getAvostoreDetail(avostoreDetail.id)
                this.closeFormModal()
                alert('data berhasil diubah');
                window.location.reload()
            })
            .catch(error => {
                console.log('Error mergeDataUserDetailToFirestore', error)
                this.closeFormModal()
                alert('Anda tidak bisa melakukan perubahan! silahkan hubungi tim IT untuk informasi lebih lanjut');
            })
    }

    setPointAudit = (updatePoint) => {
        const {avostoreDetail} = this.props.avostoreDetailReducer

        let auditPointDetail = {
            id: generateMd5Id(randomNumber(6)),
            orderId: null,
            createdAt: getDateEpochFormat(),
            userId: avostoreDetail.id,
            prevPoint: avostoreDetail.point,
            minusPoint: 0,
            addPoint: updatePoint,
            currentPoint: updatePoint,
            action: 'POINT_ADDED_BY_SELLER'
        }

        setAuditPointApi(auditPointDetail)
            .catch(error => {
                this.setState({visible: false});
                console.log("setAuditPointApi:", error)
                alert('data audit gagal diubah !');
            })
    }

    getFormItem = () => {
        const {formType} = this.props.avostoreDetailReducer

        switch (formType) {
            case 'point':
                return this.state.updatePointForm
            case 'parentRef':
                return this.state.updateParentRefForm
            case 'joinDate':
                return this.state.updateJoinDateForm
            case 'updateLevel':
                return this.state.updateLevelDateForm
            case 'updateWarehouse':
                return this.state.updateWarehouseForm
            case 'updateUserRole':
                return this.state.updateUserRole
            case 'validForChallenge':
                return this.state.updateValidChallengeForm
            case 'validForProgressiveMargin':
                return this.state.updateValidProgressiveMarginForm
            default:
                return this.state.updateLevelForm
        }
    }

    closeFormModal = () => {
        this.props.orderDispatcher(AVOSTORE_DETAIL, {modalVisibilityForm: false})
    }

    render() {
        const item = this.getFormItem()
        const {avoskinWarehouseList, avostoreDetail, modalVisibilityForm} = this.props.avostoreDetailReducer
        const {level, updatedLevelAt, userRole} = avostoreDetail

        return (
            <Modal
                title={item.title}
                visible={modalVisibilityForm}
                onCancel={() => this.props.orderDispatcher(AVOSTORE_DETAIL, {modalVisibilityForm: false})}
                footer={null}
            >
                <Form onFinish={this.onFinish}
                      initialValues={{'level': level, 'prevUpdatedLevel': moment.unix(updatedLevelAt)}}
                      ref={this.formRef}>
                    {
                        _.map(item.listItem, (v, k) => {
                            switch (v.type) {
                                case 'date':
                                    return (
                                        <Form.Item
                                            key={k.toString()}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <DatePicker disabled={v.disabled}/>
                                        </Form.Item>
                                    )
                                case 'optionWarehouses':
                                    return (
                                        <Form.Item
                                            key={k.toString()}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Select style={{width: 220}}>
                                                {
                                                    avoskinWarehouseList.length > 0 &&
                                                        avoskinWarehouseList.map(item => (
                                                            <Option
                                                                value={item.id}>{item.name}</Option>
                                                        ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'optionBoolean':
                                    return (
                                        <Form.Item
                                            key={k.toString()}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Select style={{width: 220}}>
                                                <Option value={true}>Ya</Option>
                                                <Option value={false}>Tidak</Option>
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'optionUserRole':
                                    return (
                                        <Form.Item
                                            key={k.toString()}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Select style={{width: 220}} defaultValue={userRole}>
                                                <Option value={'ADMINISTRATOR'}>ADMINISTRATOR</Option>
                                                <Option value={'SALES'}>SALES</Option>
                                                <Option value={'OPERATIONAL'}>OPERATIONAL</Option>
                                                <Option value={'FINANCE'}>FINANCE</Option>
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'options':
                                    return (
                                        <Form.Item
                                            key={k.toString()}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Select style={{width: 220}}>
                                                <Option value={PREMIUM_2}>PREMIUM 2</Option>
                                                <Option value={PREMIUM_1}>PREMIUM 1</Option>
                                                <Option value={PLATINUM}>PLATINUM</Option>
                                                <Option value={BRONZE}>BRONZE</Option>
                                                <Option value={END_CUSTOMER}>END CUSTOMER</Option>
                                                <Option value={DISTRIBUTION_CHANNEL_1}>DISTRIBUTION CHANNEL 1</Option>
                                                <Option value={DISTRIBUTION_CHANNEL_2}>DISTRIBUTION CHANNEL 2</Option>
                                                <Option value={DISTRIBUTION_CHANNEL_3}>DISTRIBUTION CHANNEL 3</Option>
                                            </Select>
                                        </Form.Item>
                                    )
                                case 'number':
                                    return (
                                        <Form.Item
                                            key={k}
                                            label={v.label}
                                            name={v.name}
                                            rules={[
                                                {required: v.required, message: v.placeholder},
                                            ]}
                                        >
                                            <InputNumber min="0" style={{width: 250}} placeholder={v.placeholder}/>
                                        </Form.Item>
                                    )
                                default:
                                    return (
                                        <Form.Item
                                            key={k}
                                            label={v.label}
                                            name={v.name}
                                            rules={[{
                                                required: v.required,
                                                message: v.messageAlert,
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    )
                            }
                        })
                    }
                    <Form.Item style={{alignItems: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer, avostoreDetailReducer} = state;

    return {warehouseReducer, avostoreDetailReducer};
}

export default connect(mapStateToProps, {orderDispatcher, getAvostoreDetail})(FormEditModal);
