import React from "react";
import {Row, Button, Card, Dropdown, Menu, Tabs} from "antd";
import {connect} from "react-redux";
import {Colors} from "../../ui-util/colors";
import {getGuaranteeListAction, guaranteeDispatcher} from "../../actions/guarantee.action";
import {GUARANTEE_REDUCER, GUARANTEE_TAB_DEFAULT} from "../../reducers/guarantee.reducer";
import GuaranteeListComponent from "./guarantee-list.component";
import isEqual from "react-fast-compare";
import GuaranteeListHeaderComponent from "./guarantee-list-header.component";
import {getUserListByLevel} from "../../actions/user.action";

const {TabPane} = Tabs;

class GuaranteePage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'ACTIVE',
                    tab: 'Active'
                }, {
                    key: 'COMPLETED',
                    tab: 'Complete'
                }
            ],
            guaranteeTypeList: [
                {
                    name: 'Avostore Guarantee',
                    value: 'GUARANTEE'
                }, {
                    name: 'Distribution Channel Guarantee',
                    value: 'DC_INSTALLMENT'
                }
            ]
        }
    }

    componentDidMount() {
        this.props.getGuaranteeListAction()
        this.props.getUserListByLevel()
    }

    onTabChange = (activeTab) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {activeTab})
    }

    dropDownGuaranteeType = () => {
        let {guaranteeTypeList} = this.state

        return (
            <Menu>
                {
                    guaranteeTypeList.map((type, index) => (
                        <Menu.Item key={index} onClick={() => this.guaranteeTypeButtonHandler(type)}>
                            <Button type="link">{type.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    guaranteeTypeButtonHandler = (guaranteeType) => {
        this.props.guaranteeDispatcher(GUARANTEE_REDUCER, {guaranteeType, sellerId: ""})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!isEqual(this.props.guaranteeReducer.guaranteeType, prevProps.guaranteeReducer.guaranteeType) ||
            !isEqual(this.props.guaranteeReducer.activeTab, prevProps.guaranteeReducer.activeTab) ||
            !isEqual(this.props.guaranteeReducer.currentPage, prevProps.guaranteeReducer.currentPage) ||
            !isEqual(this.props.guaranteeReducer.pageSize, prevProps.guaranteeReducer.pageSize)
        ) {
            this.props.getGuaranteeListAction()
        }
    }

    componentWillUnmount() {
        this.props.guaranteeDispatcher(GUARANTEE_TAB_DEFAULT)
    }

    render() {
        const {tabList} = this.state
        const {activeTab, guaranteeType} = this.props.guaranteeReducer
        return (
            <Card>
                <Row>
                    <div style={{marginTop: 5}}>Jenis Guarantee : </div>
                    <Dropdown overlay={this.dropDownGuaranteeType()} trigger={['click']}>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: Colors.darkseagreen,
                                marginBottom: 20,
                                minWidth: '100px',
                                marginLeft: 20
                            }}
                            size="medium"
                            theme="filled"
                        >
                            {guaranteeType?.name}
                        </Button>
                    </Dropdown>
                </Row>
                <GuaranteeListHeaderComponent/>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen}}
                    type="card"
                    size={'large'}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <GuaranteeListComponent/>
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {guaranteeReducer} = state

    return {guaranteeReducer}
}

export default connect(mapStateToProps, {
    guaranteeDispatcher,
    getGuaranteeListAction,
    getUserListByLevel
})(GuaranteePage)
