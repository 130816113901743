import {GenericDataModel} from "./generic-data.model";

export default class RewardModel extends GenericDataModel{
    title: String
    description: String
    image: String
    startDate: String
    endDate: String
    valid: Boolean

    constructor(RedeemRewardModel : RewardModel){
        super()
        Object.assign(this, RedeemRewardModel)
    }
}

export const RewardDetailObj = {
    title: null,
    description: null,
    image: null,
    startDate: null,
    endDate: null,
    valid: true
}
