import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportPointComponent extends React.Component {
    render() {
        const {datPoint, fileName} = this.props;
        return (
            <ExcelFile
                filename={fileName}
                element={
                    <Button style={{
                        backgroundColor: datPoint.length > 0 && Colors.darkseagreen,
                        color: datPoint.length > 0 && 'white',
                        marginLeft: 20
                    }}>Export</Button>
                }>
                <ExcelSheet data={datPoint} name="History Point">
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Created At" value="createdAt"/>
                    <ExcelColumn label="Order Id" value="orderId"/>
                    <ExcelColumn label="Action" value="action"/>
                    <ExcelColumn label="Prev Point" value="prevPoint"/>
                    <ExcelColumn label="Add Point" value="addPoint"/>
                    <ExcelColumn label="Minus Point" value="minusPoint"/>
                    <ExcelColumn label="Current Point" value="currentPoint"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
