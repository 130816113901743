import React from "react";
import {Button} from "antd";
import {Colors} from "../ui-util/colors";
import {PrinterOutlined} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import {connect} from "react-redux";
import {avoskinId, generateMd5Id, getDateEpochFormat, randomNumber} from "../ui-util/general-variable";
import {DELIVERY, PACKAGING} from "../models/order-detail.model";
import OrderAuditState from "../models/OrderAuditState.json";
import {updateRelatedDataOrderFromApi} from "../api/pre-order.api";
import PrintLabelDcComponent from "./print-label-dc.component";
import {OrderAuditObj} from "../models/order-audit.model";
import {setOrderAuditToFirestore} from "../firebase/database/order-audit";
import {getDcOrderListAction} from "../actions/dc.action";
import {DC_ORDER_REDUCER} from "../reducers/dc-order.reducer";
import {generalDispatcher} from "../actions/general.action";
import {getOrderList} from "../actions/order.action";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class ButtonPrintLabelComponent extends React.PureComponent {

    updateOrderPrinted = (orders) => {
        this.setState({selectedRowKeys: [], selectedRowPrintLabel: []})
        orders.forEach((order, index) => {
            const requestBody = {
                uid: order.sellerId,
                orderDetail: {
                    id: order.id,
                    isPrinted: true,
                    deliveryTime: order.warehouseName.includes('Sleman') ? null : getDateEpochFormat(),
                    orderState: order.warehouseName.includes('Sleman') ? PACKAGING : DELIVERY
                }
            }

            const orderAudit = {
                orderId: order.id,
                action: order.warehouseName.includes('Sleman') ? listOfKey[0] : listOfKey[1],
                description: order.warehouseName.includes('Sleman') ? OrderAuditState.SELLER.ORDER_ON_PACKAGING : OrderAuditState.SELLER.ORDER_ON_DELIVERY
            }

            updateRelatedDataOrderFromApi(requestBody)
                .then(r => {
                    this.createOrderAudit(orderAudit)
                    if((index + 1) === orders.length){
                        this.props.getDcOrderListAction()
                        this.props.generalDispatcher(DC_ORDER_REDUCER, {selectedRowKeys: []})
                    }
                })
                .catch(err => console.log("updateRelatedDataOrderFromApi error :", err))
        });
    }

    createOrderAudit = (orderAudit) => {
        let auditOrderDetail = {
            ...OrderAuditObj,
            ...orderAudit,
            id: generateMd5Id(randomNumber(6)),
            actorId: avoskinId(),
            actorName: 'Avoskin',
            timestamp: getDateEpochFormat(),
        }
        setOrderAuditToFirestore(auditOrderDetail)
            .catch(err => {
                console.log('Error setOrderAuditToFirestore', err)
            })
    }

    render() {
        const {tabReducer, dcOrderReducer} = this.props
        const {activeTab} = tabReducer
        const {selectedOrderToPrintLabel} = dcOrderReducer

        return (
            <>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            style={{
                                backgroundColor: selectedOrderToPrintLabel.length > 0 && Colors.darkseagreen,
                                color: selectedOrderToPrintLabel.length > 0 && 'white',
                                marginRight: 10
                            }}
                            icon={<PrinterOutlined/>}
                            size="large"
                            theme="filled"
                            disabled={selectedOrderToPrintLabel.length === 0}
                        >
                            Print Label
                        </Button>
                    )}
                    content={() => this.componentRef}
                    onAfterPrint={() => this.updateOrderPrinted(selectedOrderToPrintLabel)}
                />
                {activeTab === 'waitingForDelivery' &&
                <div style={{display: 'none'}}>
                    <PrintLabelDcComponent
                        pageStyle="@page { size: A4}"
                        selectedPrint={selectedOrderToPrintLabel}
                        ref={(el) => (this.componentRef = el)}
                    />
                </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {tabReducer, dcOrderReducer, orderReducer} = state;

    return {tabReducer, dcOrderReducer, orderReducer};
};

export default connect(mapStateToProps, {getDcOrderListAction, getOrderList, generalDispatcher})(ButtonPrintLabelComponent)
