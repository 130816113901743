import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    analyticsGuaranteeList: [],
    analyticsGuaranteeExportList: [],
    loading: false,
    activeTab: 'COMPLETED',
    totalData: 0,
    currentPage: 1,
    pageSize: 10,
    totalGuarantee: {},
    guaranteeType: "",
    selectedGuaranteeType : {
        name: 'All',
        value: ''
    }
}

export const ANALYTICS_GUARANTEE = 'ANALYTICS_GUARANTEE'
export const ANALYTICS_GUARANTEE_TOTAL = 'ANALYTICS_GUARANTEE_TOTAL'
export const RESET_GUARANTEE_TYPE = 'RESET_GUARANTEE_TYPE'
export const SET_SELECTED_GUARANTEE = 'SET_SELECTED_GUARANTEE'

export const analyticsGuaranteeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ANALYTICS_GUARANTEE:
        case ANALYTICS_GUARANTEE_TOTAL:
            return {...state, ...action.payload}
        case SET_SELECTED_GUARANTEE:
            return {...state, ...action.payload}
        case RESET_GUARANTEE_TYPE:
            return {...state, guaranteeType: ""}
        default:
            return state
    }
}
