import {formatUnixDateToReadable} from "./general-variable";

const formatUserAnalyticsToExport = (userList) => {
    let selectedRow = []
    userList.forEach((row) => {
        let addressDetail = row.address
        let data = {
            joinDate: formatUnixDateToReadable(row.joinDate, 'll'),
            name: row.name,
            email: row.email,
            phoneNumber: row.phoneNumber,
            identityCardNumber: row.identityCardNumber,
            address: `${addressDetail.address}, ${addressDetail.district}, ${addressDetail.city}, ${addressDetail.province}`,
            avostoreArea: row.avostoreArea,
            level: row.level,
            status: row.status,
        }
        selectedRow.push(data)
    })
    return selectedRow
}

export {formatUserAnalyticsToExport}
