import React from "react";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {Drawer, Table} from "antd";
import ExportSalesDetail from "./export-sales-detail.component";

class RevenueSaleDetailComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columnOrderDetail: [
                {
                    title: 'Order ID',
                    dataIndex: 'orderId',
                    key: 'orderId'
                }, {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'receiver',
                    render: (shippingAddress) => <div>{shippingAddress.receiver}</div>
                }, {
                    title: 'Grand Total',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (grandTotal) => (doConvertNumberToRupiahFormat(grandTotal))
                }, {
                    title: 'Total Payment Received',
                    key: 'totalPaymentReceived',
                    dataIndex: 'totalPaymentReceived',
                    render: (totalPaymentReceived) => (doConvertNumberToRupiahFormat(totalPaymentReceived))
                }, {
                    title: 'Cashback',
                    dataIndex: 'grandCashback',
                    key: 'cashback',
                    render: (grandCashback) => (doConvertNumberToRupiahFormat(grandCashback))
                }, {
                    title: 'Shipping',
                    dataIndex: 'shippingPrice',
                    key: 'shipping',
                    render: (shippingPrice) => (doConvertNumberToRupiahFormat(shippingPrice))
                }, {
                    title: 'Voucher',
                    dataIndex: 'voucherReduction',
                    key: 'voucher',
                    render: (voucherReduction) => (doConvertNumberToRupiahFormat(voucherReduction))
                }, {
                    title: 'Poin',
                    dataIndex: 'discountPoint',
                    key: 'poin',
                    render: (discountPoint) => (doConvertNumberToRupiahFormat(discountPoint))
                }, {
                    title: 'Net Sales',
                    key: 'netSales',
                    render: (record) => (doConvertNumberToRupiahFormat(record.grandTotal - record.shippingPrice))
                },
            ],
            columnRevenueDetail: [
                {
                    key: 'date',
                    title: 'Date',
                    dataIndex: 'date',
                    render: (dates) => (formatUnixDateToReadable(dates, 'DD/MM/YYYY'))
                }, {
                    key: 'orderCount',
                    title: 'Order Count',
                    dataIndex: 'orderCount'
                }, {
                    key: 'grossAmount',
                    title: 'Grand Total',
                    dataIndex: 'grossAmount',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                },
                {
                    key: 'totalPaymentReceived',
                    title: 'Total Payment Receive',
                    dataIndex: 'totalPaymentReceived',
                    render: (totalPaymentReceived) => (doConvertNumberToRupiahFormat(totalPaymentReceived))
                },
                {
                    key: 'cashback',
                    title: 'Cashback',
                    dataIndex: 'cashback',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    key: 'shipping',
                    title: 'Shipping',
                    dataIndex: 'shipping',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    key: 'voucher',
                    title: 'Voucher',
                    dataIndex: 'voucher',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    key: 'pointUsage',
                    title: 'Poin',
                    dataIndex: 'pointUsage',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    key: 'netSales',
                    title: 'Net Sales',
                    dataIndex: 'netSales',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }
            ]
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columnOrderDetail, columnRevenueDetail} = this.state
        const {setOrderDrawerVisibility, isOrderDrawerVisible, revenueSaleDetail} = this.props

        return (
            <Drawer
                title={'Revenue Sale Detail'}
                width={1200}
                onClose={() => setOrderDrawerVisibility(false)}
                visible={isOrderDrawerVisible}
            >
                <Table dataSource={[revenueSaleDetail]} columns={columnRevenueDetail} rowKey="date" pagination={false}
                       style={{marginTop: 10}}/>
                <div style={{marginTop: 20, marginBottom: 10}}>
                    <ExportSalesDetail salesDetail={revenueSaleDetail.orderDetail}/>
                </div>
                <Table dataSource={revenueSaleDetail.orderDetail} columns={columnOrderDetail} rowKey="orderId" pagination={false}
                       scroll={{x: 1200}} style={{marginTop: 20}}/>
            </Drawer>
        )
    }
}

export default RevenueSaleDetailComponent
