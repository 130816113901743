import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Collapse, Tag, Timeline} from "antd";
import {getShipmentTracking} from "../../api/rajaongkir.api";

const { Panel } = Collapse;

class ShipmentTracking extends PureComponent {
    constructor() {
        super()

        this.state = {
            shipmentTracking: []
        }
    }

    componentDidMount(){
        const {orderDetail} = this.props;

        if(orderDetail.awb){
            getShipmentTracking(orderDetail.awb, orderDetail.shippingMethod)
                .then(result => {
                    this.setState({shipmentTracking: result.manifest})
                })
                .catch(err => {
                    alert("Gagal mendapatkan rincian pengiriman")
                })
        }
    }

    renderHeader = () => {
        const { orderDetail } = this.props;

        return(
            <div className="d-flex flex-row">
                <p style={{margin: 0}}>{`${orderDetail.shippingMethod} - ${orderDetail.shippingService}`}</p>
                <Tag color="purple" style={{marginLeft: 30}}>{orderDetail.awb ? `${orderDetail.awb}` : 'Belum ada nomor resi'}</Tag>
            </div>
        )
    }

    render() {
        const { shipmentTracking } = this.state;
        return (
            <div style={{paddingTop: 30}}>
                <h6 style={{margin: 0}}>Informasi Jasa Kirim</h6>
                <Collapse bordered={false} expandIconPosition="right">
                    <Panel header={this.renderHeader()} key="1">
                        <Timeline style={{paddingTop: 30}}>
                            {
                                shipmentTracking.map((item, index ) => (
                                    <Timeline.Item key={index}>
                                        <p style={{marginBottom: 0}}>{item.manifest_date} {item.manifest_time}</p>
                                        <p>{item.manifest_description} ({item.city_name})</p>
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </Panel>
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps)(ShipmentTracking);
