import React from "react";
import {connect} from "react-redux";
import {getUserListByLevel} from "../../actions/user.action";
import {orderDispatcher} from "../../actions/order.action";
import {DC_CASHBACK_REDUCER} from "../../reducers/dc-cashback.reducer";
import {getCashbackListDc} from "../../actions/dc.action";
import DcCashbackFilterComponent from "./dc-cashback-filter.component";
import DcCashbackHeaderComponent from "./dc-cashback-header.component";
import DcCashbackListComponent from "./dc-cashback-list.component";

class DcCashbackPage extends React.PureComponent {

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        await this.props.getUserListByLevel('report')
        this.props.getCashbackListDc()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const {avoStoreDcList} = this.props.avostoreListReducer
        if (avoStoreDcList.length !== prevProps.avostoreListReducer.avoStoreDcList.length) {
            let dcDetail = avoStoreDcList[0]
            this.props.orderDispatcher(DC_CASHBACK_REDUCER, {dcId: dcDetail?.id, dcName: dcDetail?.name})
        }
    }

    render() {
        return (
            <>
                <DcCashbackFilterComponent/>
                <DcCashbackHeaderComponent/>
                <DcCashbackListComponent/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreListReducer} = state

    return {avostoreListReducer};
}

export default connect(mapStateToProps, {
    getUserListByLevel,
    orderDispatcher,
    getCashbackListDc
})(DcCashbackPage)
