import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'

const config = {
			apiKey: process.env.REACT_APP_FBS_API_KEY,
			authDomain: process.env.REACT_APP_FBS_AUTH_DOMAIN,
			databaseURL: process.env.REACT_APP_FBS_DB_URL,
			projectId: process.env.REACT_APP_FBS_PROJECT_ID,
			storageBucket: process.env.REACT_APP_FBS_STORAGE_BUCKET,
			messagingSenderId: process.env.REACT_APP_FBS_MESSAGING_SENDER_ID,
			appId: process.env.REACT_APP_FBS_APP_ID,
			measurementId: process.env.REACT_APP_FBS_MEASUREMENT_ID
        }

firebase.initializeApp(config);

const auth = firebase.auth();
const firestore = firebase.firestore();

//storage
const firebaseStorage = firebase.storage();
const storageTaskEvent = firebase.storage.TaskEvent;
const storageTaskState = firebase.storage.TaskState;

//auth
let provider = new firebase.auth.GoogleAuthProvider();

export {
	auth,
	firestore,
	firebaseStorage,
	storageTaskEvent,
	storageTaskState,
	provider
};
