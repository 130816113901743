import React from "react";
import {Button, Table} from "antd";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import RevenueSaleDetailComponent from "./revenue-sale-detail.component";
import ExportSalesList from "./export-sales-list.component";
import {connect} from "react-redux";

class RevenueSalesListComponent extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Dates',
                    dataIndex: 'date',
                    key: 'dates',
                    width: 120,
                    render: (dates) => (formatUnixDateToReadable(dates, 'DD/MM/YYYY'))
                }, {
                    title: 'Orders',
                    dataIndex: 'orderCount',
                    key: 'orders',
                    width: 80,
                }, {
                    title: 'Additional Margin',
                    dataIndex: 'additionalMargin',
                    key: 'additionalMargin',
                    width: 180,
                    render: (additionalMargin) => (doConvertNumberToRupiahFormat(additionalMargin))
                },{
                    title: 'Progressive Margin',
                    dataIndex: 'progressiveMargin',
                    key: 'progressiveMargin',
                    width: 180,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Grand Total',
                    dataIndex: 'grossAmount',
                    key: 'grandTotal',
                    width: 180,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Total Payment Received',
                    dataIndex: 'totalPaymentReceived',
                    key: 'totalPaymentReceive',
                    width: 180,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Cashback',
                    dataIndex: 'cashback',
                    key: 'cashback',
                    width: 160,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Shipping',
                    dataIndex: 'shipping',
                    key: 'shipping',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Voucher',
                    dataIndex: 'voucher',
                    key: 'voucher',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Poin',
                    dataIndex: 'pointUsage',
                    key: 'poin',
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Net Sales',
                    dataIndex: 'netSales',
                    key: 'netSales',
                    width: 180,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                }, {
                    title: 'Total Pajak',
                    dataIndex: 'totalTax',
                    key: 'totalTax',
                    width: 180,
                    render: (record) => (doConvertNumberToRupiahFormat(record))
                },
                // {
                //     title: 'Total Sebelum Pajak',
                //     dataIndex: 'subTotalBeforeTax',
                //     key: 'subTotalBeforeTax',
                //     width: 180,
                //     render: (record) => (doConvertNumberToRupiahFormat(record))
                // },
                {
                    title: 'Detail',
                    dataIndex: '',
                    key: 'detail',
                    width: 90,
                    render: (record) => (<Button onClick={() => this.detailButtonHandler(true, record)}>
                        Detail
                    </Button>)
                },
            ],
            isOrderDrawerVisible: false,
            revenueSaleDetail: {}
        }
    }

    detailButtonHandler = (value, record) => {
        this.setState({isOrderDrawerVisible: value, revenueSaleDetail: record})
    }

    setOrderDrawerVisibility = (value) => {
        this.setState({isOrderDrawerVisible: value})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {columns, isOrderDrawerVisible, revenueSaleDetail} = this.state
        const {salesDataList} = this.props.revenueReducer

        return (
            <div style={{marginTop: 20}}>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <ExportSalesList salesList={salesDataList}/>
                </div>
                <Table dataSource={salesDataList} columns={columns} rowKey="date" scroll={{x: 1500}}/>
                <RevenueSaleDetailComponent isOrderDrawerVisible={isOrderDrawerVisible}
                                            setOrderDrawerVisibility={this.setOrderDrawerVisibility}
                                            revenueSaleDetail={revenueSaleDetail}/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const {revenueReducer} = state

    return {revenueReducer}
}

export default connect(mapStateToProps, {})(RevenueSalesListComponent)
