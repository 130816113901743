import React, {Component} from 'react';
import {Button, Col, Drawer, Image, Row} from "antd";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import EmailListComponent from "../voucher-detail/email-list.component";
import _ from "lodash";

class GiftCardDetailComponent extends Component {
    constructor() {
        super();

        this.state = {
            giftCardList: [
                {key: 'image', name: 'Gambar'},
                {key: 'name', name: 'Nama'},
                {key: 'giftCode', name: 'Kode Gift Card'},
                {key: 'description', name: 'Deskripsi'},
                {key: 'startDate', name: 'Tanggal Mulai'},
                {key: 'endDate', name: 'Tanggal Berakhir'},
                {key: 'isMultiply', name: 'Berlaku Kelipatan'},
                {key: 'multiplyType', name: 'Tipe Kelipatan'},
                {key: 'userLevelList', name: 'Level'},
                {key: 'minimumMultiplyValue', name: 'Min. Kelipatan'},
                {key: 'maximumTotalUsePerUser', name: 'Total Max. Penggunaan (per user)'},
                {key: 'created_at', name: 'Tanggal Dibuat'}
            ],
            isEmailListVisible: false
        }
    }

    renderItemValue = (item) => {
        const {giftCardDetail} = this.props

        switch (item.key) {
            case 'endDate':
            case 'startDate':
            case 'created_at':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{formatUnixDateToReadable(giftCardDetail[item.key], 'll')}</p>
                        </Col>
                    </Row>
                )
            case 'image':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <Image src={giftCardDetail[item.key]} width={100} height={100}/>
                        </Col>
                    </Row>
                )
            case 'isMultiply':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{giftCardDetail.isMultiply ? 'Ya' : 'Tidak'}</p>
                        </Col>
                    </Row>
                )
            case 'multiplyType':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{giftCardDetail.multiplyType === 'PRODUCTS' ? 'Kelipatan Produk' : 'Kelipatan Total Belanja'}</p>
                        </Col>
                    </Row>
                )
            default:
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{giftCardDetail[item.key]}</p>
                        </Col>
                    </Row>
                )
        }
    }

    setEmailListVisibility = (isEmailListVisible) => {
        this.setState({isEmailListVisible})
    }

    getWarehouseList = () => {
        const {warehouseDcAvoskinList} = this.props
        const {giftCardDetail} = this.props
        let warehouseListToDisplay = []

        _.forEach(warehouseDcAvoskinList, (item, index) => {
            _.forEach(giftCardDetail.warehouseList, (id, i) => {
                if (item.id === id) {
                    warehouseListToDisplay.push({...item})
                }
            })
        })

        return warehouseListToDisplay
    }

    render() {
        const {onCloseHandler, isGiftDetailVisible, giftCardDetail} = this.props
        const {giftCardList, isEmailListVisible} = this.state;

        return (
            <Drawer
                title={'Gift Item Detail'}
                width={700}
                onClose={() => onCloseHandler(false)}
                visible={isGiftDetailVisible}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        {giftCardList.map(item => this.renderItemValue(item))}
                    </Col>
                </Row>
                {
                    giftCardDetail?.minimumProductOrdered?.length > 0 &&
                    <Row gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>Daftar Produk</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {
                                giftCardDetail.minimumProductOrdered.map((item, index) => (
                                    <div key={index}>
                                        <p>{item.name}</p>
                                        <p>minimum qty : {item.minQty}</p>
                                        <p>kelipatan : {item.minimumMultiplyValue}</p>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                }
                {
                    giftCardDetail?.warehouseList?.length > 0 &&
                    <Row gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>Daftar Gudang</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            {
                                this.getWarehouseList().map((item, index) => (
                                    <div key={index}>
                                        <p>{item.warehouseName}</p>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                }
                <EmailListComponent
                    emailList={giftCardDetail.emailList}
                    isEmailListVisible={isEmailListVisible}
                    setEmailListVisibility={this.setEmailListVisibility}
                />
                {
                    giftCardDetail.emailList.length > 0 &&
                    <div>
                        <Button onClick={() => this.setEmailListVisibility(true)}>Show Email</Button>
                    </div>
                }
            </Drawer>
        );
    }
}

export default GiftCardDetailComponent;