import React from "react";
import {Card, Divider, Image, Row, Space} from "antd";
import {Colors} from "../../ui-util/colors";
import {getLevelText} from "../../ui-util/general-variable";
import {connect} from "react-redux";

class AvostoreDetailHeaderComponent extends React.PureComponent {

    render() {
        const {image, level, referralId, name, email, id, phoneNumber, status, identityCardNumber, avostoreArea, address} = this.props.avostoreDetailReducer.avostoreDetail
        return (
            <Card style={{borderRadius: 25, borderWidth: 5, borderColor: Colors.gainsboro}}>
                <Row>
                    <Row style={{width: '48%'}}>
                        <div className="d-flex align-items-center flex-column">
                            <Image className="p-2" src={!!image ? image : require('../../images/image_profile.png')}
                                   width={100}
                                   height={100} style={{borderRadius: 75}}/>
                            <div style={{fontWeight: 'bold'}}>{getLevelText(level)}</div>
                        </div>
                        <Space direction="vertical" style={{marginLeft: 30}}>
                            <div>{name}</div>
                            <div>id : {id}</div>
                            <div>{email}</div>
                            <div>{phoneNumber}</div>
                            <div>Ref Id : {referralId}</div>
                        </Space>
                    </Row>
                    <Divider type={'vertical'} style={{borderWidth: 1, height: 120, borderColor: Colors.darkseagreen}}/>
                    <Row style={{width: '48%', marginLeft: 20}}>
                        <div style={{width: '100%'}}>
                            <Row style={{alignItems: 'center', marginBottom: 10}}>
                                <div style={{width: '20%'}}>Status</div>
                                <div>:</div>
                                <div style={{marginLeft: 10}}>{status}</div>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 10}}>
                                <div style={{width: '20%'}}>No. KTP</div>
                                <div>:</div>
                                <div style={{marginLeft: 10}}>{identityCardNumber}</div>
                            </Row>
                            <Row style={{alignItems: 'center', marginBottom: 10}}>
                                <div style={{width: '20%'}}>Avostore Area</div>
                                <div>:</div>
                                <div style={{marginLeft: 10}}>{avostoreArea}</div>
                            </Row>
                            <Row style={{marginBottom: 10}}>
                                <div style={{width: '20%'}}>Alamat KTP</div>
                                <div>:</div>
                                <div style={{marginLeft: 10, maxWidth:'70%'}}>{address.address ?
                                    `${address.address}, ${address.district}, ${address.city}, ${address.province}`
                                    : '-'
                                }</div>
                            </Row>
                        </div>
                    </Row>
                </Row>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreDetailReducer} = state;

    return {avostoreDetailReducer};
}

export default connect(mapStateToProps, {})(AvostoreDetailHeaderComponent)
