import React, {Component} from 'react';
import {Card, Col, Row, Table, Dropdown, Button, Divider} from "antd";
import {Colors} from "../../ui-util/colors";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";

class ReportChainComponent extends Component {
    render() {
        const {
            orderTotal,
            topProductListChain,
            columns,
            selectedLevel,
            dropDownLevel,
            loading,
            grandSubTotal,
            productTotalAvoDc
        } = this.props

        return (
            <div style={{marginTop: 30}}>
                <div>
                    <h5>Penjualan DC</h5>
                </div>
                <Row style={{marginTop: 20}}>
                    <Col span={12}>
                        <Card style={{
                            maxWidth: '90%',
                            borderWidth: 1,
                            borderColor: Colors.gainsboro,
                            marginLeft: 30,
                            borderRadius: 7
                        }}>
                            <Row align={'start'}>
                                <div style={{
                                    width: '40%',
                                    borderRightWidth: 'thin',
                                    borderRightStyle: 'solid',
                                    marginRight: 10
                                }}>
                                    <div style={{fontSize: 16}}>Total Penjualan: {orderTotal}</div>
                                    <Divider/>
                                    <div style={{fontSize: 16}}>Total Produk : {productTotalAvoDc}</div>
                                </div>
                                <div>
                                    <div style={{fontSize: 16}}>Total
                                        Sales: </div>
                                    <div style={{marginLeft:5, marginTop:10, fontSize: 20}}>
                                        {doConvertNumberToRupiahFormat(grandSubTotal)}
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <div style={{marginLeft: 30}}>
                            <div style={{marginBottom: 10}}> Pilih level Chain :</div>
                            <Dropdown overlay={dropDownLevel} trigger={['click']}>
                                <Button
                                    style={{
                                        backgroundColor: 'white',
                                        color: Colors.darkseagreen,
                                        marginRight: 10,
                                        minWidth: '100px'
                                    }}
                                    size="medium"
                                    theme="filled"
                                >
                                    {selectedLevel.name}
                                </Button>
                            </Dropdown>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                                    onClick={() => this.props.filterButtonHandler()}
                                    loading={loading}>Filter</Button>
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}>
                    <h5>Top Produk Terjual</h5>
                    <Table dataSource={topProductListChain.map((d, i) => ({ key: i, ...d }))} columns={columns}/>
                </div>
            </div>
        );
    }
}

export default (ReportChainComponent);