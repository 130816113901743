import {getOrderListQuery} from "../api/order.api";
import {CANCELLED, COMPLETED, SUBMITTED} from "../models/order-detail.model";
import {convertStartEndDate} from "../ui-util/general-variable";
import {SAVE_ORDER_REDUCER} from "../reducers/save-order.reducer";

const saveOrderDispatcher = (type, payload) => {
    return {type, payload}
}

const getOrderState = (activeTab) => {
    let orderState = []
    switch (activeTab){
        case COMPLETED:
            orderState = [COMPLETED]
            break
        case CANCELLED:
            orderState = [CANCELLED]
            break
        default:
            orderState = [SUBMITTED]
    }
    return orderState
}

const getNdnOrderList = () => {
    return (dispatch, getState) => {
        dispatch(saveOrderDispatcher(SAVE_ORDER_REDUCER, {
            loading: true
        }))
        const {id: warehouseId} = getState().warehouseReducer.warehouseDetail
        let {activeTab, startDate, endDate, keyword, currentPage, pageSize, buyerId } = getState().saveOrderReducer
        let orderState = getOrderState(activeTab)
        let requestBody = {
            shippingService:"NDN",
            orderState,
            startDate:convertStartEndDate(startDate, 'start'),
            tillDate: convertStartEndDate(endDate),
            warehouseId,
            sort:"desc",
            buyerId,
        }

        const params = {
            showPerPage: pageSize,
            page: currentPage - 1,
            keyword
        }

        getOrderListQuery(requestBody, params)
            .then(result => {
                dispatch(saveOrderDispatcher(SAVE_ORDER_REDUCER, {
                    orderList: result.orders,
                    dataCount: result.dataCount,
                    loading: false
                }))
            })
            .catch(err => {
                console.log('getNdnOrderList', err)
                dispatch(saveOrderDispatcher(SAVE_ORDER_REDUCER, {
                    loading: false
                }))
            })
    }
}

export {
    getNdnOrderList,
    saveOrderDispatcher
}
