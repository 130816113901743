import React from "react";
import {Card} from "antd";
import RevenueHeaderComponent from "./revenue-header.component";
import RevenueItemComponent from "./revenue-item.component";
import RevenueChartComponent from "./revenue-chart.component";
import RevenueSalesListComponent from "./revenue-sales-list.component";
import {connect} from "react-redux";
import {getAnalyticsDataAction} from "../../actions/revenue.action";
import {getUserListByLevel} from "../../actions/user.action";
import isEqual from "react-fast-compare";

class RevenuePage extends React.PureComponent {
    componentDidMount(): void {
        this.props.getUserListByLevel('report')
        this.props.getAnalyticsDataAction()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(!isEqual(this.props.dropdownSellerReducer.seller, prevProps.dropdownSellerReducer.seller)){
            this.props.getAnalyticsDataAction()
        }
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {chartData} = this.props.revenueReducer
        return (
            <Card style={{backgroundColor: 'transparent'}}>
                <RevenueHeaderComponent />
                <RevenueItemComponent />
                {!!chartData && <RevenueChartComponent />}
                <RevenueSalesListComponent />
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {revenueReducer, dropdownSellerReducer} = state

    return {revenueReducer, dropdownSellerReducer}
}

export default connect(mapStateToProps, {getAnalyticsDataAction, getUserListByLevel})(RevenuePage)
