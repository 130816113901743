import {getTargetSalesByDocNameFromFirestore, getTargetSalesListFromFirestore} from "../firebase/database/target-sales";
import {avoskinId, extractQuerySnapshotToArray, getMonthAndYear} from "../ui-util/general-variable";
import {REVENUE_REDUCER, TARGET_SALES} from "../reducers/revenue.reducer";
import _ from 'lodash'
import {getRevenueApi, getRevenueChartApi, getRevenueGrowthSale, getRevenueSalesListApi} from "../api/revenue.api";

const revenueDispatcher = (type, payload) => {
    return {type, payload}
}

const getTargetSales = () => {
    let currentMonth = getMonthAndYear('current')
    let prevMonth = getMonthAndYear('prev')
    return (dispatch) => {
        Promise.all([
            getTargetSalesByDocNameFromFirestore(currentMonth),
            getTargetSalesByDocNameFromFirestore(prevMonth)
        ])
            .then(result => {
                let currentTargetSales = extractQuerySnapshotToArray(result[0])
                let prevTargetSales = extractQuerySnapshotToArray(result[1])
                dispatch(revenueDispatcher(TARGET_SALES, {
                    currentTargetSales: currentTargetSales[0],
                    prevTargetSales: prevTargetSales[0]
                }))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

const getTargetSaleList = () => {
    return (dispatch) => {
        getTargetSalesListFromFirestore()
            .then(querySnapshot => {
                let targetSales = extractQuerySnapshotToArray(querySnapshot)
                let tempTargetSales = _.forEach(targetSales, t => t.targetAchievePercentage = Math.ceil(t.targetAchieve/Number(t.targetValue) * 100))
                let sortedTargetSales = _.orderBy(tempTargetSales, ['id'], ['desc'])
                dispatch(revenueDispatcher(TARGET_SALES, {
                    targetSalesList: sortedTargetSales
                }))
            })
            .catch(err => {
                console.log(err)
                })
    }
}

const getAnalyticsDataAction = () => {
    return (dispatch, getState) => {
        const {startDate, endDate} = getState().revenueReducer
        const {seller} = getState().dropdownSellerReducer
        const {avoStoreDcList} = getState().avostoreListReducer

        let sellerId = []
        switch (seller) {
            case 'DC':
                avoStoreDcList.forEach((item, index) => {
                    if (item.id !== null) {
                        sellerId.push(item.id)
                    }
                })
                break;
            case 'Avoskin':
                sellerId = [avoskinId()]
                break
            default:
                sellerId = []
        }
        Promise.all([
            getRevenueApi(startDate, endDate, sellerId),
            getRevenueChartApi(startDate, endDate, sellerId),
            getRevenueSalesListApi(startDate, endDate, sellerId),
            getRevenueGrowthSale()
        ])
            .then(result => {
                dispatch(revenueDispatcher(REVENUE_REDUCER, {
                    revenueData: result[0].data,
                    chartData: result[1].data,
                    salesDataList: result[2].data.saleList,
                    growthSale: result[3].data
                }))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export {revenueDispatcher, getTargetSales, getTargetSaleList, getAnalyticsDataAction}
