import moment from "moment";
import {ACTIVE} from "../models/user-detail.model";

const INITIAL_STATE = {
    activeTab: ACTIVE,
    guaranteeList: [],
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    totalData: 0,
    currentPage: 1,
    loading: false,
    sellerId: "",
    pageSize: 10,
    guaranteeType: {
        name: 'Avostore Guarantee',
        value: 'GUARANTEE'
    }
}

export const GUARANTEE_REDUCER = 'GUARANTEE_REDUCER'
export const GUARANTEE_TAB_DEFAULT = 'GUARANTEE_TAB_DEFAULT'

export const guaranteeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GUARANTEE_REDUCER:
            return {...state, ...action.payload}
        case GUARANTEE_TAB_DEFAULT:
            return {
                ...state, activeTab: ACTIVE, sellerId: "", guaranteeType: {
                    name: 'Avostore Guarantee',
                    value: 'GUARANTEE'
                }
            }
        default:
            return state
    }
}
