import React from "react";
import {Button, Card, DatePicker, Input, Row, Tabs} from "antd";
import DeliveryOrderListComponent from "./delivery-order-list.component";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {deliveryOrderDispatcher, getDeliveryOrderList} from "../../actions/delivery-order.action";
import {DELIVERY_ORDER_DETAIL} from "../../reducers/delivery-order.reducer";
import {APPROVED, CANCELLED, PENDING} from "../../models/order-detail.model";
import moment from "moment";

const {TabPane} = Tabs;
const {Search} = Input;
const {RangePicker} = DatePicker;

class DeliveryOrderPage extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [{
                key: PENDING,
                tab: 'Pending'
            },{
                key: APPROVED,
                tab: 'Approved'
            },{
                key: CANCELLED,
                tab: 'Reject'
            }],
        }
    }

    onTabChange = (tabId) => {
        this.props.deliveryOrderDispatcher(DELIVERY_ORDER_DETAIL, {activeTab: tabId})
        this.props.getDeliveryOrderLIst()
    }

    onDatePickerChange = (dates) => {
        this.props.deliveryOrderDispatcher(DELIVERY_ORDER_DETAIL, {startDate: dates[0], endDate: dates[1]})
    }

    onFilter = () => {
        this.props.getDeliveryOrderLIst()
    }

    render() {
        const {tabList} = this.state
        const {activeTab, startDate, endDate} = this.props.deliveryOrderReducer
        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Delivery Order List</h3>
                <Row style={{marginBottom: 20, marginTop: 20}} justify="space-between">
                    <Search placeholder="cari order id"
                            onSearch={(value) => this.props.deliveryOrderDispatcher(DELIVERY_ORDER_DETAIL, {searchKeyword: value})}
                            style={{width: 250, marginRight: 20, height: 50}}/>
                    <div>
                        Tgl. Pesanan
                        <RangePicker
                            style={{marginLeft: 20, marginRight: 20}}
                            value={[startDate, endDate]}
                            allowClear={false}
                            onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                onClick={() => this.onFilter()}
                        >Filter</Button>
                    </div>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen}}
                >
                    {tabList.map((tab) => (
                        <TabPane tab={tab.tab} key={tab.key}>
                            <DeliveryOrderListComponent />
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {deliveryOrderReducer} = state;

    return {deliveryOrderReducer};
};

export default connect(mapStateToProps, {deliveryOrderDispatcher, getDeliveryOrderLIst: getDeliveryOrderList})(DeliveryOrderPage)
