import React, {PureComponent} from 'react';
import {Button, Divider, Form, Input, Modal, Table} from 'antd'
import ProductList from './product-list.component';
import {
    avoskinId,
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    generateMd5Id,
    getDateEpochFormat,
    getWarehouseAvostoreJakartaId,
    getWarehouseAvostoreJogjaId,
    randomNumber,
    renderLabelOrderState
} from '../../ui-util/general-variable';
import {Colors} from '../../ui-util/colors';
import {Link} from 'react-router-dom';
import {CANCELLED, DELIVERY, PACKAGING} from '../../models/order-detail.model';
import OrderCount from './order-count.component';
import ReactToPrint from 'react-to-print';
import {updateOrderDetail} from '../../firebase/database/orders';
import {PrinterOutlined} from '@ant-design/icons';
import PrintLabelComponent from "../../components/print-label.component";
import {ORDER_LIST, ORDER_REDUCER} from "../../reducers/order.reducer";
import {OrderAuditObj} from "../../models/order-audit.model";
import OrderAuditState from "../../models/OrderAuditState";
import {setOrderAuditToFirestore} from "../../firebase/database/order-audit";
import {connect} from "react-redux";
import {getOrderList, getOrderListAction} from "../../actions/order.action";
import {updateRelatedDataOrderFromApi} from "../../api/pre-order.api";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import {generalDispatcher} from "../../actions/general.action";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import GiftListComponent from "./gift-list.component";

const listOfKey = Object.keys(OrderAuditState.SELLER)

class OrderList extends PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId'},
                {
                    title: 'Nama',
                    dataIndex: 'shippingAddress',
                    key: 'shippingAddress',
                    render: (shippingAddress) => shippingAddress.receiver
                }, {
                    title: 'Tgl. Pesanan',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    render: (orderDate) => formatUnixDateToReadable(orderDate)
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'grandTotal',
                    key: 'grandTotal',
                    render: (grandTotal) => doConvertNumberToRupiahFormat(grandTotal)
                }, {
                    title: 'Status',
                    key: 'orderState',
                    render: (record) => renderLabelOrderState(record)
                },
                {title: 'Jasa Kirim', dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, record) => (
                        <React.Fragment>
                            <Link to={`/order-detail/${record.id}`}>
                                Rincian
                            </Link>
                            <Divider type="vertical"/>
                            <Button
                                disabled={record.orderState === CANCELLED || record.paymentDetail.transactionStatus !== 'paid'}
                                type="link"
                                onClick={() => {
                                    this.props.generalDispatcher(MODAL_REDUCER, {
                                        modalVisibilityResi: true,
                                        orderDetailToUpdateResi: record
                                    })
                                }}
                            >
                                Update Resi
                            </Button>
                        </React.Fragment>
                    )
                }
            ],
            orderList: [],
            selectedRowKeys: [],
            modalAwbVisible: false,
            editResiRecord: {},
            manifestData: {},
            currentPage: 1,
            selectedRowPrintLabel: []
        };
    }

    setModalAwbVisible(modalAwbVisible, editResiRecord) {
        this.setState({modalAwbVisible, editResiRecord});
    }

    handleOk = () => {
        let {editResiRecord} = this.state;
        let values = {awb: editResiRecord.awb}
        let orderAudit = {
            orderId: editResiRecord.id,
            action: listOfKey[4],
            description: OrderAuditState.SELLER.AWB_SUBMITTED_FROM_DASHBOARD,
            awb: editResiRecord.awb,
        }
        if (Boolean(editResiRecord.awb)) {
            updateOrderDetail(editResiRecord.id, values)
                .then((r) => {
                    this.orderAudit(orderAudit)
                    this.setModalAwbVisible(false, {});
                })
                .catch((err) => {
                    this.setModalAwbVisible(false, {});
                    alert('terjadi kesalahan, gagal menyimpan data!');
                });
        } else {
            alert('resi tidak boleh kosong!');
        }
    };

    extractOrderListToDisplay() {
        const {orderList} = this.props.orderReducer;
        const {activeTab, processingActiveTab} = this.props.tabReducer
        let orderToDisplay = [];

        if (activeTab === 'waitingForDelivery') {
            switch (processingActiveTab) {
                case 'processing':
                    orderToDisplay = orderList.filter((order) => {
                        if (order.isPrinted !== true) {
                            return true;
                        }
                        return false;
                    });
                    break;
                case 'proceed':
                    orderToDisplay = orderList.filter((order) => {
                        if (order.isPrinted === true && !Boolean(order.manifestId)) {
                            return true
                        }
                        return false
                    });
                    break;
                default:
                    orderToDisplay = orderList;
            }
        } else {
            orderToDisplay = orderList;
        }

        return orderToDisplay
    }

    updateOrderPrinted = (orders) => {
        const {warehouseReducer} = this.props;
        const warehouseId = getWarehouseAvostoreJogjaId()
        const {warehouseDetail} = warehouseReducer
        let deliveryTime = warehouseDetail.id === warehouseId ? null : getDateEpochFormat()
        let orderState = warehouseDetail.id === warehouseId ? PACKAGING : DELIVERY

        this.setState({selectedRowKeys: [], selectedRowPrintLabel: []})
        orders.forEach((order, index) => {
            const requestBody = {
                uid: order.sellerId,
                orderDetail: {
                    id: order.id,
                    isPrinted: true,
                    deliveryTime,
                    orderState
                }
            }

            const orderAudit = {
                orderId: order.id,
                action: order.warehouseId === getWarehouseAvostoreJakartaId() ? listOfKey[1] : listOfKey[0],
                description: order.warehouseId === getWarehouseAvostoreJakartaId() ? OrderAuditState.SELLER.ORDER_ON_DELIVERY : OrderAuditState.SELLER.ORDER_ON_PACKAGING,
            }

            let isLastIndex = index === orders.length

            updateRelatedDataOrderFromApi(requestBody)
                .then(r => {
                    this.orderAudit(orderAudit, isLastIndex)
                    this.props.generalDispatcher(ORDER_LIST, {
                        activeTab: 'waitingForDelivery'
                    })
                })
                .catch(err => console.log("updateRelatedDataOrderFromApi error :", err))
        });
    }

    orderAudit = (orderAudit, isLastIndex) => {
        let auditOrderDetail = {
            ...OrderAuditObj,
            ...orderAudit,
            id: generateMd5Id(randomNumber(6)),
            actorId: avoskinId(),
            actorName: 'Avoskin',
            timestamp: getDateEpochFormat(),
        }
        setOrderAuditToFirestore(auditOrderDetail)
            .then(() => {
                if (isLastIndex) {
                    this.props.getOrderListAction()
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    showDownloadButton() {
        const {selectedRowPrintLabel} = this.state;
        const {warehouseDetail} = this.props.warehouseReducer

        if (Boolean(selectedRowPrintLabel) && selectedRowPrintLabel.length > 0 && !!warehouseDetail?.id) {
            return (
                <ReactToPrint
                    trigger={() => (
                        <Button
                            style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                            icon={<PrinterOutlined/>}
                            size="large"
                            theme="filled"
                        >
                            Print Label
                        </Button>
                    )}
                    content={() => this.componentRef}
                    onAfterPrint={() => this.updateOrderPrinted(selectedRowPrintLabel)}
                />
            );
        } else {
            return (
                <Button icon={<PrinterOutlined/>} size="large" theme="filled"
                        onClick={() => alert('Terjadi kesalahan, Gudang pengiriman belum dipilih!')}
                        style={selectedRowPrintLabel?.length ? {
                            backgroundColor: Colors.darkseagreen,
                            color: 'white',
                            marginRight: 10
                        } : {marginRight: 10}}
                        disabled={!selectedRowPrintLabel?.length}>
                    Print Label
                </Button>
            );
        }
    }


    dataChange(e) {
        const {editResiRecord} = this.state;
        let data = editResiRecord;
        data.awb = e.target.value;
        this.setState({editResiRecord: data});
    };

    onShowSize = (current, pageSize) => {
        this.props.generalDispatcher(ORDER_LIST, {pageSize: pageSize, currentPage: 1})
    }

    onChangePagination = (current) => {
        this.props.generalDispatcher(ORDER_LIST, {currentPage: current})
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setOrderDataSelectedRows(rows);
        this.setState({selectedRowKeys, selectedRowPrintLabel: rows});
        this.props.generalDispatcher(ORDER_REDUCER, {selectedRowExcel: selectedRow})
    };

    render() {
        const {warehouseReducer, orderReducer, tabReducer} = this.props;
        const orderList = this.extractOrderListToDisplay();
        const {currentPage, dataCount, loading} = orderReducer
        const {activeTab} = tabReducer
        const {columns, editResiRecord, modalAwbVisible, selectedRowKeys, selectedRowPrintLabel} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };
        const {warehouseDetail} = warehouseReducer

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center" style={{paddingBottom: 10}}>
                    <OrderCount count={activeTab === 'waitingForDelivery' ? orderList.length : dataCount}/>
                    <div>{activeTab === 'waitingForDelivery' && this.showDownloadButton()}</div>
                </div>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    expandedRowRender={(record) => {
                        return (
                            <>
                                <ProductList productList={record.products}/>
                                {record?.gifts?.length > 0 && <GiftListComponent giftList={record.gifts}/>}
                            </>
                        )
                    }}
                    dataSource={orderList}
                    rowKey="id"
                    rowSelection={rowSelection}
                    loading={loading}
                    pagination={{
                        total: dataCount,
                        onChange: this.onChangePagination,
                        current: currentPage,
                        onShowSizeChange: this.onShowSize,
                        showSizeChanger: true
                    }}
                />
                {activeTab === 'waitingForDelivery' &&
                <div style={{display: 'none'}}>
                    <PrintLabelComponent
                        pageStyle="@page { size: A4}"
                        senderData={warehouseDetail}
                        selectedPrint={selectedRowPrintLabel}
                        ref={(el) => (this.componentRef = el)}
                    />
                </div>
                }
                <Modal
                    title={`Update Resi ${editResiRecord.orderId}`}
                    style={{top: 20}}
                    visible={modalAwbVisible}
                    onCancel={() => this.setModalAwbVisible(false, {})}
                    footer={null}
                >
                    <Form>
                        <Form.Item label="Resi">
                            <Input onChange={(e) => this.dataChange(e)}/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                onClick={this.handleOk}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    const {orderReducer, userReducer, warehouseReducer, tabReducer} = state;
    return {orderReducer, userReducer, warehouseReducer, tabReducer};
};

export default connect(mapStateToProps,
    {
        getOrderList,
        getOrderListAction,
        generalDispatcher
    }
)(OrderList);
