import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().startOf( 'month'),
    endDate: moment(),
    activeUserList: [],
    authUserList: [],
    activeUser: {
        current: 0,
        prev: 0,
        percentage: "0%"
    },
    authUser: {
        current: 0,
        prev: 0,
        percentage: "0%"
    }
}

export const ANALYTICS_USER = 'ANALYTICS_USER'

export const analyticsUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ANALYTICS_USER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
