import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row} from 'antd';
import AvostoreDetailChainComponent from "./avostore-detail-chain.component";
import {getAvostoreChain} from "../../actions/user.action";
import {getWarehouseList} from "../../actions/warehouse.action";
import {getAvostoreDetail, getAvostorePointAudit} from "../../actions/avostore-detail.action";
import {avoskinId, isEqual} from '../../ui-util/general-variable';
import AvostoreDetailHeaderComponent from "./avostore-detail-header.component";
import AvostoreDetailBodyComponent from "./avostore-detail-body.component";
import AvostoreDetailSocialMediaComponent from "./avostore-detail-social-media.component";
import AvostoreDetailHistoryPointComponent from "./avostore-detail-history-point.component";
import FormEditModal from "./form-edit.modal";
import {orderDispatcher} from "../../actions/order.action";
import {AVOSTORE_DETAIL} from "../../reducers/avostore-detail.reducer";
import {getWarehouseListByUserIdApi} from "../../api/warehouse.api";

class AvostoreDetail extends Component {

    componentDidMount() {
        const {id} = this.props.match.params
        this.props.getAvostoreDetail(id)
        this.props.getWarehouseList(id)
        this.props.getAvostorePointAudit(id)

        getWarehouseListByUserIdApi({userId: avoskinId()})
            .then(result => {
                this.props.orderDispatcher(AVOSTORE_DETAIL, {avoskinWarehouseList: result})
            })
            .catch(error => {
                console.log("Error getWarehouseListByUserIdApi", error)
            })

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {avostoreDetail} = this.props.avostoreDetailReducer;
        if (!isEqual(this.props.avostoreDetailReducer, prevProps.avostoreDetailReducer)) {
            this.props.getAvostoreChain(avostoreDetail.parentRef, avostoreDetail.referralId)
        }
    }

    render() {
        const {avostoreDetail} = this.props.avostoreDetailReducer

        return (
            <div>
                {
                    !!avostoreDetail.id &&
                    <div style={{width: '85%', marginLeft: 30}}>
                        <AvostoreDetailHeaderComponent/>
                        <AvostoreDetailBodyComponent/>
                        <AvostoreDetailSocialMediaComponent/>
                        <Row justify={'space-between'} style={{marginTop: 20}}>
                            <AvostoreDetailHistoryPointComponent/>
                            <AvostoreDetailChainComponent/>
                        </Row>
                        <FormEditModal/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {avostoreDetailReducer} = state;

    return {avostoreDetailReducer};
}

export default connect(mapStateToProps, {
    getAvostoreChain,
    getAvostoreDetail,
    getWarehouseList,
    getAvostorePointAudit,
    orderDispatcher
})(AvostoreDetail);
