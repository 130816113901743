const INITIAL_STATE = {
    manifestList: [],
    selectedPrint: [],
    selectedRowExcel: [],
    selectedRowKeys: [],
    orderListForManifest: [],
    shippingMethod: 'Semua',
    pageSize: 10,
    currentPage: 1,
    manifestUser: 'Avoskin',
    loading: false,
    totalData: 0,
    searchKey: '',
    activeTab: 'PROCEED',
    loadingManifestList: false,
    totalDataManifestList: 0,
    currentPageManifestList: 1,
    pageSizeManifestList: 10,
    orderListInManifest: [],
    manifestDetail: {},
    loadingManifestDetail: false
}

export const MANIFEST_REDUCER = 'MANIFEST_REDUCER';
export const FLUSH_PAGINATION_MANIFEST_REDUCER = 'FLUSH_PAGINATION_MANIFEST_REDUCER';

export const manifestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANIFEST_REDUCER:
            return {...state, ...action.payload}
        case FLUSH_PAGINATION_MANIFEST_REDUCER:
            return {...state, currentPageManifestList: 1, pageSizeManifestList: 10, manifestList: []}
        default:
            return state
    }
}
