import React, {Component} from 'react';
import {Button, DatePicker, Form, Image, Input, Radio, Row, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {GiftCardModelObj} from "../../models/gift-card.model";
import {
    BRONZE,
    DISTRIBUTION_CHANNEL_1,
    DISTRIBUTION_CHANNEL_2, DISTRIBUTION_CHANNEL_3,
    END_CUSTOMER,
    PLATINUM,
    PREMIUM_1,
    PREMIUM_2
} from "../../models/user-detail.model";
import {getDetailGiftCardApi} from "../../api/gift-card.api";
import moment from "moment";
import GiftItemListFormComponent from "./gift-item-list-form.component";
import GiftProductListFormComponent from "./gift-product-list-form.component";

const {TextArea} = Input;
const {Option} = Select;

class GiftCardFormComponent extends Component {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            levelList: [
                {name: 'End customer', value: END_CUSTOMER},
                {name: 'Bronze', value: BRONZE},
                {name: 'Platinum', value: PLATINUM},
                {name: 'Premium 1', value: PREMIUM_1},
                {name: 'Premium 2', value: PREMIUM_2},
                {name: 'Distribution Channel 1', value: DISTRIBUTION_CHANNEL_1},
                {name: 'Distribution Channel 2', value: DISTRIBUTION_CHANNEL_2},
                {name: 'Distribution Channel 3', value: DISTRIBUTION_CHANNEL_3}
            ],
            giftCardDetail: {...GiftCardModelObj},
            isShowProductList: false,
            isShowMinimumMultiplyValue: false,
            isShowMultiplyType: false
        }
    }

    getOptionList = (key) => {
        const {avostoreListAll, warehouseDcAvoskinList} = this.props
        const {levelList} = this.state


        switch (key) {
            case 'userLevelList':
                return (
                    levelList.map((item, index) => (
                        <Option value={item.value}
                                key={index.toString()}>{item.name}</Option>
                    ))
                )
            case 'emailList':
                return (
                    avostoreListAll.map((item, index) => (
                        <Option value={item?.email}
                                key={index.toString()}>{item?.email}</Option>
                    ))
                )
            case 'warehouseList':
                return (
                    warehouseDcAvoskinList.map((item, index) => (
                        <Option value={item?.warehouseId}
                                key={index.toString()}>{item?.warehouseName}</Option>
                    ))
                )
        }
    }

    componentDidMount() {
        if (!!this.props.uid) {
            getDetailGiftCardApi({id: this.props.uid})
                .then(result => {
                    let giftCardDetail = result.data
                    let giftToUpdate = {
                        ...result.data,
                        startDate: moment.unix(result.data.startDate),
                        endDate: moment.unix(result.data.endDate),
                        items: []
                    }
                    if(giftCardDetail?.minimumProductOrdered?.length > 0){
                        this.setState({isShowProductList: true, isShowMultiplyType: true})
                    }
                    if(giftCardDetail?.isMultiply && giftCardDetail?.multiplyType === 'TOTAL_PURCHASE'){
                        this.setState({isShowMinimumMultiplyValue: true})
                    }
                    this.formRef.current.setFieldsValue(giftToUpdate)
                    this.props.onUpdateGiftDetailToEdit(giftCardDetail)
                })
                .catch(err => {
                    alert("gagal mendapatkan detail gift !")
                    console.log("err getDetailGiftItemApi :", err)
                })
        }
    }

    onValuesChangeHandler = (e) => {
        switch (true){
            case e.target.value === 'PRODUCTS':
                this.setState({isShowProductList: true, isShowMinimumMultiplyValue: false})
                break
            case e.target.value === 'TOTAL_PURCHASE':
                this.setState({isShowProductList: false, isShowMinimumMultiplyValue: true})
                break
            case e.target.value === 'true':
                this.setState({isShowMultiplyType: true})
                break
            case e.target.value === 'false':
                this.setState({isShowMultiplyType: false})
                break
        }
    }

    render() {
        const {isShowProductList, isShowMinimumMultiplyValue, isShowMultiplyType} = this.state
        const {
            giftCardForm,
            fileImage,
            giftItemList,
            onFinish,
            uid: id,
            beforeUploadImage,
            removeButtonHandler,
            giftCardDetail,
            productList
        } = this.props
        const initializeValue = Object.assign(GiftCardModelObj)

        return (
            <Form name="dynamic_form_nest_item" {...layout} style={{width: '70%'}} onFinish={onFinish}
                  ref={this.formRef} initialValues={initializeValue}>
                {
                    giftCardForm.map((item, index) => {
                        switch (item.type) {
                            case 'image':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.name}
                                        rules={[
                                            {required: item.required, message: item.placeholder}
                                        ]}
                                    >
                                        <Upload
                                            fileList={fileImage}
                                            onRemove={removeButtonHandler}
                                            beforeUpload={beforeUploadImage}
                                        >
                                            {
                                                giftCardDetail.image &&
                                                <Image
                                                    src={giftCardDetail.image}
                                                    width={100}
                                                />
                                            }
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 10
                                                }}>
                                                <Button icon={<UploadOutlined/>}>
                                                    Upload
                                                </Button>
                                                <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                            </Row>
                                        </Upload>
                                    </Form.Item>
                                )
                            case 'date':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.placeholder,
                                        }]}
                                    >
                                        <DatePicker/>
                                    </Form.Item>
                                )
                            case 'select':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[{
                                            required: item.required,
                                            message: item.placeholder,
                                        }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder={item.placeholder}
                                        >
                                            {this.getOptionList(item.key)}
                                        </Select>
                                    </Form.Item>
                                )
                            case 'item':
                                return <GiftItemListFormComponent item={item} id={id} index={index}
                                                                  giftCardDetail={giftCardDetail}
                                                                  giftItemList={giftItemList}
                                />
                            case 'minimumProductOrdered':
                                return ( isShowProductList ? <GiftProductListFormComponent item={item} id={id} index={index}
                                                                                           giftCardDetail={giftCardDetail}
                                                                                           productList={productList}
                                /> : null
                                )
                            case 'minimumMultiplyValue':
                                return ( !!isShowMinimumMultiplyValue ? <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[
                                            {required: item.required, message: item.placeholder},
                                        ]}
                                    >
                                        <Input placeholder={item.placeholder}/>
                                    </Form.Item> : null
                                )
                            case 'multiplyType':
                                return (isShowMultiplyType ?
                                        <Form.Item
                                            key={index.toString()}
                                            label={item.label}
                                            name={item.key}
                                            onChange={(e) => this.onValuesChangeHandler(e)}
                                            rules={[{
                                                required: item.required,
                                                message: item.placeholder,
                                            }]}
                                        >
                                            <Radio.Group>
                                                {
                                                    item.optionItem.map((item, index) => {
                                                        return (
                                                            <Radio style={{display: 'block',}} value={item.value}
                                                                   key={index.toString()}>{item.name}</Radio>
                                                        )
                                                    })
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                        : null
                                )
                            case 'isMultiply':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        onChange={(e) => this.onValuesChangeHandler(e)}
                                        rules={[{
                                            required: item.required,
                                            message: item.placeholder,
                                        }]}
                                    >
                                        <Radio.Group>
                                            {
                                                item.optionItem.map((item, index) => {
                                                    return (
                                                        <Radio style={{display: 'block',}} value={item.value}
                                                               key={index.toString()}>{item.name}</Radio>
                                                    )
                                                })
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={item.label}
                                        name={item.key}
                                        rules={[
                                            {required: item.required, message: item.placeholder},
                                        ]}
                                    >
                                        {
                                            item.type === 'textarea'
                                                ? <TextArea rows={4} placeholder={item.placeholder}/>
                                                : <Input placeholder={item.placeholder}/>
                                        }
                                    </Form.Item>
                                )
                        }
                    })
                }
                <Form.Item wrapperCol={{offset: 8}}>
                    <div className="steps-action">
                        <Button type="primary" htmlType="submit">
                            Done
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
};

export default GiftCardFormComponent;
