import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Col, DatePicker, Row, Tabs} from "antd";
import {Colors} from "../../ui-util/colors";
import OrderAvostoreListComponent from "./order-avostore-list.component";
import {getOrderAvostoreList, orderDispatcher} from "../../actions/order.action";
import _ from "lodash";
import {FLUSH_PAGINATION, ORDER_LIST, ORDER_LIST_FILTER_DATE} from "../../reducers/order.reducer";
import moment from "moment";
import ExportOrderAvostoreComponent from "./export-order-avostore.component";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import OrderCount from "../orders/order-count.component";
import SearchComponent from "../../components/search.component";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;

class OrderAvostore extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'all',
                    tab: 'Semua'
                },
                {
                    key: 'waitingForPayment',
                    tab: 'Belum Bayar'
                },
                {
                    key: 'waitingForDelivery',
                    tab: 'Perlu Dikirim'
                },
                {
                    key: 'delivery',
                    tab: 'Dikirim'
                },
                {
                    key: 'completed',
                    tab: 'Selesai'
                },
                {
                    key: 'cancelled',
                    tab: 'Dibatalkan'
                }
            ],
            selectedRow: [],
            selectedPrint: [],
            selectedRowKeys: [],
            keyword: ''
        };
    }

    componentDidMount(): void {
        this.props.getOrderAvostoreList()
    }

    onTabChange = (tabId) => {
        this.props.orderDispatcher(FLUSH_PAGINATION, tabId)
    };

    onSearchHandler = (value) => {
        this.setState({keyword: value})
    }

    getDataToDisplay = () => {
        const {orderReducer} = this.props
        const orderAvostoreToDisplay = _.filter(orderReducer.orderAvostoreList, item => {
            let orderId = item.orderId.includes(this.state.keyword)
            if (!!orderId) {
                return item.orderId.includes(this.state.keyword)
            }
        });
        return orderAvostoreToDisplay
    }

    onDatePickerChange = (dates) => {
        this.props.orderDispatcher(ORDER_LIST_FILTER_DATE, {filterStartDate: dates[0], filterEndDate: dates[1]})
    }

    filterWithDate = () => {
        let {activeTab} = this.state
        this.props.getOrderAvostoreList(activeTab)
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setOrderDataSelectedRows(rows);
        this.setState({selectedRow, selectedRowKeys});
    };

    componentWillUnmount(): void {
        this.props.orderDispatcher(FLUSH_PAGINATION, 'all')
    }

    onChangePagination = (current) => {
        this.props.orderDispatcher(ORDER_LIST, {currentPage: current})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {currentPage, searchKey, pageSize, activeTab} = this.props.orderReducer;
        if (currentPage !== prevProps.orderReducer.currentPage || searchKey !== prevProps.orderReducer.searchKey ||
            pageSize !== prevProps.orderReducer.pageSize || activeTab !== prevProps.orderReducer.activeTab) {
            this.props.getOrderAvostoreList()
        }
    }

    render() {
        const {tabList, selectedRow, selectedRowKeys} = this.state;
        let orderList = this.getDataToDisplay()
        const {filterStartDate, filterEndDate, dataCount, activeTab} = this.props.orderReducer;

        return (
            <Card>
                <Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    onChange={this.onTabChange}
                    tabBarStyle={{color: Colors.darkseagreen}}
                >
                    {tabList.map((tab) => (
                        <TabPane tab={tab.tab} key={tab.key}>
                            <Row type="flex" justify={'space-between'} align="middle">
                                <Col span={10}>
                                    <SearchComponent searchItem={'Pesanan'} onSearchHandler={this.onSearchHandler} />
                                </Col>
                                <Col span={14}>
                                    <Row type="flex" justify={'space-between'} align="middle">
                                        <Col span={20} className="d-flex justify-content-around align-items-center">
                                            Tgl. Pesanan
                                            <RangePicker
                                                allowClear={false}
                                                style={{width: '60%'}}
                                                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                                                value={[filterStartDate, filterEndDate]}
                                                onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                                                disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                                            />
                                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                                    onClick={this.filterWithDate}>Filter</Button>
                                        </Col>
                                        <Col span={4} className="d-flex justify-content-around">
                                            {selectedRow.length > 0 ?
                                                <ExportOrderAvostoreComponent selectedRow={selectedRow}/> :
                                                <Button disabled>Export</Button>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-between align-items-center"
                                 style={{paddingBottom: 10}}>
                                <OrderCount count={dataCount}/>
                            </div>
                            <OrderAvostoreListComponent orderList={orderList} onSelectRow={this.onSelectRow}
                                                        changePage={this.onChangePagination}
                                                        selectedRowKeys={selectedRowKeys}/>
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const {orderReducer} = state

    return {orderReducer};
}

export default connect(mapStateToProps, {getOrderAvostoreList, orderDispatcher})(OrderAvostore);
