import React from "react";
import {Button, Modal, Table} from "antd";
import {connect} from "react-redux";
import {getTargetSaleList} from "../../actions/revenue.action";
import {Colors} from "../../ui-util/colors";
import {
    doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    getDateEpochFormat
} from "../../ui-util/general-variable";
import {setTargetSalesToFirestore} from "../../firebase/database/target-sales";
import TargetSalesFormComponent from "./target-sales-form.component";
import _ from "lodash";
import SearchComponent from "../../components/search.component";

class TargetSalesComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    key: 'id',
                    title: 'Month',
                    dataIndex: 'id'
                }, {
                    key: 'targetValue',
                    title: 'Target Sales',
                    dataIndex: 'targetValue',
                    render: (targetValue) => doConvertNumberToRupiahFormat(targetValue)
                }, {
                    key: 'targetAchieve',
                    title: 'Target Tercapai',
                    dataIndex: 'targetAchieve',
                    render: (targetAchieve) => doConvertNumberToRupiahFormat(targetAchieve)
                }, {
                    key: 'targetAchievePercentage',
                    title: 'Persentase Ketercapaian',
                    dataIndex: 'targetAchievePercentage',
                    render: (targetAchievePercentage) => <div>{targetAchievePercentage ? targetAchievePercentage : 0} %</div>
                }, {
                    key: 'value',
                    title: 'Tanggal Pembuatan',
                    dataIndex: 'createdAt',
                    render: (createdAt) => formatUnixDateToReadable(createdAt)

                }, {
                    key: 'value',
                    title: 'Tanggal Terakhir Update',
                    dataIndex: 'lastUpdate',
                    render: (lastUpdate) => formatUnixDateToReadable(lastUpdate)

                }, {
                    key: 'action',
                    render: (record) => (
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                onClick={() => this.accessEditTargetSales(record)}>
                            Edit
                        </Button>
                    )
                }
            ],
            targetSalesModalVisibility: false,
            targetSalesDetail: {},
            isEdit: false,
            keyword: ''
        }
    }

    componentDidMount(): void {
        this.props.getTargetSaleList()
    }

    accessEditTargetSales = (record) => {
        this.setState({
            targetSalesModalVisibility: true,
            targetSalesDetail: record,
            isEdit: true
        })
    }

    onFinishButtonHandler = (values) => {
        let {isEdit} = this.state
        if(isEdit){
            this.editTargetSales(values)
        } else {
            this.addTargetSales(values)
        }
    }

    editTargetSales = (values) => {
        let {targetSalesDetail} = this.state
        targetSalesDetail.targetValue = values.targetValue
        targetSalesDetail.lastUpdate = getDateEpochFormat()

        setTargetSalesToFirestore(targetSalesDetail.id, targetSalesDetail)
            .then(result => {
                alert('Input target sale berhasil!')
                this.setState({targetSalesModalVisibility: false, isEdit: false})
                this.props.getTargetSaleList()
            })
            .catch(err => {
                console.log(err)
            })
    }

    addTargetSales = (values) => {
        const {targetSalesDate} = this.props.revenueReducer
        let targetSalesDetail = {
            id: targetSalesDate,
            createdAt: getDateEpochFormat(),
            lastUpdate: getDateEpochFormat(),
            targetValue: Number(values.targetValue),
            targetAchieve: 0,
            valid: true
        }

        setTargetSalesToFirestore(targetSalesDate, targetSalesDetail)
            .then(result => {
                alert('Input target sale berhasil!')
                this.setState({targetSalesModalVisibility: false, isEdit: false})
                this.props.getTargetSaleList()
            })
            .catch(err => {
                console.log(err)
            })
    }

    onSearchHandler = (value) => {
        this.setState({ keyword: value })
    }

    getDataToDisplay = () => {
        const {targetSalesList} = this.props.revenueReducer
        const targetSalesListToDisplay = _.filter(targetSalesList, item => {
            let id = item.id.includes(this.state.keyword)
            if (!!id) {
                return item.id.includes(this.state.keyword)
            }
        });

        return targetSalesListToDisplay
    }


    render() {
        const {columns, targetSalesModalVisibility, targetSalesDetail, isEdit} = this.state

        return (
            <>
                <div align={'end'} style={{marginBottom: 20}}>
                    <SearchComponent searchItem={'target sales'} onSearchHandler={this.onSearchHandler} />
                    <Button style={{color: 'white', backgroundColor: Colors.darkseagreen}} onClick={() => this.setState({
                        targetSalesModalVisibility: true, isEdit: false})}>Add Target Sales</Button>
                </div>
                <Table columns={columns} dataSource={this.getDataToDisplay()} rowKey='id'/>
                <Modal
                    title={ isEdit ? `Edit target sales ${targetSalesDetail.id}` : 'Create Target Sales'}
                    visible={targetSalesModalVisibility}
                    onCancel={() => this.setState({targetSalesModalVisibility: false})}
                    footer={null}
                >
                    <TargetSalesFormComponent isEdit={isEdit} targetSalesDetail={isEdit ? targetSalesDetail : {}} onFinish={this.onFinishButtonHandler}/>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {revenueReducer} = state;

    return {revenueReducer};
};

export default connect(mapStateToProps, {getTargetSaleList})(TargetSalesComponent)
