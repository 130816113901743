import React from "react";
import {Button, Card, DatePicker, Dropdown, Menu, Row} from "antd";
import moment from "moment";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {
    analyticsGuaranteeDispatcher,
    getDataAnalyticsGuaranteeList,
    getTotalGuaranteeData,
    getTotalGuaranteeDataPaid
} from "../../actions/analytics-guarantee.action";
import {ANALYTICS_GUARANTEE} from "../../reducers/analytics-guarantee.reducer";
import _ from "lodash";
import {doConvertNumberToRupiahFormat} from "../../ui-util/general-variable";
import Text from "antd/es/typography/Text";
import DropdownSellerComponent from "../../components/dropdown-seller.component";

const {RangePicker} = DatePicker;

class AnalyticsGuaranteeHeaderComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            paidUnpaidCardList: [
                {
                    id: 1,
                    key: 'paidGuarantee',
                    label: 'Terbayar',
                    value: 0
                }, {
                    id: 2,
                    key: 'unpaidGuarantee',
                    label: 'Belum Terbayar',
                    value: 0
                }, {
                    id: 3,
                    key: 'fineGuarantee',
                    label: 'Denda',
                    value: 0
                },
            ],
            guaranteeType: [
                {
                    name: 'Guarantee',
                    value: 'GUARANTEE'
                },
                {
                    name: 'Pre Order Guarantee',
                    value: 'PREORDER_GUARANTEE'
                },
                {
                    name: 'Distribution Channel Guarantee',
                    value: 'DC_INSTALLMENT'
                },
                {
                    name: 'All',
                    value: ''
                }
            ]
        }
    }

    onDatePickerChange = (dates) => {
        this.props.analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {startDate: dates[0], endDate: dates[1]})
    }

    filterButtonHandler = () => {
        const {activeTab} = this.props.analyticsGuaranteeReducer
        this.props.getDataAnalyticsGuaranteeList()
        if (activeTab === 'paid') {
            this.props.getTotalGuaranteeDataPaid()
        } else {
            this.props.getTotalGuaranteeData()
        }
    }

    dropDownGuaranteeType = () => {
        let {guaranteeType} = this.state

        return (
            <Menu>
                {
                    guaranteeType.map((type, index) => (
                        <Menu.Item key={index} onClick={() => this.guaranteeTypeButtonHandler(type)}>
                            <Button type="link">{type.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    guaranteeTypeButtonHandler = (selectedType) => {
        this.props.analyticsGuaranteeDispatcher(ANALYTICS_GUARANTEE, {guaranteeType: selectedType.value, selectedGuaranteeType: selectedType})
    }

    render() {
        const {startDate, endDate, totalGuarantee, selectedGuaranteeType} = this.props.analyticsGuaranteeReducer
        const {paidUnpaidCardList} = this.state

        return (
            <div style={{marginBottom: 20, alignItems: 'center'}}>
                <Row justify={'space-between'} style={{marginBottom: 20}}>
                    <DropdownSellerComponent/>
                    <Row>
                    <RangePicker
                        defaultValue={[startDate, endDate]}
                        format={'DD/MM/YYYY'}
                        allowClear={false}
                        disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                    />
                    <Dropdown overlay={this.dropDownGuaranteeType()} trigger={['click']}>
                        <Button
                            style={{backgroundColor: 'white', color: Colors.darkseagreen, marginRight: 10, marginLeft: 10, minWidth: '100px'}}
                            size="medium"
                            theme="filled"
                        >
                            {selectedGuaranteeType.name}
                        </Button>
                    </Dropdown>
                    <Button style={{
                        color: 'white',
                        backgroundColor: Colors.darkseagreen
                    }} onClick={() => this.filterButtonHandler()}>Filter</Button>
                    </Row>
                </Row>
                <Row justify={'center'}>
                    {
                        _.map(paidUnpaidCardList, item => {
                            return (
                                <Card key={item.key} title={item.label}
                                      style={{margin: 10, width: '20%' , borderRadius: 7, textAlign: 'center'}}
                                      headStyle={{backgroundColor: Colors.gainsboro, fontSize: 16}}>
                                    <Text>{totalGuarantee[item.key] ? doConvertNumberToRupiahFormat(totalGuarantee[item.key]) : doConvertNumberToRupiahFormat(0)}</Text>
                                </Card>
                            )
                        })
                    }
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {analyticsGuaranteeReducer} = state

    return {analyticsGuaranteeReducer}
}

export default connect(mapStateToProps, {
    analyticsGuaranteeDispatcher,
    getDataAnalyticsGuaranteeList,
    getTotalGuaranteeData,
    getTotalGuaranteeDataPaid
})(AnalyticsGuaranteeHeaderComponent)
