import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {Colors} from "../ui-util/colors";
import {connect} from "react-redux";
import {WAREHOUSE_ANALYTICS} from "../reducers/warehouse.reducer";
import {FLUSH_TAB_REDUCER} from "../reducers/tab.reducer";
import {generalDispatcher} from "../actions/general.action";

class WarehouseAvoskinComponent extends React.PureComponent{

    dropDownWarehouse = () => {
        let {warehouseList} = this.props.warehouseReducer
        return (
            <Menu>
                {
                    warehouseList.map((warehouse) => (
                        <Menu.Item key={warehouse.id} onClick={() => this.warehouseButtonHandler(warehouse)}>
                            <Button type="link">{warehouse.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    warehouseButtonHandler = (warehouseDetail) => {
        this.props.generalDispatcher(WAREHOUSE_ANALYTICS, {warehouseDetail})
        this.props.generalDispatcher(FLUSH_TAB_REDUCER)
    }

    render() {
        const {warehouseDetail} = this.props.warehouseReducer
        return (
            <Dropdown overlay={this.dropDownWarehouse()} trigger={['click']}>
                <Button
                    style={{backgroundColor: Colors.darkseagreen, color: 'white', width: '13%'}}
                    size="large"
                    theme="filled"
                >
                    {warehouseDetail.name}
                </Button>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer} = state;

    return {warehouseReducer};
};

export default connect(mapStateToProps, {generalDispatcher})(WarehouseAvoskinComponent)
