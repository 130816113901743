import axios from 'axios'
import {cloudFunctionBaseUrl, convertStartEndDate} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const getRevenueApi = (startDate, endDate, sellerId) => {
    const requestBody = {
        startDate: convertStartEndDate(startDate, 'start'),
        endDate: convertStartEndDate(endDate),
        period: 'days',
        sellerId
    }

    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/revenue`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getRevenueChartApi = (startDate, endDate, sellerId) => {
    const requestBody = {
        startDate: convertStartEndDate(startDate, 'start'),
        endDate: convertStartEndDate(endDate),
        label: 'Gross Sales',
        sellerId
    }
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/revenue/charts`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getRevenueSalesListApi = (startDate, endDate, sellerId) => {
    const requestBody = {
        startDate: convertStartEndDate(startDate, 'start'),
        endDate: convertStartEndDate(endDate),
        sellerId
    }
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/revenue/sales-list`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getRevenueGrowthSale = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}api/v1/analytic/revenue/growth-sales`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {
    getRevenueApi,
    getRevenueChartApi,
    getRevenueSalesListApi,
    getRevenueGrowthSale
}
