import React, {Component} from 'react';
import {Button} from "antd";
import {Colors} from "../../ui-util/colors";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportProductListComponent extends Component {
    render() {
        const {productList} = this.props;
        return (
            <ExcelFile element={<Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}>Export</Button>}>
                <ExcelSheet data={productList} name="Analytics Product">
                    <ExcelColumn label="Product id" value="productId"/>
                    <ExcelColumn label="Nama" value="name"/>
                    <ExcelColumn label="SKU" value="skuCode"/>
                    <ExcelColumn label="Stock" value="stock"/>
                    <ExcelColumn label="Terjual" value="productSold"/>
                    <ExcelColumn label="% Terjual" value="productSoldPercentage"/>
                    <ExcelColumn label="Penjualan Bulan Lalu" value="prevProductSold"/>
                    <ExcelColumn label="Pertumbuhan Penjualan" value="productGrowthPercentage"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default ExportProductListComponent;
