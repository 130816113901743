import React from "react";
import {Button, Card, DatePicker, Dropdown, Menu, Row, Select, Tabs} from "antd";
import moment from "moment";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {getNdnOrderList, saveOrderDispatcher} from "../../actions/save-order.action";
import {FLUSH_PAGINATION_NDN, SAVE_ORDER_REDUCER} from "../../reducers/save-order.reducer";
import OrderListNdnComponent from "./order-list-ndn.component";
import {setOrderDataSelectedRows} from "../../ui-util/table-util";
import ExportExcel from "../../components/export-excel.component";
import {WAREHOUSE_ANALYTICS} from "../../reducers/warehouse.reducer";
import {orderDispatcher} from "../../actions/order.action";
import {getWarehouseList, warehouseDispatcher} from "../../actions/warehouse.action";
import {getUserListByLevel} from "../../actions/user.action";
import SearchComponent from "../../components/search.component";

const {RangePicker} = DatePicker;
const {TabPane} = Tabs;
const {Option} = Select;

class SaveOrdersPage extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            tabList: [
                {
                    key: 'PROCEED',
                    tab: 'Perlu Diproses'
                }, {
                    key: 'COMPLETED',
                    tab: 'Selesai'
                },
                {
                    key: 'CANCELLED',
                    tab: 'Dibatalkan'
                }
            ],
            saleDataList: [],
            selectedRow: [],
            selectedPrint: [],
            selectedRowKeys: [],
        }
    }

    componentDidMount(): void {
        this.props.getWarehouseList()
        this.props.getUserListByLevel()
    }

    onChangeTab = (activeTab) => {
        this.props.saveOrderDispatcher(FLUSH_PAGINATION_NDN)
        this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {activeTab})
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setOrderDataSelectedRows(rows)
        this.setState({selectedRow, selectedRowKeys});
    };

    warehouseButtonHandler = (warehouseDetail) => {
        this.props.warehouseDispatcher(WAREHOUSE_ANALYTICS, {warehouseDetail})
    }

    dropDownWarehouse = () => {
        let {warehouseList} = this.props.warehouseReducer
        return (
            <Menu>
                {
                    warehouseList.map((warehouse) => (
                        <Menu.Item key={warehouse.id} onClick={() => this.warehouseButtonHandler(warehouse)}>
                            <Button type="link">{warehouse.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    onFilterHandler = (buyerId) => {
        this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {buyerId})
    }

    onSearchOrderNdnHandler = (value) => {
        this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {keyword: value})
    }

    render() {
        const {tabList, selectedRow, selectedRowKeys} = this.state
        const {saveOrderReducer, warehouseReducer, avostoreListReducer} = this.props
        const {startDate, endDate, activeTab} = saveOrderReducer
        const {warehouseDetail} = warehouseReducer
        const {avoStoreDcList} = avostoreListReducer

        return (
            <Card>
                <div>
                    <h3>Pesanan Non-delivery</h3>
                </div>
                <Row type="flex" justify={'space-between'} align="middle">
                    <Dropdown style={{width: '15%'}} overlay={this.dropDownWarehouse()} trigger={['click']}>
                        <Button
                            style={{backgroundColor: Colors.darkseagreen, color: 'white', marginRight: 10}}
                            size="large"
                            theme="filled"
                        >
                            {warehouseDetail.name}
                        </Button>
                    </Dropdown>
                    <SearchComponent searchItem={'Pesanan'} onSearchHandler={this.onSearchOrderNdnHandler} />
                    <div style={{width: '25%'}}>
                        DC <Select style={{width: '50%'}} defaultValue={''}
                                   onChange={(item) => this.onFilterHandler(item)}>
                        {
                            avoStoreDcList.map((item, index) => (
                                <Option value={item.id} key={index.toString()}>{item.name}</Option>
                            ))
                        }
                    </Select>
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white',  marginLeft: 10}}
                                onClick={() => this.props.getNdnOrderList()}>
                            Filter
                        </Button>
                    </div>
                    <div style={{width: '40%'}}>
                        Tgl. Pesanan
                        <RangePicker
                            allowClear={false}
                            style={{width: '60%',  marginLeft: 10}}
                            value={[startDate, endDate]}
                            onCalendarChange={(dates) => this.props.saveOrderDispatcher(SAVE_ORDER_REDUCER, {
                                startDate: dates[0],
                                endDate: dates[1]
                            })}
                            disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                        />
                        <Button style={{backgroundColor: Colors.darkseagreen, color: 'white', marginLeft: 10}}
                                onClick={() => this.props.getNdnOrderList()}>Filter</Button>
                    </div>
                </Row>
                <Row>
                    <div>
                        {selectedRow.length > 0 ? <ExportExcel selectedRow={selectedRow}/> :
                            <Button disabled>Export</Button>}
                    </div>
                </Row>
                <Tabs
                    defaultActiveKey={activeTab}
                    onChange={this.onChangeTab}
                    tabBarStyle={{color: Colors.darkseagreen, marginTop: 10}}
                >
                    {tabList.map((item) => (
                        <TabPane tab={item.tab} key={item.key}>
                            <OrderListNdnComponent selectedRowKeys={selectedRowKeys}
                                                   onSelectRow={this.onSelectRow}/>
                        </TabPane>
                    ))}
                </Tabs>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    const {saveOrderReducer, warehouseReducer, avostoreListReducer} = state;

    return {saveOrderReducer, warehouseReducer, avostoreListReducer}
}

export default connect(mapStateToProps, {
    saveOrderDispatcher,
    getNdnOrderList,
    orderDispatcher,
    getWarehouseList,
    warehouseDispatcher,
    getUserListByLevel
})(SaveOrdersPage)
