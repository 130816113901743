import React from "react";
import {Colors} from "../ui-util/colors";
import {Tabs} from "antd";
import {connect} from "react-redux";
import {generalDispatcher} from "../actions/general.action";
import {TAB_REDUCER} from "../reducers/tab.reducer";

const {TabPane} = Tabs;

class TabPrintLabelComponent extends React.PureComponent{

    constructor() {
        super();

        this.state = {
            processingTabList: [
                {
                    key: '',
                    tab: 'Semua'
                }, {
                    key: 'processing',
                    tab: 'Perlu diproses'
                }, {
                    key: 'proceed',
                    tab: 'Telah diproses'
                }
            ],
        }
    }

    onTabChange = (tabId) => {
        let isPrinted = ''
        switch (tabId){
            case 'processing':
                isPrinted = false
                break;
            case 'proceed':
                isPrinted = true
                break;
            default:
                isPrinted = null
        }
        this.props.generalDispatcher(TAB_REDUCER, {processingActiveTab: tabId, isPrinted})
    }

    render() {
        const {processingTabList} = this.state
        const {tabReducer, content} = this.props
        const {processingActiveTab} = tabReducer

        return (
            <Tabs
                defaultActiveKey={processingActiveTab}
                activeKey={processingActiveTab}
                onChange={this.onTabChange}
                tabBarStyle={{color: Colors.darkseagreen}}
            >
                {processingTabList.map((tab) => (
                    <TabPane tab={tab.tab} key={tab.key}>
                        {content}
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}

const mapStateToProps = (state) => {
    const {tabReducer} = state;

    return {tabReducer};
};

export default connect(mapStateToProps, {generalDispatcher})(TabPrintLabelComponent)
