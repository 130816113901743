import {getProductListApi} from "../api/product.api";
import {PRODUCT_LIST} from "../reducers/product.reducer";

const productDispatcher = (type, payload) => {
    return {type, payload}
}

const getProductList = (value, categories) => {
    return (dispatch) => {
        getProductListApi(value, categories)
            .then(result => {
                dispatch(productDispatcher(PRODUCT_LIST, result.data))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export {productDispatcher, getProductList}
