import React from "react";
import {Card, Divider, List, Row, Typography} from "antd";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {
    CommentOutlined,
    FacebookOutlined,
    GlobalOutlined,
    InstagramOutlined,
    ShopOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';

const {Paragraph} = Typography;

class AvostoreDetailSocialMediaComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            socialMediaListData: [
                {
                    label: 'Instagram',
                    key: 'instagram',
                    icon: <InstagramOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Facebook',
                    key: 'facebook',
                    icon: <FacebookOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Whatsapp',
                    key: 'whatsApp',
                    icon: <WhatsAppOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Line',
                    key: 'line',
                    icon: <CommentOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }
            ],
            marketPlaceListData: [
                {
                    label: 'Blibli',
                    key: 'blibli',
                    icon: <ShopOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Lazada',
                    key: 'lazada',
                    icon: <ShopOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Shopee',
                    key: 'shopee',
                    icon: <ShopOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Tokopedia',
                    key: 'tokopedia',
                    icon: <ShopOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                }, {
                    label: 'Website',
                    key: 'website',
                    icon: <GlobalOutlined style={{fontSize: 25, color: Colors.darkseagreen}}/>
                },
            ]
        }
    }

    render() {
        const {socialMediaListData, marketPlaceListData} = this.state
        const {socialMediaAccount, marketPlaceAccount} = this.props.avostoreDetailReducer.avostoreDetail
        return (
            <Card style={{marginTop: 20, borderWidth: 1, borderColor: Colors.darkseagreen}}>
                <Row>
                    <div style={{width: '48%', paddingRight: 20}}>
                        <h5 style={{marginBottom: 20}}>Media Sosial</h5>
                        <List
                            dataSource={socialMediaListData}
                            renderItem={item => (
                                <Row style={{height: 40}} align={'middle'}>
                                    <div style={{marginRight: 10}}>{item.icon}</div>
                                    <div>{socialMediaAccount[item.key]}</div>
                                </Row>
                            )}
                        />
                    </div>
                    <Divider type={'vertical'}
                             style={{borderWidth: 1, marginTop: 45, height: 190, borderColor: Colors.darkseagreen}}/>
                    <div style={{width: '48%', paddingLeft: 20}}>
                        <h5 style={{marginBottom: 20}}>Market Place</h5>
                        <List
                            dataSource={marketPlaceListData}
                            renderItem={item => (
                                <Row style={{marginBottom: 20}}>
                                    <div style={{width: '15%'}}>{item.label}</div>
                                    <div>:</div>
                                    <Paragraph ellipsis={true} style={{
                                        width: '78%',
                                        marginLeft: 5
                                    }}><a href={marketPlaceAccount[item.key]} target="_blank">{marketPlaceAccount[item.key]} </a></Paragraph>
                                </Row>
                            )}
                        />
                    </div>
                </Row>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    const {avostoreDetailReducer} = state;

    return {avostoreDetailReducer};
}

export default connect(mapStateToProps, {})(AvostoreDetailSocialMediaComponent)
