import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const createCategoryApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/product-category/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getCategoriesApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}api/v1/product-category/list`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const updateCategoryApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/product-category/update`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {createCategoryApi, getCategoriesApi, updateCategoryApi}
