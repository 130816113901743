import moment from "moment";

const INITIAL_STATE = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    activeTab: 'ORDER',
    orderTransactionList: [],
    guaranteeTransactionList: [],
    preOrderTransactionList: [],
    loading: false,
    sellerId: '',
}

export const SET_TRANSACTION_LIST_TAX = 'SET_TRANSACTION_LIST_TAX';

export const taxReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TRANSACTION_LIST_TAX:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
