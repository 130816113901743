import {VoucherDetailObj} from "../models/voucher.model";

const INITIAL_STATE = {
    voucherDetail: {...VoucherDetailObj},
    voucherList: []
}

export const SELECT_VOUCHER = 'SELECT_VOUCHER';
export const SELECT_VOUCHER_BULK = 'SELECT_VOUCHER_BULK';

export const voucherDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_VOUCHER:
            return {...state, voucherDetail: action.payload}
        case SELECT_VOUCHER_BULK:
            return {...state, voucherList: action.payload}
        default:
            return state;
    }
}
