import React from 'react';
import {Button, Col, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import Text from "antd/es/typography/Text";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {connect} from "react-redux";
import {generalDispatcher} from "../../actions/general.action";
import UpdateResiModal from "../../components/update-resi.modal";
import {updateManifestDetailApi} from "../../api/manifest.api";
import {getManifestDetailAndOrderListAction} from "../../actions/manifest.action";
import OrderListManifestComponent from "./order-list-manifest.component";

class ManifestDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {title: "No. Pesanan", dataIndex: 'orderId', key: 'orderId'},
                {title: "Ekspedisi", dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {title: "Service", dataIndex: 'shippingService', key: 'shippingService'},
                {title: "Resi", dataIndex: 'awb', key: 'awb'},
                {
                    title: 'Aksi',
                    key: 'action',
                    render: (item, record, index) => (
                        <div>
                            <Button
                                type="link"
                                disabled={this.state.manifestDetail.state === 'COMPLETED' || record.shippingMethod === 'Self Service'}
                                onClick={() => {
                                    this.props.generalDispatcher(MODAL_REDUCER, {
                                        modalVisibilityResi: true,
                                        orderDetailToUpdateResi: record
                                    })
                                    this.setState({orderIndex: index})
                                }}
                            >
                                Edit Resi
                            </Button>
                        </div>
                    )
                }
            ],
            manifestDetail: {},
            orderListManifest: [],
            orderIndex: 0,
        }
    }

    componentDidMount(): void {
        const {id} = this.props.match.params
        this.props.getManifestDetailAndOrderListAction(id)
    }

    afterUpdateHandler = (awb) => {
        let {manifestDetail} = this.props.manifestReducer
        let {orderIndex} = this.state;
        manifestDetail.orderList[orderIndex].awb = awb
        updateManifestDetailApi(manifestDetail)
            .then(() => {
                this.props.getManifestDetailAndOrderListAction(manifestDetail.id)
            })
            .catch((err) => {
                alert('Terjadi kesalahan, gagal menyimpan data!');
            })
    }

    completeManifestButtonHandler = () => {
        let {manifestDetail} = this.props.manifestReducer;
        let newValid = []

        manifestDetail.orderList.map((item) => {
            if (item.shippingMethod === 'Self Service') {
                newValid.push(true)
            } else if (item.shippingMethod !== 'Self Service' && item.awb !== null) {
                newValid.push(true)
            } else {
                newValid.push(false)
            }

            return 0
        })
        let isValid = newValid.includes(false)
        if (isValid) {
            alert("Mohon masukkan resi")
        } else {
            this.updateManifestDetailtoFirebase()
        }
    }

    updateManifestDetailtoFirebase = () => {
        let {manifestDetail} = this.props.manifestReducer;
        manifestDetail.state = 'COMPLETED'
        updateManifestDetailApi(manifestDetail)
            .then(() => {
                this.props.getManifestDetailAndOrderListAction(manifestDetail.id)
                alert('Manifest telah selesai')
            })
            .catch((err) => {
                console.log('error update manifest detail', err)
            })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {manifestDetail} = this.props.manifestReducer
        return (
            <Row>
                <OrderListManifestComponent/>
                <Col span={6} style={{padding: 20}}>
                    <Text>Manifest Detail {manifestDetail?.manifestId} </Text>
                    <Button
                        size="large" theme="filled"
                        disabled={manifestDetail?.state === 'COMPLETED'}
                        style={{
                            backgroundColor: manifestDetail?.state !== 'COMPLETED' && Colors.darkseagreen,
                            color: manifestDetail?.state !== 'COMPLETED' && 'white',
                            marginTop: 20
                        }}
                        onClick={() => this.completeManifestButtonHandler()}
                    >
                        Complete
                    </Button>
                </Col>
                <UpdateResiModal afterUpdateHandler={(awb) => this.afterUpdateHandler(awb)}/>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const {manifestReducer} = state;

    return {manifestReducer};
}

export default connect(mapStateToProps, {generalDispatcher, getManifestDetailAndOrderListAction})(ManifestDetail);
