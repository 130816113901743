import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Table} from "antd";
import ExportUserAnalyticsListComponent from "./export-user-analytics-list.component";
import {getAnalyticsUserListFromApi} from "../../api/analytics-user.api";
import moment from "moment";
import {formatUnixDateToReadable, getLevelText, isEqual} from "../../ui-util/general-variable";
import {ANALYTICS_USER} from "../../reducers/analytics-user.reducer";
import {analyticsUserDispatcher} from "../../actions/analytics-user.action";

class AnalyticsUserListComponent extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Tanggal Aktif',
                    dataIndex: 'joinDate',
                    key: 'joinDate',
                    width: 80,
                    render: (joinDate) => (formatUnixDateToReadable(joinDate, 'DD/MM/YYYY'))
                }, {
                    title: 'Nama',
                    dataIndex: 'name',
                    key: 'name',
                    width: 80
                }, {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 80
                }, {
                    title: 'No Hp',
                    dataIndex: 'phoneNumber',
                    key: 'phoneNumber',
                    width: 80
                }, {
                    title: 'NIK',
                    dataIndex: 'identityCardNumber',
                    key: 'identityCardNumber',
                    width: 80
                }, {
                    title: 'Alamat',
                    dataIndex: 'address',
                    key: 'address',
                    width: 80,
                    render: (address) => `${address.address}, ${address.district}, ${address.city}, ${address.province}`
                }, {
                    title: 'Avostore Area',
                    dataIndex: 'avostoreArea',
                    key: 'avostoreArea',
                    width: 80
                },
                {
                    title: 'Level',
                    dataIndex: 'level',
                    key: 'level',
                    width: 220,
                    render: (level) => getLevelText(level)
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 80
                },
            ],
            activeUserList: [],
            dataCount: 0,
            currentPage: 1,
            pageSize: 10,
            selectedRowKeys: []
        }
    }

    componentDidMount(): void {
        this.fetchAnalyticsUserActiveList()
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if(!isEqual(this.props.analyticsUserReducer, nextProps.analyticsUserReducer) ||
            !isEqual(this.props.analyticsUserReducer, nextProps.analyticsUserReducer)){
            return true
        }
        return false
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(!isEqual(this.props.analyticsUserReducer, prevProps.analyticsUserReducer) ||
            !isEqual(this.props.analyticsUserReducer,prevProps.analyticsUserReducer)){
            this.fetchAnalyticsUserActiveList()
        }
    }

    fetchAnalyticsUserActiveList = () => {
        const {startDate, endDate} = this.props.analyticsUserReducer

        let reqBody = {
            status:["ACTIVE"],
            startDate: moment(startDate).startOf('day').unix(),
            endDate: moment(endDate).endOf('day').unix(),
        }

        getAnalyticsUserListFromApi(reqBody)
            .then(result => {
                this.props.analyticsUserDispatcher(ANALYTICS_USER, {activeUserList: result.data.data})
            })
            .catch(err => {
                this.props.analyticsUserDispatcher(ANALYTICS_USER, {activeUserList: []})
                console.log("error getAnalyticsUserListFromApi:", err)
            })
    }

    onSelectPrint = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };


    render() {
        const {columns, selectedRowKeys} = this.state
        const {activeUserList} = this.props.analyticsUserReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectPrint(selectedRowKeys, selectedRows);
            }
        };

        return (
            <div style={{marginTop: 30}}>
                <Row justify={'space-between'} style={{marginLeft: 10, marginRight: 10}}>
                    <h5 style={{textAlign: 'center'}}>Daftar Pengguna Aktif</h5>
                    <div style={{marginBottom: 10}}>
                        <ExportUserAnalyticsListComponent userList={activeUserList}/>
                    </div>
                </Row>
                <Table
                    dataSource={activeUserList}
                    columns={columns}
                    rowKey="id"
                    rowSelection={rowSelection}
                    pagination={{
                        total: activeUserList.length,
                        showSizeChanger: true
                    }}
                    scroll={{x: 1500}}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {analyticsUserReducer} = state
    return {analyticsUserReducer};
}

export default connect(mapStateToProps, {analyticsUserDispatcher})(AnalyticsUserListComponent);
