import React from "react";
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {Button, Modal, Table} from "antd";
import UpdatePaymentDetailModal from "../order-detail/update-payment-detail.modal";
import moment from "moment";
import {updatePaymentTerminGuaranteeApi} from "../../api/guarantee.api";
import {COMPLETED} from "../../models/order-detail.model";

class GuaranteeTerminListComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    key: 'dueDate', dataIndex: 'dueDate', title: 'Batas Pembayaran',
                    render: (dueDate) => formatUnixDateToReadable(dueDate, 'DD-MM-YYYY')
                }, {
                    key: 'cicilan', dataIndex: 'payAmount', title: 'Jumlah Cicilan',
                    render: (payAmount) => doConvertNumberToRupiahFormat(payAmount)
                }, {
                    key: 'transactionStatus', dataIndex: 'paymentDetail', title: 'Status Transaksi',
                    render: (paymentDetail) => paymentDetail.transactionStatus
                }, {
                    key: 'transactionTime', dataIndex: 'paymentDetail', title: 'Waktu Transaksi',
                    render: (paymentDetail) => paymentDetail.transactionTime
                }, {
                    key: 'fines', title: 'Denda',
                    render: (record) => doConvertNumberToRupiahFormat(record?.fines)
                },

                //TODO: diisi kalo denda nya udah ada

                // {
                //     key: 'paymentLate', dataIndex: 'daysLateness', title: 'Keterlambatan /hari',
                //     render: (daysLateness) => daysLateness > 0 && <div>{daysLateness} hari</div>
                // }, {
                //     key: 'payAmount', dataIndex: 'totalPayAmount', title: 'Total Pembayaran',
                //     render: (totalPayAmount) => totalPayAmount && doConvertNumberToRupiahFormat(totalPayAmount)
                // },
                {
                    key: '', title: '',
                    render: (record, item, index) => this.buttonUpdatePayment(record, item, index)
                }
            ],
            isVisible: false,
            paymentTermin: {},
            terminIndex: 0
        }
    }

    buttonUpdatePayment = (record, item, index) => {
        const {isUpdateTermin} = this.props
        return (
            <>
                {
                    isUpdateTermin && !(record.paymentDetail.transactionStatus === 'paid') &&
                    <Button onClick={() => this.updatePaymentDetail(record, index)}>Update Payment Detail</Button>
                }
            </>

        )
    }

    updatePaymentDetail = (record, index) => {
        this.setState({isVisible: true, paymentTermin: record, terminIndex: index})
    }

    onFinish = (value) => {
        const {terminIndex} = this.state
        const {guaranteeDetail} = this.props
        guaranteeDetail.status = guaranteeDetail.remainingPaymentGuarantee === 0 ? COMPLETED : guaranteeDetail.status
        guaranteeDetail.payment[terminIndex].paymentDetail = {
            transactionStatus: value.transactionStatus,
            transactionId: value.transactionId,
            transactionTime: moment(value.transactionTime).format('YYYY-MM-DD HH:mm:ss'),
            vaNumber: value.vaNumber
        }
        updatePaymentTerminGuaranteeApi(guaranteeDetail)
            .then(result => {
                this.setState({isVisible: false})
                alert('Update pembayaran termin berhasil!!')
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    render() {
        const {columns, isVisible} = this.state
        const {guaranteeDetail} = this.props

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={guaranteeDetail?.payment}
                    rowKey={'dueDate'}
                    pagination={false}
                />
                <Modal
                    title={'Update Detail Pembayaran termin'}
                    visible={isVisible}
                    onCancel={() => this.setState({isVisible: false})}
                    footer={null}
                >
                    <UpdatePaymentDetailModal orderDetail={guaranteeDetail} onFinish={this.onFinish}/>
                </Modal>
            </>
        )
    }
}

export default GuaranteeTerminListComponent
