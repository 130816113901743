import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Row, Timeline} from 'antd';
import _ from 'lodash'
import {Link} from 'react-router-dom';
import {Colors} from "../../ui-util/colors";
import {getLevelText} from "../../ui-util/general-variable";

class AvostoreDetailChainComponent extends Component {
    render() {
        const {parentReducer, referralReducer} = this.props.userChainReducer
        const {name} = this.props.avostoreDetailReducer.avostoreDetail

        return (
            <Card style={{width: '48%', borderWidth: 1, borderColor: Colors.darkseagreen}}>
                <h5>AVO Chain</h5>
                <Timeline style={{marginTop: 30}}>
                    <Timeline.Item color={Colors.darkseagreen}>
                        <Card style={{borderRadius: 15, borderColor: Colors.darkseagreen}}>
                            <Row>
                                <div style={{fontWeight: 'bold', marginRight: 5}}>Parent :</div>
                                {parentReducer.name}
                            </Row>
                        </Card></Timeline.Item>
                    <Timeline.Item color={Colors.darkseagreen}>
                        <Card style={{borderRadius: 15, borderColor: Colors.darkseagreen}}>
                            <Row>
                                <div style={{fontWeight: 'bold', marginRight: 5}}>Avostore :</div>
                                {name}
                            </Row>
                        </Card>
                    </Timeline.Item>
                    {
                        _.map(referralReducer, (v, k) => {
                            return (
                                <Timeline.Item key={k} color={Colors.darkseagreen}>
                                    <Card style={{borderRadius: 15, borderColor: Colors.darkseagreen}}>
                                        <Row>
                                            <div style={{fontWeight: 'bold', marginRight: 5}}>Avostore child :</div>
                                            <Link to={`/avostore-detail/${v.id}`} target="_blank">
                                                {v.name} - {getLevelText(v.level)}
                                            </Link>
                                        </Row>
                                    </Card>
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>
            </Card>
            // <Col span={12}>
            //     <div style={{ marginLeft: 30 }}>
            //         <h5>Avo Chain</h5>
            //     </div>
            //     <div style={{ marginTop: 30, marginLeft: 30 }}>
            //         <Timeline>
            //             <Timeline.Item>Parent : {parentReducer.name}</Timeline.Item>
            //             <Timeline.Item>Avostore : {avostoreDetail.name}</Timeline.Item>
            //             {
            //                 _.map(referralReducer, (v, k) => {
            //                     return (
            //                         <Timeline.Item key={k}>Avostore child :
            //                             <Link to={`/avostore-detail/${v.id}`} target="_blank">
            //                                  {v.name}
            //                             </Link>
            //                         </Timeline.Item>
            //                     )
            //                 })
            //             }
            //         </Timeline>
            //     </div>
            //     <div style={{ marginLeft: 30 }}>
            //         <h5>Avo Point History</h5>
            //     </div>
            //     <div style={{ marginTop: 30, marginLeft: 30 }}>
            //         <Timeline>
            //             {
            //                 _.map(auditPoint, (v, k) => {
            //                     return (
            //                         <Timeline.Item key={k}>
            //                             <p style={{ marginBottom: 0 }}>{`${v.action}`}</p>
            //                             <p style={{ marginBottom: 0 }}>{`${v.prevPoint} point --> ${v.currentPoint} point`}</p>
            //                             <p style={{ marginBottom: 0 }}>
            //                                 {formatUnixDateToReadable(
            //                                     v.createdAt,
            //                                     "YYYY-MM-DD HH:mm:ss"
            //                                 )}
            //                             </p>
            //                         </Timeline.Item>
            //                     )
            //                 })
            //             }
            //         </Timeline>
            //     </div>
            // </Col>
        );
    }
}

const mapStateToProps = (state) => {
    const {userChainReducer, warehouseReducer, avostoreDetailReducer} = state;

    return {userChainReducer, warehouseReducer, avostoreDetailReducer};
}

export default connect(mapStateToProps, {})(AvostoreDetailChainComponent);
