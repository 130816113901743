import {GenericDataModel, GenericDataObj} from "./generic-data.model";

export default class GiftCardModel extends GenericDataModel{
    name: String
    giftCode: String
    description: String
    startDate: Number
    endDate: Number
    items: Array
    image: String
    userLevelList: Array
    minimumPurchasePerOrder: Number
    maximumUseCount: Number
    maximumTotalUsePerUser: Number
    created_at: Number
    isMultiply: Boolean
    minimumMultiplyValue: Number
    maximumMultiplyValue: Number
    paymentMethod: Array
    emailList: Array
    warehouseList: Array
    minimumProductOrdered: Array
    multiplyType: String


    constructor(giftCardModel : GiftCardModel){
        super()
        Object.assign(this, giftCardModel)
    }
}

export const GiftCardModelObj = {
    ...GenericDataObj,
    name: null,
    giftCode: null,
    description: null,
    startDate: null,
    endDate: null,
    items: [],
    image: null,
    userLevelList: [],
    minimumPurchasePerOrder: null,
    maximumUseCount: null,
    maximumTotalUsePerUser: null,
    created_at: null,
    isMultiply: false,
    minimumMultiplyValue: null,
    maximumMultiplyValue: null,
    paymentMethod: [],
    emailList: [],
    warehouseList:[],
    minimumProductOrdered: [{
        id: null,
        name: null,
        skuCode: null,
        minQty: null
    }],
    multiplyType: null
}


