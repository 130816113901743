import React from 'react';
import {Button, Card, Divider, Modal, Table} from "antd";
import {Colors} from "../../ui-util/colors";
import ProductCategoryForm from "./product-category-form";
import {createCategoryApi, getCategoriesApi, updateCategoryApi} from "../../api/product-category.api";
import {generateMd5Id, randomNumber} from "../../ui-util/general-variable";

class ProductCategory extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    key: 'name',
                    title: 'Kategori',
                    dataIndex: 'name',
                    width: '70%'
                },
                {
                    key: 'action',
                    title: 'Action',
                    render: (record) => (
                        <div>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.onEditButtonHandler(record)}>
                                Edit
                            </Button>
                            <Divider type="vertical"/>
                            <Button style={{backgroundColor: Colors.darkseagreen, color: 'white'}}
                                    onClick={() => this.onRemoveButtonHandler(record)}>
                                Hapus
                            </Button>
                        </div>
                    )
                }
            ],
            Categories: [],
            isVisible: false,
            isEdit: false,
            selectedItem: {},
            isDeleteModalVisible: false
        }
    }

    componentDidMount() {
        getCategoriesApi()
            .then(result => {
                this.setState({Categories: result})
            })
            .catch(err => {
                alert("Gagal mendapatkan data kategori !")
            })
    }

    onEditButtonHandler = (item) => {
        this.setState({isVisible: true, selectedItem: item, isEdit: true})
    }

    onRemoveButtonHandler = (item) => {
        this.setState({isDeleteModalVisible: true, selectedItem: item})
    }

    onOkHandler = () => {
        let {selectedItem} = this.state
        let updatedData = {...selectedItem, valid: false}
        this.setState({isDeleteModalVisible: false})
        this.doUpdateCategory(updatedData)
    }

    onFinishHandler = (values) => {
        let {isEdit, selectedItem} = this.state
        let tempData = {}
        if (!!isEdit) {
            tempData = {
                ...selectedItem,
                name: values.name,
            }
        } else {
            tempData = {
                id: generateMd5Id(randomNumber(6)),
                valid: true,
                name: values.name
            }
        }

        if (isEdit) {
            this.doUpdateCategory(tempData)
        } else {
            this.onCreateCategory(tempData)
        }
    }

    doUpdateCategory = (data) => {
        updateCategoryApi(data)
            .then(result => {
                alert("Data kategori berhasil diperbarui !")
                this.setState({isVisible: false, selectedItem: {}, isEdit: false, isDeleteModalVisible: false})
                window.location.reload()
            })
            .catch(err => alert("Gagal update data !"))
    }

    onCreateCategory = (data) => {
        createCategoryApi(data)
            .then(result => {
                alert("Kategori baru berhasil dibuat !")
                this.setState({isVisible: false, selectedItem: {}, isEdit: false})
                window.location.reload()
            })
            .catch(err => alert("Gagal menyimpan data !"))
    }


    render() {
        const {columns, Categories, isEdit, isVisible, selectedItem, isDeleteModalVisible} = this.state

        return (
            <Card>
                <h3 style={{marginBottom: 20}}>Daftar Kategori Produk</h3>
                <div align={'end'} style={{marginBottom: 20}}>
                    <Button style={{color: 'white', backgroundColor: Colors.darkseagreen}}
                            onClick={() => this.setState({
                                isVisible: true, isEdit: false
                            })}>Add New Category</Button>
                </div>
                <Table columns={columns} dataSource={Categories} rowKey='id'/>
                {
                    isVisible &&
                    <Modal
                        title={isEdit ? `Edit Category` : 'Create Category'}
                        visible={isVisible}
                        onCancel={() => this.setState({isVisible: false})}
                        footer={null}
                    >
                        <ProductCategoryForm onFinish={this.onFinishHandler} isEdit={isEdit}
                                             selectedItem={selectedItem}/>
                    </Modal>
                }
                {
                    isDeleteModalVisible &&
                    <Modal
                        title={'Hapus Category'}
                        visible={isDeleteModalVisible}
                        onCancel={() => this.setState({isDeleteModalVisible: false})}
                        onOk={() => this.onOkHandler()}
                    >
                        <p>Apakah anda yakin akan menghapus category ini ?</p>
                    </Modal>
                }
            </Card>
        );
    }
}

export default ProductCategory;
