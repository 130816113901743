import React from 'react';
import {Button, Card, Col, DatePicker, Divider, Dropdown, Menu, Row, Typography} from "antd";
import moment from "moment";
import {Colors} from "../../ui-util/colors";
import {connect} from "react-redux";
import {WAREHOUSE_ANALYTICS} from "../../reducers/warehouse.reducer";
import {ANALYTICS_PRODUCT, SET_LOADING} from "../../reducers/analytics-product.reducer";
import {percentFormat} from "../../ui-util/general-variable";
import {analyticsProductDispatcher} from "../../actions/analytics-product.action"
import DropdownSellerComponent from "../../components/dropdown-seller.component";

const {Text} = Typography;
const {RangePicker} = DatePicker;

class AnalyticsProductHeaderComponent extends React.PureComponent {

    dropDownWarehouse = () => {
        let {warehouseList} = this.props.warehouseReducer

        return (
            <Menu>
                {
                    warehouseList.map((warehouse) => (
                        <Menu.Item key={warehouse.id} onClick={() => this.warehouseButtonHandler(warehouse)}>
                            <Button type="link">{warehouse.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    warehouseButtonHandler = (warehouseAnalytics) => {
        this.props.analyticsProductDispatcher(WAREHOUSE_ANALYTICS, {warehouseAnalytics})
        this.props.analyticsProductDispatcher(SET_LOADING, {loading: true})
    }

    onDatePickerChange = (dates) => {
        this.props.analyticsProductDispatcher(ANALYTICS_PRODUCT, {
            startDate: dates[0],
            endDate: moment(dates[1]).endOf('days')
        })
    }

    filterHandler = () => {
        const {reloadPage} = this.props.analyticsProductReducer
        this.props.analyticsProductDispatcher(ANALYTICS_PRODUCT, {reloadPage: !reloadPage})
    }

    render() {
        const {warehouseReducer, analyticsProductReducer} = this.props;
        const {productGrowth, startDate, endDate} = analyticsProductReducer
        const {warehouseAnalytics} = warehouseReducer
        const {seller} = this.props.dropdownSellerReducer

        return (
            <div style={{marginTop: 50}}>
                <h3>Analytic Product</h3>
                <Row justify={'space-between'} align="center" style={{marginTop: 30}}>
                    <DropdownSellerComponent/>
                    <Col>
                        <Row>
                            <div style={{flexDirection: 'row'}}>
                                <RangePicker
                                    defaultValue={[startDate, endDate]}
                                    allowClear={false}
                                    disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                                    onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                                    format={'DD/MM/YYYY'}/>
                                {
                                    seller === 'Avoskin' &&
                                    <Dropdown overlay={this.dropDownWarehouse()} trigger={['click']}>
                                        <Button
                                            style={{
                                                backgroundColor: 'white',
                                                color: Colors.darkseagreen,
                                                marginRight: 10,
                                                marginLeft: 10
                                            }}
                                            size="medium"
                                            theme="filled"
                                        >
                                            {warehouseAnalytics.name}
                                        </Button>
                                    </Dropdown>}
                                <Button style={{marginLeft: 10, backgroundColor: Colors.darkseagreen, color: 'white'}}
                                        onClick={this.filterHandler}>Filter</Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}} justify={'center'}>
                    <Card style={{
                        minWidth: 500,
                        borderWidth: 1,
                        borderColor: Colors.gainsboro,
                        marginLeft: 30,
                        borderRadius: 7
                    }}>
                        <Row align={'start'}>
                            <div style={{marginRight: 20}}>
                                <Text style={{fontSize: 16}}>Product Growth</Text>
                                <div align={'center'} style={{marginTop: 10}}>
                                    <h5>{productGrowth && productGrowth.prev > 0 ? percentFormat(((productGrowth.current - productGrowth.prev) / productGrowth.prev) * 100, 'twoDigits') : '0%'}</h5>
                                </div>
                            </div>
                            <Divider style={{height: 75, borderWidth: 1, borderColor: 'black'}} type={"vertical"}/>
                            <div>
                                <div style={{fontSize: 16}}>This Period: {productGrowth.current} pcs</div>
                                <br/>
                                <div style={{fontSize: 16}}>Prev. Period: {productGrowth.prev} pcs</div>
                            </div>
                        </Row>
                    </Card>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {warehouseReducer, analyticsProductReducer, dropdownSellerReducer} = state;

    return {warehouseReducer, analyticsProductReducer, dropdownSellerReducer};
};

export default connect(mapStateToProps, {analyticsProductDispatcher})(AnalyticsProductHeaderComponent);
