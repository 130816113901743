import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportOrderComponent extends React.Component {
    render() {
        const {selectedRow, fileName} = this.props;
        return (
            <ExcelFile
                filename={fileName}
                element={
                    <Button disabled={!(selectedRow.length > 0)}
                            style={{
                                backgroundColor: selectedRow.length > 0 && Colors.darkseagreen,
                                color: selectedRow.length > 0 && 'white',
                                marginLeft: 20
                            }}>Export</Button>
                }>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="Jenis Order" value="orderType"/>
                    <ExcelColumn label="Tanggal Order" value="orderDate"/>
                    <ExcelColumn label="Status Order" value="orderState"/>
                    <ExcelColumn label="ID Pembeli" value="buyerId"/>
                    <ExcelColumn label="Nama Penerima" value="receiverName"/>
                    <ExcelColumn label="ID Penjual" value="sellerId"/>
                    <ExcelColumn label="No. Resi" value="awb"/>
                    <ExcelColumn label="Payment Method" value="paymentMethod"/>
                    <ExcelColumn label="Status Pembayaran" value="paymentStatus"/>
                    <ExcelColumn label="Waktu Pembayaran" value="paymentTime"/>
                    <ExcelColumn label="VA Number" value="vaNumber"/>
                    <ExcelColumn label="Shipping Method" value="shippingMethod"/>
                    <ExcelColumn label="Shipping Service" value="shippingService"/>
                    <ExcelColumn label="Shipping Price" value="shippingPrice"/>
                    <ExcelColumn label="Total Diskon Voucher" value="voucherDiscount"/>
                    <ExcelColumn label="Diskon Product" value="voucherDiscountProduct"/>
                    <ExcelColumn label="Diskon Shipping" value="voucherDiscountShipping"/>
                    <ExcelColumn label="Diskon Poin" value="discountPoint"/>
                    <ExcelColumn label="Cashback" value="grandCashback"/>
                    <ExcelColumn label="Additional Margin" value="sumSubtotalMargin"/>
                    <ExcelColumn label="Progressive Margin" value="progressiveMargin"/>
                    <ExcelColumn label="Grand Total" value="grandTotal"/>
                    <ExcelColumn label="Grand Total Guarantee" value="grandTotalGuarantee"/>
                    <ExcelColumn label="Grand Total Pre Order" value="grandTotalPreOrder"/>
                    <ExcelColumn label="Product ID" value="productId"/>
                    <ExcelColumn label="Nama Product" value="productName"/>
                    <ExcelColumn label="Harga Product" value="productPrice"/>
                    <ExcelColumn label="Additional Margin" value="additionalMargin"/>
                    <ExcelColumn label="Qty" value="productQty"/>
                    <ExcelColumn label="Subtotal" value="productsubTotal"/>
                    <ExcelColumn label="Subtotal Margin" value="subtotalMargin"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
