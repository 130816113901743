import {countProductPrice, doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "./general-variable";
import _ from 'lodash'

const formatGuaranteeAnalyticsExport = (rows) => {
    let selectedRow = [];
    rows.forEach((item) => {
        item.products.forEach((product, idx) => {
            let productObj = {}
            if (idx === 0) {
                productObj = {
                    userGuaranteeName: item.userGuaranteeName,
                    userId: item.userId,
                    orderId: item.orderId,
                    billingId: item.billingId,
                    orderDate: formatUnixDateToReadable(item.orderDate),
                    orderGrandTotal: doConvertNumberToRupiahFormat(item.orderGrandTotal),
                    status: item.status,
                    productId: product.id,
                    productName: product.name,
                    productPrice: doConvertNumberToRupiahFormat(countProductPrice(product.subtotal, product.count)),
                    productQty: product.count,
                    productsubTotal: doConvertNumberToRupiahFormat(product.subtotal),
                    termin: 0,
                    paymentMethod: item.orderDetail.paymentMethod,
                    transactionId: item.orderDetail.paymentDetail.transactionId,
                    paymentStatus: item.orderDetail.paymentDetail.transactionStatus,
                    paymentTime: item.orderDetail.paymentDetail.transactionTime,
                    payAmount: doConvertNumberToRupiahFormat(item.orderDetail.grandTotalGuarantee),
                    paymentPercent: `${Math.round((item.orderDetail.grandTotalGuarantee / item.orderGrandTotal)*100)}%`,
                    vaNumber: item.orderDetail.paymentDetail.vaNumber
                }
                selectedRow.push(productObj);
                if (item.products.length === 1) {
                    item.payment.forEach((payment, index) => {
                        let data = {
                            termin: payment.paymentDetail.transactionStatus === 'paid' ? index + 1 : '',
                            paymentMethod: payment.paymentMethod,
                            transactionId: payment.paymentDetail.transactionId,
                            paymentStatus: payment.paymentDetail.transactionStatus,
                            paymentTime: payment.paymentDetail.transactionTime,
                            payAmount: payment.paymentDetail.transactionStatus === 'paid' ? doConvertNumberToRupiahFormat(payment.totalPayAmount) : '',
                            paymentPercent: payment.paymentDetail.transactionStatus === 'paid' ? `${Math.round((payment.payAmount / item.orderGrandTotal)*100)}%` : '',
                            vaNumber: payment.paymentDetail.vaNumber
                        }
                        selectedRow.push(data);
                    })
                }
            } else if (idx < 3 && item.payment.length < 3) {
                productObj = {
                    productId: product.id,
                    productName: product.name,
                    productPrice: doConvertNumberToRupiahFormat(countProductPrice(product.subtotal, product.count)),
                    productQty: product.count,
                    productsubTotal: doConvertNumberToRupiahFormat(product.subtotal),
                }
                selectedRow.push(productObj);
                item.payment.forEach((payment, index) => {
                    if ((index === 0 && idx === 1) || (index === 1 && idx === 2) ) {
                        productObj.termin = payment.paymentDetail.transactionStatus === 'paid' ? index + 1 : ''
                        productObj.paymentMethod = payment.paymentMethod
                        productObj.transactionId = payment.paymentDetail.transactionId
                        productObj.paymentStatus = payment.paymentDetail.transactionStatus
                        productObj.paymentTime = payment.paymentDetail.transactionTime
                        productObj.payAmount = payment.paymentDetail.transactionStatus === 'paid' ? doConvertNumberToRupiahFormat(payment.totalPayAmount) : ''
                        productObj.paymentPercent = payment.paymentDetail.transactionStatus === 'paid' ? `${payment.percentage}%` : ''
                        productObj.vaNumber = payment.paymentDetail.vaNumber
                    } else if (item.products.length < 3 && item.payment.length === 2 && index !== 0) {
                        let data = {
                            termin: payment.paymentDetail.transactionStatus === 'paid' ? index + 1 : '',
                            paymentMethod: payment.paymentMethod,
                            transactionId: payment.paymentDetail.transactionId,
                            paymentStatus: payment.paymentDetail.transactionStatus,
                            paymentTime: payment.paymentDetail.transactionTime,
                            payAmount: payment.paymentDetail.transactionStatus === 'paid' ? doConvertNumberToRupiahFormat(payment.totalPayAmount) : '',
                            paymentPercent: payment.paymentDetail.transactionStatus === 'paid' ? `${payment.percentage}%` : '',
                            vaNumber: payment.paymentDetail.vaNumber
                        }
                        selectedRow.push(data);
                    }


                })
            } else {
                productObj = {
                    userGuaranteeName: '',
                    userId: '',
                    orderId: '',
                    billingId: '',
                    orderDate: '',
                    orderGrandTotal: '',
                    status: '',
                    paymentMethod: '',
                    transactionId: '',
                    paymentStatus: '',
                    paymentTime: '',
                    payAmount: '',
                    paymentPercent: '',
                    vaNumber: '',
                    productId: product.id,
                    productName: product.name,
                    productPrice: doConvertNumberToRupiahFormat(countProductPrice(product.subtotal, product.count)),
                    productQty: product.count,
                    productsubTotal: doConvertNumberToRupiahFormat(product.subtotal),
                }
                selectedRow.push(productObj);
            }
        });
    });
    let exportGuarantee = _.remove(selectedRow, function (n) {
        return !_.isEmpty(n)
    });
    return exportGuarantee
};

export {formatGuaranteeAnalyticsExport}
