import React, {Component} from 'react';
import {Button, Card, Col, DatePicker, Dropdown, Form, Menu, Row, Select, Table} from "antd";
import SearchComponent from "../../components/search.component";
import {
    convertStartEndDate, doConvertNumberToRupiahFormat,
    formatUnixDateToReadable,
    getStatusDisbursement,
    isEqual
} from "../../ui-util/general-variable";
import moment from "moment";
import {getListDisbursementApi, updateDisbursementListApi} from "../../api/disbursement.api";
import _ from "lodash";
import {Colors} from "../../ui-util/colors";
import {Link} from "react-router-dom";
import {setDisbursementListToExport} from "../../ui-util/table-util";
import ExportDisbursementListComponent from "./export-disbursement-list.component";

const {RangePicker} = DatePicker;
const {Option} = Select;

class DisbursementPage extends Component {
    formRef = React.createRef();

    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Nama Avostore/DC',
                    dataIndex: 'userName',
                    key: 'userName',
                    width: 170,
                },
                {
                    title: 'Email',
                    dataIndex: 'userEmail',
                    key: 'userEmail',
                    width: 170,
                },
                {
                    title: 'Nomor Rekening',
                    dataIndex: 'bankAccountNumber',
                    key: 'bankAccountNumber',
                    width: 170,
                },
                {
                    title: 'Nama Pemilik',
                    dataIndex: 'bankAccountName',
                    key: 'bankAccountName',
                    width: 170,
                },
                {
                    title: 'Nama Bank',
                    dataIndex: 'bankAccountCode',
                    key: 'bankAccountCode',
                    width: 170,
                },
                {
                    title: 'Total Disbursement',
                    dataIndex: 'totalDisbursement',
                    key: 'totalDisbursement',
                    render: (totalDisbursement) => doConvertNumberToRupiahFormat(totalDisbursement),
                    width: 170,
                },
                {
                    title: 'Tanggal Pengajuan',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    width: 200,
                    render: (created_at) => formatUnixDateToReadable(created_at, 'lll')
                },
                {
                    title: 'Status Disbursement',
                    dataIndex: 'payoutStatus',
                    key: 'payoutStatus',
                    width: 170,
                    render: (payoutStatus) => getStatusDisbursement(payoutStatus)
                },
                {
                    title: 'Aksi',
                    key: 'operation',
                    render: (text, item) => (
                        <React.Fragment>
                            <Link to={`/disbursement-detail/${item.id}`}>
                                Detail
                            </Link>
                        </React.Fragment>
                    ),
                    width: 80,
                    fixed: 'right'
                }
            ],
            statusList: [
                {status: '', name: 'All'},
                {status: 'queued', name: 'Menunggu persetujuan'},
                {status: 'approved', name: 'Pengajuan disetujui'},
                {status: 'rejected', name: 'Pengajuan ditolak'}
            ],
            listItem: [
                {
                    value: 'approved',
                    status: 'Pengajuan disetujui'
                },
                {
                    value: 'rejected',
                    status: 'Pengajuan ditolak'
                },
            ],
            disbursementList: [],
            filterStartDate: moment().subtract(30, 'days'),
            filterEndDate: moment(),
            searchKey: '',
            selectedRow: [],
            selectedRowKeys: [],
            loading: false,
            selectedData: {
                status: '',
                name: 'All'
            },
            selectedStatus: ''
        }
    }

    componentDidMount() {
        this.fetchDisbursementListFromApi()
        window.scrollTo(0,0)
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!isEqual(this.state.filterStartDate, prevState.filterStartDate) ||
            !isEqual(this.state.filterEndDate, prevState.filterEndDate) ||
            !isEqual(this.state.selectedData, prevState.selectedData)) {
            this.fetchDisbursementListFromApi()
        }
    }

    fetchDisbursementListFromApi = () => {
        this.setState({loading: true})
        const {filterStartDate, filterEndDate, selectedData} = this.state;
        let body = {
            startDate: convertStartEndDate(filterStartDate, 'start'),
            endDate: convertStartEndDate(filterEndDate),
            payoutStatus: selectedData.status
        }

        getListDisbursementApi(body)
            .then(result => {
                this.setState({disbursementList: result, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log("error get disbursementList :", err)
                alert("error get disbursementList  !!")
            })
    }

    onSearchEmailHandler = (value) => {
        this.setState({searchKey: value})
    }

    dataToDisplay = () => {
        const {disbursementList, searchKey} = this.state
        let dataToDisplay = []
        if (!!searchKey) {
            dataToDisplay = _.filter(disbursementList, item => item.userEmail.toLowerCase().includes(searchKey.toLowerCase()))
        } else {
            dataToDisplay = disbursementList
        }
        return dataToDisplay
    }

    onDatePickerChange = (dates) => {
        this.setState({filterStartDate: dates[0], filterEndDate: dates[1]})
    }

    filterWithDate = () => {
        this.fetchDisbursementListFromApi()
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setDisbursementListToExport(rows);
        this.setState({selectedRow, selectedRowKeys});
    }

    dropDownHandler = () => {
        let {statusList} = this.state

        return (
            <Menu>
                {
                    statusList.map((type, index) => (
                        <Menu.Item key={index} onClick={() => this.dropDownButtonHandler(type)}>
                            <Button type="link">{type.name}</Button>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    dropDownButtonHandler = (value) => {
        let selectedData = {status: value.status, name: value.name}
        this.setState({selectedData})
    }

    onChangeSelectHandler = (value) => {
        this.setState({selectedStatus: value})
    }

    onFinish = () => {
        const {selectedRow, selectedStatus} = this.state;
        let disbursementIdList = []

        if (!!selectedStatus && selectedRow.length > 0) {
            _.map(selectedRow, r => disbursementIdList.push(r.id))

            let body = {payoutStatus: selectedStatus, disbursementIdList}
            updateDisbursementListApi(body)
                .then(result => {
                    alert("sukses melakukan update data")
                    window.location.reload()
                })
                .catch(err => {
                    console.log("error updateDisbursementListApi :", err)
                    alert("Gagal update pencairan dana !!")
                })
        } else {
            alert("pilih status terlebih dahulu")
        }
    }

    render() {
        const {
            columns,
            loading,
            filterStartDate,
            filterEndDate,
            selectedRowKeys,
            selectedRow,
            selectedData,
            listItem
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };

        return (
            <Card>
                <h5 style={{marginBottom: 20}}>Daftar Disbursement</h5>
                <Row justify={"space-between"} align="middle" style={{marginBottom: 10}}>
                    <Col span={8}>
                        <Row>
                            Pilih Status :
                            <Dropdown overlay={this.dropDownHandler()} trigger={['click']}>
                                <Button
                                    style={{
                                        backgroundColor: 'white',
                                        color: Colors.darkseagreen,
                                        minWidth: '150px',
                                        marginLeft: 20,
                                        marginRight: 20
                                    }}
                                    size="medium"
                                    theme="filled"
                                >
                                    {selectedData?.name}
                                </Button>
                            </Dropdown>
                        </Row>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" justify={'end'} align="middle">
                            Tgl. Pengajuan
                            <RangePicker
                                allowClear={false}
                                style={{width: '40%', marginRight: 15, marginLeft: 15}}
                                value={[filterStartDate, filterEndDate]}
                                onCalendarChange={(dates) => this.onDatePickerChange(dates)}
                                disabledDate={(currentDate) => currentDate > moment().endOf('day')}
                            />

                            {selectedRow.length > 0 ? <ExportDisbursementListComponent selectedRow={selectedRow}/> :
                                <Button disabled>Export</Button>}
                        </Row>
                    </Col>
                </Row>
                <Row justify={"space-between"} align="middle" style={{marginBottom: 10}}>
                    <Col style={{paddingBottom: 10}}>
                        <SearchComponent searchItem={'email'} onSearchHandler={this.onSearchEmailHandler}/>
                    </Col>
                    <Col>
                        <Row>
                            <p>Update Pencairan Dana : </p>
                            <Form onFinish={(value) => this.onFinish(value)} ref={this.formRef}>
                                <Form.Item>
                                    <Select style={{width: 220, marginRight: 10}}
                                            placeholder={'Pilih Status'}
                                            onChange={(value) => this.onChangeSelectHandler(value)}>
                                        {
                                            listItem.map((item) => <Option key={item.value}
                                                                           value={item.value}>{item.status}</Option>)
                                        }
                                    </Select>
                                    <Button type="primary" htmlType="submit" disabled={selectedRow.length === 0}>
                                        Update
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Row>
                    </Col>
                </Row>
                <Table scroll={{x: 1500}}
                       columns={columns}
                       rowSelection={rowSelection}
                       loading={loading}
                       dataSource={this.dataToDisplay().map((d, index) => ({key: index, ...d}))}
                       pagination={{pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true}}
                />
            </Card>
        );
    }
}

export default DisbursementPage;