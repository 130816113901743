import React, {Component} from 'react';
import {Col, Drawer, Image, Row} from "antd";
import {formatUnixDateToReadable} from "../../ui-util/general-variable";

class GiftItemDetailComponent extends Component {
    constructor() {
        super();

        this.state = {
            giftItemList: [
                {key: 'image', name: 'Gambar'},
                {key: 'itemName', name: 'Nama'},
                {key: 'description', name: 'Deskripsi'},
                {key: 'created_at', name: 'Tanggal Dibuat'},
                {key: 'stock', name: 'Stock'},
                {key: 'weight', name: 'Berat'}
            ],
        }
    }

    renderItemValue(item) {
        const {giftDetail} = this.props

        switch (item.key) {
            case 'created_at':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{formatUnixDateToReadable(giftDetail[item.key], 'ddd, DD MMM YYYY HH:mm')}</p>
                        </Col>
                    </Row>
                )
            case 'image':
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <Image src={giftDetail[item.key]} width={100} height={100}/>
                        </Col>
                    </Row>
                )
            default:
                return (
                    <Row key={item.key} gutter={16}>
                        <Col className={'gutter-row'} span={10}>
                            <p>{item.name}</p>
                        </Col>
                        <Col className={'gutter-row'} span={14}>
                            <p>{giftDetail[item.key] && giftDetail[item.key] !== "null" ? giftDetail[item.key] : '-'}</p>
                        </Col>
                    </Row>
                )
        }
    }

    render() {
        const {onCloseHandler, isGiftDetailVisible} = this.props
        const {giftItemList} = this.state;

        return (
            <Drawer
                title={'Gift Item Detail'}
                width={700}
                onClose={() => onCloseHandler(false)}
                visible={isGiftDetailVisible}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        {giftItemList.map(item => this.renderItemValue(item))}
                    </Col>
                </Row>
            </Drawer>
        );
    }
}

export default GiftItemDetailComponent;