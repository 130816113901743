import {getDeliveryOrderListApi} from "../api/delivery-order.api";
import {DELIVERY_ORDER_DETAIL} from "../reducers/delivery-order.reducer";
import {convertStartEndDate} from "../ui-util/general-variable";

const deliveryOrderDispatcher = (type, payload) => {
    return {type, payload}
}

const getDeliveryOrderList = () => {
    return (dispatch, getState) => {
        dispatch(deliveryOrderDispatcher(DELIVERY_ORDER_DETAIL, {loading: true}))
        let {activeTab, startDate, endDate} = getState().deliveryOrderReducer
        let requestBody = {
            orderState: [activeTab],
            startDate: convertStartEndDate(startDate, 'start'),
            endDate: convertStartEndDate(endDate)
        }
        getDeliveryOrderListApi(requestBody)
            .then(result => {
                dispatch(deliveryOrderDispatcher(DELIVERY_ORDER_DETAIL, {loading: false, deliveryOrderList: result}))
            })
            .catch(err => console.log(err))
    }
}

export {deliveryOrderDispatcher, getDeliveryOrderList}
