import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

const getAnalyticsUserListFromApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/user-list`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getAnalyticsUserChartFromApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/user-chart`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getAnalyticsUserRegister = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/user-register`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getAnalyticsUserActive = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/analytic/user-active`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getUserTopSpender = (bodyRequest, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v2/analytic/user-top-spender`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {
    getAnalyticsUserListFromApi,
    getAnalyticsUserChartFromApi,
    getAnalyticsUserRegister,
    getAnalyticsUserActive,
    getUserTopSpender
}
