import {GenericDataModel, GenericDataObj} from "./generic-data.model";

//Voucher Type Enum
const UNIQUE_BY_EMAIL = 'UNIQUE_BY_EMAIL'
const GENERAL_PROMO = 'GENERAL_PROMO'
const MINIMUM_PURCHASE_PER_ORDER = 'MINIMUM_PURCHASE_PER_ORDER'
const MINIMUM_PURCHASE_PER_MONTH = 'MINIMUM_PURCHASE_PER_MONTH'
const MAXIMUM_USE_COUNT = 'MAXIMUM_USE_COUNT'
const MAXIMUM_TOTAL_LIMIT = 'MAXIMUM_TOTAL_LIMIT'
const MAXIMUM_TOTAL_LIMIT_PER_USER = 'MAXIMUM_TOTAL_LIMIT_PER_USER'
const MAXIMUM_USE_PER_USER = 'MAXIMUM_USE_PER_USER'
const MINIMUM_PRODUCT_COUNT_PER_ORDER = 'MINIMUM_PRODUCT_COUNT_PER_ORDER'
const MAXIMUM_PRODUCT_COUNT_PER_ORDER = 'MAXIMUM_PRODUCT_COUNT_PER_ORDER'
const DISCOUNT_PERCENT = 'DISCOUNT_PERCENT'
const DISCOUNT_NOMINAL = 'DISCOUNT_NOMINAL'
const FREE_SHIPPING = 'FREE_SHIPPING'


type VoucherType =
    UNIQUE_BY_EMAIL
    | GENERAL_PROMO
    | MINIMUM_PURCHASE_PER_ORDER
    | MINIMUM_PURCHASE_PER_MONTH
    | MAXIMUM_USE_COUNT
    | MAXIMUM_TOTAL_LIMIT
    | MAXIMUM_TOTAL_LIMIT_PER_USER
    | MAXIMUM_USE_PER_USER
    | MINIMUM_PRODUCT_COUNT_PER_ORDER
    | MAXIMUM_PRODUCT_COUNT_PER_ORDER
    | FREE_SHIPPING
    | DISCOUNT_PERCENT
    | DISCOUNT_NOMINAL

export default class VoucherDetailModel extends GenericDataModel {
    voucherName: String
    voucherCode: String
    description: String
    startDate: Number
    endDate: Number
    voucherValue: Number
    voucherValueType: String
    voucherImage: String
    voucherType: String
    isMultiple: Boolean
    userLevelList: Array<String>
    userLevel: Array<String>
    paymentMethod: Array<String>
    maximumVoucherValue: Number
    minimumPurchasePerOrder: Number
    minimumPurchasePerMonth: Number
    maximumUseCount: Number
    maximumTotalLimit: Number
    maximumTotalLimitPerUser: Number
    maximumTotalUsePerUser: Number
    minimumProductCountPerOrder: Number
    maximumProductCountPerOrder: Number

    constructor() {
        super();
        Object.assign(this, VoucherDetailModel)
    }
}

export const VoucherDetailObj = {
    ...GenericDataObj,
    voucherName: null,
    voucherCode: null,
    image: null,
    description: null,
    startDate: null,
    endDate: null,
    voucherValue: 0,
    voucherValueType: null,
    voucherType: null,
    isMultiple: false,
    emailList: [],
    userLevelList: [],
    paymentMethod: [],
    maximumVoucherValue:0,
    minimumPurchasePerOrder: 0,
    minimumPurchasePerMonth: null,
    maximumUseCount: 0,
    maximumTotalLimit: null,
    maximumTotalLimitPerUser: null,
    maximumTotalUsePerUser: 0,
    minimumProductCountPerOrder: null,
    maximumProductCountPerOrder: null
}

export {
    UNIQUE_BY_EMAIL,
    GENERAL_PROMO,
    MAXIMUM_PRODUCT_COUNT_PER_ORDER,
    MINIMUM_PRODUCT_COUNT_PER_ORDER,
    MAXIMUM_TOTAL_LIMIT,
    MAXIMUM_TOTAL_LIMIT_PER_USER,
    MAXIMUM_USE_COUNT,
    MAXIMUM_USE_PER_USER,
    MINIMUM_PURCHASE_PER_MONTH,
    MINIMUM_PURCHASE_PER_ORDER,
    FREE_SHIPPING,
    DISCOUNT_PERCENT,
    DISCOUNT_NOMINAL
}
