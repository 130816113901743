import {formatUnixDateToReadable} from "./general-variable";

const setAvostoreDataSelectedRows = (rows) => {
    let selectedRow = []
    rows.forEach((row) => {
        let data = {
                id: row.id,
                name: row.name,
                email: row.email,
                level: row.level,
                avostoreArea: row.avostoreArea,
                parentRef: row.parentRef,
                point: row.point,
                phoneNumber: row.phoneNumber,
                identityCardNumber: row.identityCardNumber,
                referralId: row.referralId,
                joinDate: formatUnixDateToReadable(row.joinDate, 'll')
            }
        selectedRow.push(data)
    })
    return selectedRow
}

export {setAvostoreDataSelectedRows}
