import {getWarehouseListFromFirestore} from "../firebase/database/warehouse";
import {avoskinId, extractQuerySnapshotToArray} from "../ui-util/general-variable";
import {WAREHOUSE_LIST} from "../reducers/warehouse.reducer";
import {WarehouseObject} from "../models/warehouse.model";
import {getAllWarehouseDcAvoskinApi, getWarehouseDcListApi} from "../api/warehouse.api";

const warehouseDispatcher = (type, payload) => {
    return {type, payload}
}

const getWarehouseList = (avostoreId) => {
    return ((dispatch) => {
        const uid = !!avostoreId ? avostoreId : avoskinId()
        getWarehouseListFromFirestore(uid)
            .then(result => {
                const warehouseList = extractQuerySnapshotToArray(result)
                const warehouse = warehouseList[0]
                let warehouseDefault = {...WarehouseObject, id: '', name: 'All'}
                let warehouseToDisplay = [...warehouseList, warehouseDefault]

                if (!!avostoreId) {
                    dispatch(warehouseDispatcher(WAREHOUSE_LIST, {warehouseAvoStoreList: warehouseList}))
                } else {
                    dispatch(warehouseDispatcher(WAREHOUSE_LIST, {
                        warehouseList: warehouseToDisplay,
                        warehouseDetail: warehouse,
                        warehouseAnalytics: warehouse
                    }))
                }
            })
            .catch(err => {
                console.log('Error get warehouse list', err)
            })
    })
}

const getWarehouseDcListFromApi = (slug) => {
    return ((dispatch) => {
        getWarehouseDcListApi()
            .then(result => {
                let defaultWarehouse = slug === 'editProduct' ? {
                    warehouseId: '',
                    warehouseName: 'Gudang DC Jakarta Pusat - zico',
                    userId: ''
                } : {
                    warehouseId: '', warehouseName: 'All', userId: ''
                }
                let warehouseDefault = {...WarehouseObject, ...defaultWarehouse}
                let warehouseToDisplay = [...result.data, warehouseDefault]
                dispatch(warehouseDispatcher(WAREHOUSE_LIST, {
                    warehouseAvoStoreList: warehouseToDisplay
                }))
            })
            .catch(err => {
                console.log('Error get warehouse DC list', err)
            })
    })
}

const getWarehouseDcAvoskinList = () => {
    return ((dispatch) => {
        getAllWarehouseDcAvoskinApi()
            .then(result => {
                dispatch(warehouseDispatcher(WAREHOUSE_LIST, {
                    warehouseDcAvoskinList: result.data
                }))
            })
            .catch(err => {
                console.log('Error getWarehouseListByUserIdApi', err)
            })
    })
}

export {getWarehouseList, warehouseDispatcher, getWarehouseDcListFromApi, getWarehouseDcAvoskinList}
