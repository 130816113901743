import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {Colors} from "../ui-util/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportOrderWithoutAwbComponent extends React.Component {
    render() {
        const {selectedRow} = this.props;
        return (
            <ExcelFile filename={'Order'}
                       element={<Button style={{
                           height: 40,
                           width: 120,
                           backgroundColor: selectedRow.length !== 0 && Colors.darkseagreen,
                           color: selectedRow.length !== 0 && 'white'
                       }} disabled={selectedRow.length === 0}>Export</Button>}>
                <ExcelSheet data={selectedRow} name="Order List">
                    <ExcelColumn label="ID" value="id"/>
                    <ExcelColumn label="ID Order" value="orderId"/>
                    <ExcelColumn label="Nama Penerima" value="receiverName"/>
                    <ExcelColumn label="Jasa Pengiriman" value="shippingMethod"/>
                    <ExcelColumn label="Nama Gudang" value="warehouseName"/>
                    <ExcelColumn label="No. Resi" value="awb"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
