import {getGiftItemListApi} from "../api/gift.api";
import {SET_GIFT_ITEMS} from "../reducers/gift.reducer";

const giftDispatcher = (type, payload) => {
    return {type, payload}
}

const getGiftItemListFromApi = () => {
    return (dispatch) => {
        getGiftItemListApi()
            .then(result => {
                dispatch(giftDispatcher(SET_GIFT_ITEMS, {
                    giftItemList: result.data.GiftProductList
                }))
            })
            .catch(err => {
                console.log('getTransactionList', err)
            })
    }
}

export {
    getGiftItemListFromApi,
    giftDispatcher
}
