const INITIAL_STATE = {
    dcOrderList: [],
    searchKey: '',
    warehouseDcId: '',
    loading: false,
    dataCount: 0,
    currentPage: 1,
    pageSize: 10,
    selectedRowExcel: [],
    selectedOrderToPrintLabel: [],
    selectedRowKeys: []
}

export const DC_ORDER_REDUCER = 'DC_ORDER_REDUCER';

export const dcOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DC_ORDER_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
