import React from "react";
import {Button, Table, Col} from "antd";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {connect} from "react-redux";
import {generalDispatcher} from "../../actions/general.action";

class OrderListManifestComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            columns: [
                {title: "No. Pesanan", dataIndex: 'orderId', key: 'orderId'},
                {title: "Ekspedisi", dataIndex: 'shippingMethod', key: 'shippingMethod'},
                {title: "Service", dataIndex: 'shippingService', key: 'shippingService'},
                {title: "Resi", dataIndex: 'awb', key: 'awb'},
                {
                    title: 'Aksi',
                    key: 'action',
                    render: (item, record, index) => (
                        <div>
                            <Button
                                type="link"
                                disabled={this.props?.manifestReducer?.manifestDetail?.state === 'COMPLETED' || record.shippingMethod === 'Self Service'}
                                onClick={() => {
                                    this.props.generalDispatcher(MODAL_REDUCER, {
                                        modalVisibilityResi: true,
                                        orderDetailToUpdateResi: record
                                    })
                                    this.setState({orderIndex: index})
                                }}
                            >
                                Edit Resi
                            </Button>
                        </div>
                    )
                }
            ]
        }
    }

    render() {
        const {columns} = this.state
        const {orderListInManifest, loadingManifestDetail} = this.props.manifestReducer
        return (
            <Col span={18} style={{padding: 20}}>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={orderListInManifest}
                    rowKey="id"
                    loading={loadingManifestDetail}
                />
            </Col>
        )
    }
}

const mapStateToProps = (state) => {
    const {manifestReducer} = state;

    return {manifestReducer};
}

export default connect(mapStateToProps, {generalDispatcher})(OrderListManifestComponent)
