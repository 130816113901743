const formatExportCashbackDcList = (rows) => {
    let selectedRow = []
    rows.forEach((row, index) => {
        let data = {
            no: index + 1,
            buyerName: row.buyerName,
            buyerLevel: row.buyerLevel,
            orderId: row.orderId,
            billingId: row.billingId,
            poPaymentId: row.poPaymentId,
            qty: row.qty,
            grossLine: row.grossLine,
            shipping: row.shipping,
            discountPoint: row.discountPoint,
            voucherReduction: row.voucher,
            cashback: row.cashback,
            grandTotal: row.grandTotal,
            fines: row.fines,
            cashPaidByUser: row.cashPaidByUser,
            remainingPayment: row.remainingPayment,
            netCashValue: row.netCashValue,
            topLine: row.topLine,
            topLineNetCashValue: row.topLineNetCashValue,
            potentialMarginPercentage: `${row.potentialMarginPercentage}%`,
            potentialCashback: row.potentialCashback,
            handlingFee: row.handlingFee,
            cashbackPerCashValue: row.cashbackPerCashValue,
        }
        selectedRow.push(data)
    })
    return selectedRow
};

export {
    formatExportCashbackDcList
}
