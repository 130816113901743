import {firestore} from "../index";

const targetSalesRef = firestore.collection('target_sales')

const setTargetSalesToFirestore = (docName, targetSalesDetail) => {
    return targetSalesRef.doc(docName).set(targetSalesDetail)
};

const getTargetSalesByDocNameFromFirestore = (id) => {
    return targetSalesRef.where('id', '==', id).where('valid', '==', true).get()
}

const getTargetSalesListFromFirestore = () => {
    return targetSalesRef.where('valid', '==', true).get()
}

export {
    setTargetSalesToFirestore,
    getTargetSalesByDocNameFromFirestore,
    getTargetSalesListFromFirestore
}
