import React from 'react'
import {Button, Card, Descriptions} from "antd";
import {Colors} from "../../ui-util/colors";
import {sendTaxInvoiceRequestEmailApi} from "../../api/email.api";
import TaxInvoiceModal from "../../components/tax-invoice.modal";

class TaxInvoiceRequestComponent extends React.PureComponent {

    constructor() {
        super();

        this.state = {
            modalVisibility: false
        }
    }

    onFinish = (values) => {
        this.setState({loading: true})
        const {orderId} = this.props.orderDetail
        values.orderId = orderId
        sendTaxInvoiceRequestEmailApi(values)
            .then(result => {
                alert('Email permintaan faktur pajak berhasil dikirim!')
                this.setState({loading: false, modalVisibility: false})
            })
            .catch(err => {
                this.setState({loading: false})
                console.log('Error sendTaxInvoiceRequestEmailApi', err)
            })
    }

    render() {
        const {modalVisibility} = this.state
        const {orderDetail} = this.props

        return (
            <Card style={{marginTop: 30}}>
                <Descriptions title="Permintaan Faktur Pajak" layout="vertical" column={1}>
                    <div style={{marginBottom: 10}}>
                        <Button
                            disabled={orderDetail.paymentDetail.transactionStatus !== 'paid'}
                            onClick={() => this.setState({modalVisibility: true})}
                            style={{
                                backgroundColor: Colors.gainsboro,
                                borderColor: Colors.darkseagreen,
                                borderRadius: 5
                            }}>Kirim permintaan
                        </Button>
                    </div>
                </Descriptions>
                <TaxInvoiceModal
                    modalVisibility={modalVisibility}
                    onCancel={() => this.setState({modalVisibility: false})}
                    onFinish={this.onFinish}
                />
            </Card>
        )
    }
}

export default TaxInvoiceRequestComponent
