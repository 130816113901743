import React, {Component} from 'react';
import {connect} from 'react-redux';
import {doConvertNumberToRupiahFormat, formatUnixDateToReadable} from "../../ui-util/general-variable";
import {Button, Table, Tag} from "antd";
import {CANCELLED, COMPLETED} from "../../models/order-detail.model";
import _ from "lodash";
import {setRemainingPaymentPreOrderRows} from "../../ui-util/po-list-table.util";
import ExportRemainingPaymentComponent from "./export-remaining-payment.component";

class RemainingPaymentComponent extends Component {
    constructor() {
        super();

        this.state = {
            columns: [
                {
                    title: 'Status',
                    key: 'status',
                    align: 'center',
                    render: (record) => {
                        return (
                            <React.Fragment>
                                <Tag color={record.status === COMPLETED ? 'orange' : 'blue'} key={record.status}>
                                    {record.status}
                                </Tag>
                            </React.Fragment>
                        );
                    }
                },
                {title: 'No. Pesanan', dataIndex: 'orderId', key: 'orderId', align: 'center', width: 200},
                {
                    title: 'Nama',
                    dataIndex: 'userName',
                    key: 'userName',
                    align: 'center',
                    render: (userName) => <div>{userName}</div>
                },
                {
                    title: 'Batas Pembayaran Termin 2',
                    dataIndex: 'expiredDate',
                    key: 'expiredDate',
                    align: 'center',
                    render: (expiredDate) => <div>{formatUnixDateToReadable(expiredDate)}</div>
                },
                {
                    title: 'Total Pembayaran DP',
                    dataIndex: 'payAmount',
                    key: 'payAmount',
                    align: 'center',
                    render: (payAmount) => <div>{doConvertNumberToRupiahFormat(payAmount)}</div>
                },
                {
                    title: 'Total Pembayaran 2',
                    dataIndex: 'payAmount',
                    key: 'payAmount',
                    align: 'center',
                    render: (payAmount) => <div>{doConvertNumberToRupiahFormat(payAmount)}</div>
                },
                {
                    title: 'Total Denda',
                    dataIndex: 'fines',
                    key: 'fines',
                    align: 'center',
                    render: (fines) => <div>{doConvertNumberToRupiahFormat(fines)}</div>
                },
                {
                    title: 'Total Pembayaran',
                    dataIndex: 'orderGrandTotal',
                    key: 'orderGrandTotal',
                    align: 'center',
                    render: (orderGrandTotal, record) => <div>{doConvertNumberToRupiahFormat(orderGrandTotal+ record.fines)}</div>
                },
            ],
            deliveryOrderList: [],
            modalVisible: false,
            orderDetail: {},
            modalMessage: '',
            selectedRowKeys: [],
            selectedRow: []
        }
    }

    dataToDisplay = () => {
        const {remainingPaymentList, searchKeyword} = this.props.preOrderReducer
        const filteredList = _.filter(remainingPaymentList, item =>
            item.orderId.toLowerCase().includes(searchKeyword.toLowerCase()))
        return filteredList
    }

    onSelectRow = (selectedRowKeys, rows) => {
        let selectedRow = setRemainingPaymentPreOrderRows(rows);
        this.setState({selectedRow, selectedRowKeys});
    };

    render() {
        const {columns, selectedRowKeys, selectedRow} = this.state
        const {activeTab, loading} = this.props.preOrderReducer
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectRow(selectedRowKeys, selectedRows);
            }
        };


        return (
            <>
                <div style={{marginBottom: 20}} className="d-flex flex-column align-items-end">
                    {activeTab !== CANCELLED ? selectedRow.length > 0 ?
                        <ExportRemainingPaymentComponent selectedRow={selectedRow}/> :
                        <Button disabled>Export</Button> : null}
                </div>
                <Table
                    scroll={{x: 'calc(1000px + 100%)', y: 600}}
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={this.dataToDisplay()}
                    rowKey="id"
                    loading={loading}
                    rowSelection={rowSelection}
                    pagination={{pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true}}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {preOrderReducer} = state;
    return {preOrderReducer};
}

export default connect(mapStateToProps, {})(RemainingPaymentComponent);