import React from "react";
import {Button, Input, Row} from "antd";
import {Colors} from "../../ui-util/colors";
import FilterDateComponent from "../../components/filter-date.component";
import ExportOrderComponent from "../../components/export-order.component";
import {generateMd5Id, getDateEpochFormat, randomNumber} from "../../ui-util/general-variable";
import {connect} from "react-redux";
import OrderCount from "../orders/order-count.component";
import FilterShippingComponent from "./filter-shipping.component";
import ReactToPrint from "react-to-print";
import {FileTextOutlined} from "@ant-design/icons";
import PrintManifestComponent from "../../components/print-manifest.component";
import {MANIFEST_REDUCER} from "../../reducers/manifest.reducer";
import {generalDispatcher} from "../../actions/general.action";
import {getOrderListToCreateManifestAction} from "../../actions/manifest.action";
import {createManifestApi} from "../../api/manifest.api";
import DropdownAvoskinDcComponent from "../../components/dropdown-avoskin-dc.component";

const {Search} = Input;

class CreateManifestHeaderComponent extends React.PureComponent {

    showButtonDownload = () => {
        const {shippingMethod, selectedPrint, manifestUser} = this.props.manifestReducer;
        const manifestData = {
            id: generateMd5Id(randomNumber(6)),
            isDcOrders: manifestUser === 'DC' ? true : false,
            manifestId: `MNF-${getDateEpochFormat()}-${randomNumber(3)}`,
            createdAt: getDateEpochFormat(),
            lastUpdate: getDateEpochFormat(),
            orderList: selectedPrint,
            state: 'PROCEED'
        }
        return (
            <React.Fragment>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            style={(shippingMethod !== 'Semua') && selectedPrint.length > 0 ? {
                                backgroundColor: Colors.darkseagreen,
                                color: 'white'
                            } : null}
                            icon={<FileTextOutlined/>}
                            size="large"
                            theme="filled"
                            disabled={!((shippingMethod !== 'Semua') && selectedPrint.length > 0)}
                        >
                            Create Manifest
                        </Button>
                    )}
                    content={(() => this.manifestRef)}
                    onAfterPrint={() => this.setManifestData(manifestData)}
                />
                <div style={{display: 'none'}}>
                    <PrintManifestComponent
                        data={manifestData}
                        orderList={selectedPrint}
                        ref={(el) => (this.manifestRef = el)}
                    />
                </div>
            </React.Fragment>
        )
    }

    setManifestData(manifestData) {
        createManifestApi(manifestData)
            .then(res => {
                alert('Manifest berhasil dibuat')
                window.location.reload()
            })
            .catch(err => {
                console.log('Error', err)
            })
    }

    render() {
        const {selectedRowExcel, totalData} = this.props.manifestReducer

        return (
            <>
                <Row type="flex" justify={'space-between'} align="middle" style={{marginBottom: 30}}>
                    <DropdownAvoskinDcComponent />
                    <Search
                        placeholder="Cari Pesanan"
                        onSearch={value => this.props.generalDispatcher(MANIFEST_REDUCER, {searchKey: value})}
                        style={{marginTop: 20, width: 250, height: 50}}
                    />
                    <Row>
                        <FilterDateComponent
                            filterHandler={() => this.props.getOrderListToCreateManifestAction()}
                            filterLabel={'Tgl Pesanan'}
                        />
                        <ExportOrderComponent
                            fileName={'Buat Surat Jalan'}
                            selectedRow={selectedRowExcel}
                        />
                    </Row>
                </Row>
                <Row type="flex" justify={'space-between'} align="middle" style={{marginBottom: 30}}>
                    <OrderCount count={totalData}/>
                    <FilterShippingComponent/>
                    {this.showButtonDownload()}
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    const {manifestReducer} = state;

    return {manifestReducer}
}

export default connect(mapStateToProps, {
    generalDispatcher,
    getOrderListToCreateManifestAction
})(CreateManifestHeaderComponent)
