import React from "react";
import {Colors} from "../ui-util/colors";
import {Tabs} from "antd";
import {connect} from "react-redux";
import {generalDispatcher} from "../actions/general.action";
import {TAB_REDUCER} from "../reducers/tab.reducer";
import {DC_ORDER_REDUCER} from "../reducers/dc-order.reducer";

const {TabPane} = Tabs;

class TabComponent extends React.PureComponent{

    onTabChange = (tabId) => {
        this.props.generalDispatcher(TAB_REDUCER, {activeTab: tabId})
        this.props.generalDispatcher(DC_ORDER_REDUCER, {searchKey: '', selectedRowKeys: [], selectedRowExcel: []})
    }

    render() {
        const {tabList, tabReducer, content} = this.props
        const {activeTab, disabled} = tabReducer

        return (
            <Tabs
                defaultActiveKey={activeTab}
                activeKey={activeTab}
                onChange={this.onTabChange}
                tabBarStyle={{color: Colors.darkseagreen}}
            >
                {tabList.map((tab) => (
                    <TabPane tab={tab.tab} key={tab.key} disabled={disabled}>
                        {content}
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}

const mapStateToProps = (state) => {
    const {tabReducer} = state;

    return {tabReducer};
};

export default connect(mapStateToProps, {generalDispatcher})(TabComponent)
