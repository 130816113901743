import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Table, Tag} from 'antd';
import {Link} from 'react-router-dom';
import {formatUnixDateToReadable} from "../../ui-util/general-variable";
import ReactToPrint from "react-to-print";
import PrintManifestComponent from "../../components/print-manifest.component";
import BuyerListComponent from "./buyer-list.component";
import {generalDispatcher} from "../../actions/general.action";
import {MANIFEST_REDUCER} from "../../reducers/manifest.reducer";

export class ManifestList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {title: "No. Manifest", dataIndex: 'manifestId', key: 'manifestId'},
                {
                    title: "Jasa Kirim", dataIndex: 'orderList', key: 'shippingMethod',
                    render: (orderList) => {
                        return(orderList[0].shippingMethod)
                    }
                },
                {
                    title: 'Tgl. Manifest',
                    dataIndex: 'createdAt',
                    key: 'manifestDate',
                    render: (date) => formatUnixDateToReadable(date)
                }, {
                    title: 'Status',
                    dataIndex: 'state',
                    key: 'state',
                    render: (state) => this.stateHandler(state)
                },
                {
                    title: 'Aksi',
                    key: 'action',
                    render: (record) => this.actionHandler(record)
                }
            ],
            manifestDetail: {},
            orderManifestList: [],
            readyToPrint: false
        }
    }

    actionHandler = (record) => {
        let actions = 'Complete';

        if (record.state === "PROCEED") {
            actions = 'Complete';
        } else if (record.state === "COMPLETED") {
            actions = 'Rincian';
        }
        return (
            <React.Fragment>
                <ReactToPrint
                    trigger={() => (
                        <Button type="link">
                            Print Ulang
                        </Button>
                    )}
                    content={() => this.manifestRef}
                    onBeforeGetContent={() => {
                        return new Promise((resolve, reject) => {
                            this.setState({manifestDetail: record, orderManifestList: record.orderList}, () => resolve())
                        })
                    }}
                />

                <Divider type="vertical"/>
                <Link to={`/manifest-detail/${record.id}`} target="_blank">
                    {actions}
                </Link>
            </React.Fragment>
        )
    }

    stateHandler = (state) => {
        let color = 'red';
        let label = 'ERROR';

        if (state === "PROCEED") {
            color = 'orange';
            label = 'SEDANG DIPROSES';
        } else if (state === "COMPLETED") {
            color = 'green';
            label = 'SELESAI';
        }

        return (
            <Tag color={color} key={state}>
                {label}
            </Tag>
        );
    }

    changePagination = (current) => {
        console.log(current)
        this.props.generalDispatcher(MANIFEST_REDUCER, {currentPageManifestList: current})
    }

    onShowSize = (current, pageSize) => {
        this.props.generalDispatcher(MANIFEST_REDUCER, {pageSizeManifestList: pageSize, currentPageManifestList: 1})
    }

    render() {
        const {columns, manifestDetail, orderManifestList} = this.state;
        let {manifestList, loadingManifestList, totalDataManifestList, currentPageManifestList} = this.props.manifestReducer

        return (
            <div>
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={manifestList}
                    rowKey="id"
                    loading={loadingManifestList}
                    expandedRowRender={(record) => <BuyerListComponent manifestDetail={record}/>}
                    pagination={{
                        total: totalDataManifestList,
                        onChange: this.changePagination,
                        current: currentPageManifestList,
                        onShowSizeChange: this.onShowSize,
                        showSizeChanger: true
                    }}
                />
                <div style={{display: 'none'}}>
                    <PrintManifestComponent
                        data={manifestDetail}
                        orderList={orderManifestList}
                        ref={el => (this.manifestRef = el)}
                    />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    const {manifestReducer} = state;

    return {manifestReducer};
}

export default connect(mapStateToProps, {generalDispatcher})(ManifestList)
