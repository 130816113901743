import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {AUTHORIZATION_KEY} from "./thirth-party.config";

interface OrderListQueryReqBody {
    buyerLevel: String,
    buyerId: String,
    sellerId: String,
    warehouseId: String,
    orderState: Array<String>,
    column: Array<String>,
    startDate: Number,
    tillDate: Number,
    paymentDetail: {
        transactionStatus: String
    },
    sort: 'desc' | 'asc'
}

interface OrderListQueryParams {
    showPerPage: Number,
    page: Number,
    keyword: String
}

const getOrderListQuery = (requestBody: OrderListQueryReqBody, params: OrderListQueryParams) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/query/orders/admin`,
            {
                column: ['orderId', 'awb', 'sellerId', 'buyerId', 'paymentDetail.vaNumber', 'shippingAddress.receiver'],
                ...requestBody
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getOrderAvostoreListQuery = (requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/avostore`,
            {
                ...requestBody
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const updateOrderDetailAPi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/update`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const setOrderAuditApi = (orderDetail) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/set-order-audit`,
            orderDetail,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                console.log("err setOrderAuditApi :", err)
                reject(err)
            }
        )
    })
}

const getOrderDetailFromApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/admin/get-order-detail`,
            {orderId: id},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                console.log("err getOrderDetailFromApi :", err)
                reject(err)
            }
        )
    })
}

const uploadTaxInvoiceApi = (bodyRequest) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/tax-invoice`,
            bodyRequest,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/pdf',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                console.log("err uploadTaxInvoiceApi :", err)
                reject(err)
            }
        )
    })
}

const updatePaymentDcAPi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/update/dc`,
            {...requestBody},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

const getOrderDetailByOrderIdApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/get-order-detail`,
            {orderId: id},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                console.log("err getOrderDetailFromApi :", err)
                reject(err)
            }
        )
    })
}

const getOrderListDcApi = (requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v1/orders/list/dc`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                console.log("err getOrderDetailFromApi :", err)
                reject(err)
            }
        )
    })
}

const getOrderListByManifestIdApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v2/orders/get-by-manifestId`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                console.log("err getOrderDetailFromApi :", err)
                reject(err)
            }
        )
    })
}

const getOrderListToImportResiApi = (requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/order/awb`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                },
                params
            })
            .then(function (response) {
                resolve(response.data.data)
            }).catch(function (err) {
                console.log("err getOrderDetailFromApi :", err)
                reject(err)
            }
        )
    })
}

const bulkUpdateAwbApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}api/v3/order/import-awb`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `${AUTHORIZATION_KEY}`
                }
            })
            .then(function (response) {
                resolve(response.data)
            }).catch(function (err) {
                reject(err)
            }
        )
    })
}

export {
    getOrderListQuery,
    updateOrderDetailAPi,
    setOrderAuditApi,
    getOrderAvostoreListQuery,
    getOrderDetailFromApi,
    uploadTaxInvoiceApi,
    updatePaymentDcAPi,
    getOrderDetailByOrderIdApi,
    getOrderListDcApi,
    getOrderListByManifestIdApi,
    getOrderListToImportResiApi,
    bulkUpdateAwbApi
}
