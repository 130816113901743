import {firestore} from "../index"

const historyUpdateLevelRef = firestore.collection('history_user_level');

const setHistoryUpdateUserLevelToFirestore = (historyDetail) => {
    return historyUpdateLevelRef.doc(historyDetail.id).set(historyDetail)
};

export {
    setHistoryUpdateUserLevelToFirestore,
}