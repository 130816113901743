import React from 'react';
import {signInWithGoogle} from "../../firebase/emailAuth/authentication";
import {connect} from 'react-redux';
import {userDispatcher} from "../../actions/user.action";
import {getUserDetailFromFirestore} from "../../firebase/database/user";
import {isUserRole, stringToBase64} from "../../ui-util/general-variable";
import {Row} from "antd";
import {Colors} from "../../ui-util/colors";

class SignIn extends React.Component {

    responseGoogle = () => {
        signInWithGoogle()
            .then(result => {
                getUserDetailFromFirestore(result.user.uid)
                    .then(response => {
                        let userDetail = response.data()
                        let userRoleEncrypted = stringToBase64(userDetail?.userRole)
                        if (isUserRole(userRoleEncrypted)) {
                            localStorage.setItem('AVOSTORE_UID', userDetail?.id)
                            localStorage.setItem('AVOSTORE_ROLE', userRoleEncrypted)
                            window.location.reload()
                        } else {
                            alert('Maaf anda tidak memiliki akses!!')
                        }
                    })
                    .catch(err => {
                        console.log('Error getUserDetailFromFirestore', err)
                        alert('User tidak ditemukan')
                    })
            })
            .catch(err => {
                console.log('Error signInWithGoogle', err)
                alert('Maaf terjadi kesalahan')
            })

    }

    render() {
        return (
            <div className="App" style={{marginTop: '50px'}}>
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <Row justify={'center'}>
                            <img src={require('../../images/avo_icon.png')} alt="Smiley face" width={468 / 2}
                                 height={76 / 2}/>
                        </Row>
                        <button
                            style={{marginTop: 50, backgroundColor: Colors.darkseagreen, borderWidth: 0}}
                            onClick={this.responseGoogle}
                            type="button" className="btn btn-primary btn-block">
                            Sign In with Google
                        </button>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {userDispatcher})(SignIn);
