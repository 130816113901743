import moment from "moment";

const INITIAL_STATE = {
    monthSelected: moment(),
    dcId: '',
    dcName: '',
    cashbackDcList: [],
    topLineNetCashValue: 0,
    potentialCashback: 0,
    handlingFee: 0,
    totalCashback: 0,
    potentialMarginPercentage: 0,
    loading: false
}

export const DC_CASHBACK_REDUCER = 'DC_CASHBACK_REDUCER';

export const dcCashbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DC_CASHBACK_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
